import { makeAutoObservable, runInAction } from 'mobx';
import { inputValueToPositiveFloat } from 'src/slices/AppDeal/lib';
import { formatDateSwaggerZ } from '@mx-ui/helpers';
import { toFloat } from '@mx-ui/helpers';

export interface ValueStoreProps {
  value: string; // Исходное значение

  /**
   * Задержка перед обработкой измененного через input значения
   */
  inputDelay: number;

  /**
   * Вызывается сразу после изменения в input
   * @param value
   */
  onInputChangeSynced?: (value: any) => void;

  /**
   * Вызывается после изменения значения в input через inputDelay
   * @param value
   */
  onInputChangeDebounced?: (value: any) => void;
}

export const ValueStoreInputTypes = {
  Date: 'date' as const,
  String: 'string' as const,
  Number: 'number' as const,
  PositiveFloat: 'positiveFloat' as const,
  Boolean: 'boolean' as const,
};
type ValueStoreInputTypesKeys = keyof typeof ValueStoreInputTypes;
export type ValueStoreInputTypesType = (typeof ValueStoreInputTypes)[ValueStoreInputTypesKeys];
export const castInput = (value: any, type: ValueStoreInputTypesType): string | number | boolean => {
  switch (type) {
    case ValueStoreInputTypes.PositiveFloat:
      return inputValueToPositiveFloat(value);
    case ValueStoreInputTypes.Number:
      return toFloat(value);
    case ValueStoreInputTypes.Date:
      return formatDateSwaggerZ(value);
    case ValueStoreInputTypes.Boolean:
      return Boolean(value);
  }
  return value;
};
export class ValueStore {
  private inputValue = '';
  private modelValue;

  private readonly inputDelay: number = 1;

  private readonly onInputChangeSynced: (value: any) => void;
  private readonly onInputChangeDebounced: (value: any) => void;

  private inputChangeDebounceTimer: NodeJS.Timeout;

  /**
   * Время последнего изменения в инпуте
   * @private
   */
  private lastInputChangeTimestamp: Date;

  constructor(props: ValueStoreProps) {
    this.inputValue = props.value;
    this.modelValue = props.value;
    this.inputDelay = props.inputDelay;
    this.onInputChangeSynced = props.onInputChangeSynced;
    this.onInputChangeDebounced = props.onInputChangeDebounced;
    makeAutoObservable(this);
  }

  /**
   * Вызывается из модели (или стора) например при получении новых значений с бэкенда.
   * @param value Новое значение с сервера
   * @param requestTime Время отправки запроса к серверу, на который получено это значение
   */
  handleModelChange(value: any, requestTime: Date): void {
    runInAction(() => {
      if (!this.lastInputChangeTimestamp) {
        // Значение в инпуте еще не менялось, значит можно просто перезаписать.
        this.modelValue = value;
        this.inputValue = value;
      } else if (this.lastInputChangeTimestamp < requestTime) {
        this.modelValue = value;
        this.inputValue = value;
      } else {
        // ignore value from server
      }
    });
  }

  /**
   * Вызывается из input при изменении значения
   * @param value
   */
  handleInputChange(value: string): void {
    this.lastInputChangeTimestamp = new Date();
    this.inputValue = value;
    if (this.onInputChangeSynced) {
      this.onInputChangeSynced(value);
    }
    this.triggerInputChangeDebounce();
  }

  /**
   * Запуск отложенной реакции на изменение значения через поле ввода.
   */
  triggerInputChangeDebounce(): void {
    if (this.inputChangeDebounceTimer) {
      clearTimeout(this.inputChangeDebounceTimer);
    }
    this.inputChangeDebounceTimer = setTimeout(() => {
      if (this.onInputChangeDebounced) {
        this.onInputChangeDebounced(this.inputValue);
      }
    }, this.inputDelay);
  }

  asBoolean(): boolean {
    return Boolean(this.inputValue);
  }
  asValue(): Date {
    return (this.inputValue as any) instanceof Date ? (this.inputValue as any) : new Date(this.inputValue);
  }
  asString(): string {
    return this.inputValue === undefined ? '' : String(this.inputValue);
  }
}
