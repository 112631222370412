export const MIMEText = ['text/plain', 'text/csv', 'text/markdown'];
export const MIMEDocument = [
  'application/pdf', // .pdf
  'application/vnd.ms-outlook', // .msg
  '.msg', // .msg
  'application/rtf',
  'text/rtf', // .rtf
  'application/msword', // .doc
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx
  'application/vnd.ms-word.document.macroEnabled.12', // .docm
  'application/vnd.openxmlformats-officedocument.wordprocessingml.template', // .dotx
  'application/vnd.ms-word.template.macroEnabled.12', // .dotm
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // .xlsx
  'application/vnd.ms-excel.sheet.binary.macroEnabled.12', // .xlsb
  'application/vnd.ms-excel', // .xls
  'application/vnd.ms-excel.sheet.macroEnabled.12', // .xlsm
  'application/vnd.ms-powerpoint', // .ppt
  'application/vnd.ms-powerpoint.presentation.macroEnabled.12', // .pptm
  'application/vnd.openxmlformats-officedocument.presentationml.presentation', // .pptx
];
export const MIMEImage = [
  'image/gif',
  'image/jpeg',
  'image/pjpeg',
  'image/png',
  'image/svg+xml',
  'image/tiff',
  'image/vnd.microsoft.icon',
  'image/vnd.wap.wbmp',
  'image/webp',
  'image/bmp',
];
