import React, { FC, JSX, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { BillItemStore } from '../../store/Documents/BillItemStore';
import Grid from '@mui/material/Grid';
import { BillReserveButtons } from './BillReserveButtons';
import Typography from '@mui/material/Typography';
import { SwitcherListItem, ThreeStateSwitcher } from './ThreeStateSwitcher';

interface BillSummaryReserveControlProps {
  billStore: BillItemStore;
  minHeightForTabSections: string;
}

export const BillSummaryReserveControl: FC<BillSummaryReserveControlProps> = observer(
  ({ billStore, minHeightForTabSections }): JSX.Element => {
    const [switcherList, setSwitcherList] = useState<SwitcherListItem[]>(() => {
      const state = billStore.autoReserveSettings?.autoReserveState;
      return [
        { isCurrent: state === 'disabled', value: 'disabled', type: 'left' },
        { isCurrent: state === 'intermediate', value: 'intermediate', type: 'middle' },
        { isCurrent: state === 'enabled', value: 'enabled', type: 'right' },
      ];
    });
    useEffect(() => {
      setSwitcherList(() => {
        const state = billStore.autoReserveSettings?.autoReserveState;
        return [
          { isCurrent: state === 'disabled', value: 'disabled', type: 'left' },
          { isCurrent: state === 'intermediate', value: 'intermediate', type: 'middle' },
          { isCurrent: state === 'enabled', value: 'enabled', type: 'right' },
        ];
      });
    }, [billStore?.autoReserveSettings?.autoReserveState]);

    const isBillFromMx = !!billStore.item.isMX;
    const controlEnabled = billStore.item?.state?.code === 'partially_reserved' || billStore.item?.state?.code === 'reserve_withdrawn';
    const areBtnActive = isBillFromMx && controlEnabled && billStore.item?.hasUpdateAccess;
    const handleSwitchChange = (value: string): void => {
      billStore.updateAutoReserveSettingsForBill(value === 'enabled');
    };

    return (
      <Grid container minHeight={minHeightForTabSections}>
        <Grid item xs={12} container gap={1}>
          <BillReserveButtons
            billStore={billStore}
            disabled={billStore.autoReserveSettings?.autoReserveState === 'enabled'}
            onReserved={() => billStore.requestPositionReserve()}
            onUnReserved={() => billStore.requestReserveWithdraw()}
          />
        </Grid>
        <Grid item xs={12} container alignItems={'center'}>
          <Grid item xs={7}>
            <Typography color="text.secondary" variant="body2">
              Автоматическое резервирование
            </Typography>
          </Grid>
          <Grid item xs={5}>
            <Grid item xs={12}>
              <ThreeStateSwitcher
                switcherList={switcherList}
                setSwitcherList={setSwitcherList}
                onChange={handleSwitchChange}
                disabled={billStore.isLoading || billStore.isEditing || !areBtnActive || !billStore?.autoReserveSettings?.autoReserveState}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
);
