import React, { FC, useState } from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { red, blueGrey, lightGreen } from '@mui/material/colors';
import { observer } from 'mobx-react';
import { DealApprovalResolutionGroupReason, DealApprovalStateEnum } from 'src/api/marketx';
import { AppUserGroupCodes } from 'src/types/AppUserGroup';
import { ApprovalStoreInterface } from 'src/store/DealItemStore';
import { toJS } from 'mobx';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';

import Grid from '@mui/material/Grid';
const colors: { [key: string]: SxProps<Theme> } = {
  true: { bgcolor: lightGreen[600], color: 'white', border: `1px solid ${lightGreen[800]}` },
  false: {
    backgroundColor: 'transparent',
    color: theme => (theme.palette.mode === 'dark' ? blueGrey[200] : blueGrey[900]),
    border: theme => (theme.palette.mode === 'dark' ? `1px solid ${blueGrey[200]}` : `1px solid ${blueGrey[900]}`),
  },
};
const resolutionByCodeMapper = (i: DealApprovalResolutionGroupReason, shortName: string, resolutionByCode: any): void => {
  if (Array.isArray(resolutionByCode[shortName])) {
    resolutionByCode[shortName].push(i);
  } else {
    resolutionByCode[shortName] = [i];
  }
};
export const IconApproval: FC<{ approvalStore: ApprovalStoreInterface }> = observer(({ approvalStore }) => {
  const approval = approvalStore.approval ?? {};
  const [anchorEl, setAnchorEl] = useState(null);
  const [shortName, setShortName] = useState('');
  const [name, setName] = useState('');
  const handleTooltipOpen = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, shortName: string, name: string): void => {
    event.stopPropagation();
    setAnchorEl(event.target);
    setShortName(shortName);
    setName(name);
  };
  const handleTooltipClose = (): void => {
    setAnchorEl(null);
    setShortName('');
    setName('');
  };
  if (!approval?.controlVisible) {
    return null;
  }
  const circles = [];
  const { resolutionPossibleGroups, resolutionMinimumGroup, resolutionGroupReasons: resGroupReasons } = approval;
  const resolutionGroupReasons = toJS(resGroupReasons) || [];
  const stateCode = approvalStore.stateCode();

  const colorForOffice =
    colors[
      String(
        stateCode === DealApprovalStateEnum.AwaitingDivision ||
          stateCode === DealApprovalStateEnum.AwaitingCompany ||
          stateCode === DealApprovalStateEnum.Approved ||
          stateCode === DealApprovalStateEnum.SelfApproved
      )
    ];
  const colorForGroup = colors[String(stateCode === DealApprovalStateEnum.Approved || stateCode === DealApprovalStateEnum.SelfApproved)];

  const colorForDepartment =
    colors[
      String(
        stateCode === DealApprovalStateEnum.Awaiting ||
          stateCode === DealApprovalStateEnum.AwaitingOffice ||
          stateCode === DealApprovalStateEnum.AwaitingDivision ||
          stateCode === DealApprovalStateEnum.AwaitingCompany ||
          stateCode === DealApprovalStateEnum.Approved ||
          stateCode === DealApprovalStateEnum.SelfApproved
      )
    ];

  switch (resolutionMinimumGroup || '') {
    case AppUserGroupCodes.COMPANY_CHIEF:
      circles.push({
        shortName: 'О',
        name: 'Отдел',
        style: colorForDepartment,
      });
      circles.push({
        shortName: 'Ф',
        name: 'Филиал',
        style: colorForOffice,
      });
      circles.push({
        shortName: 'Д',
        name: 'Дивизион',
        style:
          colors[
            String(
              stateCode === DealApprovalStateEnum.AwaitingCompany ||
                stateCode === DealApprovalStateEnum.Approved ||
                stateCode === DealApprovalStateEnum.SelfApproved
            )
          ],
      });
      circles.push({
        shortName: 'К',
        name: 'Компания',
        style: colorForGroup,
      });
      break;
    case AppUserGroupCodes.DIVISION_CHIEF:
      circles.push({
        shortName: 'О',
        name: 'Отдел',
        style: colorForDepartment,
      });
      circles.push({
        shortName: 'Ф',
        name: 'Филиал',
        style: colorForOffice,
      });
      circles.push({
        shortName: 'Д',
        name: 'Дивизион',
        style: colorForGroup,
      });
      break;
    case AppUserGroupCodes.OFFICE_CHIEF:
      circles.push({
        shortName: 'О',
        name: 'Отдел',
        style: colorForDepartment,
      });
      circles.push({
        shortName: 'Ф',
        name: 'Филиал',
        style: colorForGroup,
      });
      break;
    case AppUserGroupCodes.SELLER_CHIEF:
      circles.push({
        shortName: 'О',
        name: 'Отдел',
        style: colorForGroup,
      });
      break;
    case AppUserGroupCodes.SELLER:
      circles.push({
        shortName: 'М',
        name: 'Менеджер',
        style: colorForGroup,
      });
      break;
    case AppUserGroupCodes.AGREEMENT_APPROVING:
      circles.push({
        shortName: 'С',
        name: 'Менеджер соглашений',
        style: colorForGroup,
      });
      break;
    case '':
      // Никто не может согласовать
      break;
    default:
      // Может согласовать неизвестная группа
      circles.push({
        shortName: 'X',
        name: resolutionMinimumGroup,
        style: colorForGroup,
      });
  }

  if (!resolutionPossibleGroups?.length) {
    circles.push({
      shortName: '—',
      name: '',
      style: {
        bgcolor: red[500],
        border: theme => (theme.palette.mode === 'dark' ? `1px solid ${blueGrey[200]}` : `1px solid ${blueGrey[900]}`),
      },
    });
  }
  const resolutionByCode = {};
  if (resolutionGroupReasons?.length) {
    resolutionGroupReasons.forEach(i => {
      switch (i.userGroupCode || '') {
        case AppUserGroupCodes.COMPANY_CHIEF:
          resolutionByCodeMapper(i, 'К', resolutionByCode);
          break;
        case AppUserGroupCodes.DIVISION_CHIEF:
          resolutionByCodeMapper(i, 'Д', resolutionByCode);
          break;
        case AppUserGroupCodes.OFFICE_CHIEF:
          resolutionByCodeMapper(i, 'Ф', resolutionByCode);
          break;
        case AppUserGroupCodes.SELLER_CHIEF:
          resolutionByCodeMapper(i, 'О', resolutionByCode);
          break;
        case AppUserGroupCodes.SELLER:
          resolutionByCodeMapper(i, 'М', resolutionByCode);
          break;
        default:
          resolutionByCodeMapper(i, 'Х', resolutionByCode);
          break;
      }
    });
  }

  return (
    <Box display={'flex'} alignItems={'flex-end'} sx={{ marginRight: 0.5 }}>
      {circles.map((circle, index) => (
        <Box
          key={index}
          sx={{ marginRight: 0.5 }}
          onMouseEnter={event => resolutionByCode[circle.shortName] && handleTooltipOpen(event, circle.shortName, circle.name)}
          onMouseLeave={() => handleTooltipClose()}
        >
          <Avatar title={resolutionByCode[circle.shortName] ? '' : circle.name} sx={{ ...circle.style, width: '36px', height: '36px' }}>
            <Typography style={{ fontWeight: 500, fontSize: 18 }}>{circle.shortName}</Typography>
          </Avatar>
        </Box>
      ))}
      <Popper placement="bottom" open={!!anchorEl && !!shortName} anchorEl={anchorEl} sx={{ zIndex: 1200 }}>
        <Paper
          sx={{
            padding: 1,
          }}
        >
          <Grid container flexDirection={'column'}>
            {resolutionByCode[shortName]?.map((i, index) => {
              return (
                <Grid key={index} item mb={index === resolutionByCode[shortName].length - 1 ? 0 : 1}>
                  <Grid container alignItems={'center'} spacing={1}>
                    <Grid item>
                      <Typography color="text.secondary">{name}</Typography>
                    </Grid>
                    <Grid item>
                      <Typography color="text.secondary">-</Typography>
                    </Grid>
                    <Grid item>
                      <Typography color="text.secondary">{i.reason}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </Paper>
      </Popper>
    </Box>
  );
});
