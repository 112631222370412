import { makeAutoObservable, runInAction } from 'mobx';
import { ParticipantInteraction, ParticipantInteractionsResponse, ParticipantInteractionsSaveRequest } from 'src/api/marketx';
import { ApiStore } from '../Global/ApiStore';
import { RootStore } from '../StoreManager';
import { AxiosCallContext, getCallContext } from '../../utils/axiosInit';
import { AxiosResponse } from 'axios';
export type InteractionsListRequest = {
  clientCode?: string; // код клиента
  page?: number;
  count?: number;
};
export const defaultInteractionsPageSize = 20;
export class InteractionsListStore {
  apiStore: ApiStore;
  svc: InteractionsListService;
  clientCode = '';
  interactions: ParticipantInteraction[] = [];
  request: InteractionsListRequest = { clientCode: '' };
  hasCreateAccess = false;
  hasEditAccess = false;
  total = 0;
  isLoaded = false;
  isLoading = false;
  isMoreLoading = false;
  hasMore = false;

  constructor(rootStore: RootStore) {
    this.apiStore = rootStore.getApiStore();
    this.svc = new InteractionsListService();
    makeAutoObservable(this, {
      apiStore: false,
    });
  }

  loadMore(): void {
    if (this.isLoading) {
      // уже загружается
      return;
    }
    this.isMoreLoading = true;
    if (this.request) {
      this.request.page = (this.request?.page || 1) + 1;
    }
    this.load(this.request);
  }

  load(req: InteractionsListRequest): void {
    runInAction(() => {
      this.request = req;
      if (!this.clientCode && req.clientCode) {
        this.clientCode = req.clientCode;
      }

      this.svc.load(this);
    });
  }
  /**
   * Создание и редактирование Взаимодействий
   * если есть code то редактирование иначе создание
   */
  saveInteractions(interaction: ParticipantInteractionsSaveRequest): Promise<AxiosResponse<ParticipantInteraction>> {
    return this.apiStore.apiClientParticipant().participantInteractionsSave(interaction);
  }
  setResultInteractions(ctx: AxiosCallContext, { data }: AxiosResponse<ParticipantInteractionsResponse>): void {
    runInAction(() => {
      if (this.request.page === 1 || !this.request.page) {
        this.interactions = data.interactions || [];
      } else {
        this.interactions.push(...data.interactions);
      }
      this.hasEditAccess = data.hasEditAccess;
      this.hasCreateAccess = data.hasCreateAccess;
      this.isLoading = false;
      this.isMoreLoading = false;
      this.isLoaded = true;
      this.hasMore = data.interactions.length >= this.request.count;
    });
  }
}

export class InteractionsListService {
  load(store: InteractionsListStore): void {
    runInAction(() => {
      store.isLoading = true;
    });
    store.apiStore
      .apiClientParticipant()
      .participantInteractions(undefined, store.request.clientCode, store.request.page || undefined, store.request.count || undefined)
      .then((res): void => {
        store.setResultInteractions(getCallContext(res), res);
      });
  }
}
