import Box from '@mui/material/Box';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

import Typography from '@mui/material/Typography';
import { FC } from 'react';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import LightTooltip from 'src/components/ToolTip/LightTooltip';
import { observer } from 'mobx-react-lite';
import { TaskPriorityItem } from 'src/api/marketx';

//* example https://codesandbox.io/s/ym3rvj1z71?file=/src/primatives/quote-list.js:464-873
export const TwoLineHorizontal: FC<{ fontSize?: SvgIconProps['fontSize']; htmlColor?: string; titleAccess?: string }> = ({
  htmlColor,
}): JSX.Element => (
  <SvgIcon htmlColor={htmlColor}>
    <g>
      <rect fill="none" fillRule="evenodd" height="24" width="24" />
      <g>
        <rect xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" height="2" width="12" x="6" y="8" />
        <rect xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" height="2" width="12" x="6" y="14" />
      </g>
    </g>
  </SvgIcon>
);

export const iconByCodePriority = (code: string): JSX.Element => {
  if (code === 'highest') {
    return <KeyboardDoubleArrowUpIcon htmlColor="#ff7452" />;
  } else if (code === 'lowest') {
    return <KeyboardDoubleArrowDownIcon htmlColor="#0065ff" />;
  } else if (code === 'high') {
    return <KeyboardArrowUpIcon htmlColor="#ff5630" />;
  } else if (code === 'low') {
    return <KeyboardArrowDownIcon htmlColor="#0065ff" />;
  } else if (code === 'medium') {
    return <TwoLineHorizontal htmlColor="#ffab00" />;
  }
};
interface PrioritiesIconProps {
  priority: TaskPriorityItem;
}
export const PrioritiesIcon: FC<PrioritiesIconProps> = observer(({ priority }): JSX.Element => {
  const { code, title = '' } = priority;
  return (
    <LightTooltip
      arrow
      placement="bottom"
      title={<Box p={0.5}>{title && <Typography variant="caption">{title} Приоритет</Typography>}</Box>}
    >
      <Box color="red" display={'flex'} alignSelf="center">
        {iconByCodePriority(code)}
      </Box>
    </LightTooltip>
  );
});
//   {
// 	"priorities": [
// 		{
// 			"code": "highest",
// 			"title": "Наивысший"
// 		},
// 		{
// 			"code": "high",
// 			"title": "Высокий"
// 		},
// 		{
// 			"code": "medium",
// 			"title": "Средний"
// 		},
// 		{
// 			"code": "low",
// 			"title": "Низкий"
// 		},
// 		{
// 			"code": "lowest",
// 			"title": "Самый низкий"
// 		}
// 	]
// }
