import { makeAutoObservable, runInAction } from 'mobx';
import { AxiosCallContext, getCallContext } from '../../utils/axiosInit';
import { CatalogProductAccordionNomenclatureResponse } from '../../api/marketx';
import { ApiStore } from '../Global/ApiStore';
import { RootStore } from '../StoreManager';

export class CatalogItemNomenclatureStore {
  apiStore: ApiStore;

  ignoreBeforeDate?: Date;
  isLoaded = false;
  isLoading = false;

  productCode: string;
  warehouseCode: string;

  item: CatalogProductAccordionNomenclatureResponse = null;

  constructor(rootStore: RootStore) {
    this.apiStore = rootStore.getApiStore();
    makeAutoObservable(this, {
      apiStore: false,
    });
  }

  loadForProduct(productCode: string, warehouseCode: string): void {
    if (this.productCode === productCode && this.warehouseCode === warehouseCode) {
      return;
    }
    runInAction(() => {
      this.ignoreBeforeDate = new Date();
      this.productCode = productCode;
      this.warehouseCode = warehouseCode;
      this.isLoading = true;
      this.runLoad();
    });
  }

  runLoad(): void {
    this.apiStore
      .apiClientCatalog()
      .catalogProductAccordionNomenclature({ productCode: this.productCode, warehouseCode: this.warehouseCode })
      .then((res): void => {
        this.setResult(getCallContext(res), res.data);
      });
  }

  setResult(ctx: AxiosCallContext, res: CatalogProductAccordionNomenclatureResponse): void {
    if (this.ignoreBeforeDate && this.ignoreBeforeDate.getTime() > ctx.startTime.getTime()) {
      return;
    }
    this.ignoreBeforeDate = ctx.startTime;
    this.item = res;
    this.isLoaded = true;
    this.isLoading = false;
  }
}
