import React, { FC } from 'react';
import Grid, { GridSize } from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

interface CustomCardProps {
  xs?: GridSize;
  md?: GridSize;
  children?: React.ReactNode;
  align?: any;
  noBackground?: boolean;
  style?: any;
  fullWidth?: boolean;
  card?: boolean;
}

const CustomCard: FC<CustomCardProps> = ({ xs, md, children, align, noBackground, style, fullWidth, card }): JSX.Element => {
  let padding = '12px';
  if (!!fullWidth || !!noBackground) {
    padding = '0px';
  }

  return (
    <Grid item md={md} xs={xs} style={{ padding: card ? '6px' : '0px' }}>
      <Paper
        sx={{
          // border: 'solid 1px',
          cursor: 'default',
          textAlign: align ? align : 'unset',
          height: '100%',
          padding: padding,
          // backgroundColor: noBackground ? 'unset' : '#fff',
          ...style,
        }}
        elevation={0}
      >
        {children}
      </Paper>
    </Grid>
  );
};

export default CustomCard;
