import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { formatDateYmdHis } from '@mx-ui/helpers';
import { FC } from 'react';
import ErrorPage from 'src/assets/svg/404';
import { useAuthStore } from 'src/store/MobxStoreProvider';
import Button from '@mui/material/Button';
import { FeedbackButton } from './DashboardLayout/FeedbackButton';
import { useRouter } from 'next/router';

export const ErrorBoundaryFallback: FC<{
  error: Error;
  componentStack: string;
  resetError(): void;
}> = ({ error, resetError }): JSX.Element => {
  const date = new Date();
  const auth = useAuthStore();
  const router = useRouter();
  return (
    <Grid container flexDirection={'row'} justifyContent={'center'} p={3}>
      <Grid item xs={'auto'} container flexDirection={'column'}>
        <Grid item alignSelf={'center'}>
          <ErrorPage />
        </Grid>
        <Typography variant="body1" color="textPrimary">
          Произошла ошибка ({error.toString()})
        </Typography>
        <Typography variant="body1" color="textPrimary">
          Время: {formatDateYmdHis(date)}
        </Typography>
        <Typography variant="body1" color="textPrimary">
          Пользователь: {auth.profile.employee.surname || ''} {auth.profile.employee.name || ''} {auth.profile.employee.patronymic || ''}
        </Typography>
        <Typography variant="body1" color="textPrimary">
          {auth.profile.email}
        </Typography>
        <Typography variant="body1" color="textPrimary">
          {document?.location?.href || router.asPath}
        </Typography>
        <Grid container flexDirection={'column'}>
          <Box sx={{ margin: '12px auto' }}>
            <FeedbackButton />
          </Box>
          <Button variant={'contained'} size="small" onClick={resetError} sx={{ mt: 6 }}>
            Обновить страницу
          </Button>
        </Grid>

        {/* <div>
          <pre>{componentStack}</pre>
        </div> */}
      </Grid>
    </Grid>
  );
};
