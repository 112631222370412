import React, { FC, useRef, useState } from 'react';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { formatPriceCur } from '@mx-ui/helpers';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { CustomPopper } from '../../../views/deals/components/CustomPopper';
import { useAuthStore } from '../../../store/MobxStoreProvider';
import { observer } from 'mobx-react-lite';
import { DealMotivation, SaleShipmentMotivation } from '../../../api/marketx';
import { totalMotivationDictionary } from '../../../slices/AppDeal/lib';
import { PositionMotivationItem } from './PositionMotivationList';
import { motivationAccessFunc } from 'src/components/DashboardLayout/NavBar/mainSections';

interface PositionMotivationTotalProps {
  motivation: DealMotivation | SaleShipmentMotivation;
  isForDeal?: boolean;
}

export const PositionMotivationTotal: FC<PositionMotivationTotalProps> = observer(({ motivation, isForDeal = false }): JSX.Element => {
  const authStore = useAuthStore();
  const totalPremiumAmount = motivation?.totalPremiumAmount || 0;
  const motivationBtnRef = useRef();
  const [isMotivationDialogOpen, setIsMotivationDialogOpen] = useState(false);
  const optionalMotivation = { p1Amount: 0, p2Amount: 0, ...motivation };
  const handleMotivationDialogClick = (): void => {
    setIsMotivationDialogOpen(isMotivationDialogOpen => !isMotivationDialogOpen);
  };
  const handleMotivationDialogClose = (): void => {
    setIsMotivationDialogOpen(false);
  };
  const motivationAccess = motivationAccessFunc(authStore);
  let backgroundColor = '#19B28D';

  const p1 = optionalMotivation?.p1Amount || 0;
  const p2 = optionalMotivation?.p2Amount || 0;
  const bss = optionalMotivation?.selfPurchaseAmount || 0;
  const bsc = optionalMotivation?.centralPurchaseAmount || 0;
  if ((p1 === 0 && p2 + bss + bsc !== 0) || (p1 !== 0 && p2 + bss + bsc === 0)) {
    backgroundColor = '#F88545';
  }
  if (p1 === 0 && p2 + bss + bsc === 0) {
    backgroundColor = '#ee505a';
  }

  return motivationAccess ? (
    <>
      <Grid container width={'100%'}>
        <Grid item sx={{ backgroundColor, borderRadius: '5px' }} flex={'0 1 auto'} mb={1} ref={motivationBtnRef}>
          <Grid container>
            <Grid item>
              <Typography style={{ fontSize: '14px', padding: '10px 5px', color: '#fff' }}>{`${
                isForDeal ? 'Прогноз премии:' : 'Премия:'
              } ${formatPriceCur(totalPremiumAmount || 0)}`}</Typography>
            </Grid>
            <Grid item ml={2}>
              <IconButton onClick={handleMotivationDialogClick}>
                <InfoOutlinedIcon sx={{ color: '#fff' }} />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <CustomPopper
        isOpen={isMotivationDialogOpen}
        anchorEl={motivationBtnRef.current}
        onClose={handleMotivationDialogClose}
        style={{ minWidth: '100px' }}
        placement={'top-start'}
      >
        <Grid container>
          {Object.keys(totalMotivationDictionary).map(motivationItem => (
            <PositionMotivationItem
              key={motivationItem}
              item={motivationItem}
              motivation={optionalMotivation}
              dictionary={totalMotivationDictionary}
            />
          ))}
        </Grid>
      </CustomPopper>
    </>
  ) : null;
});
