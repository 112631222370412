import { createContext, ReactNode, useContext, useState } from 'react';
import { RootStore } from './StoreManager';
import { AuthStore } from './AuthStore';
import { useSnackbar } from 'notistack';
import { useRouter } from 'next/router';

// create the context for root store
const StoreContext = createContext<RootStore | undefined>(undefined);

export function MobxStoreProvider({ root, children }: { root: RootStore; children: ReactNode }): JSX.Element {
  return <StoreContext.Provider value={root}>{children}</StoreContext.Provider>;
}

// create the hook
export function useRootStore(): RootStore {
  const rootStore = useContext(StoreContext);
  if (!rootStore) {
    throw new Error('useRootStore must be used within MobxStoreProvider');
  }
  rootStore.getSnackbar().setProviderContext(useSnackbar());
  rootStore.getRouter().setBaseRouter(useRouter());
  const [localRootStore] = useState<RootStore>(() => {
    return new RootStore({}, rootStore);
  });
  return localRootStore;
}

export function useAuthStore(): AuthStore {
  return useRootStore().getAuth();
}
