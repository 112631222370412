import React, { FC, useCallback, useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { DropDownButton } from 'src/views/deals/components/DropDownButton';
import { getMarginalityColor } from '../lib';
import { CustomPopper } from '../components/CustomPopper';
import Skeleton from '@mui/material/Skeleton';
import { formatNumber02, formatNumber2, formatNumber3, formatPrice02, formatPriceCur, pluralRus } from '@mx-ui/helpers';
import PanToolOutlined from '@mui/icons-material/PanToolOutlined';
import { formatMinutePeriodAsText } from '@mx-ui/helpers';
import { DealItemStore } from '../../../store/DealItemStore';
import { observer } from 'mobx-react-lite';
import { useAuthStore, useRootStore } from '../../../store/MobxStoreProvider';
import { renderPriceCur } from '../../../utils/renderFormat';
import { PaymentRangesBlock } from '../../../components/Documents/PaymentRangesBlock';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { PositionMotivationTotal } from '../../../components/Deals/Motivation/PositionMotivationTotal';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined';
import { Divider } from '@mui/material';
import { MxLabel } from '@mx-ui/ui';
import Box from '@mui/material/Box';
import { agreementDeviationLevelColors } from '../../../components/Agreements/agreementDeviationLevelColors';
import { useTheme } from '@mui/material/styles';
import { ShippedProgress } from '../../../components/Bills/BillListItem';
export const SummaryTotalSkeleton = (
  <Grid item md={12} lg={4} xs={12}>
    <Paper
      sx={{
        height: '100%',
        padding: 1,
        position: 'relative',
        borderWidth: '2px',
      }}
    >
      <Grid container flexDirection={'column'} flexWrap={'nowrap'} justifyContent={'space-between'} height={'100%'}>
        <Grid item container>
          <Grid item container justifyContent={'flex-end'} mb={1}>
            <Grid
              item
              xs={6}
              px={1}
              sx={{ border: theme => `1px solid ${theme.palette.mode === 'dark' ? '#434141' : '#cbcbcb'}`, borderRadius: '6px' }}
            >
              <Skeleton variant="text" width={'100%'} />
            </Grid>
          </Grid>
          <Grid item xs={12} container>
            <Grid item xl={6} lg={12} md={6} xs={12} p={1}>
              <Grid container p={1} sx={{ border: '1px solid #B9B9BA', borderRadius: '6px' }}>
                <Grid item xs={12} mb={1}>
                  <Skeleton variant="text" width={'100%'} />
                </Grid>
                <Grid item xs={12}>
                  <Skeleton variant="text" width={'100%'} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xl={6} lg={12} md={6} xs={12} p={1}>
              <Grid container p={1} sx={{ border: '1px solid #B9B9BA', borderRadius: '6px' }}>
                <Grid item xs={12} mb={1}>
                  <Skeleton variant="text" width={'100%'} />
                </Grid>
                <Grid item xs={12}>
                  <Skeleton variant="text" width={'100%'} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} container pl={1}>
            <Grid item xs={6} mb={1}>
              <Skeleton variant="text" width={'90%'} />
            </Grid>
            <Grid item xs={6}>
              <Skeleton variant="text" width={'50%'} />
            </Grid>
          </Grid>
          <Grid item xs={12} container pl={1}>
            <Grid item xs={6} mb={1}>
              <Skeleton variant="text" width={'90%'} />
            </Grid>
            <Grid item xs={6}>
              <Skeleton variant="text" width={'50%'} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item container pl={1} pb={1}>
          <Skeleton variant="text" width={'176px'} height={'61px'} />
        </Grid>
      </Grid>
    </Paper>
  </Grid>
);

interface DealSummaryTotalProps {
  dealStore: DealItemStore;
  setSelectedDeliveryTab: React.Dispatch<React.SetStateAction<number>>;
}

export const DealSummaryTotal: FC<DealSummaryTotalProps> = observer(({ dealStore, setSelectedDeliveryTab }): JSX.Element => {
  const theme = useTheme();
  const [daysAnchorEl, setDaysAnchorEl] = useState(null);
  const [daysOpen, setDaysOpen] = useState(false);

  const [costPopperAnchor, setCostPopperAnchor] = useState(null);
  const [costPopperOpen, setCostPopperOpen] = useState(false);

  const { profile } = useAuthStore();

  const handleDaysClose = useCallback((): void => {
    setDaysOpen(false);
  }, [setDaysOpen]);

  const handleDaysClick = (event): void => {
    setDaysAnchorEl(event.currentTarget);
    setDaysOpen(daysOpen => !daysOpen);
  };

  // Всплывающее окно с маржинальностями
  const [marginalityPopperAnchor, setMarginalityPopperAnchor] = useState(null);
  const [liftingAnchor, setLiftingAnchor] = useState(null);
  const [marginalityPopperOpen, setMarginalityPopperOpen] = useState(false);
  const [liftingTimeOpen, setLiftingTimeOpen] = useState(false);

  const handleMarginalityClick = useCallback((e): void => {
    setMarginalityPopperAnchor(e.currentTarget);
    setMarginalityPopperOpen(v => !v);
  }, []);

  const handleLiftingClick = useCallback((e): void => {
    setLiftingAnchor(e.currentTarget);
    setLiftingTimeOpen(v => !v);
  }, []);

  const handleMarginalityClose = useCallback((): void => {
    setMarginalityPopperOpen(false);
  }, [setMarginalityPopperOpen]);

  const handleLiftingClose = useCallback((): void => {
    setLiftingTimeOpen(false);
  }, [setLiftingTimeOpen]);
  const paymentStore = dealStore.getDealPaymentStore();
  const isDateNotValid = new Date(dealStore.creditStateStore.state.validUntil) < new Date();
  const deal = dealStore.deal;
  const orderedShipped = dealStore.orderedShipped;
  const outlineValue = dealStore.deal?.marginalityPct === 0 ? 'none' : `solid 2px ${getMarginalityColor(dealStore.deal?.marginalityPct)}`;
  const boxShadow =
    dealStore.deal?.marginalityPct === 0
      ? '0px 3px 2px 0px rgb(0 0 0 / 16%), 0px -1px 1px 0px rgb(0 0 0 / 12%), 0px 2px 1px 0px rgb(0 0 0 / 12%)'
      : 'none';

  const customerMarginalityPlot = useRootStore().getCustomerMarginalityChartsDataStore();
  useEffect(() => {
    if (dealStore.deal?.customerCode && dealStore?.dealCode) {
      customerMarginalityPlot.loadPlot(dealStore.deal?.customerCode, dealStore?.dealCode);
    }
  }, [dealStore, dealStore.deal?.customerCode, dealStore?.dealCode]);
  const actualMarketDeviationPct = dealStore.deal?.contractCostAgreements?.[0]?.actualMarketDeviationPct;
  const hasDeviationPct = typeof actualMarketDeviationPct === 'number';
  const { color, bgColor, status } = agreementDeviationLevelColors(actualMarketDeviationPct, theme);
  if (!dealStore.isLoaded) {
    return SummaryTotalSkeleton;
  }
  return (
    <Grid item md={12} lg={4} xs={12}>
      <Paper
        sx={{
          height: '100%',
          padding: 1,
          position: 'relative',
          outline: outlineValue,
          boxShadow: boxShadow,
        }}
      >
        <Grid container flexDirection="column" justifyContent="space-between" height="100%">
          <Grid item container>
            <Grid md={12} item xs={12} style={{ textAlign: 'right' }} pr={1}>
              <>
                <DropDownButton size="small" color={'greyMx'} showIcon onClick={handleDaysClick}>
                  {`Предоплата ${dealStore.deal?.prepayPct}%, `}
                  {deal?.postpayDays > 0
                    ? `отсрочка ${deal?.postpayDays} ${pluralRus(deal?.postpayDays || 0, ['день', 'дня', 'дней'])}`
                    : 'отсрочки нет'}
                </DropDownButton>

                <CustomPopper isOpen={daysOpen} anchorEl={daysAnchorEl} onClose={handleDaysClose}>
                  <PaymentRangesBlock paymentStore={paymentStore} isDateNotValid={isDateNotValid} />
                </CustomPopper>
              </>
            </Grid>

            {(deal?.prepayPct ?? 0) < 100 ? (
              <Grid item xs={12} style={{ textAlign: 'right', minHeight: '36px' }} pr={1}>
                <Typography color={'text.secondary'} variant="caption">
                  стоимость услуги
                </Typography>
                <Typography component={'div'} fontWeight={600} color={'text.successMx'} variant="caption">
                  {formatPriceCur(deal?.creditCost)}
                </Typography>
              </Grid>
            ) : (
              <Grid item xs={12} mb={1}></Grid>
            )}

            <Grid item container xs={12}>
              <Grid item xl={6} lg={12} md={6} xs={12} p={1}>
                <Grid
                  container
                  p={1}
                  alignItems={'center'}
                  flexWrap={'nowrap'}
                  sx={{ border: `1px solid ${getMarginalityColor(deal?.marginalityPct)}`, borderRadius: '6px' }}
                >
                  <Grid item container>
                    <Grid item xs={12}>
                      <Typography variant="body1" fontWeight={'bold'} color={getMarginalityColor(deal?.marginalityPct)}>
                        Маржинальность
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body1" fontWeight={'bold'} color={getMarginalityColor(deal?.marginalityPct)}>
                        {`${formatNumber2(deal?.marginalityPct || 0)}% (${formatPriceCur(deal?.managerProfitMarga || 0)})`}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <IconButton onClick={handleMarginalityClick} size="small" style={{ padding: '0px', marginTop: '-4px' }}>
                      <KeyboardArrowDownIcon htmlColor={getMarginalityColor(deal?.marginalityPct)} />
                    </IconButton>
                  </Grid>
                  <CustomPopper
                    isOpen={marginalityPopperOpen}
                    anchorEl={marginalityPopperAnchor}
                    onClose={handleMarginalityClose}
                    style={{ width: '340px' }}
                  >
                    <Grid container spacing={1}>
                      <Grid item xs={6}>
                        <Typography fontSize={'14px'}>От МРЦ</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography style={{ fontSize: '14px', fontWeight: 700 }}>
                          {formatNumber2(deal?.marginalityPct)}% ({renderPriceCur(deal?.managerProfitMarga)})
                        </Typography>
                      </Grid>

                      <Grid item xs={6}>
                        <Typography fontSize={'14px'}>От прайслиста</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography style={{ fontSize: '14px', fontWeight: 700 }}>
                          {formatNumber2(deal?.priceListCostMarginPct)}%
                          {(deal?.priceListCostMargin > 0 || deal?.priceListCostMargin < 0) && (
                            <> ({renderPriceCur(deal?.priceListCostMargin)})</>
                          )}
                        </Typography>
                      </Grid>

                      {profile?.features?.canSeeDealPrimeCostMargin && (
                        <>
                          <Grid item xs={6}>
                            <Typography fontSize={'14px'}>От цены закупки</Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography style={{ fontSize: '14px', fontWeight: 700 }}>
                              {formatNumber2(deal?.purchaseCostMarginPct)}% ({renderPriceCur(deal?.purchaseCostMargin)})
                            </Typography>
                          </Grid>
                        </>
                      )}

                      {profile?.features?.canSeeDealPrimeCostMargin && (
                        <>
                          <Grid item xs={6}>
                            <Typography fontSize={'14px'}>От ПлC</Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography style={{ fontSize: '14px', fontWeight: 700 }}>
                              {formatNumber2(deal?.primeCostMarginPct)}% ({renderPriceCur(deal?.primeCostMargin)})
                            </Typography>
                          </Grid>
                        </>
                      )}
                      {customerMarginalityPlot.plot?.middleVal && (
                        <Grid item xs={12} container alignItems={'center'}>
                          <Grid item xs={12}>
                            <Divider />
                          </Grid>
                          <Grid item xs={6}>
                            <Typography fontSize={'14px'}>Средняя</Typography>
                          </Grid>
                          <Grid item xs={6} container alignItems={'center'} justifyContent={'space-between'} pl={0.5}>
                            <Typography style={{ fontSize: '14px', fontWeight: 700 }}>
                              {formatPrice02(customerMarginalityPlot.plot?.middleVal)} %
                            </Typography>
                            <IconButton size={'small'} color={'secondary'} onClick={() => setSelectedDeliveryTab(6)}>
                              <BarChartOutlinedIcon />
                            </IconButton>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  </CustomPopper>
                </Grid>
              </Grid>

              <Grid item xl={6} lg={12} md={6} xs={12} p={1} style={{ textAlign: 'left' }}>
                <Grid
                  container
                  p={1}
                  alignItems={'center'}
                  flexWrap={'nowrap'}
                  sx={{ border: '1px solid #B9B9BA', borderRadius: '6px', height: '100%' }}
                >
                  <Grid item container>
                    <Grid item xs={12}>
                      <Grid container flexWrap="nowrap" alignItems="center">
                        <Tooltip title={'Сумма заявки, включая услуги с НДС'} arrow placement="top">
                          <Typography variant="body1" fontWeight={'bold'} color={'text.secondary'}>
                            Сумма заявки
                          </Typography>
                        </Tooltip>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body1" fontWeight={'bold'} color={'text.secondary'}>
                        {formatPriceCur(deal?.totalPrice || 0)}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <IconButton
                      onClick={e => {
                        setCostPopperAnchor(e.currentTarget);
                        setCostPopperOpen(!costPopperOpen);
                      }}
                      size="small"
                      style={{ padding: '0px', marginTop: '-4px' }}
                    >
                      <KeyboardArrowDownIcon htmlColor={'text.secondary'} />
                    </IconButton>
                  </Grid>
                </Grid>

                <CustomPopper
                  isOpen={costPopperOpen}
                  anchorEl={costPopperAnchor}
                  onClose={() => setCostPopperOpen(false)}
                  style={{ width: '300px' }}
                >
                  <Grid container spacing={1}>
                    <Grid item xs={8}>
                      <Typography fontSize={'14px'}>Стоимость товаров</Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography style={{ fontSize: '14px', fontWeight: 700 }}>{formatPriceCur(deal?.productsPrice)}</Typography>
                    </Grid>

                    <Grid item xs={8}>
                      <Typography fontSize={'14px'}>Стоимость услуг</Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography style={{ fontSize: '14px', fontWeight: 700 }}>
                        {formatPriceCur(deal?.servicesPrice)}
                        {deal?.useManualServicesCost && (
                          <>
                            &nbsp;
                            <PanToolOutlined fontSize="inherit" sx={{ verticalAlign: 'middle' }} />
                          </>
                        )}
                      </Typography>
                    </Grid>

                    <Grid item xs={8}>
                      <Typography fontSize={'14px'}>Стоимость кредитования</Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography style={{ fontSize: '14px', fontWeight: 700 }}>{formatPriceCur(deal?.creditCost)}</Typography>
                    </Grid>

                    <Grid item xs={8}>
                      <Typography fontSize={'14px'}>Стоимость доставки</Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography style={{ fontSize: '14px', fontWeight: 700 }}>
                        {(deal?.transport?.totalCost > 0 && formatPriceCur(deal?.transport?.totalCost)) || 'Бесплатно'}
                        {deal?.useManualDeliveryCost && (
                          <>
                            &nbsp;
                            <PanToolOutlined fontSize="inherit" sx={{ verticalAlign: 'middle' }} />
                          </>
                        )}
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography fontSize={'14px'}>Сумма Акции</Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography style={{ fontSize: '14px', fontWeight: 700 }}>{formatPriceCur(deal?.psTotalCost)}</Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography fontSize={'14px'}>Сумма без НДС</Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography style={{ fontSize: '14px', fontWeight: 700 }}>{formatPriceCur(deal?.totalPriceWoVat ?? 0)}</Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography fontSize={'14px'}>НДС</Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography style={{ fontSize: '14px', fontWeight: 700 }}>{formatPriceCur(deal?.vatTotalPrice ?? 0)}</Typography>
                    </Grid>
                  </Grid>
                </CustomPopper>
              </Grid>
            </Grid>

            <Grid item xs={12} pl={1} mb={1}>
              <Grid container alignItems={'center'}>
                <Grid container item xs={12} alignItems={'center'}>
                  {hasDeviationPct && (
                    <>
                      <Grid item xs={6}>
                        <Typography style={{ fontSize: '15.6px' }}>Соответствие соглашения рынку</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Grid container alignItems={'center'}>
                          <Grid item>
                            <Typography style={{ fontSize: '15.6px', color: color }}>
                              {formatNumber02(actualMarketDeviationPct)} %
                            </Typography>
                          </Grid>
                          <Grid item ml={1}>
                            <MxLabel
                              sx={{
                                cursor: 'pointer',
                                borderRadius: '16px',
                                fontWeight: '400',
                                color: color,
                                backgroundColor: bgColor,
                              }}
                            >
                              <Box fontSize={14}>{status}</Box>
                            </MxLabel>
                          </Grid>
                        </Grid>
                      </Grid>
                    </>
                  )}
                  <Grid item xs={6}>
                    <Typography style={{ fontSize: '15.6px' }}>Количество</Typography>
                  </Grid>
                  <Grid item pl={0.5}>
                    <Typography style={{ fontSize: '15.6px' }}>{formatNumber3((deal?.totalWeight || 0) / 1000)} т</Typography>
                  </Grid>
                  {!dealStore.isViewOnly() && deal?.lifting?.timeSeconds > 0 && (
                    <Grid item>
                      <IconButton onClick={handleLiftingClick} size={'small'}>
                        <InfoOutlinedIcon />
                      </IconButton>
                    </Grid>
                  )}

                  <CustomPopper
                    isOpen={liftingTimeOpen}
                    anchorEl={liftingAnchor}
                    onClose={handleLiftingClose}
                    style={{ minWidth: '240px' }}
                  >
                    <Grid container pl={1}>
                      <Typography sx={{ fontSize: '14px' }}>
                        Общее время на сборку {formatMinutePeriodAsText(deal?.lifting?.timeSeconds)}
                      </Typography>
                    </Grid>
                  </CustomPopper>
                </Grid>
                {orderedShipped.totalShipped > 0 && (
                  <>
                    <Grid container item xs={12} alignItems={'center'}>
                      <Grid item xs={6}>
                        <Typography style={{ fontSize: '15.6px' }}>Отгружено</Typography>
                      </Grid>
                      <Grid item pl={0.5}>
                        <Typography style={{ fontSize: '15.6px' }}>{formatNumber3(orderedShipped.totalShipped)} т</Typography>
                      </Grid>
                      <Grid item pl={1}>
                        <ShippedProgress currentQuantity={orderedShipped.totalShipped} baseQuantity={deal?.totalWeight / 1000} />
                      </Grid>
                    </Grid>
                    <Grid container item xs={12}>
                      <Grid item xs={6}>
                        <Typography style={{ fontSize: '15.6px' }}>Остаток</Typography>
                      </Grid>
                      <Grid item pl={0.5}>
                        <Typography style={{ fontSize: '15.6px' }}>
                          {formatNumber3((deal?.totalWeight || 0) / 1000 - orderedShipped.totalShipped)} т
                        </Typography>
                      </Grid>
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>

          <Grid item px={1}>
            <Grid container flexDirection={'column'} flexWrap={'nowrap'}>
              {(deal?.motivation && Object.keys(deal?.motivation || {}).length && (
                <PositionMotivationTotal motivation={deal?.motivation} isForDeal />
              )) ||
                null}
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
});
