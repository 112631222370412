import React, { FC, useRef, useState } from 'react';
import { Box } from '@mui/system';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import Typography from '@mui/material/Typography';

export const IsOldIcon: FC = (): JSX.Element => {
  const tooltipRef = useRef();
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const handleTooltipOpen = (): void => {
    setIsTooltipOpen(true);
  };
  const handleTooltipClose = (): void => {
    setIsTooltipOpen(false);
  };
  return (
    <Box
      sx={{
        position: 'relative',
        borderRadius: '3px 3px 3px 3px',
        fontSize: 16,
        fontWeight: 500,
        verticalAlign: 'middle',
        fontFamily: 'Arial, Helvetica, sans-serif',
        backgroundColor: '#67c3e1',
        display: 'inline-block',
        width: 40,
        height: 20,
        textAlign: 'center',
        marginLeft: '10px',
        lineHeight: '20px',
      }}
      ref={tooltipRef}
    >
      <Box
        sx={{
          position: 'absolute',
          left: '-19px',
          width: 0,
          height: 20,
          border: '10px solid transparent; border-right: 10px solid #67c3e1',
        }}
      />
      <Box
        display="flex"
        justifyContent="center"
        sx={{ color: '#f6eeee' }}
        onMouseEnter={handleTooltipOpen}
        onMouseLeave={handleTooltipClose}
      >
        Old
      </Box>
      <Popper placement="top" open={isTooltipOpen} style={{ zIndex: 1300 }} anchorEl={tooltipRef.current}>
        <Paper sx={{ borderRadius: '3px' }}>
          <Box p={0.6}>
            <Typography variant="caption" color="inherit" fontWeight="500">
              Самая старая партия
            </Typography>
          </Box>
        </Paper>
      </Popper>
    </Box>
  );
};
