import React, { FC } from 'react';
import Button from '@mui/material/Button';
import { ButtonProps } from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { Theme } from '@mui/material';
import { SxProps } from '@mui/system';

interface TextButtonProps {
  onClick: (event) => void;
  selected?: boolean;
  disabled?: boolean;
  children: React.ReactNode;
  sx?: SxProps<Theme>;
}
type StyledButtonProps = ButtonProps & {
  selected: boolean;
};
const StyledButton = styled(Button, {
  shouldForwardProp: prop => prop !== 'selected',
})<StyledButtonProps>(({ selected, theme }) => ({
  fontSize: '80%',
  fontWeight: 700,
  height: '30px',
  marginRight: '6px',
  paddingLeft: '12px',
  paddingRight: '12px',
  boxSizing: 'border-box',
  ...(selected === true && {
    backgroundColor: theme.palette.mode === 'dark' ? '#121212' : theme.palette.common.white,
    border: 'solid 1px #d3dce1',
    color: theme.palette.mode === 'dark' ? '#ffffff!important' : theme.palette.text.secondary,
  }),
  ...(selected === false && {
    backgroundColor: theme.palette.mode === 'dark' ? '#2f2f2f' : theme.palette.action.selected,
    border: 'solid 1px rgb(244, 246, 248)',
    color: theme.palette.mode === 'dark' ? '#d8d8d8b3' : theme.palette.text.secondary,
  }),
}));
export const TextButton: FC<TextButtonProps> = ({ onClick, selected = false, disabled, children, sx }): JSX.Element => {
  return (
    <StyledButton onClick={onClick} selected={selected} disabled={disabled} sx={sx}>
      {children}
    </StyledButton>
  );
};
