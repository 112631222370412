import React, { FC, useCallback } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { AppDealPosition } from '../../../slices/AppDeal';
import { formatNumber3 } from '@mx-ui/helpers';
import { formatMinutePeriodAsText } from '@mx-ui/helpers';
import { observer } from 'mobx-react-lite';
import { useAuthStore } from 'src/store/MobxStoreProvider';
import { renderPriceCur } from '../../../utils/renderFormat';
import { DealItemStore } from '../../../store/DealItemStore';
import { Divider } from '@mui/material';
import { ReserveWidget } from './ReserveWidget';
import { TabSelectWrapper } from '../../../components/Deals/CustomIconTabs/TabSelectWrapper';
import { Link as MxLink } from '@mx-ui/ui';

interface DealPositionAdditionalTabProductInfoProps {
  dealStore: DealItemStore;
  dealPosition: AppDealPosition;
  onPositionAddDialogClick?: (categoryCode?: string) => void;
  isViewOnly?: boolean;
  setSelectedAdditionalTab?: React.Dispatch<React.SetStateAction<number>>;
}

// Закладка с информацией о товаре в строке товара в сделке
export const DealPositionAdditionalTabProductInfo: FC<DealPositionAdditionalTabProductInfoProps> = observer(
  ({ dealStore, dealPosition, onPositionAddDialogClick, isViewOnly, setSelectedAdditionalTab }): JSX.Element => {
    const categoryCode = dealPosition.product.categoryCode;
    const categoryName = dealPosition.product.categoryName;
    const { profile } = useAuthStore();
    const handleCatalogClick = useCallback(
      (event: React.MouseEvent<Element, MouseEvent>): void => {
        event.stopPropagation();
        event.preventDefault();
        onPositionAddDialogClick(categoryCode);
      },
      [onPositionAddDialogClick, dealPosition.product.categoryCode]
    );

    const positionWarehouseCode = dealPosition.warehouseCode || dealStore.deal?.warehouseCode;
    let productCustodyAvgUnitCost = dealPosition.product?.custodyAvgUnitCost;
    dealPosition.product?.custodyWarehouses?.forEach(cw => {
      if (cw.warehouseCode === positionWarehouseCode) {
        productCustodyAvgUnitCost = cw.unitCost;
      }
    });

    return (
      <TabSelectWrapper>
        <Grid mt={0.5} container sx={{ maxHeight: '100%', overflow: 'auto' }} className="withCustomScroll">
          {!dealPosition.isArchive && (
            <>
              <Grid item xs={12} pb={1.5}>
                <Grid maxWidth={'390px'}>
                  <ReserveWidget
                    position={dealPosition}
                    setSelectedAdditionalTab={setSelectedAdditionalTab}
                    documentState={dealStore?.deal?.state || ''}
                    selectedTabNumber={6}
                    isDeal
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} pb={1.5}>
                <Divider />
              </Grid>
            </>
          )}
          <Grid item xs={12} pb={1.5} container>
            <Grid item xs={6}>
              <Typography variant="body2" color="text.secondary">
                Артикул
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" color="text.secondary">
                {dealPosition.product.code || '-'}
              </Typography>
            </Grid>
          </Grid>

          <Grid item xs={12} pb={1.5} container>
            <Grid item xs={6}>
              <Typography variant="body2" color="text.secondary">
                MdmKey
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" color="text.secondary">
                {dealPosition.product?.mdmKey || '-'}
              </Typography>
            </Grid>
          </Grid>

          <Grid item xs={12} pb={1.5} container>
            <Grid item xs={6}>
              <Typography variant="body2" color="text.secondary">
                Наименование товара клиента
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" color="text.secondary">
                {dealPosition.productParticipantName || '-'}
              </Typography>
            </Grid>
          </Grid>

          <Grid item xs={12} pb={1.5} container>
            <Grid item xs={6}>
              <Typography variant="body2" color="text.secondary">
                MdmKey номенклатуры
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" color="text.secondary">
                {dealPosition.product.nomenclatureMdmKey || '-'}
              </Typography>
            </Grid>
          </Grid>

          <Grid container mb={1}>
            <Grid item xs={6}>
              <Typography color="text.secondary" variant="body2">
                Группа сегментации
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography color="text.secondary" variant="body2">
                {dealPosition.product.segmentationGroupTitle || '-'}
              </Typography>
            </Grid>
          </Grid>

          <Grid item xs={12} pb={1.5} container>
            <Grid item xs={6}>
              <Typography variant="body2" color="text.secondary">
                Каталог
              </Typography>
            </Grid>
            <Grid item xs={6}>
              {!!categoryCode ? (
                <>
                  {isViewOnly ? (
                    <>
                      <MxLink href={'/app/catalog/' + categoryCode} variant="body2" color="text.secondary">
                        {categoryName || 'перейти'}
                      </MxLink>
                    </>
                  ) : (
                    <MxLink href={'/app/catalog/' + categoryCode} onClick={handleCatalogClick} color="text.secondary" variant="body2">
                      {categoryName || 'перейти'}
                    </MxLink>
                  )}
                </>
              ) : (
                <Typography variant="body2" color="text.secondary">
                  &mdash;
                </Typography>
              )}
            </Grid>
          </Grid>

          <Grid container mb={1}>
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </Grid>

          <Grid item xs={12} pb={1.5} container>
            <Grid item xs={6}>
              <Typography variant="body2" color="text.secondary">
                Кратность
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" color="text.secondary">
                {dealPosition.units && dealPosition.units?.length > 1 ? (
                  dealPosition.units.map(unit => {
                    if (unit.code === 'TNE') {
                      return null;
                    }
                    return (
                      <span key={unit.code} style={{ whiteSpace: 'nowrap' }}>
                        <span> {unit.weight}</span>
                        <span> {`т/${unit.title}`}</span>
                      </span>
                    );
                  })
                ) : (
                  <> &mdash;</>
                )}
              </Typography>
            </Grid>
          </Grid>

          <Grid item xs={12} pb={1.5} container>
            <Grid item xs={6}>
              <Typography variant="body2" color="text.secondary">
                Базовый размер
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" color="text.secondary">
                {dealPosition.product.baseSize?.valueText || '-'}
              </Typography>
            </Grid>
          </Grid>

          <Grid item xs={12} pb={1.5} container>
            <Grid item xs={6}>
              <Typography variant="body2" color="text.secondary">
                Код сегмента товара
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" color="text.secondary">
                {dealPosition.segment || '-'}
              </Typography>
            </Grid>
          </Grid>

          <Grid item xs={12} pb={1.5} container>
            <Grid item xs={6}>
              <Typography variant="body2" color="text.secondary">
                Коэффициент товарного запаса (КТЗ)
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" color="text.secondary">
                {dealPosition.stockCoefficient ?? '-'}
              </Typography>
            </Grid>
          </Grid>

          <Grid container mb={1}>
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </Grid>

          <Grid item xs={12} pb={1.5} container>
            <Grid item xs={6}>
              <Typography variant="body2" color="text.secondary">
                Базовая МРЦ
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" color="text.secondary">
                {renderPriceCur(dealPosition.baseMinRetailUnitCost)}
              </Typography>
            </Grid>
          </Grid>

          <Grid item xs={12} pb={1.5} container>
            <Grid item xs={6}>
              <Typography variant="body2" color="text.secondary">
                Средневзвешенная цена ОХ
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" color="text.secondary">
                {productCustodyAvgUnitCost > 0 ? renderPriceCur(productCustodyAvgUnitCost) : <>&mdash;</>}
              </Typography>
            </Grid>
          </Grid>

          {profile?.features?.canSeeDealPrimeCostMargin && (
            <Grid item xs={12} pb={1.5} container>
              <Grid item xs={6}>
                <Typography variant="body2" color="text.secondary">
                  Плановая себестоимость
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2" color="text.secondary">
                  {renderPriceCur(dealPosition.weightedAvgUnitCost)}
                </Typography>
              </Grid>
            </Grid>
          )}

          <Grid container mb={1}>
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </Grid>

          <Grid item xs={12} pb={1.5} container>
            <Grid item xs={6}>
              <Typography variant="body2" color="text.secondary">
                Ср. вес пачки
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" color="text.secondary">
                {formatNumber3(dealPosition.lifting?.avgPackWeight) || '-'}&nbsp;т
              </Typography>
            </Grid>
          </Grid>

          <Grid item xs={12} pb={1.5} container>
            <Grid item xs={6}>
              <Typography variant="body2" color="text.secondary">
                Кол-во целых пачек
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" color="text.secondary">
                {dealPosition.lifting?.fullPackCount || '-'}
              </Typography>
            </Grid>
          </Grid>

          <Grid item xs={12} pb={1.5} container>
            <Grid item xs={6}>
              <Typography variant="body2" color="text.secondary">
                Кол-во битых пачек
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" color="text.secondary">
                {dealPosition.lifting?.partialCount || '-'}
              </Typography>
            </Grid>
          </Grid>

          <Grid item xs={12} pb={1.5} container>
            <Grid item xs={6}>
              <Typography variant="body2" color="text.secondary">
                Время на сборку
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" color="text.secondary">
                {formatMinutePeriodAsText(dealPosition?.lifting?.timeSeconds)}
              </Typography>
            </Grid>
          </Grid>

          {dealPosition.sgMrcMarkupAmount > 0 && (
            <Grid item xs={12} pb={1.5} container>
              <Grid item xs={6}>
                <Typography variant="body2" color="text.secondary">
                  Наценка филиала в МРЦ
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2" color="text.secondary">
                  {renderPriceCur(dealPosition.sgMrcMarkupAmount)}
                </Typography>
              </Grid>
            </Grid>
          )}
        </Grid>
      </TabSelectWrapper>
    );
  }
);
