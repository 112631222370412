import { CSSProperties, FC, useCallback } from 'react';
import { useRootStore } from '../MobxStoreProvider';
import { observer } from 'mobx-react-lite';
import Container from '@mui/material/Container';
import { styled } from '@mui/material/styles';

const PREFIX = 'DevPanel';

const classes = {
  root: `${PREFIX}-root`,
  head: `${PREFIX}-head`,
  body: `${PREFIX}-body`,
};

const StyledContainer = styled(Container)(() => {
  return {
    [`&.${classes.root}`]: {
      color: '#111',
      position: 'fixed',
      top: 10,
      left: 10,
      width: 120,
      height: 200,
      zIndex: 9999999,
      background: '#ffffffaa',
      padding: 2,
      border: '1px solid #ccc',
      fontSize: 10,
    },
    [`& .${classes.head}`]: {
      background: '#0000ff66',
      padding: '0 2px',
    },
    [`& .${classes.body}`]: {
      whiteSpace: 'pre',
    },
  };
});

const containerStyle = { position: 'fixed', background: '#ffffffaa' } as CSSProperties;

export const DevPanel: FC = observer((): JSX.Element => {
  const rootStore = useRootStore();
  const devStore = rootStore.getDevPanel();

  const handleHideClick = useCallback(() => {
    devStore.hide();
  }, [devStore]);

  return !devStore.visible ? (
    <></>
  ) : (
    <StyledContainer className={classes.root} maxWidth={false} style={containerStyle}>
      <div className={classes.head}>
        dev panel{' '}
        <span style={{ cursor: 'pointer' }} onClick={handleHideClick}>
          [x]
        </span>
      </div>
      <div className={classes.body}>{JSON.stringify(devStore.data, null, 2)}</div>
    </StyledContainer>
  );
});
