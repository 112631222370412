import React, { useState, useEffect } from 'react';
import ReactCSSTransitionReplace from 'react-css-transition-replace';

export const ContentSwapper = (
  props: { checked: boolean; style: any; children: JSX.Element[] } & typeof ReactCSSTransitionReplace
): JSX.Element => {
  const [index, setIndex] = useState(Number(props.checked));
  useEffect(() => {
    if (props.checked) {
      setIndex(1);
    } else {
      setIndex(0);
    }
  }, [props.checked]);
  const content = React.Children.toArray(props.children);
  const { style = {} } = props;

  const newStyle = {
    ...style,
    ...(index === 0 ? { height: 'inherit' } : {}),
    boxSizing: 'initial',
  };
  return (
    <ReactCSSTransitionReplace {...props} style={newStyle}>
      {content[index]}
    </ReactCSSTransitionReplace>
  );
};
