import React, { FC, JSX } from 'react';
import { observer } from 'mobx-react-lite';
import { Breakpoint, Dialog, DialogClasses, DialogContent, Typography } from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

interface DialogContainerProps {
  open: boolean;
  onClose: (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  handleSuccess?: () => void;
  handleCansel?: () => void;
  dialogContent?: JSX.Element;
  dialogTitle?: string;
  canselBtnText?: string;
  infiniteScrollId?: string;
  successBtnText?: string;
  fullWidth?: boolean;
  disabled?: boolean;
  maxWidth?: false | Breakpoint;
  classes?: Partial<DialogClasses>;
}

export const DialogContainer: FC<DialogContainerProps> = observer(
  ({
    open,
    onClose,
    maxWidth,
    classes,
    handleSuccess,
    dialogContent,
    dialogTitle,
    canselBtnText,
    successBtnText,
    handleCansel,
    fullWidth = false,
    disabled = false,
    infiniteScrollId,
  }): JSX.Element => {
    return (
      <Dialog open={open} onClose={onClose} maxWidth={maxWidth} classes={classes} fullWidth={fullWidth}>
        {dialogTitle && (
          <DialogTitle sx={{ padding: '8px 24px' }}>
            <Typography variant={'body1'} fontWeight={500}>
              {dialogTitle}
            </Typography>
          </DialogTitle>
        )}
        {dialogContent && (
          <DialogContent id={infiniteScrollId || null} className="withCustomScroll" sx={{ pb: 4 }}>
            {dialogContent}
          </DialogContent>
        )}
        <DialogActions>
          <Grid container alignItems={'center'} justifyContent={'flex-end'} spacing={1}>
            {canselBtnText && (
              <Grid item>
                <Button
                  onClick={handleCansel || onClose}
                  variant={'contained'}
                  size={'small'}
                  sx={{ color: theme => (theme.palette.mode === 'dark' ? 'secondary.light' : 'primary') }}
                >
                  {canselBtnText}
                </Button>
              </Grid>
            )}
            {handleSuccess && (
              <Grid item>
                <Button
                  disabled={disabled}
                  onClick={handleSuccess}
                  variant={'contained'}
                  size={'small'}
                  sx={{ color: theme => (theme.palette.mode === 'dark' ? 'secondary.light' : 'primary') }}
                >
                  {successBtnText}
                </Button>
              </Grid>
            )}
          </Grid>
        </DialogActions>
      </Dialog>
    );
  }
);
