import { makeAutoObservable } from 'mobx';
import { ClientsAttentionsReasonItem, ClientsAttentionsReasonsResponse, CustomerAttention } from 'src/api/marketx';
import { ClientItemStore } from '../ClientItemStore';
import { ApiStore } from '../Global/ApiStore';
export class AttensionForClientStore {
  attentionList: CustomerAttention[] = [];
  attentionReasonsList: ClientsAttentionsReasonItem[] = [];
  apiStore: ApiStore;
  clientCode: string;
  constructor(clientStore: ClientItemStore) {
    this.apiStore = clientStore.apiStore;
    makeAutoObservable(this, {
      apiStore: false,
    });
  }
  setClientCode(code: string): void {
    this.clientCode = code;
  }
  setDeletedAttentionItem(attentionCode: string): void {
    const indexOfDeletedItem = this.attentionList.findIndex(el => el.code === attentionCode);
    this.attentionList.splice(indexOfDeletedItem, 1);
  }
  setAttentionReasons(data: ClientsAttentionsReasonsResponse): void {
    this.attentionReasonsList = data.reasons;
  }
  setAttentionList(attention: CustomerAttention[]): void {
    this.attentionList = attention;
  }
  deleteAttentionItem(attentionCode: string): Promise<void> {
    return this.deleteAttentionItemRequest(attentionCode);
  }
  loadAttentionList(): void {
    this.apiStore
      .apiClientCustomer()
      .clientsItemAttentions(this.clientCode)
      .then(res => {
        this.setAttentionList(res.data.attentions);
      })
      .catch(e => {
        console.warn('loadAttentionList', e);
      });
  }
  addNewAttentionItem(attention: CustomerAttention): void {
    this.attentionList.push(attention);
  }
  saveAttentionItem(clientCode: string, reasonCode: string, validUntil: string): Promise<void> {
    return this.apiStore
      .apiClientCustomer()
      .clientsItemAttentionsCreate(clientCode, { reasonCode, validUntil })
      .then(res => {
        this.addNewAttentionItem(res.data.attention);
      })
      .catch(e => {
        console.warn('saveAttentionItem', e);
      });
  }
  setEditAttention(editCode: string, data: CustomerAttention): void {
    this.attentionList.forEach((attention, index) => {
      if (attention.code === editCode) {
        this.attentionList[index] = { ...data };
      }
    });
  }
  editAttentionItem(editCode: string, reasonCode: string, validUntil: string): Promise<void> {
    return this.apiStore
      .apiClientCustomer()
      .clientsAttentionsItemSave(editCode, { reasonCode, validUntil })
      .then(res => {
        this.setEditAttention(editCode, res.data.attention);
      })
      .catch(e => {
        console.warn('saveAttentionItem', e);
      });
  }
  loadAttentionReasons(): void {
    this.apiStore
      .apiClientCustomer()
      .clientsAttentionsReasons()
      .then(res => {
        this.setAttentionReasons(res.data);
      })
      .catch(e => {
        console.warn('loadAttentionReasons', e);
      });
  }
  deleteAttentionItemRequest(attentionCode: string): Promise<void> {
    return this.apiStore
      .apiClientCustomer()
      .clientsAttentionsItemDelete(attentionCode)
      .then(() => {
        this.setDeletedAttentionItem(attentionCode);
      })
      .catch(e => {
        console.warn('saveAttentionItem', e);
      });
  }
}
