import React, { FC, JSX, useState } from 'react';
import { observer } from 'mobx-react-lite';
import Grid from '@mui/material/Grid';
import { BillItemStore } from '../../store/Documents/BillItemStore';
import { Divider, IconButton } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { BillPosition, BillsVisualizeEmulatedWithdrawResponseDiffItem } from '../../api/marketx';
import { DialogContainer } from '../ui/dialogs/DialogContainer';
import { ReserveDetailsItemV2, ReserveDetailsV2Skeleton } from '../Product/Reserve/ReserveDetailsV2';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
interface RemovalPositionReserveProps {
  billStore: BillItemStore;
  position: BillPosition;
}

export const RemovalPositionReserve: FC<RemovalPositionReserveProps> = observer(({ billStore, position }): JSX.Element => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [reserveData, setReserveData] = useState<BillsVisualizeEmulatedWithdrawResponseDiffItem[]>(null);
  const handleDialogClose = (): void => {
    setIsDialogOpen(false);
    setTimeout(() => {
      setReserveData(null);
    }, 100);
  };
  const handleLoadReservesEmulatedWithdraw = (): void => {
    setIsDialogOpen(true);
    setIsLoading(true);
    billStore.apiStore
      .apiClientBill()
      .billsVisualizeEmulatedWithdraw(billStore.documentNumber, {
        productCode: position.product.code,
        warehouseCode: position.warehouse.code,
      })
      .then(res => {
        setReserveData(res.data.diff);
      })
      .catch(e => {
        console.warn('billsEmulatedWithdrawRequest', e);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  return (
    <Grid item>
      <IconButton size={'small'} onClick={handleLoadReservesEmulatedWithdraw}>
        <InfoOutlinedIcon />
      </IconButton>
      <DialogContainer
        open={isDialogOpen}
        onClose={handleDialogClose}
        dialogContent={
          isLoading ? (
            <ReserveDetailsV2Skeleton />
          ) : (
            <ReserveEmulatedItem reserveData={reserveData} documentNumber={billStore.documentNumber} position={position} />
          )
        }
        dialogTitle={`После снятия резерва произойдут следующие изменения`}
        canselBtnText={'Закрыть'}
        maxWidth={'lg'}
      />
    </Grid>
  );
});

interface ReserveEmulatedItemProps {
  reserveData: BillsVisualizeEmulatedWithdrawResponseDiffItem[];
  documentNumber?: string;
  position?: BillPosition;
}

export const ReserveEmulatedItem: FC<ReserveEmulatedItemProps> = observer(({ reserveData, documentNumber, position }): JSX.Element => {
  return (
    <Grid container>
      <Grid item mb={2} xs={12}>
        <Typography fontSize={'12px'} sx={{ pl: '2px' }} color="text.secondary">
          {position.product.title}
        </Typography>
        <Divider />
      </Grid>
      <Grid item>
        {reserveData?.length ? (
          <Grid sx={{ height: '100%', display: 'grid', gridTemplateColumns: 'minmax(310px, 1fr)' }}>
            <Grid
              container
              spacing={0}
              className="withCustomScroll"
              sx={{ maxHeight: '100%', display: 'grid', gridTemplateColumns: 'minmax(720px, 1fr)' }}
            >
              {reserveData.map((item, index) => {
                return (
                  <Grid item sx={{ mb: 1 }} key={index}>
                    <ReserveDetailsItemV2 reserve={item.after} documentNumber={documentNumber} />
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        ) : (
          <Box p={2}>
            <Typography variant="body2">Данных нет</Typography>
          </Box>
        )}
      </Grid>
      <Grid item></Grid>
    </Grid>
  );
});
