import React, { FC, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import DialogContentText from '@mui/material/DialogContentText';
import LinearProgress from '@mui/material/LinearProgress';
import { useRouter } from 'next/router';
import { v4 as uuidV4 } from 'uuid';
import { useRootStore } from '../../store/MobxStoreProvider';
import { AgreementItemStore } from '../../store/Clients/AgreementItemStore';
import MoreVertSharpIcon from '@mui/icons-material/MoreVertSharp';
import { useDropzone } from 'react-dropzone';
import { MsgType } from '../../store/Global/WebSocketStore';
import { DialogContainer } from '../ui/dialogs/DialogContainer';
import { Link as MxLink } from '@mx-ui/ui';

interface AgreementCloneButtonProps {
  sourceCode: string;
  agreementStore: AgreementItemStore;
}

export const DialogAgreementClone: FC<AgreementCloneButtonProps> = observer(({ sourceCode, agreementStore }): JSX.Element => {
  const router = useRouter();
  const rootStore = useRootStore();
  const snackbarStore = rootStore.getSnackbar();
  const eventMgr = rootStore.getEventManager();
  const apiStore = useRootStore().getApiStore();

  const [isCloneAgreement, setIsCloneAgreement] = useState(false);
  const [frontCode] = useState(uuidV4());
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const disabled = useMemo(() => agreementStore.isViewOnly(), [agreementStore]);
  const handleUploadFiles = (file: File[]): void => {
    agreementStore
      .priceUpload(file)
      .then(() => {
        snackbarStore.showSuccess(`Соглашение обновлено"`);
        eventMgr.processMessages({
          msgType: MsgType.SHOP_FRONT_AGREEMENT_PRODUCT_ADDED,
          data: {
            agreementCode: agreementStore.agreementCode,
          },
        });
      })
      .catch(error => {
        console.warn('contractCostAgreementsItemPricesUpload', error);
      });
  };

  const { getRootProps, getInputProps } = useDropzone({
    maxSize: 20 * 1024 * 1024, // 20mbm
    // maxFiles: 1,
    onDrop: acceptedFiles => {
      handleUploadFiles(acceptedFiles);
    },
  });

  const handleClickConfirmationOpen = (): void => {
    setConfirmationOpen(true);
    handleClose();
  };
  const handleConfirmationClose = (): void => {
    setConfirmationOpen(false);
  };
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (): void => {
    setAnchorEl(null);
  };
  const handleConfirmationConfirm = (): void => {
    setIsCloneAgreement(true);

    const cloneAgreement = apiStore.apiClientCustomer().contractCostAgreementsClone(
      {
        frontCode: frontCode,
        sourceCode: sourceCode,
      },
      {}
    );
    cloneAgreement.catch(e => {
      console.error(e);
      setConfirmationOpen(false);
      setIsCloneAgreement(false);
    });
    cloneAgreement.then((res): void => {
      setConfirmationOpen(false);
      setIsCloneAgreement(false);
      router.push(`/app/agreements/` + encodeURIComponent(res.data.agreement.code));
    });
  };

  return (
    <>
      <IconButton color="primary" onClick={handleClick} component="span" size="medium" disabled={!!agreementStore.isDeleted}>
        <MoreVertSharpIcon />
      </IconButton>

      <Menu anchorEl={anchorEl} keepMounted open={open} onClose={handleClose}>
        <MenuItem onClick={handleClickConfirmationOpen} disabled={isCloneAgreement}>
          Дублировать
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <MxLink
            href={{
              pathname: `/api/contract/cost/agreements/${encodeURIComponent(agreementStore.agreementCode)}/pdf`,
              query: { fn: `${encodeURIComponent(agreementStore.agreementCode)}.pdf` },
            }}
            variant="body1"
            color="textPrimary"
          >
            Сформировать КП
          </MxLink>
        </MenuItem>
        <MenuItem onClick={handleClose} disabled={disabled}>
          <div {...getRootProps({ className: 'dropzone' })}>
            <input {...getInputProps()} />
            Загрузить из файла
          </div>
        </MenuItem>
        <MenuItem>
          <MxLink
            href={`/app/print-documents/specification/agreements/${agreementStore.agreement.code}`}
            variant="body1"
            color="textPrimary"
          >
            Сформировать спецификацию
          </MxLink>
        </MenuItem>
      </Menu>
      <DialogContainer
        open={confirmationOpen}
        onClose={handleConfirmationClose}
        disabled={isCloneAgreement}
        dialogTitle={'Дублировать соглашение?'}
        dialogContent={
          <>
            <DialogContentText id="alert-dialog-description">
              <span>Будет создано новое соглашение на основе {sourceCode}.</span>
            </DialogContentText>
            {isCloneAgreement && <LinearProgress />}
          </>
        }
        canselBtnText={'Отмена'}
        successBtnText={'Создать'}
        handleSuccess={handleConfirmationConfirm}
        maxWidth={'md'}
      />
    </>
  );
});
