import { RootStore } from './StoreManager';
import { makeAutoObservable, runInAction } from 'mobx';
import { ApiStore } from './Global/ApiStore';
import { StatMarginalityEmployeeOverallRatingItem, StatMarginalityEmployeeOverallRatingResponse } from 'src/api/marketx';
export type RatingTabsType = 'branch' | 'company';
export const namesRatingTabs: Record<RatingTabsType, string> = {
  branch: 'В филиале',
  company: 'В компании',
};
export class RatingOfManagersStore {
  rating: Record<RatingTabsType, StatMarginalityEmployeeOverallRatingItem[]> = { branch: [], company: [] };
  isLoaded = false;
  apiStore: ApiStore;
  constructor(rootStore: RootStore) {
    this.apiStore = rootStore.getApiStore();
    makeAutoObservable(this, { apiStore: false });
  }
  setResultByBranch(value: StatMarginalityEmployeeOverallRatingResponse): void {
    this.rating['branch'] = value.rating || [];
  }
  setResultByCompany(value: StatMarginalityEmployeeOverallRatingResponse): void {
    this.rating['company'] = value.rating || [];
  }
  async initialLoad(branchOfficeCode: string): Promise<void> {
    try {
      const { data: dataByBranch } = await this.apiStore.apiStatApi().statMarginalityEmployeeOverallRating(branchOfficeCode);
      this.setResultByBranch(dataByBranch);
      const { data: dataByCompany } = await this.apiStore.apiStatApi().statMarginalityEmployeeOverallRating(undefined, undefined, true);
      this.setResultByCompany(dataByCompany);
      runInAction(() => {
        this.isLoaded = true;
      });
    } catch (error) {
      console.warn(error);
      runInAction(() => {
        this.isLoaded = true;
      });
    }
  }
}
