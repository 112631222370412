import { FC } from 'react';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import { observer } from 'mobx-react-lite';

interface SkeletonProps {
  isCreate: boolean;
}

const SkeletonItem: FC = () => <Skeleton height={57} width="100%" />;

export const TaskDialogSkeleton: FC<SkeletonProps> = observer(({ isCreate }) => {
  return (
    <Grid container flexDirection="column" sx={{ width: 800 }}>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12}>
          <SkeletonItem />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SkeletonItem />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SkeletonItem />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SkeletonItem />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SkeletonItem />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SkeletonItem />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SkeletonItem />
        </Grid>
        {!isCreate && (
          <Grid item xs={12} sm={6}>
            <SkeletonItem />
          </Grid>
        )}
        {!isCreate && (
          <Grid item xs={12} sm={6}>
            <SkeletonItem />
          </Grid>
        )}
        <Grid item xs={12}>
          <Skeleton height={153} width="100%" />
        </Grid>
      </Grid>
    </Grid>
  );
});
