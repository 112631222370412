import React, { FC, JSX, useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { ContractCostAgreement } from '../../api/marketx';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { formatDateYmd, formatNumber3 } from '@mx-ui/helpers';
import { ThreadLink } from '../Bills/ThreadLink';
import { HandShakeIcon } from '../ui/icons/HandShakeIcon';
import { renderPriceCur } from '../../utils/renderFormat';
import { MxLabel } from '@mx-ui/ui';
import Box from '@mui/material/Box';
import { agreementStateColors, agreementStateDictionary } from './AgreementStateSelect';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { AgreementListStore } from '../../store/Clients/AgreementListStore';
import IconButton from '@mui/material/IconButton';
import DoDisturbOnOutlinedIcon from '@mui/icons-material/DoDisturbOnOutlined';
import FilterListIcon from '@mui/icons-material/FilterList';
import { DialogAgreementClone } from './DialogAgreementClone';
import { PreviewMenu } from '../UploadFile/PreviewMenu';
import { useRootStore } from '../../store/MobxStoreProvider';
import { getFormattedDocuments } from '../../views/clients/components/Order';
import { AgreementItemStore } from '../../store/Clients/AgreementItemStore';
import { Link as MxLink } from '@mx-ui/ui';
import useDocumentsPath from '../../hooks/useDocumentsPath';

interface AgreementTableViewProps {
  agreement: ContractCostAgreement;
  agreementsStore?: AgreementListStore;
}

export const AgreementTableView: FC<AgreementTableViewProps> = observer(({ agreement, agreementsStore }): JSX.Element => {
  const { asQueryPath } = useDocumentsPath('agreements');
  const matchesXs = useMediaQuery('(min-width:470px)');
  const agreementDocuments = getFormattedDocuments(agreement);
  const rootStore = useRootStore();
  const { profile } = rootStore.getAuth();
  const canCloneAgreement = agreement?.branchOfficeCode == profile?.chosenBranchOfficeCode;
  const theme = useTheme();
  const agreementStore = useMemo<AgreementItemStore>(() => {
    const store = new AgreementItemStore(rootStore);
    store.setAgreement(agreement);
    return store;
  }, [rootStore, agreement]);
  const { color: stateColor, bgColor: stateBgColor } = agreementStateColors(
    agreementStateDictionary[agreement?.simplifiedState?.code].id,
    theme
  );

  const handleCustomerChange = useCallback(
    (customerCode?: string) => {
      if (agreementsStore.request?.customerCodes?.includes(customerCode)) {
        return;
      }
      agreementsStore.mergeRequest({ customerCodes: customerCode ? [customerCode] : undefined });
    },
    [agreementsStore]
  );

  const handleCustomerRemoveChange = useCallback(
    (customerCode?: string) => {
      if (!agreementsStore.request?.customerCodes?.includes(customerCode)) {
        return;
      }
      const newCustomerCodes = agreementsStore.request?.customerCodes?.filter(i => i !== customerCode);
      agreementsStore.mergeRequest({ customerCodes: newCustomerCodes });
    },
    [agreementsStore]
  );

  const handleChangeFilterByStatus = useCallback(
    (status?: number) => {
      if (agreementsStore.request?.stateIds?.includes(status)) {
        return;
      }
      agreementsStore.mergeRequest({ stateIds: [status] });
    },
    [agreementsStore]
  );

  const handleRemoveState = useCallback(
    (status?: number) => {
      if (!agreementsStore.request?.stateIds?.includes(status)) {
        return;
      }
      const newStatusCodes = agreementsStore.request?.stateIds?.filter(i => i !== status);
      agreementsStore.mergeRequest({ stateIds: newStatusCodes });
    },
    [agreementsStore]
  );
  const currentAgreementStateId = agreementStateDictionary[agreement?.simplifiedState?.code].id;
  return (
    <Paper sx={{ p: 1, mb: 2 }}>
      <Grid container alignItems="center" justifyContent={'space-between'} spacing={1} position={'relative'} pr={5}>
        <Grid item flex={'0 1 400px'}>
          <Grid container alignItems="center" flexWrap={'nowrap'}>
            <Grid mr={1} item>
              <HandShakeIcon fontSize="medium" color="primary" />
            </Grid>
            <Grid item>
              <MxLink href={`/app/agreements/${agreement.code}?${asQueryPath}`} color="primary" fontWeight={600}>
                {`${agreement.code}${
                  matchesXs ? ' с ' + formatDateYmd(agreement.validFrom) + ' по ' + formatDateYmd(agreement.validUntil) : ''
                }`}
              </MxLink>
            </Grid>
            <Grid item>
              <ThreadLink link={`/app/thread/agreements/${encodeURIComponent(agreement.code)}`} color={'primary'} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item flex={'0 1 200px'} textAlign={'left'}>
          <Grid container alignItems="center">
            <MxLabel
              sx={{
                cursor: 'pointer',
                borderRadius: '16px',
                fontWeight: '400',
                color: stateColor,
                backgroundColor: stateBgColor,
              }}
            >
              <Box fontSize={14}>{agreement?.simplifiedState?.title}</Box>
            </MxLabel>
            {agreementsStore ? (
              agreementsStore.request?.stateIds?.includes(currentAgreementStateId) ? (
                <Box>
                  <IconButton
                    size={'small'}
                    onClick={() => handleRemoveState(currentAgreementStateId)}
                    title={`Убрать фильтрацию по статусу ${agreement?.simplifiedState?.title || ''}`}
                  >
                    <DoDisturbOnOutlinedIcon color={'primary'} sx={{ fontSize: '20px' }} />
                  </IconButton>
                </Box>
              ) : (
                <Box>
                  <IconButton
                    size={'small'}
                    onClick={() => handleChangeFilterByStatus(currentAgreementStateId)}
                    title={`Найти счета по статусу ${agreement?.simplifiedState?.title || ''}`}
                  >
                    <FilterListIcon color={'primary'} sx={{ fontSize: '20px' }} />
                  </IconButton>
                </Box>
              )
            ) : null}
          </Grid>
        </Grid>

        <Grid item flex={'0 1 90px'} textAlign={'right'}>
          <Typography color="text.secondary">
            {formatNumber3(agreement.totalWeight / 1000)}
            {` т`}
          </Typography>
        </Grid>
        <Grid item flex={'0 1 15%'} textAlign={'right'}>
          <Typography style={{ fontWeight: 700 }} color="textPrimary">
            {renderPriceCur(agreement.totalCost)}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container alignItems={'center'}>
            {!matchesXs && (
              <Grid item xs={12} textAlign={'left'}>
                <Typography color="text.secondary">
                  {' с ' + formatDateYmd(agreement.validFrom) + ' по ' + formatDateYmd(agreement.validUntil)}
                </Typography>
              </Grid>
            )}
            <Grid item xs={12} md={'auto'} mr={1}>
              <Typography variant="body2">ИНН {agreement.customer.inn || '—'}</Typography>
            </Grid>
            <Grid item xs={12} md={'auto'}>
              <Grid container alignItems={'center'}>
                <Grid item>
                  <MxLink
                    href={`/app/clients/${encodeURIComponent(agreement.customer.code)}`}
                    color="text.secondary"
                    typographySx={{ fontSize: 15, fontWeight: 600 }}
                  >
                    {agreement?.customer?.shortTitle || agreement?.customer?.title}
                  </MxLink>
                </Grid>
                {agreementsStore && agreement?.customer?.code ? (
                  agreementsStore.request?.customerCodes?.includes(agreement?.customer?.code) ? (
                    <Grid item ml={1}>
                      <IconButton
                        size={'small'}
                        onClick={() => handleCustomerRemoveChange(agreement?.customer?.code)}
                        title={`Убрать фильтрацию по ${agreement?.customer?.shortTitle || agreement?.customer?.title}`}
                      >
                        <DoDisturbOnOutlinedIcon color={'primary'} sx={{ fontSize: '20px' }} />
                      </IconButton>
                    </Grid>
                  ) : (
                    <Grid item ml={1}>
                      <IconButton
                        size={'small'}
                        onClick={() => handleCustomerChange(agreement?.customer?.code)}
                        title={`Найти соглашения ${agreement?.customer?.shortTitle || agreement?.customer?.title}`}
                      >
                        <FilterListIcon color={'primary'} sx={{ fontSize: '20px' }} />
                      </IconButton>
                    </Grid>
                  )
                ) : null}
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {canCloneAgreement && (
          <Grid item sx={{ position: 'absolute', right: '-6px', top: '-6px' }}>
            <Grid container flexDirection={'column'}>
              <Grid item>
                <DialogAgreementClone sourceCode={agreementStore.agreementCode} agreementStore={agreementStore} />
              </Grid>
              {agreementDocuments[agreementStore.agreementCode] ? (
                <Grid item>
                  <PreviewMenu documents={agreementDocuments} positionCode={agreementStore.agreementCode} isFromPosition />
                </Grid>
              ) : null}
            </Grid>
          </Grid>
        )}
      </Grid>
    </Paper>
  );
});
