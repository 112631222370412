import { makeAutoObservable } from 'mobx';
import { PaymentRangesStore } from '../../components/Documents/PaymentRangesBlock';
import { ValueStore } from '../ValueStore';
import { DealItemStore } from '../DealItemStore';

export class DealPaymentStore implements PaymentRangesStore {
  store: DealItemStore = null;
  // agreement: ContractCostAgreement = null;

  prepayPctValueStore: ValueStore;
  prepayPctMax = 100;
  prepayPctMin = 0;
  prepayPctStep = 100;

  postpayDaysValueStore: ValueStore;
  postpayDaysMax = 30;

  constructor(store: DealItemStore) {
    this.store = store;

    this.prepayPctValueStore = new ValueStore({
      value: String(this.store?.deal?.prepayPct || ''),
      inputDelay: 1,
      onInputChangeDebounced: (value: any): void => {
        this.store.updateDealPrepayPct(Number(value || 0)).catch(r => {
          console.log('onInputChangeDebounced Error', r);
        });
      },
    });

    this.postpayDaysValueStore = new ValueStore({
      value: String(this.store?.deal?.postpayDays || ''),
      inputDelay: 1,
      onInputChangeDebounced: (value: any): void => {
        this.store.updateDealPostpayDays(Number(value || 0)).catch(r => {
          console.log('updateDealPostpayDays Error', r);
        });
      },
    });

    this.setDeal(store, new Date());

    makeAutoObservable(this);
  }
  setDeal(store: DealItemStore, requestTime: Date): void {
    const deal = store.deal;
    if (deal) {
      this.prepayPctMax = 100;
      this.prepayPctMin = 0;
      this.prepayPctStep = 100;
      this.postpayDaysMax = deal.payment?.officeMaxPostpayDays || 30;

      switch (deal.payment?.officePrepayPercents) {
        case '[0,50,100]': {
          this.prepayPctStep = 50;
          break;
        }
        case '[100]': {
          this.prepayPctMin = 100;
          this.prepayPctStep = 200;
          this.postpayDaysMax = 0;
          break;
        }
      }

      this.prepayPctValueStore.handleModelChange((deal.prepayPct as any) || '', requestTime);
      this.postpayDaysValueStore.handleModelChange((deal.postpayDays as any) || '', requestTime);
    }
  }

  creditCost(): number | undefined {
    return this.store.deal?.creditCost;
  }

  isViewOnly(): boolean {
    return this.store.isViewOnly();
  }
  /**
   * Ограничитель для отсрочки
   * работает так же как и Знак ограничение скорости
   * можно превышать можно не превышать, но при превышении будет гореть красным айка
   */
  getLimitPostPayDays(): number {
    return this.store.getCreditStateStore().state?.delayDays;
  }
  getPostpayDays(): ValueStore {
    return this.postpayDaysValueStore;
  }

  getPostpayDaysMax(): number {
    return this.postpayDaysMax;
  }

  getPrepayPctMax(): number {
    return this.prepayPctMax;
  }

  getPrepayPctMin(): number {
    return this.prepayPctMin;
  }

  getPrepayPct(): ValueStore {
    return this.prepayPctValueStore;
  }

  getPrepayPctStep(): number {
    return this.prepayPctStep;
  }
}
