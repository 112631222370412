import React, { FC, useCallback, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { AppDealPosition } from '../../../slices/AppDeal';
import { DealItemStore } from '../../../store/DealItemStore';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { SyncedTextField } from 'src/components/SyncedInputs/SyncedTextField';
import { ValueStoreInputTypes } from 'src/store/ValueStore';
import { TabSelectWrapper } from '../../../components/Deals/CustomIconTabs/TabSelectWrapper';
// const useStyles = makeStyles({});

interface DealPositionValidateTabProps {
  dealStore: DealItemStore;
  dealPosition: AppDealPosition;
  isViewOnly: boolean;
}

// Закладка с информацией о товаре в строке товара в сделке
export const DealPositionValidateTab: FC<DealPositionValidateTabProps> = observer(
  ({ dealStore, dealPosition, isViewOnly }): JSX.Element => {
    const psUnitCostStore = dealStore.getValueStoreByPos(dealPosition, 'psUnitCost', ValueStoreInputTypes.PositiveFloat);
    const unit = dealPosition?.unit || 'т';
    const currency = dealPosition?.currency || '₽';

    const [usePsUnitCost, setUsePsUnitCost] = useState(!!dealPosition?.usePsUnitCost);
    const handleUsePsUnitCostOnChange = useCallback(() => {
      const newValue = !usePsUnitCost;
      setUsePsUnitCost(newValue);
      dealStore.updatePosition(dealPosition, { usePsUnitCost: newValue }).catch(r => console.warn('handleUsePsUnitCostOnChange', r));
    }, [dealStore, usePsUnitCost, setUsePsUnitCost, dealPosition]);

    // const handlePsUnitCostOnChange = useCallback(
    //   (value: string) => {
    //     dealStore
    //       .updatePosition(dealPosition, { psUnitCost: inputValueToPositiveFloat(value) })
    //       .catch(r => console.warn('handlePsUnitCostOnChange', r));
    //   },
    //   [dealStore, dealPosition]
    // );
    return (
      <TabSelectWrapper>
        <Grid container spacing={0} className="withCustomScroll" sx={{ maxHeight: '100%' }}>
          <Grid item xs={7}>
            <Checkbox
              disabled={isViewOnly}
              checked={usePsUnitCost}
              name={`usePsUnitCost-${dealPosition.code}`}
              onChange={handleUsePsUnitCostOnChange}
            />
            <Typography color="text.secondary" variant="body2" fontWeight={500} component="span">
              Акция
            </Typography>
          </Grid>
          <Grid item container xs={5} alignSelf="center">
            <Box maxWidth={100} alignSelf="center">
              <SyncedTextField isViewOnly={isViewOnly} color="#19B28D" value={psUnitCostStore} />
            </Box>
            <Typography alignSelf="center" color="text.secondary" variant="caption" fontWeight={500} component="span">
              &nbsp;{currency}/{unit}
            </Typography>
          </Grid>
        </Grid>
      </TabSelectWrapper>
    );
  }
);
