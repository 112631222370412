import React, { FC, SyntheticEvent } from 'react';
import Tab from '@mui/material/Tab';
import Tabs, { TabsProps } from '@mui/material/Tabs';
import Badge from '@mui/material/Badge';
import Grid from '@mui/material/Grid';
import { styled, Theme } from '@mui/material/styles';
import { a11yProps } from '../../ui/TabPanel';
import { SxProps } from '@mui/system';

type CustomTabsProps = TabsProps & {
  padding?: string;
};
const StyledTabs = styled(Tabs, { shouldForwardProp: prop => prop !== 'padding' })<CustomTabsProps>(({ theme, padding = '4px 20px' }) => ({
  borderRadius: '5px',
  overflow: 'visible',
  margin: '0 auto',
  display: 'flex',
  alignItems: 'center',
  '& .MuiTabs-scroller': { overflow: 'visible !important' },
  '& .MuiTab-wrapper': { overflow: 'visible' },
  '& .MuiTabs-indicator': { display: 'none' },
  '& .MuiTabs-flexContainer': {},
  '& .MuiTab-root': {
    minWidth: '32px',
    minHeight: '25px',
    padding: padding,
    overflow: 'visible',
    position: 'static',
    background: theme.palette.mode === 'dark' ? '#2f2f2f' : theme.palette.action.selected,
    border: theme.palette.mode === 'dark' ? '0.1px solid #646262' : '0.5px solid #d9d9db',
    zIndex: 0,
    color: theme.palette.mode === 'dark' ? '#d8d8d8b3' : theme.palette.text.secondary,
  },
  '& .MuiTab-root:focus-visible': { border: '1px solid grey' },
  '& .Mui-selected': {
    ...(theme.palette.mode === 'dark'
      ? {
          background: '#121212',
          color: `#e8e8e8!important`,
        }
      : {
          background: theme.palette.common.white,
          color: '#1a237e',
        }),
    borderRadius: '5px',
    border: theme.palette.mode === 'dark' ? `0.5px solid ${theme.palette.action.disabled}` : 'none',
    boxShadow: 'rgba(0, 0, 0, 0.12) 0px 3px 2px 0px, rgba(0, 0, 0, 0.1) 0px -1px 1px 0px, rgba(0, 0, 0, 0.1) 0px 2px 1px 0px',
    transform: 'scale(110%)',
    position: 'relative',
    zIndex: 10,
  },
  '& .MuiBadge-badge': {
    backgroundColor: theme.palette.mode === 'dark' ? '#ffffff!important' : '#1a237e',
  },
}));

export interface TabValue {
  label?: string;
  title?: string;
  tabPanelIndex: number;
  disabled?: boolean;
  name?: string;
  icon?: any;
  badgeOn?: boolean;
  isHide?: boolean;
}

interface CustomIconTabsProps {
  toggleButtonValue: number | string;
  handleToggleButtonChange: (event: SyntheticEvent<Element, Event>, newValue: number | string) => void;
  tabsValue: Array<TabValue>;
  useName?: boolean;
  sx?: SxProps<Theme>;
  padding?: string;
}

export const CustomIconTabs: FC<CustomIconTabsProps> = ({
  toggleButtonValue,
  handleToggleButtonChange,
  tabsValue,
  sx,
  useName = false,
  padding = '4px 20px',
}): JSX.Element => {
  return (
    <Grid container>
      <StyledTabs value={toggleButtonValue} onChange={handleToggleButtonChange} sx={{ margin: '0', ...sx }} padding={padding}>
        {tabsValue.map(item => {
          return (
            <Tab
              key={item.tabPanelIndex}
              title={item.title}
              sx={!!item.isHide ? { display: 'none' } : {}}
              icon={
                <Badge
                  color="primary"
                  variant="dot"
                  invisible={!item.badgeOn}
                  style={{ fontSize: `${typeof item.icon === 'string' ? '14px' : '11px'}` }}
                >
                  {item.icon}
                </Badge>
              }
              label={item.label}
              {...a11yProps(item.tabPanelIndex, item.name)}
              value={useName ? item.name : item.tabPanelIndex}
              disabled={item.disabled}
              disableRipple
            />
          );
        })}
      </StyledTabs>
    </Grid>
  );
};
