import React, { FC, JSX, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import WarningIcon from '@mui/icons-material/Warning';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';
import { CustomerAttention } from '../../../api/marketx';
import { v4 as uuidV4 } from 'uuid';
interface AttentionBlockProps {
  attentions: CustomerAttention[];
}

export const AttentionBlock: FC<AttentionBlockProps> = observer(({ attentions }): JSX.Element => {
  const anchorEl = useRef();
  const [isAttentionBlockOpen, setIsAttentionBlockOpen] = useState(false);

  const handleAttentionOpen = (): void => {
    setIsAttentionBlockOpen(true);
  };
  const handleAttentionClose = (): void => {
    setIsAttentionBlockOpen(false);
  };

  return (
    <Box
      p={0}
      m={0}
      display="flex"
      alignItems={'center'}
      sx={{ cursor: 'pointer' }}
      onMouseEnter={handleAttentionOpen}
      onMouseLeave={handleAttentionClose}
      ref={anchorEl}
    >
      <WarningIcon color="warning" fontSize="medium" sx={{ marginRight: '5px' }} />
      <Popper open={isAttentionBlockOpen} anchorEl={anchorEl.current} sx={{ zIndex: 1300 }}>
        <Paper sx={{ p: 1 }}>
          <Grid container flexDirection={'column'}>
            {attentions?.map((attention, i) => {
              return (
                <React.Fragment key={attention.code || uuidV4()}>
                  <Grid item>
                    <Typography color="text.secondary">{attention.reasonTitle}</Typography>
                  </Grid>
                  {i !== attentions.length - 1 && <Divider />}
                </React.Fragment>
              );
            })}
          </Grid>
        </Paper>
      </Popper>
    </Box>
  );
});
