import React, { FC, useCallback } from 'react';
import { useRouter } from 'next/router';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import IconButton from '@mui/material/IconButton';
interface ProductLinkBtnProps {
  productCode: string;
}
export const ProductLinkBtn: FC<ProductLinkBtnProps> = ({ productCode }): JSX.Element => {
  const routerStore = useRouter();
  const handleLinkToProduct = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, productCode: string) => {
      e.stopPropagation();
      routerStore.push(`/app/product?productCode=${encodeURIComponent(productCode)}`);
    },
    [routerStore]
  );
  return (
    <>
      <IconButton size="small" sx={{ ml: '2px' }} title="Перейти к продукту" onClick={e => handleLinkToProduct(e, productCode)}>
        <SearchOutlinedIcon />
      </IconButton>
    </>
  );
};
