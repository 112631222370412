import React, { FC } from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { observer } from 'mobx-react-lite';
import { DealItemStore } from '../../../store/DealItemStore';
import { SyncedTextField } from '../../../components/SyncedInputs/SyncedTextField';
import { ValueStoreInputTypes } from '../../../store/ValueStore';
import { TabSelectWrapper } from '../../../components/Deals/CustomIconTabs/TabSelectWrapper';

interface DealSummaryTabCommentProps {
  dealStore: DealItemStore;
  isViewOnly?: boolean;
  minHeightForTabSections?: string;
}
export const customViewOnlyComment = (value: any, style: any): JSX.Element => (
  <TextField
    multiline
    sx={{
      width: '100%',
      '& .Mui-disabled': {
        fontSize: '14px',
        padding: '7px 7px 6.5px',
        cursor: 'not-allowed',
      },
    }}
    disabled
    style={style}
    value={value}
    variant="outlined"
  />
);
export const DealSummaryTabComment: FC<DealSummaryTabCommentProps> = observer(
  ({ dealStore, isViewOnly, minHeightForTabSections }): JSX.Element => {
    const approvalComments = dealStore.getDealCommentValueStore('approval', ValueStoreInputTypes.String);
    const storeComments = dealStore.getDealCommentValueStore('store', ValueStoreInputTypes.String);
    const commercialProposalComments = dealStore.getDealCommentValueStore('commercial_proposal', ValueStoreInputTypes.String);

    return (
      <TabSelectWrapper>
        <Grid container spacing={0} minHeight={minHeightForTabSections}>
          <Grid container flexDirection="column" sx={{ maxHeight: '100%', overflow: 'hidden' }}>
            <Grid item flex="1 1 auto">
              <Typography variant="body2" color="text.secondary" sx={{ paddingLeft: 0 }}>
                Комментарий для согласования
              </Typography>
              <SyncedTextField
                type="text"
                value={approvalComments}
                isViewOnly={isViewOnly}
                isForComment
                multiline
                sx={{ maxWidth: '100%', '& legend': { width: 0 } }}
                sxTypographyProps={{ maxWidth: '100%' }}
              />
            </Grid>
            <Grid item flex="1 1 auto">
              <Typography variant="body2" color="text.secondary" sx={{ paddingTop: 1, paddingLeft: 0 }}>
                Комментарий для склада
              </Typography>
              <SyncedTextField
                type="text"
                value={storeComments}
                isViewOnly={isViewOnly}
                isForComment
                multiline
                sx={{ maxWidth: '100%', '& legend': { width: 0 } }}
                sxTypographyProps={{ maxWidth: '100%' }}
              />
            </Grid>
            <Grid item flex="1 1 auto">
              <Typography variant="body2" color="text.secondary" sx={{ paddingTop: 1, paddingLeft: 0 }}>
                Комментарий для коммерческого предложения
              </Typography>
              <SyncedTextField
                type="text"
                value={commercialProposalComments}
                isViewOnly={isViewOnly}
                isForComment
                multiline
                sx={{ maxWidth: '100%', '& legend': { width: 0 } }}
                sxTypographyProps={{ maxWidth: '100%' }}
              />
            </Grid>
          </Grid>
        </Grid>
      </TabSelectWrapper>
    );
  }
);
