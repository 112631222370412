import {
  AvailableCatalogManufacturer,
  CategoryPriceRange,
  Category as CategoryWithDetails,
  ProductBaseSize,
  ProductItemPrice,
  Product as ProductWithDetails,
} from 'src/api/marketx';

import { AppCategoryPriceRange, Category, Manufacturer, Product, CategoryViewState, BaseSize, ProductPriceRange } from '.';

const mapCategoryPriceRange = (priceRanges?: Array<CategoryPriceRange>): Record<string, AppCategoryPriceRange> => {
  const result: Record<string, AppCategoryPriceRange> = {};

  if (!priceRanges || !priceRanges.length) {
    result['1'] = {
      index: 0,
      code: '1',
      rangeCode: '1',
      minCount: 1,
      maxCount: 1,
      title: 'Без названия',
    };
    return result;
  }

  priceRanges.forEach((range, index) => {
    result[range.rangeCode] = {
      index,
      code: range.code,
      rangeCode: range.rangeCode,
      minCount: range.minCount || 0,
      maxCount: range.maxCount || 1,
      title: range.title || 'Без названия',
    };
  });

  return result;
};

const mapCategoryManufacturers = (manufacturers?: Array<AvailableCatalogManufacturer>): Record<string, Manufacturer> => {
  const result: Record<string, Manufacturer> = {};
  if (!manufacturers || !manufacturers.length) return result;

  manufacturers.forEach(man => {
    result[man.manufacturerCode || '(not set)'] = {
      quantity: man.productsQuantity,
      code: man.manufacturerCode || '(not set)',
      title: man.manufacturerTitle,
    };
  });

  return result;
};

export const mapCategory = (cat: CategoryWithDetails, manufacturers?: Array<AvailableCatalogManufacturer>): Category => {
  if (!cat || !cat.code?.length || !cat.title?.length) return null;

  const priceCodes = (cat.priceRanges || []).map(p => p.code);

  if (!priceCodes.length) priceCodes.push('1');
  return {
    index: 0,
    url: cat.code,
    code: cat.code,
    parentCode: cat.parentCode || null,
    parentCodes: [],
    title: cat.title || null,
    level: null,
    goodsCount: cat.goodsCount || 0,
    pricesCount: cat.pricesCount || 0,
    stocksCount: cat.stocksCount || 0,
    manufacturersByCode: mapCategoryManufacturers(manufacturers),
    priceRangesCodes: priceCodes,
    priceRangesByCode: mapCategoryPriceRange(cat.priceRanges || []),
    childrenCodes: [],
  };
};

const isSystemTitle = (title: string): boolean => {
  if (title.toLowerCase().indexOf('не исп') >= 0) return true;
  // if (title && /code-[a-f0-9]{8,8}-[a-f0-9]{4,4}-[a-f0-9]{4,4}-[a-f0-9]{4,4}-[a-f0-9]{12,12}/.test(title) ) {

  //     return true
  // }
  return false;
};

const hideSystemCategories = (items: Array<CategoryWithDetails>): Array<CategoryWithDetails> => {
  return items.filter(cat => !isSystemTitle(cat.title) && (cat.code || '').length);
};

const getCategoryParentCodes = (code: string, byCode: Record<string, Category>, level: number, parents: string[]): string[] => {
  const parentCode = byCode[code]?.parentCode;
  if (parentCode) {
    return getCategoryParentCodes(parentCode, byCode, level + 1, [parentCode, ...parents]);
  } else {
    return [...parents];
  }
};

const updateCategoriesParentCodes = (result: { codes: string[]; byCode: Record<string, Category> }): number => {
  const { codes, byCode } = result;

  let maxLevel = 0;
  codes.forEach(key => {
    const parentCodes = getCategoryParentCodes(key, byCode, 0, []);
    const category = byCode[key];

    // const path = parentCodes.join('/');
    category.level = parentCodes.length;
    category.parentCodes = parentCodes;
    if (parentCodes.length > maxLevel) maxLevel = parentCodes.length;
  });
  return maxLevel;
};

export const mapCategories = (
  input: Array<CategoryWithDetails>,
  currentCode?: string,
  manufacturers?: Array<AvailableCatalogManufacturer>
): CategoryViewState => {
  const result = <CategoryViewState>{ maxLevel: 0, codes: [], byCode: {} };

  if (!input || !input.length) return result;
  const backCategories = hideSystemCategories(input);

  /* backCategories.sort((a, b) => {
    const title = (a.title || '').trim().toLowerCase();
    if (title.indexOf('иное') >= 0) return 1;
    return a.title > b.title ? 1 : -1;
  });*/

  let index = 0;

  const arr = [];

  backCategories.forEach(cat => {
    const category = mapCategory(cat, manufacturers);
    if (!category || !category.code || !category.title) return;

    const childrenCodes = [...new Set(backCategories.filter(b => b.parentCode === cat.code).map(b => b.code))];

    const mapped = {
      ...category,
      childrenCodes,
      index: index,
    };

    arr.push(mapped);

    index++;
  });

  /* arr.sort((a, b) => {
    return b.childrenCodes.length - a.childrenCodes.length;
  });*/

  arr.forEach(cat => {
    if (!result.byCode[cat.code]) {
      result.byCode[cat.code as string] = cat;
      result.codes.push(cat.code);
    }
  });

  result.maxLevel = updateCategoriesParentCodes(result);

  return result;
};

const mapProductBaseSize = (baseSize?: ProductBaseSize): BaseSize => {
  if (!baseSize) {
    return null;
  }

  return {
    groupCode: baseSize.groupCode,
    groupName: baseSize.groupName,
    valueCode: baseSize.valueCode,
    valueText: baseSize.valueText,
  };
};

const mapProductPrices = (prices?: Array<ProductItemPrice>): Record<string, ProductPriceRange> => {
  const result: Record<string, ProductPriceRange> = {};

  if (!prices || !prices.length) {
    result['1'] = {
      index: 0,
      code: '1',
      title: 'Без названия',
      priceBu: 0,
      priceSu: 0,
      wovBuCost: 0,
      wovSuCost: 0,
    };
    return result;
  }

  prices.forEach((price, index) => {
    result[price.rangeCode] = <ProductPriceRange>{
      index,
      code: price.rangeCode,
      title: price.rangeTitle,
      priceBu: Math.round(price.priceBu * 100) / 100,
      priceSu: Math.round(price.priceSu * 100) / 100,
      wovBuCost: Math.round(price.wovBuCost * 100) / 100,
      wovSuCost: Math.round(price.wovSuCost * 100) / 100,
    };
  });

  return result;
};

export const mapProduct = (product: ProductWithDetails): Product => {
  if (!product || !product.code?.length) {
    return null;
  }

  return <Product>{
    index: 0,
    code: product.code,
    typeCode: product.typeCode,
    title: product.title,
    groups: product.groups,
    categoryCode: product.categoryCode,
    categoryName: product.categoryName,
    custodyAvgUnitCost: product.custodyAvgUnitCost,
    custodyWarehouses: product.custodyWarehouses,
    globalMarketCost: Math.round(product.globalMarketCost * 100) / 100,
    inReserve: Math.round((product.inReserve || 0) * 1000) / 1000,
    inStock: Math.round((product.inStock || 0) * 1000) / 1000,
    inStockForSale: Math.round((product.inStockForSale || 0) * 1000) / 1000,
    manufacturerTitle: product.manufacturerTitle,
    manufacturerCode: product.manufacturerCode,
    prices: product?.prices,
    pricesByCode: mapProductPrices(product.prices),
    reserves: product?.reserves,
    saleUnitShort: product.saleUnitShort,
    saleUnitWeight: product.saleUnitWeight,
    saleUnitCode: product.saleUnitCode,
    multiplicity: product.multiplicity,
    multiplicityUnitShort: product.multiplicityUnitShort,
    multiplicityUnitTitle: product.multiplicityUnitTitle,
    nomenclatureName: product.nomenclatureName,
    nomenclatureCode: product.nomenclatureCode,
    nomenclatureMdmKey: product.nomenclatureMdmKey,
    segmentationGroupTitle: product.segmentationGroupTitle,
    mdmKey: product.mdmKey,
    hasReserves: !!product.hasReserves,
    baseSize: mapProductBaseSize(product.baseSize),
    inTransitQuantity: product.inTransitQuantity || 0,
    distributedQuantity: product.distributedQuantity || 0,
  };
};

export type ProductsUnderPriceGroup = {
  productsCodes: string[];
  productsByCode: Record<string, Product>;
  priceRangesCodes: string[];
  priceRangesByCode?: Record<string, AppCategoryPriceRange>;
};

export type PricesGroups = {
  groupsCodes: string[];
  groupsByCodes: Record<string, ProductsUnderPriceGroup>;
};
