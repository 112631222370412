import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { ApprovalHistoryStore } from '../../../store/ApprovalHistoryStore';
import { formatYmdHiLZ } from '@mx-ui/helpers';
import { AppUserGroupCodes } from '../../../types/AppUserGroup';
import { useWebSocketHandler } from '../../../hooks/useWebSocketHandler';
import Avatar from '@mui/material/Avatar';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import Skeleton from '@mui/material/Skeleton';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Badge from '@mui/material/Badge';
import { blueGrey } from '@mui/material/colors';
import FactCheck from '@mui/icons-material/FactCheck';
import { LightTooltip, TabPanel, StyledInnerTabs } from '@mx-ui/ui';
interface ApproveHistoryDropDownProps {
  approvalStore: ApprovalHistoryStore;
  immediatelyOnStart?: boolean;
  showTabs?: boolean;
}
const actionCodeByColor = {
  DealApprovalApproveAction: 'green',
  DealApprovalDeclineAction: 'red',
  DealApprovalAskWithdraw: '#ffea00', // yellow
};

const groupsIcons = {
  [AppUserGroupCodes.DIVISION_CHIEF]: 'Д',
  [AppUserGroupCodes.COMPANY_CHIEF]: 'К',
  [AppUserGroupCodes.SELLER]: 'М',
  [AppUserGroupCodes.SELLER_CHIEF]: 'О',
  [AppUserGroupCodes.OFFICE_CHIEF]: 'Ф',
};
const sxAvatar = {
  backgroundColor: 'transparent',
  color: theme => (theme.palette.mode === 'dark' ? blueGrey[200] : blueGrey[900]),
  fontSize: '14px',
  border: theme => (theme.palette.mode === 'dark' ? `1px solid ${blueGrey[200]}` : `1px solid ${blueGrey[900]}`),
  width: 24,
  height: 24,
};

const partnersTabsValue = [
  { label: `Изменение согласования`, tabPanelIndex: 0, name: 'changeAgreement', disabled: false },
  { label: `Изменение заявки`, tabPanelIndex: 1, name: 'changeDeal', disabled: false },
];

export const ApproveHistoryDropDown: FC<ApproveHistoryDropDownProps> = observer(
  ({ approvalStore, immediatelyOnStart = false, showTabs = false }): JSX.Element => {
    const [isApprovalListOpen, setIsApprovalListOpen] = useState(false);
    const [toggleButtonValueEntity, setToggleButtonValueEntity] = useState(0);

    const handleApprovalOpen = useCallback(() => {
      approvalStore.setEnableLoading(true);
      setIsApprovalListOpen(true);
    }, [approvalStore]);
    const handleApprovalClose = useCallback(() => {
      setIsApprovalListOpen(false);
    }, [approvalStore]);
    useEffect(() => {
      approvalStore.reload(immediatelyOnStart);
    }, [immediatelyOnStart]);
    useWebSocketHandler(approvalStore.handleWs, [approvalStore]);
    const handleToggleButtonChangeEntity = useCallback((event: React.ChangeEvent<unknown>, newValue: number): void => {
      if (newValue !== null) {
        setToggleButtonValueEntity(Number(newValue));
      }
    }, []);
    const titleEl = useMemo(() => {
      return (
        <Box maxWidth={'700px'} minWidth={'500px'} maxHeight={'500px'} overflow="auto">
          {showTabs && (
            <StyledInnerTabs
              handleToggleButtonChange={handleToggleButtonChangeEntity}
              toggleButtonValue={toggleButtonValueEntity}
              tabsValue={partnersTabsValue}
            />
          )}
          <TabPanel value={toggleButtonValueEntity} index={0} paddingY={0}>
            {approvalStore.isLoaded ? (
              <Table
                size="small"
                sx={{
                  '& .MuiTableCell-body': {
                    padding: '3px 10px 3px 6px',
                  },
                  '& .MuiTableCell-root': {
                    borderBottom: 'none',
                  },
                }}
              >
                <TableBody>
                  {!!approvalStore.history?.logs?.length ? (
                    <>
                      {approvalStore.history?.logs.map((log, index) => {
                        return (
                          <TableRow key={index}>
                            <TableCell align="left" scope="row">
                              <Typography variant="body2">{formatYmdHiLZ(log.when)}</Typography>
                            </TableCell>
                            <TableCell align="left" scope="row">
                              <Avatar sx={sxAvatar}>{groupsIcons[log.userGroupCode] || '-'}</Avatar>
                            </TableCell>
                            <TableCell align="left" scope="row">
                              <Typography variant="body2">
                                {log.employee?.surname} {log.employee?.name}
                              </Typography>
                            </TableCell>
                            <TableCell align="left" scope="row">
                              <Typography variant="body2" sx={{ color: actionCodeByColor[log?.actionCode] ?? undefined }}>
                                {log.actionTitle || `Перевел в статус: ${log.newStateTitle}`}
                              </Typography>
                            </TableCell>
                            <TableCell align="left" scope="row">
                              <Typography variant="body2">{log.comment}</Typography>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </>
                  ) : (
                    <TableRow>
                      <TableCell colSpan={6} scope="row">
                        <Box width={'100%'} textAlign="center">
                          Нет данных
                        </Box>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            ) : (
              <Skeleton width={100} />
            )}
          </TabPanel>
          <TabPanel value={toggleButtonValueEntity} index={1} paddingY={0}>
            <Table
              size="small"
              sx={{
                '& .MuiTableCell-body': {
                  padding: '3px 10px 3px 6px',
                },
                '& .MuiTableCell-root': {
                  borderBottom: 'none',
                },
              }}
            >
              <TableBody>
                {!!approvalStore.updateDealLogs?.length ? (
                  <>
                    {approvalStore.updateDealLogs.map((log, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell align="left" scope="row">
                            <Typography variant="body2">{formatYmdHiLZ(log.createdAt)}</Typography>
                          </TableCell>
                          <TableCell align="left" scope="row">
                            <Avatar sx={sxAvatar}>{groupsIcons[log.userGroupCode] || '-'}</Avatar>
                          </TableCell>
                          <TableCell align="left" scope="row">
                            <Typography variant="body2">
                              {log.initiatorEmployee?.surname} {log.initiatorEmployee?.name}
                            </Typography>
                          </TableCell>
                          <TableCell align="left" scope="row">
                            <Typography variant="body2">{log.changedEntity}</Typography>
                          </TableCell>
                          <TableCell align="left" scope="row">
                            <Typography variant="body2">{log.changeEntityProperty}</Typography>
                          </TableCell>
                          <TableCell align="left" scope="row">
                            <Typography variant="body2">{log.changeValue}</Typography>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </>
                ) : (
                  <TableRow>
                    <TableCell colSpan={6} scope="row">
                      <Box width={'100%'} textAlign="center">
                        Нет данных
                      </Box>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TabPanel>
        </Box>
      );
    }, [approvalStore.loadedEpoch, toggleButtonValueEntity, approvalStore.updateDealLogs]);
    return (
      <Box title="История согласований">
        <LightTooltip
          open={isApprovalListOpen}
          onOpen={handleApprovalOpen}
          onClose={handleApprovalClose}
          title={<Box sx={{ maxWidth: '750px', overflow: 'auto' }}>{titleEl}</Box>}
          placement="bottom"
        >
          <Box p={1.5} display="flex">
            <Badge color="primary" badgeContent={approvalStore.history?.logs?.length ?? 0}>
              <FactCheck fontSize="medium" />
            </Badge>
          </Box>
        </LightTooltip>
      </Box>
    );
  }
);
