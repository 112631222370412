import { Autocomplete, Box, Chip, TextField, Theme, alpha, useTheme } from '@mui/material';
import { LabelColor, MxLabel } from '@mx-ui/ui';
import { observer } from 'mobx-react-lite';
import { CSSProperties, FC, useCallback, useEffect, useState } from 'react';
import { DocFlowExchangeState } from 'src/api/marketx';
import { ShipmentsListStore } from 'src/store/Documents/ShipmentsListStore';

type ShipmentDocFlowExchangeStatesSelectProps = {
  shipmentListStore: ShipmentsListStore;
  onChange: (docFlowExchangesCodes: string[]) => void;
  disabled?: boolean;
  sx?: CSSProperties;
  defaultValue?: DocFlowExchangeState[];
};

const getStyles = (color: string, theme: Theme): Record<string, string> => {
  return {
    ...(color === 'info' && {
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : alpha(theme.palette.grey[900], 0.1),
    }),
    ...(color === 'success' && {
      color: theme.palette.success.main,
      backgroundColor: alpha(theme.palette.success.main, 0.1),
    }),
    ...(color === 'warning' && {
      color: theme.palette.warning.main,
      backgroundColor: alpha(theme.palette.warning.main, 0.1),
    }),
    ...(color === 'error' && {
      color: theme.palette.error.main,
      backgroundColor: alpha(theme.palette.error.main, 0.1),
    }),
  };
};

export const getDocFlowExchangeStatesColor = (statusCode: string): LabelColor => {
  switch (statusCode) {
    case 'new': {
      return 'info';
    }
    case 'sent': {
      return 'warning';
    }
    case 'sending_error': {
      return 'error';
    }
    case 'sent_client': {
      return 'success';
    }
    case 'deleted': {
      return 'error';
    }
    case 'completed': {
      return 'success';
    }
    case 'canceled': {
      return 'error';
    }
    case 'removal': {
      return 'warning';
    }
    case 'in_cancel': {
      return 'warning';
    }
    case 'signed': {
      return 'success';
    }
    case 'rejected': {
      return 'error';
    }
    default: {
      return 'info';
    }
  }
};

export const ShipmentDocFlowExchangeStatesSelect: FC<ShipmentDocFlowExchangeStatesSelectProps> = observer(props => {
  const { onChange, shipmentListStore, disabled = false, sx, defaultValue } = props;

  const [docFlowExchanges, setDocFlowExchanges] = useState<DocFlowExchangeState[]>([]);

  const handleDocFlowExchangeChange = useCallback(
    (_, docFlowExchange: DocFlowExchangeState[]): void => {
      const docFlowExchanges = docFlowExchange.map(i => i.code);
      setDocFlowExchanges(docFlowExchange);
      onChange(docFlowExchanges);
    },
    [onChange]
  );

  const theme = useTheme();

  useEffect(() => {
    if (defaultValue) {
      setDocFlowExchanges(defaultValue);
    }
  }, [defaultValue]);

  const options = shipmentListStore.docFlowExchangeStatesList || [];

  return (
    <Autocomplete
      value={docFlowExchanges || []}
      onChange={handleDocFlowExchangeChange}
      multiple
      size={'small'}
      options={options || []}
      getOptionLabel={option => option.title}
      isOptionEqualToValue={(option, value) => option.code === value.code}
      renderInput={params => <TextField {...params} variant="outlined" label="Статус ЭДО документа" />}
      sx={sx}
      disabled={disabled}
      renderOption={(props: object, option) => {
        const statusCustomerColor = getDocFlowExchangeStatesColor(option.code);
        return (
          <Box sx={{ marginLeft: 0.5, cursor: 'default' }} {...props}>
            <MxLabel color={statusCustomerColor} sx={{ cursor: 'pointer', borderRadius: '16px', fontWeight: '400' }}>
              <Box fontSize={14}>{option.title}</Box>
            </MxLabel>
          </Box>
        );
      }}
      renderTags={(value: readonly DocFlowExchangeState[], getTagProps) =>
        value.map((option, index: number) => {
          const statusCustomerColor = getDocFlowExchangeStatesColor(option.code);

          return (
            <Chip
              key={option.code}
              variant="outlined"
              label={option.title}
              {...getTagProps({ index })}
              sx={{
                ...getStyles(statusCustomerColor, theme),
                border: 'none',
                textTransform: 'uppercase',
                height: '24px',
              }}
            />
          );
        })
      }
    />
  );
});
