import React, { FC, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { useRootStore } from '../../store/MobxStoreProvider';
import { BatchCertificateStore } from '../../store/Catalog/BatchCertificateStore';
import Box from '@mui/material/Box';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { DialogContainer } from '../ui/dialogs/DialogContainer';
interface CertificateBatchModalProps {
  batchCode: string;
  batchNumber?: string;
  certificateName?: string;
  shipmentCode?: string;
  closeModal: () => void;
  isModalOpen: boolean;
}

export const CertificateBatchModal: FC<CertificateBatchModalProps> = observer(
  ({ batchCode, batchNumber, certificateName, shipmentCode, closeModal, isModalOpen }) => {
    const rootStore = useRootStore();
    const certificateStore = useMemo(() => {
      return new BatchCertificateStore(rootStore);
    }, [rootStore]);
    const handleCancel = (): void => {
      closeModal();
    };

    useEffect(() => {
      if (!!batchCode.length || !!shipmentCode.length) {
        certificateStore.loadBatchCertificate(batchCode, shipmentCode);
      }
    }, [batchCode, shipmentCode]);

    return (
      <DialogContainer
        open={isModalOpen}
        onClose={handleCancel}
        dialogTitle={`${shipmentCode ? 'Сертификаты' : 'Сертификат'} партии ${batchNumber}`}
        dialogContent={
          <DialogContent>
            <DialogContentText>
              <span>
                Загрузка {shipmentCode ? 'сертификатов' : 'сертификата'} {certificateName}
              </span>
              <span></span>
            </DialogContentText>
            <Box mt={1}>
              {!certificateStore.isLoaded ? (
                <p>Получение статуса...</p>
              ) : certificateStore.isAwaiting ? (
                <p>Статус: {certificateStore.stateTitle || 'загрузка'}...</p>
              ) : Boolean(certificateStore.certificateError) ? (
                <span>Ошибка: {certificateStore.certificateError}</span>
              ) : Boolean(certificateStore.certificateHref && certificateStore.certificateSize) ? (
                <span>
                  {certificateStore.awaitingBatchCode && (
                    <a href={certificateStore.certificateHref}>Скачать {certificateStore.certificateSize} байт</a>
                  )}
                  {certificateStore.awaitingShipmentCode && <a href={certificateStore.certificateHref}>Скачать zip файл</a>}
                </span>
              ) : (
                <span>Файл недоступен</span>
              )}
            </Box>
          </DialogContent>
        }
        canselBtnText={'Отмена'}
      />
    );
  }
);
