import { makeAutoObservable, onBecomeUnobserved, runInAction, set, toJS } from 'mobx';
import { RootStore } from '../StoreManager';
import { ApiStore, AxiosCallContext, getCallContext } from '../Global/ApiStore';
import {
  ClientsForFilterResponse,
  ContractCostAgreement,
  ContractCostAgreementsResponse,
  CustomerAsFilter,
  DocumentApprovalState,
  DocumentsApprovalStatesResponse,
  DocumentsTypesResponse,
  DocumentType,
  Employee,
} from '../../api/marketx';
import { AxiosResponse } from 'axios';
import { RouterStore } from '../RouterStore';
import { formatDateSwagger } from '@mx-ui/helpers';
import { quickDateRanges, RangeVariant } from 'src/components/SelectDateRange/MenuButtonNew';
import { dateEnd, dateStart, defaultQuickDate } from '../Documents/utils/defaultQuickDate';
import { AutocompleteValue } from '../../components/Clients/ClientDocuments/Hooks/useClientDocumentsSelect';
import { TotalStoreEntity, totalStoreGenerate } from 'src/components/Documents/DocumentTotalCount';
import { WebSocketStore } from '../Global/WebSocketStore';
import { IncomeMessage } from '../Global/EventManager';
import { SnackbarStore } from '../SnackbarStore';
import { MyApprovalsAskingStore } from '../Deals/MyApprovalsAsking';
import { DealListRequest } from '../DealListStore';
import { AppDealDistributor } from '../../slices/AppDeal';
const defaultPageSize = 12;
const defaultCustomerFilterCount = 100;

export class AgreementListRequest {
  customerCode?: string;
  customerCodes?: string[];
  clientCode?: string;
  query?: string;
  queryCustomer?: string;
  employeeSetCode?: string; // Фильтр по реализатору
  quickRange?: string;
  officeCodes?: string[];
  approvalStateCodes?: string[];
  documentTypeCodes?: string[];
  page?: number;
  count?: number;
  customerFilterPage?: number;
  customerFilterCount?: number;
  dateFrom?: Date;
  dateTo?: Date;
  marketDeviationLevels?: number[];
  stateIds?: number[];
}

export class AgreementListStore {
  apiStore: ApiStore;
  webSocketStore: WebSocketStore;
  snackbarStore: SnackbarStore;
  distributorsStore: MyApprovalsAskingStore;
  isLoaded = false;
  customerCodeFromDeal = '';
  routerStore: RouterStore;
  isLoading = true;
  isStatsLoading = true;
  isMoreLoading = false;
  public isFilterCustomerLoading = true;
  routerControlEnabled = false;
  loadedEpoch = 0;
  quickRange: RangeVariant = defaultQuickDate;
  request: AgreementListRequest = {
    count: defaultPageSize,
    query: '',
    dateFrom: dateStart,
    dateTo: dateEnd,
  };
  ignoreBeforeDate?: Date;
  public filterBranchOffices: AutocompleteValue[] = [];
  public initialBranchOffice: AutocompleteValue = null;
  isInitialLoading = true;

  items?: ContractCostAgreement[] = [];
  searchIndex = 0;
  public documentsCustomersFilterList: CustomerAsFilter[] = [];

  hasMore = false;
  totalStoreEntity: TotalStoreEntity = null;
  approvalStatuses: DocumentApprovalState[] = [];
  docTypes: DocumentType[] = [];

  constructor(rootStore: RootStore, customerCodeForWS = '') {
    this.apiStore = rootStore.getApiStore();
    this.routerStore = rootStore.getRouter();
    if (customerCodeForWS) {
      this.customerCodeFromDeal = customerCodeForWS;
      this.webSocketStore = rootStore.getWebSocket();
      this.snackbarStore = rootStore.getSnackbar();
    }
    this.handleWs = this.handleWs.bind(this);
    this.loadMore = this.loadMore.bind(this);
    this.loadMoreForClient = this.loadMoreForClient.bind(this);
    makeAutoObservable(this, {
      apiStore: false,
      webSocketStore: false,
      snackbarStore: false,
    });
    onBecomeUnobserved(this, 'customerCodeFromDeal', () => {
      this.webSocketStore.removeSubscriptions(['agreement_customer.' + this.customerCodeFromDeal]);
    });
  }

  employeeSetChange(currentRole: string, emp?: Employee): void {
    if (!emp) {
      this.mergeRequest({ employeeSetCode: currentRole });
      return;
    }
    let newVal = '';
    if (!this.distributorsStore.employees?.length) {
      return;
    }
    for (let i = 0; i < this.distributorsStore.employees?.length; i++) {
      if (this.distributorsStore.employees[i]?.code === emp?.code || this.distributorsStore.employees[i]?.employee?.code === emp?.code) {
        newVal = this.distributorsStore.employees[i]?.code;
        break;
      }
    }
    if (!newVal) {
      newVal = `~emp~2~${emp?.code}`;
    }
    runInAction(() => {
      const oldVal = this.request.employeeSetCode || null;
      if (newVal !== oldVal) {
        this.employeeSetMergeRequest({ employeeSetCode: newVal }, emp);
      }
    });
  }
  loadMyApprovalsAskingStore(rootStore: RootStore, initEmployee?: string): void {
    this.distributorsStore = new MyApprovalsAskingStore(rootStore);
    this.distributorsStore.loadMyList(initEmployee);
  }
  employeeSetMergeRequest(req: DealListRequest, emp: AppDealDistributor): void {
    this.distributorsStore.loadMyList(emp.employeeCode);
    this.mergeRequest(req);
  }
  changeQuickRange(v: RangeVariant): void {
    this.quickRange = v;
    set(this.request, { quickRange: v.value });
  }
  setRouterControl(enabled: boolean): void {
    runInAction(() => {
      this.routerControlEnabled = enabled;
    });
  }
  mergeRequest(req: AgreementListRequest): void {
    set(this.request, req);
    this.request.page = undefined;
    this.isLoaded = false;
    this.isMoreLoading = false;
    this.isLoading = true;
    this.isStatsLoading = true;
    this.actualizeRouter(toJS(this.request));
    this.refresh();
  }
  actualizeRouter(req: AgreementListRequest): void {
    if (!this.routerControlEnabled) {
      return;
    }
    const params = new URLSearchParams();

    if (req.customerCodes) {
      params.set('customerCodes', `${req.customerCodes.join(',')}`);
    }
    if (req.query) {
      params.set('query', req.query);
    }
    if (req.employeeSetCode) {
      params.set('employeeSetCode', req.employeeSetCode);
    }
    if (req.quickRange) {
      params.set('quickRange', req.quickRange);
    }
    if (req.dateFrom) {
      params.set('dateFrom', formatDateSwagger(req.dateFrom));
    }
    if (req.dateTo) {
      params.set('dateTo', formatDateSwagger(req.dateTo));
    }

    if (req.marketDeviationLevels?.length) {
      params.set('marketDeviationLevels', req.marketDeviationLevels.join(','));
    }

    if (req.approvalStateCodes?.length) {
      params.set('approvalStateCodes', req.approvalStateCodes.join(','));
    }

    if (req.documentTypeCodes?.length) {
      params.set('documentTypeCodes', req.documentTypeCodes.join(','));
    }

    if (req.stateIds?.length) {
      params.set('stateIds', req.stateIds.join(','));
    }

    let paramsStr = params.toString();
    if (paramsStr) {
      paramsStr = '?' + paramsStr;
    }
    let url = '/app/agreements';
    url += paramsStr;
    this.routerStore.replace(url, undefined, { shallow: true });
  }
  refresh(): void {
    this.loadList(this.request);
  }
  sendTouchUpdateWs(code?: string): void {
    const entities = code ? ['agreement_customer.' + code] : [];
    const sendUpdate = (): void => {
      if (this.webSocketStore.isConnected) {
        this.webSocketStore.addSubscriptions(entities);
      } else {
        setTimeout(sendUpdate, 1000);
      }
    };
    sendUpdate();
  }
  handleWs(msg: IncomeMessage): void {
    const entities = msg.data?.entities;
    if (entities?.includes(`agreement_customer.${this?.customerCodeFromDeal}`)) {
      this.refresh();
      setTimeout(() => {
        this.snackbarStore.showInfo(`Список согласованных соглашений изменен`);
      }, 200);
      return;
    }
  }
  loadList(r: AgreementListRequest): void {
    this.isLoading = true;
    set(this.request, r);
    const req = Object.assign({}, this.request);
    req.count = req.count || defaultPageSize;
    const dateFrom = req.dateFrom ? formatDateSwagger(req.dateFrom) : undefined;
    const dateTo = req.dateTo ? formatDateSwagger(req.dateTo) : undefined;
    runInAction(() => {
      if (req.quickRange) {
        this.quickRange = quickDateRanges.find(t => t.value === req.quickRange);
      }
    });
    this.apiStore
      .apiClientCustomer()
      .contractCostAgreements(
        req.customerCode,
        req.customerCodes?.length ? req.customerCodes : undefined,
        undefined,
        undefined,
        req.page,
        req.count,
        req.query || undefined,
        req.employeeSetCode,
        dateFrom,
        dateTo,
        req.marketDeviationLevels,
        req.stateIds,
        req.approvalStateCodes?.length ? req.approvalStateCodes : undefined,
        req.documentTypeCodes?.length ? req.documentTypeCodes : undefined
      )
      .then((res: AxiosResponse<ContractCostAgreementsResponse>): void => {
        this.loadListResponse(getCallContext(res), req, res.data);
        this.loadAgreementStats();
      })
      .catch(r => {
        console.warn(r);
      });
  }

  loadAgreementStats(): void {
    this.isStatsLoading = true;
    const req = Object.assign({}, this.request);
    req.count = req.count || defaultPageSize;
    const dateFrom = req.dateFrom ? formatDateSwagger(req.dateFrom) : undefined;
    const dateTo = req.dateTo ? formatDateSwagger(req.dateTo) : undefined;
    runInAction(() => {
      if (req.quickRange) {
        this.quickRange = quickDateRanges.find(t => t.value === req.quickRange);
      }
    });
    this.apiStore
      .apiClientCustomer()
      .contractCostAgreementsStats(
        req.customerCode || req.clientCode,
        req.customerCodes?.length ? req.customerCodes : undefined,
        undefined,
        undefined,
        req.page,
        req.count,
        req.query || undefined,
        req.employeeSetCode,
        dateFrom,
        dateTo,
        req.marketDeviationLevels,
        req.stateIds,
        req.approvalStateCodes?.length ? req.approvalStateCodes : undefined,
        req.documentTypeCodes?.length ? req.documentTypeCodes : undefined,
        this.searchIndex
      )
      .then((res): void => {
        runInAction(
          () => (this.totalStoreEntity = totalStoreGenerate(res.data.totalCount || 0, res.data.totalWeight || 0, res.data.totalCost || 0))
        );
      })
      .catch(r => {
        console.warn(r);
      })
      .finally(() => runInAction(() => (this.isStatsLoading = false)));
  }

  loadListResponse(ctx: AxiosCallContext, req: AgreementListRequest, res: ContractCostAgreementsResponse): void {
    if (this.ignoreBeforeDate && ctx.startTime < this.ignoreBeforeDate) {
      return;
    }
    if (this.isMoreLoading) {
      this.isMoreLoading = false;
      this.items.push(...res.agreements);
    } else {
      this.items = res.agreements || [];
    }
    this.searchIndex = res.searchIndex;
    this.webSocketStore && this.customerCodeFromDeal && this.sendTouchUpdateWs(this.customerCodeFromDeal);
    this.isLoading = false;
    this.isLoaded = true;
    this.loadedEpoch++;
    this.hasMore = res.agreements.length >= req.count;

    this.routerControlEnabled && this.loadCustomersForFilter();
  }

  loadMore(): void {
    if (this.isLoading) {
      // уже загружается
      return;
    }
    this.isMoreLoading = true;
    this.request.page = (this.request?.page || 1) + 1;
    this.refresh();
  }

  // ↓ методы для загрузки счетов со страницы клиента (документы)
  setInitialOffice(code: string, name: string): void {
    this.initialBranchOffice = { code, name };
  }

  resetItems(): void {
    runInAction(() => {
      this.items = [];
    });
  }

  loadMoreForClient(officesItems: AutocompleteValue[]): void {
    if (this.isLoading) {
      // уже загружается
      return;
    }
    runInAction(() => {
      this.isMoreLoading = true;
      this.request.officeCodes = officesItems.some(i => i.code === 'selectAll')
        ? this.filterBranchOffices.filter(i => i.code !== 'selectAll').map(i => i.code)
        : officesItems.map(i => i.code);
      this.request.page = (this.request.page || 1) + 1;
    });
    this.loadFromClientDocuments(this.request);
  }
  mergeRequestToClient(req: AgreementListRequest): void {
    set(this.request, req);
    runInAction(() => {
      this.request.page = undefined;
      this.isMoreLoading = false;
      this.isLoading = true;
      this.isStatsLoading = true;
    });
    this.loadFromClientDocuments(this.request);
  }
  loadCustomersForFilter(queryCustomer?: string): void {
    if (queryCustomer) {
      this.request.queryCustomer = queryCustomer;
    } else {
      this.request.queryCustomer = undefined;
    }
    if (this.routerControlEnabled) {
      this.loadFilterCustomerCodes();
    }
  }

  loadFilterCustomerCodes(): void {
    runInAction(() => {
      this.isFilterCustomerLoading = true;
    });
    const req = Object.assign({}, this.request);
    req.customerFilterPage = req.customerFilterPage > 1 ? req.customerFilterPage : undefined;
    req.customerFilterCount = req.customerFilterCount || defaultCustomerFilterCount;
    this.apiStore
      .apiClientCustomer()
      .clientsForFilter({
        documentType: 'agreement',
        requiredCustomerCodes: req.customerCodes?.length ? req.customerCodes : undefined,
        dateFrom: req.dateFrom ? formatDateSwagger(req.dateFrom) : undefined,
        dateTo: req.dateTo ? formatDateSwagger(req.dateTo) : undefined,
        employeeSet: req.employeeSetCode || undefined,
        agreementStateIds: req.stateIds?.length ? req.stateIds : undefined,
        queryDocuments: req.query || undefined,
        marketDeviationLevel: req.marketDeviationLevels?.length ? req.marketDeviationLevels : undefined,
        approvalStateCodes: req.approvalStateCodes?.length ? req.approvalStateCodes : undefined,
        page: req.customerFilterPage || undefined,
        count: req.customerFilterCount,
        queryCustomers: req.queryCustomer || undefined,
        documentTypeCodes: req.documentTypeCodes?.length ? req.documentTypeCodes : undefined,
      })
      .then(res => {
        this.setFilterCustomerResult(res.data);
      })
      .catch(e => {
        console.warn('loadFilterCustomerCodes req', e);
        runInAction(() => {
          this.isFilterCustomerLoading = false;
        });
      });
  }

  setFilterCustomerResult(statuses: ClientsForFilterResponse): void {
    if (statuses.customers?.length) {
      const prevCheckedCustomers = [];
      this.documentsCustomersFilterList.forEach(prevI => {
        if (this.request.customerCodes?.includes(prevI.code) && !statuses.customers.find(i => i.code === prevI.code)) {
          prevCheckedCustomers.push(prevI);
        }
      });
      if (this.request?.customerCodes?.length) {
        this.documentsCustomersFilterList = [...prevCheckedCustomers, ...statuses.customers];
      } else {
        this.documentsCustomersFilterList = [...statuses.customers];
      }
    }
    runInAction(() => {
      this.isFilterCustomerLoading = false;
    });
  }
  loadFromClientDocuments(r: AgreementListRequest): void {
    this.isLoading = true;
    set(this.request, r);
    const req = Object.assign({}, this.request);
    req.count = req.count || defaultPageSize;
    this.apiStore
      .apiClientCustomer()
      .contractCostAgreements(
        req.clientCode,
        req.customerCodes?.length ? req.customerCodes : undefined,
        undefined,
        this.isInitialLoading && !req.officeCodes?.length ? undefined : req.officeCodes,
        req.page,
        req.count,
        undefined,
        undefined,
        req.dateFrom ? formatDateSwagger(req.dateFrom) : undefined,
        req.dateFrom ? formatDateSwagger(req.dateTo) : undefined,
        req.marketDeviationLevels,
        req.stateIds,
        req.approvalStateCodes?.length ? req.approvalStateCodes : undefined,
        req.documentTypeCodes?.length ? req.documentTypeCodes : undefined
      )
      .then((res): void => {
        this.loadForClientResult(req, res.data);
        this.loadAgreementStats();
      })
      .catch(r => {
        console.warn(r);
      });
  }
  loadForClientResult(req: AgreementListRequest, res: ContractCostAgreementsResponse): void {
    if (this.isMoreLoading) {
      this.isMoreLoading = false;
      this.items.push(...res.agreements);
      if (!this.filterBranchOffices.length) {
        this.filterBranchOffices = (res.agreementsBranchOffices as AutocompleteValue[]) || [];
      }
    } else {
      this.items = res.agreements;
      this.filterBranchOffices = (res.agreementsBranchOffices as AutocompleteValue[]) || [];
    }
    this.searchIndex = res.searchIndex;
    if (!this.filterBranchOffices.some(item => item.code === this.initialBranchOffice.code)) {
      this.filterBranchOffices.push(this.initialBranchOffice);
    }
    if (this.filterBranchOffices.length > 1) {
      this.filterBranchOffices = [{ code: 'selectAll', name: 'Все филиалы' }, ...this.filterBranchOffices];
    }
    this.isInitialLoading = false;
    this.isLoading = false;
    this.isLoaded = true;
    this.hasMore = res.agreements.length >= req.count;
  }
  // загрузка справочника статусов согласований
  loadApprovalStatuses(): void {
    this.apiStore
      .apiDocuments()
      .documentsApprovalStates()
      .then(res => {
        this.setApprovalStatusesResult(res.data);
      })
      .catch(e => {
        console.warn('loadDealStatuses', e);
      });
  }
  loadDocTypes(): void {
    this.apiStore
      .apiDocuments()
      .documentsTypes()
      .then(res => {
        this.setDocTypesResult(res.data);
      })
      .catch(e => {
        console.warn('loadDocTypesRequest', e);
      });
  }
  setDocTypesResult(data: DocumentsTypesResponse): void {
    this.docTypes = data.types;
  }
  setApprovalStatusesResult(data: DocumentsApprovalStatesResponse): void {
    this.approvalStatuses = data.states;
  }
}
