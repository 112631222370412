import React, { FC, useMemo, useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { HandShakeIcon } from '../../ui/icons/HandShakeIcon';
import { formatNumber3, formatNumberByUnit, formatPrice, formatPriceCur } from '@mx-ui/helpers';
import { MxLabel } from '@mx-ui/ui';
import { getMarginalityColorClass, multicoloredPopoverBg } from '../../../views/clients/lib';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { observer } from 'mobx-react-lite';
import { useAuthStore, useRootStore } from '../../../store/MobxStoreProvider';
import { AppDeal, AppDealPosition } from '../../../slices/AppDeal';
import { PositionWithDocuments, PreviewMenu } from '../../UploadFile/PreviewMenu';
import { CollapseButton } from '../../../views/deals/components/CollapseButton';
import {
  ChipTitleStyled,
  FindQuantityFromList,
  findQuantityFromList,
  InfoIconDeal,
} from '../../../views/deals/DealPosition/DealPositionPrices';
import { ColorChangedPropertyDeal } from 'src/views/deals/DealContainer';
import LightTooltip from 'src/components/ToolTip/LightTooltip';
import { ProductLinkBtn } from '../../Product/ProductLinkBtn/ProductLinkBtn';
import { TooltipForReserveList } from '../../Product/Reserve/TooltipForReseveList';
import { ReservesStore } from '../../../store/ReservesStore';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import IconButton from '@mui/material/IconButton';
import FindReplaceOutlinedIcon from '@mui/icons-material/FindReplaceOutlined';
import { PositionsManufacturersListStore } from '../../../store/Deals/PositionsManufacturersListStore';
import { DialogContainer } from '../../ui/dialogs/DialogContainer';
import { DealListAlternativeProductsTable } from '../../Deals/DealListAlternativeProductsTable';
import { Link as MxLink } from '@mx-ui/ui';

interface OderPositionsProps {
  order: AppDeal;
  documents: Record<string, PositionWithDocuments>;
  reserveStore?: ReservesStore;
}

export const OrderPositions: FC<OderPositionsProps> = observer(({ order, documents, reserveStore }): JSX.Element => {
  const matchesMd = useMediaQuery('(min-width:900px)');
  const rootStore = useRootStore();
  const { profile } = useAuthStore();
  const allAccordionIndexes = order?.positions.map((item, index) => `panel${index}`) || [];
  const [expandAll, setExpandAll] = useState([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [currentPosition, setCurrentPosition] = useState<AppDealPosition | null>(null);
  const positionsManufacturersStore = useMemo(() => {
    return new PositionsManufacturersListStore(rootStore);
  }, [rootStore]);
  const handleCloseAllPositions = (): void => {
    setExpandAll(prevState => {
      if (prevState.length) {
        return [];
      }
      return allAccordionIndexes;
    });
  };
  const handleAccordionChange = (panelIndex: string): void => {
    setExpandAll(prevState => {
      const position = prevState.indexOf(panelIndex);
      if (position === -1) {
        return [...prevState, panelIndex];
      }
      return prevState.filter((item, index) => index !== position);
    });
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const [tooltipName, setTooltipName] = useState('');
  const handleTooltipOpen = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, type: string): void => {
    event.stopPropagation();
    setAnchorEl(event.target);
    setTooltipName(type);
  };
  const handleTooltipClose = (): void => {
    setAnchorEl(null);
    setTooltipName('');
  };
  const positionQuantityParams = useMemo<Record<string, FindQuantityFromList>>(() => {
    const result = {};
    order.positions.forEach(pos => {
      result[pos.code] = findQuantityFromList(pos);
    });
    return result;
  }, [order, order.positions]);

  const handleAlternativeBtnClick = (pos: AppDealPosition): void => {
    if (!positionsManufacturersStore.loaded) {
      positionsManufacturersStore.loadForDeal(order);
    }
    setCurrentPosition(pos);
    setIsDialogOpen(true);
  };
  const handleDialogClose = (): void => {
    setIsDialogOpen(false);
  };

  return order.positions?.length > 0 ? (
    <>
      {matchesMd ? (
        <Grid mb={'20px'} container>
          {order.positions.map((pos, index) => {
            const currency = pos.currency;
            const clientUnit = pos.unit;
            if (pos.isArchive) return null;
            return (
              <Grid item key={index} xs={12}>
                <Grid container px={2}>
                  <Grid item xs={12}>
                    <Grid container flexWrap={'nowrap'} alignItems={'center'} spacing={1}>
                      <Grid item>
                        <ChipTitleStyled
                          label={`#${pos.lineNumber}`}
                          sx={{ backgroundColor: pos.changesLog ? '#AFDEEF' : '', color: pos.changesLog ? '#465960' : 'text.light' }}
                        />
                      </Grid>
                      <Grid item>
                        <Typography component="span" style={{ fontSize: '11px' }}>
                          {`${pos?.product?.code || ''} `}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <MxLink href={`/app/product?productCode=${encodeURIComponent(pos.productCode)}`} color="inherit" fontSize={'14px'}>
                          {pos?.title || ''}
                        </MxLink>
                      </Grid>
                      {documents[pos.code] && (
                        <Grid item>
                          <Box component="div" sx={{ display: 'inline-block' }}>
                            <PreviewMenu documents={documents} positionCode={pos.code} isWithBadge />
                          </Box>
                        </Grid>
                      )}
                      {pos?.product?.hasReserves && reserveStore ? (
                        <Grid item>
                          <TooltipForReserveList
                            reserveStore={reserveStore}
                            product={pos?.product}
                            icon={'deal'}
                            branchOfficeCode={order.branchOfficeCode}
                            warehouseCode={pos?.warehouseCode}
                          />
                        </Grid>
                      ) : null}
                    </Grid>
                  </Grid>
                  <Grid item xs={12} container flexDirection="row" justifyContent="space-between">
                    <Grid px={1} item minWidth={'100px'}>
                      {!pos.isService && (
                        <Grid container flexDirection="column" alignItems="center">
                          <Grid item>
                            <Typography fontSize={'12px'}>Альтернативы</Typography>
                          </Grid>
                          <Grid item>
                            <IconButton
                              size={'small'}
                              title={'Список альтернативных товаров'}
                              onClick={() => handleAlternativeBtnClick(pos)}
                              disabled={positionsManufacturersStore.loading}
                            >
                              <FindReplaceOutlinedIcon sx={{ fontSize: '20px' }} />
                            </IconButton>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                    <Grid px={1} item>
                      <Grid container flexDirection="column" alignItems="flex-end">
                        <Grid item>
                          <Typography fontSize={'12px'}>МРЦ</Typography>
                        </Grid>
                        <Grid item container flexWrap="nowrap">
                          {!!pos.agreementPositionCode && <HandShakeIcon />}
                          <Typography fontSize={'12px'} color="text.secondary">
                            {formatPrice(pos?.minRetailUnitCost || 0) + ' ' + currency + '/' + clientUnit}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid px={1} item>
                      <Grid container flexDirection="column" alignItems="flex-end">
                        <Grid item>
                          <Typography fontSize={'12px'}>Цена</Typography>
                        </Grid>
                        <Grid item>
                          <LightTooltip
                            placement="right"
                            title={
                              pos.changesLog?.oldUnitCost ? (
                                <Box p={0.6}>
                                  <Typography variant="subtitle2" component="span" fontWeight={500}>
                                    Старое значение: {pos.changesLog?.oldUnitCost}&nbsp;{currency}/{clientUnit}
                                  </Typography>
                                </Box>
                              ) : undefined
                            }
                            arrow
                          >
                            <Typography
                              fontSize="12px"
                              color="text.secondary"
                              sx={{ backgroundColor: pos.changesLog?.unitCost ? ColorChangedPropertyDeal : undefined }}
                            >
                              {formatPrice(pos?.calculatedBareUnitCost || pos?.bareUnitCost || pos?.cost || 0) +
                                ' ' +
                                currency +
                                '/' +
                                clientUnit}
                            </Typography>
                          </LightTooltip>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid px={1} item>
                      <Grid container flexDirection="column" alignItems="flex-end">
                        <Grid item>
                          <Typography fontSize={'12px'}>Доставка</Typography>
                        </Grid>
                        <Grid item>
                          <Typography fontSize="12px" color="text.secondary">
                            {formatPrice(pos?.distributeDeliveryCost || 0) + ' ' + currency + '/' + clientUnit}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid px={1} item>
                      <Grid container flexDirection="column" alignItems="flex-end">
                        <Grid item>
                          <Typography fontSize={'12px'}>Кредит</Typography>
                        </Grid>
                        <Grid item>
                          <Typography fontSize="12px" color="text.secondary">
                            {formatPrice(pos?.creditUnitCost || 0) + ' ' + currency + '/' + clientUnit}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid px={1} item>
                      <Grid container flexDirection="column" alignItems="flex-end">
                        <Grid item>
                          <Typography fontSize={'12px'}>Цена&nbsp;с&nbsp;услугами</Typography>
                        </Grid>
                        <Grid item>
                          <Typography fontSize="12px" color="text.secondary">
                            {formatPrice(pos?.fullUnitCost || 0) + ' ' + currency + '/' + clientUnit}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid px={1} item>
                      <Grid container flexDirection="column" alignItems="flex-end">
                        <Grid item>
                          <Typography fontSize={'12px'}>Количество</Typography>
                        </Grid>
                        <Grid item>
                          <Grid container>
                            <Grid
                              item
                              onMouseEnter={event => pos.changesLog?.oldQuantity && handleTooltipOpen(event, `${pos.code}oldQuantity`)}
                              onMouseLeave={() => handleTooltipClose()}
                            >
                              <Typography
                                fontSize="12px"
                                color="text.secondary"
                                sx={{ backgroundColor: pos.changesLog?.quantity ? ColorChangedPropertyDeal : undefined }}
                              >
                                {formatNumberByUnit(pos?.amount || 0, pos?.unitCode || 'TNE') + ' ' + clientUnit}
                              </Typography>
                              <Popper placement="top" open={tooltipName === `${pos.code}oldQuantity`} anchorEl={anchorEl}>
                                <Paper
                                  sx={{
                                    padding: 0.5,
                                  }}
                                >
                                  <Box p={0.6}>
                                    <Typography variant="subtitle2" component="span" fontWeight={500}>
                                      Старое значение: {pos.changesLog?.oldQuantity}&nbsp;{clientUnit}
                                    </Typography>
                                  </Box>
                                </Paper>
                              </Popper>
                            </Grid>
                            <Grid
                              item
                              ml={0.8}
                              onMouseEnter={event => handleTooltipOpen(event, `${pos.code}amount`)}
                              onMouseLeave={() => handleTooltipClose()}
                            >
                              <Box display="flex" justifyContent="center">
                                <InfoIconDeal color={positionQuantityParams[pos.code]?.colorForStock} />
                              </Box>
                              <Popper placement="top" open={tooltipName === `${pos.code}amount`} anchorEl={anchorEl}>
                                <Paper
                                  sx={{
                                    padding: 0.5,
                                    ...multicoloredPopoverBg(positionQuantityParams[pos.code]?.colorForStock),
                                  }}
                                >
                                  <Box p={0.6}>
                                    <Typography variant="caption" color="inherit" fontWeight="500">
                                      {positionQuantityParams[pos.code].titleForStock}
                                    </Typography>
                                  </Box>
                                </Paper>
                              </Popper>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid px={1} item>
                      <Grid container flexDirection="column" alignItems="flex-end">
                        <Grid item>
                          <Typography fontSize={'12px'}>Сумма</Typography>
                        </Grid>
                        <Grid item>
                          <Typography fontSize="12px" color="text.secondary">
                            {formatPrice(pos?.fullTotalCost || 0) + ' ' + currency}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid px={1} item>
                      <Grid container flexDirection="column" alignItems="flex-end">
                        <Grid item>
                          <Typography fontSize={'12px'}>МаржМРЦ</Typography>
                        </Grid>
                        <Grid item>
                          <MxLabel
                            style={{ fontSize: '12px', display: 'inline', paddingTop: '4px' }}
                            color={getMarginalityColorClass(pos.marginalityPct, true)}
                          >
                            {formatPriceCur(pos?.marginalityPct || 0, '%')}
                          </MxLabel>
                        </Grid>
                      </Grid>
                    </Grid>
                    {profile?.features?.canSeeDealPrimeCostMargin && (
                      <>
                        <Grid px={1} item>
                          <Grid container flexDirection="column" alignItems="flex-end">
                            <Grid item>
                              <Typography fontSize={'12px'}>МаржПлС</Typography>
                            </Grid>
                            <Grid item>
                              <MxLabel
                                style={{ fontSize: '12px', display: 'inline', paddingTop: '4px' }}
                                color={getMarginalityColorClass(pos.primeCostMarginPct, true)}
                              >
                                {formatPriceCur(pos?.primeCostMarginPct || 0, '%')}
                              </MxLabel>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid px={1} item>
                          <Grid container flexDirection="column" alignItems="flex-end">
                            <Grid item>
                              <Typography fontSize={'12px'}>МаржЦЗ</Typography>
                            </Grid>
                            <Grid item>
                              <MxLabel
                                style={{ fontSize: '12px', display: 'inline', paddingTop: '4px' }}
                                color={getMarginalityColorClass(pos.purchaseCostMarginPct, true)}
                              >
                                {formatPriceCur(pos?.purchaseCostMarginPct || 0, '%')}
                              </MxLabel>
                            </Grid>
                          </Grid>
                        </Grid>
                      </>
                    )}
                  </Grid>
                  {index !== order.positions.length - 1 && (
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      ) : (
        <Grid mb={'20px'}>
          <Grid container justifyContent="flex-end" pr={2} mb={1}>
            <CollapseButton color={'#757575'} type={expandAll.length ? 'expand-all' : 'less-all'} onClick={handleCloseAllPositions} />
          </Grid>
          {order.positions.map((pos, index) => {
            const hasMarginalityMarker =
              pos?.marginalityPct < 0 ||
              pos?.primeCostMarginPct ||
              pos?.purchaseCostMarginPct < 0 ||
              positionQuantityParams[pos.code]?.colorForStock === 'red' ||
              positionQuantityParams[pos.code]?.colorForStock === 'yellow';
            const currency = pos.currency;
            const clientUnit = pos.unit;
            if (pos.isArchive) return null;
            return (
              <Accordion
                key={index}
                expanded={expandAll.includes(`panel${index}`)}
                onChange={() => handleAccordionChange(`panel${index}`)}
                sx={{
                  margin: '0px !important',
                  boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%)',
                  ':before': {
                    display: 'none',
                  },
                }}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ borderTop: '1px solid #DBDBDB' }}>
                  <Grid container flexWrap="nowrap" justifyContent="space-between" alignItems="center" sx={{ position: 'relative' }}>
                    <Grid item alignItems="center">
                      <ChipTitleStyled
                        label={`#${pos.lineNumber}`}
                        sx={{ backgroundColor: pos.changesLog ? '#AFDEEF' : '', color: pos.changesLog ? '#465960' : 'text.light' }}
                      />
                      <Typography component="span" style={{ fontSize: '11px' }}>
                        {`${pos?.product?.code || ''} `}
                      </Typography>
                      <Typography component="span" fontSize={'14px'}>
                        {pos?.title || ''}
                      </Typography>
                      <ProductLinkBtn productCode={pos.productCode} />
                      {documents[pos.code] && (
                        <Box component="div" sx={{ display: 'inline-block' }}>
                          <PreviewMenu documents={documents} positionCode={pos.code} isWithBadge />
                        </Box>
                      )}
                      {pos?.product?.hasReserves ? (
                        <TooltipForReserveList
                          reserveStore={reserveStore}
                          product={pos?.product}
                          icon={'deal'}
                          branchOfficeCode={order.branchOfficeCode}
                          warehouseCode={pos?.warehouseCode}
                        />
                      ) : null}
                    </Grid>
                    {hasMarginalityMarker && (
                      <Grid item sx={{ position: 'absolute', right: '-34px', top: '50%', transform: 'translateY(-50%)' }}>
                        <Box sx={{ width: '10px', height: '10px', borderRadius: '50%', backgroundColor: '#D32F2F' }}></Box>
                      </Grid>
                    )}
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container alignItems={'center'} justifyContent="space-between">
                    {!pos.isService && (
                      <>
                        <Grid item>
                          <Typography fontSize={'12px'}>Альтернативы</Typography>
                        </Grid>
                        <Grid item>
                          <IconButton
                            size={'small'}
                            title={'Список альтернативных товаров'}
                            onClick={() => handleAlternativeBtnClick(pos)}
                            disabled={positionsManufacturersStore.loading}
                          >
                            <FindReplaceOutlinedIcon sx={{ fontSize: '20px' }} />
                          </IconButton>
                        </Grid>
                      </>
                    )}
                  </Grid>
                  <Divider />
                  <Grid container justifyContent="space-between">
                    <Grid item>
                      <Typography fontSize={'12px'}>МРЦ</Typography>
                    </Grid>
                    <Grid item>
                      <Grid container flexWrap="nowrap">
                        {!!pos.agreementPositionCode && <HandShakeIcon />}
                        <Typography fontSize={'12px'} color="text.secondary">
                          {formatPrice(pos?.minRetailUnitCost || 0) + ' ' + currency + '/' + clientUnit}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Divider />
                  <Grid my={0.5} container justifyContent="space-between">
                    <Grid item>
                      <Typography fontSize={'12px'}>Цена</Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        fontSize="12px"
                        color="text.secondary"
                        sx={{ backgroundColor: pos.changesLog?.unitCost ? ColorChangedPropertyDeal : undefined }}
                      >
                        {formatPrice(pos?.calculatedBareUnitCost || pos?.bareUnitCost || pos?.cost || 0) +
                          ' ' +
                          currency +
                          '/' +
                          clientUnit}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Divider />
                  <Grid my={0.5} container justifyContent="space-between">
                    <Grid item>
                      <Typography fontSize={'12px'}>Доставка</Typography>
                    </Grid>
                    <Grid item>
                      <Typography fontSize="12px" color="text.secondary">
                        {formatPrice(pos?.distributeDeliveryCost || 0) + ' ' + currency + '/' + clientUnit}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Divider />
                  <Grid my={0.5} container justifyContent="space-between">
                    <Grid item>
                      <Typography fontSize={'12px'}>Кредит</Typography>
                    </Grid>
                    <Grid item>
                      <Typography fontSize="12px" color="text.secondary">
                        {formatPrice(pos?.creditUnitCost || 0) + ' ' + currency + '/' + clientUnit}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Divider />
                  <Grid my={0.5} container justifyContent="space-between">
                    <Grid item>
                      <Typography fontSize={'12px'}>Цена&nbsp;с&nbsp;услугами</Typography>
                    </Grid>
                    <Grid item>
                      <Typography fontSize="12px" color="text.secondary">
                        {formatPrice(pos?.fullUnitCost || 0) + ' ' + currency + '/' + clientUnit}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Divider />
                  <Grid my={0.5} container justifyContent="space-between" alignItems="center">
                    <Grid item>
                      <Typography fontSize={'12px'}>Количество</Typography>
                    </Grid>
                    <Grid item>
                      <Grid container alignItems="center">
                        <Grid
                          item
                          onMouseEnter={event => pos.changesLog?.oldQuantity && handleTooltipOpen(event, `${pos.code}oldQuantity`)}
                          onMouseLeave={() => handleTooltipClose()}
                        >
                          <Typography
                            fontSize="12px"
                            color="text.secondary"
                            sx={{ backgroundColor: pos.changesLog?.quantity ? ColorChangedPropertyDeal : undefined }}
                          >
                            {formatNumber3(pos?.amount || 0) + ' ' + clientUnit}
                          </Typography>
                          <Popper placement="top" open={tooltipName === `${pos.code}oldQuantity`} anchorEl={anchorEl}>
                            <Paper
                              sx={{
                                padding: 0.5,
                              }}
                            >
                              <Box p={0.6}>
                                <Typography variant="subtitle2" component="span" fontWeight={500}>
                                  Старое значение: {pos.changesLog?.oldQuantity}&nbsp;{clientUnit}
                                </Typography>
                              </Box>
                            </Paper>
                          </Popper>
                        </Grid>
                        <ClickAwayListener onClickAway={() => tooltipName && handleTooltipClose()}>
                          <Grid item ml={0.8} onClick={e => !tooltipName && handleTooltipOpen(e, `${pos.code}amount`)}>
                            <Box display="flex" justifyContent="center">
                              <InfoIconDeal color={positionQuantityParams[pos.code]?.colorForStock} />
                            </Box>
                            <Popper placement="top-start" open={tooltipName === `${pos.code}amount`} anchorEl={anchorEl}>
                              <Paper
                                sx={{
                                  padding: 0.5,
                                  ...multicoloredPopoverBg(positionQuantityParams[pos.code]?.colorForStock),
                                }}
                              >
                                <Box p={0.6}>
                                  <Typography variant="caption" color="inherit" fontWeight="500">
                                    {positionQuantityParams[pos.code].titleForStock}
                                  </Typography>
                                </Box>
                              </Paper>
                            </Popper>
                          </Grid>
                        </ClickAwayListener>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Divider />
                  <Grid my={0.5} container justifyContent="space-between">
                    <Grid item>
                      <Typography fontSize={'12px'}>Сумма</Typography>
                    </Grid>
                    <Grid item>
                      <Typography fontSize="12px" color="text.secondary">
                        {formatPrice(pos?.fullTotalCost || 0) + ' ' + currency}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Divider />
                  <Grid my={0.5} container justifyContent="space-between">
                    <Grid item>
                      <Typography fontSize={'12px'}>МаржМРЦ</Typography>
                    </Grid>
                    <Grid item>
                      <MxLabel
                        style={{ fontSize: '12px', display: 'inline', paddingTop: '4px' }}
                        color={getMarginalityColorClass(pos.marginalityPct, true)}
                      >
                        {formatPriceCur(pos?.marginalityPct || 0, '%')}
                      </MxLabel>
                    </Grid>
                  </Grid>
                  {profile?.features?.canSeeDealPrimeCostMargin && (
                    <>
                      <Divider />
                      <Grid my={0.5} container justifyContent="space-between">
                        <Grid item>
                          <Typography fontSize={'12px'}>МаржПлС</Typography>
                        </Grid>
                        <Grid item>
                          <MxLabel
                            style={{ fontSize: '12px', display: 'inline', paddingTop: '4px' }}
                            color={getMarginalityColorClass(pos.primeCostMarginPct, true)}
                          >
                            {formatPriceCur(pos?.primeCostMarginPct || 0, '%')}
                          </MxLabel>
                        </Grid>
                      </Grid>
                      <Divider />
                      <Grid my={0.5} container justifyContent="space-between">
                        <Grid item>
                          <Typography fontSize={'12px'}>МаржЦЗ</Typography>
                        </Grid>
                        <Grid item>
                          <MxLabel
                            style={{ fontSize: '12px', display: 'inline', paddingTop: '4px' }}
                            color={getMarginalityColorClass(pos.purchaseCostMarginPct, true)}
                          >
                            {formatPriceCur(pos?.purchaseCostMarginPct || 0, '%')}
                          </MxLabel>
                        </Grid>
                      </Grid>
                      <Divider />
                    </>
                  )}
                </AccordionDetails>
              </Accordion>
            );
          })}
        </Grid>
      )}
      <DialogContainer
        maxWidth={'md'}
        open={isDialogOpen}
        onClose={handleDialogClose}
        dialogContent={
          <DealListAlternativeProductsTable deal={order} position={currentPosition} manufacturersStore={positionsManufacturersStore} />
        }
        dialogTitle={''}
        canselBtnText={'Закрыть'}
      />
    </>
  ) : (
    <Grid item xs={12} container>
      <Grid item xs={12} mb={1}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <Typography variant={'body2'}>Без позиций</Typography>
      </Grid>
    </Grid>
  );
});
