import React, { FC } from 'react';
import { observer } from 'mobx-react';
import MenuItemMUI from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import MenuList from '@mui/material/MenuList';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import { formatYmdHiLZ, isValidDateYear } from '@mx-ui/helpers';
import { Control, DealApprovalState, MenuItem } from '.';
import { ApprovalStoreInterface } from '../../../store/DealItemStore';

interface ApproveResolutionListProps {
  approval: ApprovalStoreInterface;
  control: Control;
  menuItems: MenuItem[];
  handleClickMenuItem: any;
}

export const ApproveResolutionList: FC<ApproveResolutionListProps> = observer(
  React.forwardRef<HTMLUListElement, ApproveResolutionListProps>(({ approval, control, menuItems = [], handleClickMenuItem }, ref) => {
    return (
      <MenuList ref={ref} variant="menu">
        {control.type === DealApprovalState.Declined && (
          <Box p={1}>
            <Typography variant="body2" color="inherit">
              {control.label} {isValidDateYear(approval.approval?.resolutionDate) && formatYmdHiLZ(approval.approval?.resolutionDate)}
            </Typography>
          </Box>
        )}
        {control.type === DealApprovalState.Approved && approval.approval?.resolutionEmployeeSurname && (
          <Box p={1}>
            <Typography variant="body2" color="inherit">
              Одобрил(а): {approval.approval?.resolutionEmployeeSurname || ''} {approval.approval?.resolutionEmployeeName || ''}{' '}
              {approval.approval?.resolutionEmployeePatronymic || ''}
            </Typography>
          </Box>
        )}
        {approval.approval?.resolutionComment && (
          <Box p={1} maxWidth={'500px'} maxHeight={'500px'} overflow="auto">
            <Typography variant="body2" color="inherit">
              Комментарий: {approval.approval?.resolutionComment}
            </Typography>
          </Box>
        )}

        {menuItems.length > 0 &&
          menuItems.map((menuItemBtn, idx) => (
            <MenuItemMUI key={idx} onClick={() => handleClickMenuItem(menuItemBtn.action)}>
              {menuItemBtn.icon && <ListItemIcon>{menuItemBtn.icon}</ListItemIcon>}
              <ListItemText>{menuItemBtn.label}</ListItemText>
            </MenuItemMUI>
          ))}
      </MenuList>
    );
  })
);
