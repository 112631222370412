import React, { FC, JSX } from 'react';

import { observer } from 'mobx-react-lite';
import WarningIcon from '@mui/icons-material/Warning';
import VerifiedIcon from '@mui/icons-material/Verified';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import { Bill, BillPosition } from 'src/api/marketx';
import { BillItemInvoicingStore, confirmDialog } from './BillItemInvoicingStore';

interface ButtonInvoicingProps {
  billItem: Bill;
  invoicingStore: BillItemInvoicingStore;
  billPosition?: BillPosition;
  isBtnShown?: boolean;
}
const textDisabledBill = 'В счете есть товары, отгруженные менее порога фактурирования, поэтому функция доступна только построчно';
const textDisabledPosition = 'Отгружено менее порога фактурирования, поэтому функция доступна только менеджерам резерва';

const textWarningPosition = 'По данному товару имеется незакрытая доставка покупателю';
const textWarningBill = 'По некоторым товарам имеется незакрытая доставка покупателю';

export const ButtonInvoicing: FC<ButtonInvoicingProps> = observer(
  ({ billItem, invoicingStore, billPosition, isBtnShown = true }): JSX.Element => {
    const bill = billItem;
    let isPosition = false;
    let isDisabled: boolean;
    let isShowTooltip: boolean;
    let isShowWarning: boolean;
    if (billPosition) {
      isPosition = true;
      isDisabled = !billPosition.canReconcile;
      isShowWarning = !!billPosition.showReconciliationHasActiveDpShipmentWarning;
      isShowTooltip = !!billPosition.showReconciliationThresholdHint;
    } else {
      isDisabled = !bill?.canReconcile;
      isShowWarning = !!bill.showReconciliationHasActiveDpShipmentWarning;
      isShowTooltip = !!bill.showReconciliationThresholdHint;
    }

    const shippedQuantity = billPosition?.reservationSummary?.shippedQuantity || 0;
    const billBaseQuantity = billPosition?.reservationSummary?.billBaseQuantity || 0;

    // Показывать кнопку, если есть подчиненные документы и процент отгрузки меньше ста и счёт имеет статус "частично отгружен" или "неотгружен"
    let isShowButton =
      bill.hasSubordinateDoc &&
      ['partially_shipped', 'unshipped'].includes(bill.shipmentsState?.code) &&
      (!isPosition || shippedQuantity / billBaseQuantity < 1) &&
      isBtnShown;

    // Если можно нажать, то всё-равно показываем
    if (!isDisabled) {
      isShowButton = true;
    }
    // Не показываем для не MX-счетов
    if (!bill.isMX) {
      isShowButton = false;
    }

    const handleClick = (): void => {
      let typeShowDialog: confirmDialog = 'confirm';
      if (isPosition) {
        invoicingStore.onChangeTypeEntity('position');

        invoicingStore.setPosition(billPosition);
        if (billPosition.shippedQuantity == 0) {
          typeShowDialog = 'delete';
        } else if (billPosition.shippedQuantity > 0 && billPosition.showReconciliationThresholdWarning) {
          typeShowDialog = 'change';
        } else {
          typeShowDialog = 'confirm';
        }
        invoicingStore.onClickButton(typeShowDialog);
        invoicingStore.setBillDocumentNumber(bill.documentNumber);
      } else {
        invoicingStore.onChangeTypeEntity('bill');
        invoicingStore.setBillDocumentNumber(bill.documentNumber);
        invoicingStore.onClickButton(typeShowDialog);
      }
    };

    return (
      <Box display={'flex'}>
        {isShowButton && (
          <Tooltip title={isShowTooltip ? (isPosition ? textDisabledPosition : textDisabledBill) : null}>
            <Box component={'span'} sx={{ cursor: isDisabled ? 'not-allowed' : 'default' }}>
              <IconButton
                aria-label="delete"
                disabled={isDisabled || invoicingStore.billDocumentNumber === bill.documentNumber}
                onClick={handleClick}
              >
                <VerifiedIcon />
              </IconButton>
            </Box>
          </Tooltip>
        )}
        {isShowWarning && (
          <Tooltip title={isPosition ? textWarningPosition : textWarningBill}>
            <Box component={'span'} p={1}>
              <WarningIcon color="warning" />
            </Box>
          </Tooltip>
        )}
      </Box>
    );
  }
);
