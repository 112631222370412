import { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { Box } from '@mui/system';
import LightTooltip from '../../../components/ToolTip/LightTooltip';
import Typography from '@mui/material/Typography';
import { ProductExtensionWrapper } from '../../../store/Catalog/ProductExtensionsStore';
import { IlliquidIcon } from '../../../components/Catalog/Liting/IlliquidIcon';
import { TodhIcon } from '../../../components/Catalog/Liting/TodhIcon';
import { SelfPurchaseIcon } from '../../../components/Catalog/Liting/SelfPurchaseIcon';
import { HasCustodyIcon } from '../../../components/Catalog/Liting/HasCustodyIcon';
import Grid from '@mui/material/Grid';
import { IsNewIcon } from '../../../components/Catalog/Liting/IsNewIcon';
import { IsOldIcon } from '../../../components/Catalog/Liting/IsOldIcon';

interface ProductIndicatorsByValueProps {
  isNew?: boolean;
  isOld?: boolean;
  hasCustody: boolean;
  hasSelfPurchase: boolean;
  hasTodh: boolean;
  hasIlliquid: boolean;
}
export const ProductIndicatorsByValue: FC<ProductIndicatorsByValueProps> = observer(
  ({ hasCustody, hasSelfPurchase, hasTodh, hasIlliquid, isNew, isOld }): JSX.Element => {
    return (
      <Grid container>
        {(isNew || isOld) && (
          <Grid item xs={12} container alignItems={'center'} flexWrap={'nowrap'} mb={0.5} justifyContent={'flex-end'}>
            {isNew && (
              <Box sx={{ paddingLeft: '5px' }}>
                <IsNewIcon />
              </Box>
            )}{' '}
            {isOld && (
              <Box sx={{ paddingLeft: '5px' }}>
                <IsOldIcon />
              </Box>
            )}
          </Grid>
        )}
        <Grid item xs={12} container alignItems={'center'} flexWrap={'nowrap'} justifyContent={'flex-end'}>
          {hasCustody && (
            <Box sx={{ paddingLeft: '5px' }}>
              <HasCustodyIcon />
            </Box>
          )}

          {hasSelfPurchase && (
            <Box sx={{ paddingLeft: '5px' }}>
              <SelfPurchaseIcon />
            </Box>
          )}

          {hasTodh && (
            <Box sx={{ paddingLeft: '5px' }}>
              <TodhIcon />
            </Box>
          )}

          {hasIlliquid && (
            <Box sx={{ paddingLeft: '5px' }}>
              <IlliquidIcon />
            </Box>
          )}
        </Grid>
      </Grid>
    );
  }
);

export const ProductIndicatorsByValueForAccordion: FC<ProductIndicatorsByValueProps> = observer(
  ({ hasCustody, hasSelfPurchase, hasTodh, hasIlliquid, isNew, isOld }): JSX.Element => {
    return (
      <Grid container>
        {isNew && (
          <Grid item>
            <Box sx={{ paddingLeft: '5px' }}>
              <IsNewIcon />
            </Box>
          </Grid>
        )}{' '}
        {isOld && (
          <Grid item>
            <Box sx={{ paddingLeft: '5px' }}>
              <IsOldIcon />
            </Box>
          </Grid>
        )}
        {hasCustody && (
          <Grid item>
            <Box sx={{ paddingLeft: '5px' }}>
              <HasCustodyIcon />
            </Box>
          </Grid>
        )}
        {hasSelfPurchase && (
          <Grid item>
            <Box sx={{ paddingLeft: '5px' }}>
              <SelfPurchaseIcon />
            </Box>
          </Grid>
        )}
        {hasTodh && (
          <Grid item>
            <Box sx={{ paddingLeft: '5px' }}>
              <TodhIcon />
            </Box>
          </Grid>
        )}
        {hasIlliquid && (
          <Grid item>
            <Box sx={{ paddingLeft: '5px' }}>
              <IlliquidIcon />
            </Box>
          </Grid>
        )}
      </Grid>
    );
  }
);

interface ProductPeculiaritiesIndicatorsProps {
  extension: ProductExtensionWrapper;
  isNew?: boolean;
  isOld?: boolean;
}

export const ProductPeculiaritiesIndicators: FC<ProductPeculiaritiesIndicatorsProps> = observer(
  ({ extension, isNew = false, isOld = false }): JSX.Element => {
    const hasCustody = extension.loaded && extension.data.hasCustodyBatches;
    const hasIlliquid = extension.loaded && extension.data.hasIlliquidBatches;
    const hasSelfPurchase = extension.loaded && extension.data.hasSelfPurchase;
    const hasTodh = extension.loaded && extension.data.hasTodhDiscounts;
    return (
      <>
        {isNew && <IsNewIcon />} {isOld && <IsOldIcon />}
        {hasCustody && (
          <Box
            sx={{
              borderRadius: '3px',
              fontSize: 16,
              verticalAlign: 'middle',
              fontFamily: 'Arial, Helvetica, sans-serif',
              backgroundColor: '#EDD514',
              display: 'inline-block',
              width: 40,
              height: 20,
              textAlign: 'center',
              marginLeft: '5px',
              lineHeight: '20px',
            }}
          >
            <LightTooltip
              placement="bottom"
              title={
                <Box p={0.6}>
                  <Box display={'flex'} justifyContent="space-between" p={'2px'} borderRadius="3px">
                    <Typography variant="caption" color="inherit">
                      <span>Ответственное Хранение</span>
                    </Typography>
                  </Box>
                </Box>
              }
              arrow
            >
              <Box display="flex" justifyContent="center" sx={{ color: '#f6eeee' }}>
                ОХ
              </Box>
            </LightTooltip>
          </Box>
        )}
        {hasSelfPurchase && (
          <Box sx={{ display: 'inline-block', paddingLeft: '5px' }}>
            <SelfPurchaseIcon />
          </Box>
        )}
        {hasTodh && (
          <Box sx={{ display: 'inline-block', paddingLeft: '5px' }}>
            <TodhIcon />
          </Box>
        )}
        {hasIlliquid && (
          <Box sx={{ display: 'inline-block', paddingLeft: '5px' }}>
            <IlliquidIcon />
          </Box>
        )}
      </>
    );
  }
);
