import { Grid, Skeleton, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { DocumentTypeList, enabledFieldSkeletonEnum } from './DocumentTotalCount';

interface DocumentTotalCountSkeletonProps {
  documentTypeListDictionary: Record<string, string>;
  documentType: DocumentTypeList;
  enabledFieldSkeleton?: enabledFieldSkeletonEnum[];
}

export const DocumentTotalCountSkeleton: FC<DocumentTotalCountSkeletonProps> = props => {
  const { documentType, documentTypeListDictionary, enabledFieldSkeleton = [] } = props;

  return (
    <Stack flexDirection={'column'} minWidth={'170px'}>
      {enabledFieldSkeleton.includes(enabledFieldSkeletonEnum.total) && (
        <Grid container justifyContent={'space-between'}>
          <Grid item>
            <Typography variant="caption" color="text.secondary">
              {`Найдено ${documentTypeListDictionary[documentType]}: `}
            </Typography>
          </Grid>
          <Grid item minWidth={'50px'} textAlign={'right'}>
            <Skeleton sx={{ maxWidth: '100%' }} />
          </Grid>
        </Grid>
      )}

      {enabledFieldSkeleton.includes(enabledFieldSkeletonEnum.cost) && (
        <Grid container justifyContent={'space-between'}>
          <Grid item>
            <Typography variant="caption" color="text.secondary">
              {`Сумма: `}
            </Typography>
          </Grid>
          <Grid item minWidth={'50px'} textAlign={'right'}>
            <Skeleton sx={{ maxWidth: '100%' }} />
          </Grid>
        </Grid>
      )}

      {enabledFieldSkeleton.includes(enabledFieldSkeletonEnum.weight) && (
        <Grid container justifyContent={'space-between'}>
          <Grid item>
            <Typography variant="caption" color="text.secondary">
              {`Количество: `}
            </Typography>
          </Grid>
          <Grid item minWidth={'50px'} textAlign={'right'}>
            <Skeleton sx={{ maxWidth: '100%' }} />
          </Grid>
        </Grid>
      )}

      {enabledFieldSkeleton.includes(enabledFieldSkeletonEnum.reservedCost) && (
        <Grid container justifyContent={'space-between'}>
          <Grid item>
            <Typography variant="caption" color="text.secondary">
              {`Сумма заморожено: `}
            </Typography>
          </Grid>
          <Grid item minWidth={'50px'} textAlign={'right'}>
            <Skeleton sx={{ maxWidth: '100%' }} />
          </Grid>
        </Grid>
      )}

      {enabledFieldSkeleton.includes(enabledFieldSkeletonEnum.reservedWeight) && (
        <Grid container justifyContent={'space-between'}>
          <Grid item>
            <Typography variant="caption" color="text.secondary">
              {`Количество заморожено: `}
            </Typography>
          </Grid>
          <Grid item minWidth={'50px'} textAlign={'right'}>
            <Typography variant="caption" color="text.secondary"></Typography>
          </Grid>
        </Grid>
      )}
    </Stack>
  );
};
