import { ChangeEvent, FC, JSX, useCallback, useEffect, useState } from 'react';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { Theme, styled } from '@mui/material/styles';
import { observer } from 'mobx-react-lite';
import { ValueStore } from 'src/store/ValueStore';
import { inputValueToFloatPositiveString } from '@mx-ui/helpers';
import { SxProps } from '@mui/system';
import { outlinedInputClasses } from '@mui/material';

export interface SyncedTextFieldProps {
  /**
   *  влияет на обработку значения для onChange (не является HTMLInputTypeAttribute)
   */
  type?: 'number' | 'text';
  isViewOnly?: boolean;
  multiline?: boolean;
  isForComment?: boolean;
  maxRows?: number;
  color?: string | any;
  value: ValueStore;
  onChange?: (value: string) => void;
  incorrectValue?: any;
  suffix?: string;
  sx?: SxProps<Theme>;
  sxTypographyProps?: SxProps<Theme>;
  validatorFn?: (value: string) => boolean;
}
type StyledTextFieldProps = TextFieldProps & {
  inputColor?: string;
};
export const StyledTextField = styled(TextField, {
  shouldForwardProp: prop => prop !== 'inputColor',
})<StyledTextFieldProps>(({ inputColor /* theme */ }) => ({
  maxWidth: '200px',
  // background: 'white',
  [`& .${outlinedInputClasses.error}`]: {
    ...(inputColor && {
      color: inputColor,
    }),
  },
  [`& .${outlinedInputClasses.input}`]: {
    padding: '4px 0 4px 4px',
    fontWeight: 700,
    fontSize: '14px',
    ...(inputColor && {
      color: inputColor,
    }),
  },
})) as React.ComponentType<StyledTextFieldProps>;

const commonInputProps = { className: 'digitsOnly' };
const styleTypography: SxProps<Theme> = {
  cursor: 'not-allowed',
  display: 'inline-block',
  background: 'none',
  color: theme => theme.palette.text.disabled,
  fontSize: '14px',
  paddingLeft: '6px',
  border: '1px solid',
  borderColor: theme => theme.palette.action.disabled,
  lineHeight: '20px',
  boxSizing: 'border-box',
  paddingTop: '2px',
  paddingBottom: '1px',
  paddingRight: '6px',
  borderRadius: '4px',
  minWidth: '35px',
  width: '100%',
  maxWidth: '200px',
  minHeight: '23px',
};

export const SyncedTextField: FC<SyncedTextFieldProps> = observer(
  ({
    type = 'number',
    isViewOnly,
    color,
    value,
    onChange,
    incorrectValue,
    suffix,
    sx,
    sxTypographyProps = {},
    multiline = false,
    isForComment = false,
    maxRows = 5,
    validatorFn,
  }): JSX.Element => {
    const [hasError, setHasError] = useState(!!incorrectValue);
    const handleChange = useCallback(
      (e: ChangeEvent<HTMLInputElement>): void => {
        let localValue = e.target.value;
        if (type === 'number') {
          localValue = inputValueToFloatPositiveString(e.target.value);
        }
        if (validatorFn) {
          if (!validatorFn(localValue)) {
            setHasError(true);
            return;
          } else {
            setHasError(false);
          }
        }
        value.handleInputChange(localValue);
        if (onChange) {
          onChange(localValue);
        }
      },
      [value, onChange, validatorFn]
    );
    useEffect(() => {
      setHasError(!!incorrectValue);
    }, [incorrectValue]);
    if (isViewOnly && !isForComment) {
      return (
        <Typography component="p" sx={{ ...styleTypography, ...sxTypographyProps }}>
          {value.asString()}
        </Typography>
      );
    }

    return (
      <>
        <StyledTextField
          // type={type}
          maxRows={maxRows}
          onBlur={() => validatorFn && setHasError(!validatorFn(value.asString()))}
          multiline={multiline}
          type={'text'}
          inputColor={!hasError ? color : '#C74952'}
          inputProps={commonInputProps}
          variant="outlined"
          autoComplete="off"
          fullWidth
          hiddenLabel
          size="small"
          onChange={handleChange}
          value={value.asString()}
          error={!!hasError}
          sx={{ ...sx, '& .MuiInputBase-input': { cursor: isViewOnly && isForComment && 'not-allowed', fontWeight: isForComment && 400 } }}
          disabled={isViewOnly}
        />
        {suffix && (
          <Typography variant="body2" fontWeight={500} color="text.secondary" pl="4px">
            {suffix}
          </Typography>
        )}
      </>
    );
  }
);
