import React, { FC } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { DoughnutChartBackground } from './plugins/DoughnutChartBackground';
import annotationPlugin from 'chartjs-plugin-annotation';
import { CenterText } from './plugins/CenterText';
import { generateColorChartjs } from './plugins/generateColorChartjs';
import { useTheme } from '@mui/material/styles';

ChartJS.register(ArcElement, Tooltip, Legend, DoughnutChartBackground, CenterText, annotationPlugin);

interface DoughnutChartProps {
  value?: number;
  text?: string;
  textColor?: boolean;
  isWarningColor?: boolean;
}
const DoughnutChart: FC<DoughnutChartProps> = ({ value, text, textColor, isWarningColor }): JSX.Element => {
  const theme = useTheme();
  const { data, backgroundColor } = generateColorChartjs(value, theme);
  const plugins = {
    tooltip: {
      enabled: false,
    },
    centerText: {
      display: true,
      color: isWarningColor ? '#ffa726' : textColor && value === 0 ? '#f44336' : backgroundColor[0],
      text: text || `${value ?? 0}%`,
    },
    doughnutBackground: {
      enabled: true,
      color: isWarningColor ? (value < 100 ? backgroundColor[1] : '#c48120') : backgroundColor[1],
    },
    legend: {
      display: false,
    },
  };
  return (
    <Doughnut
      options={{
        hover: { mode: null },
        plugins,
        aspectRatio: 1,
        responsive: true,
        maintainAspectRatio: true,
        cutout: '76%',
      }}
      data={{
        labels: ['Red', 'Blue'],
        datasets: [
          {
            label: '',
            data,
            backgroundColor: [isWarningColor ? '#ffa726' : backgroundColor[0], 'transparent'],
            borderWidth: 0,
            borderRadius: [50, 0],
          },
        ],
      }}
    />
  );
};

export { DoughnutChart };
