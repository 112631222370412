import React, { ChangeEvent, FC, ReactElement, useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { AgreementListStore } from '../../store/Clients/AgreementListStore';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import { MxLabel } from '@mx-ui/ui';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import { alpha, useTheme } from '@mui/material/styles';
import { Theme } from '@mui/material';

interface AgreementStateSelectProps {
  agreements: AgreementListStore;
  disabled?: boolean;
  isForClient?: boolean;
  sx?: React.CSSProperties;
}

interface SelectOption {
  id: number;
  title: string;
}

const customSelectStyle = {
  minWidth: '280px',
  flex: '1 1 auto',
};

export const agreementStateDictionary: Record<string, SelectOption> = {
  approval: {
    id: 1,
    title: 'На согласовании',
  },
  approved: {
    id: 2,
    title: 'Согласовано',
  },
  active: {
    id: 3,
    title: 'Активно',
  },
  not_active: {
    id: 4,
    title: 'Неактивно',
  },
};

const agreementStateList: SelectOption[] = Object.values(agreementStateDictionary);

export const agreementStateColors = (id: number, theme: Theme): { color: string; bgColor: string } => {
  if (!id || !theme) {
    return {
      color: 'transparent',
      bgColor: 'transparent',
    };
  }

  let color = theme.palette.success.main;
  switch (id) {
    case agreementStateDictionary.approved.id:
      color = theme.palette.success.main;
      break;
    case agreementStateDictionary.approval.id:
      color = theme.palette.warning.main;
      break;
    case agreementStateDictionary.active.id:
      color = '#72b482';
      break;
    case agreementStateDictionary.not_active.id:
      color = theme.palette.error.main;
      break;
  }

  return {
    color,
    bgColor: alpha(color, 0.08),
  };
};

export const AgreementStateSelect: FC<AgreementStateSelectProps> = observer(
  ({ agreements, disabled, sx, isForClient = false }): ReactElement => {
    const theme = useTheme();
    const [localItems, setLocalItems] = useState(null);
    const handlePositionManufacturerChange = useCallback(
      (e: ChangeEvent, stateIds: SelectOption[]): void => {
        setLocalItems(stateIds);
        if (isForClient) {
          agreements.mergeRequestToClient({ stateIds: stateIds.map(i => i.id).flat() });
        } else {
          agreements.mergeRequest({ stateIds: stateIds.map(i => i.id).flat() });
        }
      },
      [agreements, isForClient]
    );
    useEffect(() => {
      if (agreements.request.stateIds?.length && !localItems) {
        setLocalItems(agreementStateList.filter(i => agreements.request.stateIds.includes(i.id)));
      }
      if (agreements.request?.stateIds?.length !== localItems?.length) {
        setLocalItems(agreementStateList?.filter(i => agreements.request?.stateIds?.includes(i.id)));
      }
    }, [agreements.request.stateIds]);

    return (
      <Autocomplete
        multiple
        disabled={disabled}
        style={{ ...customSelectStyle, ...sx }}
        size={'small'}
        onChange={handlePositionManufacturerChange}
        isOptionEqualToValue={(option: SelectOption, value: SelectOption) => option.id === value.id}
        getOptionLabel={(o: SelectOption) => o.title}
        options={agreementStateList}
        value={localItems || []}
        renderOption={(props: object, option: SelectOption) => {
          const { color, bgColor } = agreementStateColors(option.id, theme);

          return (
            <Box sx={{ marginLeft: 0.5, cursor: 'default' }} {...props}>
              <MxLabel
                sx={{
                  cursor: 'pointer',
                  borderRadius: '16px',
                  fontWeight: '400',
                  color: color,
                  backgroundColor: bgColor,
                }}
              >
                <Box fontSize={14}>{option.title}</Box>
              </MxLabel>
            </Box>
          );
        }}
        renderTags={(value: readonly SelectOption[], getTagProps) =>
          value.map((option: SelectOption, index: number) => {
            const { color, bgColor } = agreementStateColors(option.id, theme);
            return (
              <Chip
                key={option.id}
                variant="outlined"
                label={option.title}
                {...getTagProps({ index })}
                sx={{
                  color: color,
                  backgroundColor: bgColor,
                  border: 'none',
                  textTransform: 'uppercase',
                  height: '24px',
                }}
              />
            );
          })
        }
        renderInput={params => <TextField {...params} label="Статус соглашения" />}
      />
    );
  }
);
