import React, { FC } from 'react';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Badge from '@mui/material/Badge';
import Typography from '@mui/material/Typography';
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined';
import { DealDocument } from 'src/api/marketx';
import { DialogContainer } from '../ui/dialogs/DialogContainer';
import { DocumentsTable } from './DocumentsTable';
interface PreviewMenuProps {
  documents: Record<string, PositionWithDocuments>;
  positionCode?: string;
  isFromPosition?: boolean;
  isWithBadge?: boolean;
}

export interface PositionWithDocuments {
  name: string;
  number: number;
  documents: DealDocument[];
}
export const PreviewMenu: FC<PreviewMenuProps> = ({
  documents: filesDictionary,
  positionCode,
  isFromPosition = false,
  isWithBadge = false,
}): JSX.Element => {
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = (): void => {
    setOpen(true);
  };
  const handleCloseDialog = (): void => {
    setOpen(false);
  };

  return (
    <div>
      {isFromPosition ? (
        <IconButton sx={{ color: `${isFromPosition ? '#00a5bc' : '#707070'}` }} onClick={handleClickOpen} component="span" size="medium">
          <Badge badgeContent={isWithBadge ? filesDictionary[positionCode]?.documents.length : 0} color="primary">
            <AttachFileOutlinedIcon />
          </Badge>
        </IconButton>
      ) : (
        <IconButton size="small" sx={{ ml: '2px' }} onClick={handleClickOpen} title="Прикрепленные документы">
          <Badge badgeContent={isWithBadge ? filesDictionary[positionCode]?.documents.length : 0} color="primary">
            <AttachFileOutlinedIcon />
          </Badge>
        </IconButton>
      )}
      <DialogContainer
        open={open}
        onClose={handleCloseDialog}
        dialogTitle={''}
        maxWidth={'lg'}
        dialogContent={
          <>
            {filesDictionary[positionCode]?.documents.length ? (
              <Box sx={{ mt: 2 }}>
                <Typography variant="body1" fontWeight={500}>
                  Прикрепленные файлы
                </Typography>
                <DocumentsTable files={filesDictionary[positionCode]?.documents || []} isDeletable={false} />
              </Box>
            ) : null}
          </>
        }
        canselBtnText={'Закрыть'}
      />
    </div>
  );
};
