import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import React, { FC, useState } from 'react';
import { displayNumber } from 'src/views/deals/lib';
import PanToolOutlined from '@mui/icons-material/PanToolOutlined';
import { AppDeal } from '../../../slices/AppDeal';
import { formatNumber3, formatPriceCur } from '@mx-ui/helpers';
import { CustomPopper } from '../components/CustomPopper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { ColorChangedPropertyDeal } from '../DealContainer';

export const DeliveryInfoBlock: FC<{ deal: AppDeal }> = ({ deal }): JSX.Element => {
  const [deliveryPopperAnchor, setDeliveryPopperAnchor] = useState(null);
  const [deliveryPopperOpen, setDeliveryPopperOpen] = useState(false);
  return (
    <Grid item xs={12}>
      <Grid container alignItems={'center'} spacing={2}>
        <Grid item>
          <Typography variant="body2" color={'text.secondary'}>
            Количество транспортных единиц
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body2" color={'text.secondary'}>
            {deal?.transport?.unitsCode}
          </Typography>
        </Grid>
      </Grid>
      <Grid container alignItems={'center'} spacing={2}>
        <Grid item>
          <Typography variant="body2" color={'text.secondary'}>
            Эффективность загрузки
          </Typography>
        </Grid>
        <Grid item>
          {' '}
          <Typography variant="body2" color={'text.secondary'}>
            {displayNumber(deal?.transport?.efficiency)}%
          </Typography>
        </Grid>
      </Grid>
      <Grid container alignItems={'center'} spacing={2}>
        <Grid item>
          <Box
            sx={{ backgroundColor: deal.updateLog?.changed?.deliveryCost ? ColorChangedPropertyDeal : undefined, padding: '3px 0 3px 0' }}
          >
            <Typography variant="body2" color={'text.secondary'} fontWeight={600}>
              Стоимость доставки
              <IconButton
                onClick={e => {
                  setDeliveryPopperAnchor(e.currentTarget);
                  setDeliveryPopperOpen(!deliveryPopperOpen);
                }}
                size="small"
                style={{ padding: '0px', marginTop: '-4px' }}
              >
                <KeyboardArrowDownIcon />
              </IconButton>
            </Typography>
          </Box>
        </Grid>
        <Grid item>
          {' '}
          <Typography variant="body2" color={'text.secondary'} fontWeight={600}>
            {(deal?.transport?.totalCost > 0 && formatPriceCur(deal?.transport?.totalCost)) || 'Бесплатно'}
            {deal?.useManualDeliveryCost && (
              <>
                &nbsp;
                <PanToolOutlined fontSize="inherit" sx={{ verticalAlign: 'middle' }} />
              </>
            )}
          </Typography>
        </Grid>
      </Grid>

      <CustomPopper
        isOpen={deliveryPopperOpen}
        anchorEl={deliveryPopperAnchor}
        onClose={() => setDeliveryPopperOpen(false)}
        sx={{ maxWidth: '450px' }}
      >
        <Grid container spacing={1}>
          <Grid item xs={8}>
            <Typography>Стоимость доставки</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography style={{ fontSize: '14px', fontWeight: 700 }}>
              {(deal?.transport?.totalCost > 0 && formatPriceCur(deal?.transport?.totalCost)) || 'Бесплатно'}
            </Typography>
          </Grid>

          {deal?.deliveryType === 'express_delivery' && !deal?.useManualDeliveryCost && (
            <>
              <Grid item xs={8}>
                <Typography>Включая за срочность</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography style={{ fontSize: '14px', fontWeight: 700 }}>
                  {formatPriceCur(deal?.transport?.expressDeliveryCost)}
                </Typography>
              </Grid>
            </>
          )}

          {(deal?.deliveryMarkup || 0) > 0 && (
            <>
              <Grid item xs={8}>
                <Typography>Включая за количество позиций</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography style={{ fontSize: '14px', fontWeight: 700 }}>{formatPriceCur(deal?.deliveryMarkup)}</Typography>
              </Grid>
            </>
          )}

          <Grid item xs={8}>
            <Typography>Общий тоннаж заявки</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography style={{ fontSize: '14px', fontWeight: 700 }}>
              {formatNumber3((deal?.totalWeight || 0) / 1000)}&nbsp;тонн
            </Typography>
          </Grid>

          {deal?.transport?.distance > 0 && (
            <>
              <Grid item xs={8}>
                <Typography>Расстояние</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography style={{ fontSize: '14px', fontWeight: 700 }}>
                  {formatNumber3(deal.transport.distance)}&nbsp;км
                  {deal?.useManualDeliveryDistance && (
                    <>
                      &nbsp;
                      <PanToolOutlined fontSize="inherit" sx={{ verticalAlign: 'middle' }} />
                    </>
                  )}
                </Typography>
              </Grid>
            </>
          )}

          <Grid item xs={8}>
            <Typography>Эффективность транспорта</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography style={{ fontSize: '14px', fontWeight: 700 }}>{displayNumber(deal?.transport?.efficiency)}%</Typography>
          </Grid>

          <Grid item xs={8}>
            <Typography>Кол-во транспортных единиц</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography style={{ fontSize: '14px', fontWeight: 700 }}>
              {displayNumber(deal?.transport?.items?.reduce((sum, item) => sum + item.count, 0))}
            </Typography>
          </Grid>
          {/* <Grid item xs={12} style={{ marginBottom: '12px' }}></Grid>*/}

          {!!deal?.transport?.items?.length &&
            deal.transport.items.map(t => {
              // 200
              // 150
              // 120
              const size = parseFloat(t.title?.split(',').join('.'));
              return (
                <Grid container spacing={0} key={t.title}>
                  <Grid item xs={8} style={{ padding: '8px' }}>
                    {size <= 3.5 && <img alt="машина до 3.5 тонн" src={`/images/car-xs-adapting.png`} style={{ width: '110px' }} />}

                    {size > 3.5 && size <= 5 && (
                      <img alt="машина до 5 тонн" src={`/images/car-m-adapting.png`} style={{ width: '150px' }} />
                    )}

                    {size > 5 && <img alt="машина более 5 тонн" src={`/images/car-xl-adapting.png`} style={{ maxWidth: '220px' }} />}
                  </Grid>
                  <Grid item xs={4} style={{ paddingTop: '18px' }}>
                    <Typography style={{ fontSize: '22px', fontWeight: 700 }}>
                      {t.count}&nbsp;x&nbsp;{t.title}
                    </Typography>
                  </Grid>
                </Grid>
              );
            })}
        </Grid>
      </CustomPopper>
    </Grid>
  );
};
