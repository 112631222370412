import Tooltip, { TooltipProps } from '@mui/material/Tooltip';
import { FC } from 'react';

const LightTooltip: FC<TooltipProps> = ({ className, ...props }) => (
  <Tooltip {...props} componentsProps={{ tooltip: { bgwhite: true } }} classes={{ popper: className }} />
);

// ? перенесено в тему
// const LightTooltip = styled(({ className, ...props }: TooltipProps) => <Tooltip {...props} classes={{ popper: className }} />)(
//   ({ theme }) => ({
//     [`& .${tooltipClasses.tooltip}`]: {
//       backgroundColor: theme.palette.common.white,
//       color: 'rgba(0, 0, 0, 0.87)',
//       boxShadow: theme.shadows[1],
//       fontSize: 11,
//       maxWidth: 'none',
//       padding: 0,
//     },
//   })
// );

export default LightTooltip;
