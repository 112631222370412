import React, { ChangeEvent, FC, useState, useEffect } from 'react';
import DialogContentText from '@mui/material/DialogContentText';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { DialogContainer } from '../../ui/dialogs/DialogContainer';
interface ApproveResolutionDialogProps {
  defaultCommentValue?: string;
  dialogContentText?: string;
  dialogContentHeader?: string;
  dialogHeader?: string;
  action?: string;
  isShowInputComment?: boolean;
  isInputCommentRequired?: boolean;
  keepMounted: boolean;
  open: boolean;
  onClose: (isOk: boolean, action?: string, value?: string) => void;
}
export const ApproveResolutionDialog: FC<ApproveResolutionDialogProps> = ({
  defaultCommentValue = '',
  dialogContentText,
  dialogContentHeader,
  dialogHeader,
  isShowInputComment = false,
  isInputCommentRequired = false,
  open = false,
  onClose,
  action,
}): JSX.Element => {
  const [confirmComment, setConfirmComment] = useState<string>(defaultCommentValue);
  const [isFormError, setIsFormError] = useState<boolean>(false);

  useEffect(() => {
    if (isInputCommentRequired && !confirmComment) {
      setIsFormError(true);
    } else {
      setIsFormError(false);
    }
  }, [confirmComment]);

  useEffect(() => {
    if (open) {
      setConfirmComment(defaultCommentValue ?? '');
    }
  }, [defaultCommentValue, open]);
  const handleConfirmCommentChange = (e: ChangeEvent<HTMLTextAreaElement>): void => {
    setConfirmComment(e.target.value);
  };
  const handleCancel = (): void => {
    onClose(false);
    setConfirmComment('');
  };

  const handleOk = (): void => {
    onClose(true, action, confirmComment);
  };

  return (
    <DialogContainer
      open={open}
      onClose={handleCancel}
      handleSuccess={handleOk}
      dialogTitle={dialogHeader}
      dialogContent={
        <>
          <DialogContentText>
            {dialogContentHeader != '' && (
              <>
                <span>{dialogContentHeader}</span>
                <br />
              </>
            )}
            <span>{dialogContentText}</span>
          </DialogContentText>
          {isShowInputComment && (
            <Box mt={1}>
              <TextField
                autoFocus
                margin="dense"
                multiline
                maxRows={3}
                id="name"
                autoComplete="off"
                label="Комментарий"
                type="text"
                fullWidth
                value={confirmComment}
                onChange={handleConfirmCommentChange}
                required={isInputCommentRequired}
                error={isFormError}
                helperText={isInputCommentRequired && 'Поле обязательно к заполнению'}
              />
            </Box>
          )}
        </>
      }
      canselBtnText={'Отмена'}
      successBtnText={'ОК'}
      maxWidth={'sm'}
      fullWidth
    />
  );
};
