import React, { CSSProperties, FC } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { observer } from 'mobx-react';
import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { ShipmentsListItem } from './ShipmentsListItem';
import { ShipmentsListStore } from '../../store/Documents/ShipmentsListStore';
import { SaleShipment } from 'src/api/marketx';
import { ShipmentListItemShortened } from './ShipmentListItemShortened';
import { ShipmentItemTableView } from './ShipmentItemTableView';
import { SalesListSkeleton } from '../Agreements/AgreementList';

interface ShipmentsListProps {
  store: ShipmentsListStore;
  viewType?: string;
}

export const ShipmentStackList: FC<{ items: SaleShipment[]; viewType?: string; store?: ShipmentsListStore }> = observer(
  ({ items = [], viewType, store }): JSX.Element => (
    <Stack width="100%" spacing={2}>
      {viewType === 'list'
        ? items.map((shipment: SaleShipment) => (
            <ShipmentsListItem key={shipment.shipmentCode} shipment={shipment} shipmentsListStore={store} />
          ))
        : viewType === 'table-view'
        ? items.map((shipment: SaleShipment) => (
            <ShipmentItemTableView key={shipment.shipmentCode} shipment={shipment} shipmentsListStore={store} />
          ))
        : items.map((shipment: SaleShipment) => (
            <ShipmentListItemShortened key={shipment.shipmentCode} shipment={shipment} shipmentsListStore={store} />
          ))}
    </Stack>
  )
);

const listStyle = { overflowX: 'hidden', overflowY: 'hidden', padding: 1 } as CSSProperties;
const listSkeleton = <Skeleton variant="rectangular" style={{ margin: '10px' }} />;

export const ShipmentsList: FC<ShipmentsListProps> = observer(({ store, viewType = 'list' }): JSX.Element => {
  if (!store.isLoaded) {
    return <SalesListSkeleton viewType={viewType} />;
  }

  if (!store.items?.length) {
    return <Box minHeight={300}>Нет данных</Box>;
  }

  return (
    <Box width={'inherit'}>
      <InfiniteScroll dataLength={store.items.length} next={store.loadMore} hasMore={store.hasMore} style={listStyle} loader={listSkeleton}>
        <ShipmentStackList items={store.items} viewType={viewType} store={store} />
      </InfiniteScroll>
    </Box>
  );
});
