import { AppDeal, AppClientContract } from '../slices/AppDeal';
import { makeAutoObservable, runInAction, set, toJS } from 'mobx';
import {
  ClientContractSendingLog,
  ClientsItemContractsResponse,
  ContractCostAgreement,
  ContractKindItem,
  ContractKindsResponse,
  ContractsResponse,
  ContractStatusesResponse,
  ContractStatusItem,
} from '../api/marketx';
import { AxiosResponse } from 'axios';
import { AxiosCallContext, getCallContext } from '../utils/axiosInit';
import { mapClientContract, mapApiArray } from '../views/clients/lib';
import { ApiStore } from './Global/ApiStore';
import { RootStore } from './StoreManager';
import { AutocompleteValue } from '../components/Clients/ClientDocuments/Hooks/useClientDocumentsSelect';
import { TotalStoreEntity, totalStoreGenerate } from '../components/Documents/DocumentTotalCount';

const defaultPageSize = 30;
class ContractsListRequest {
  customerCode?: string;
  statusCode?: string;
  kind?: string;

  page?: number;
  officeCode?: string;
  quickRange?: string;
  officeCodes?: string[];
  clientCode?: string;
  count?: number;
  dateFrom?: Date;
  dateTo?: Date;
}

export class ContractListStore {
  svc: ContractListStoreService;
  apiStore: ApiStore;

  filterKind?: string;
  withDrafts = false;
  forDeals = false;
  branchOfficeCode?: string;
  preset = 'with_drafts';

  isLoaded = false;
  isLoading = true;
  ignoreBeforeDate?: Date;

  deal?: AppDeal;
  customerCode: string;
  items: AppClientContract[] = new Array<AppClientContract>();
  contractLogsByCode: Record<string, ClientContractSendingLog[]> = {};

  public initialBranchOffice: AutocompleteValue = null;
  public filterBranchOffices: AutocompleteValue[] = [];
  request: ContractsListRequest = { count: defaultPageSize };
  isMoreLoading = false;

  isInitialLoading = true;
  hasMore = false;
  totalStoreEntity: TotalStoreEntity = null;

  public contractStatuses = new Array<ContractStatusItem>();
  public contractKindTypes = new Array<ContractKindItem>();

  constructor(rootStore: RootStore) {
    this.svc = new ContractListStoreService();
    this.apiStore = rootStore.getApiStore();
    makeAutoObservable(this, {
      svc: false,
      apiStore: false,
    });
  }

  loadListForAgreement(agreement: ContractCostAgreement): void {
    this.filterKind = 'with_buyer';
    if (!agreement) {
      this.customerCode = undefined;
      this.setEmptyList();
      return;
    }
    this.branchOfficeCode = agreement.branchOfficeCode;
    this.ignoreBeforeDate = new Date();
    this.isLoaded = false;
    this.deal = null;
    this.customerCode = agreement.customer?.code;
    this.items.splice(0);
    this.preset = 'for_deals';
    if (agreement.contract && agreement.contract.code) {
      this.items.push(Object.assign({}, agreement.contract));
    }
    this.svc.executeOne(this);
  }

  loadListForDeal(deal: AppDeal): void {
    this.filterKind = 'with_buyer';
    this.forDeals = true;
    if (!deal) {
      this.customerCode = undefined;
      this.setEmptyList();
      return;
    }
    this.branchOfficeCode = deal.branchOfficeCode;
    this.ignoreBeforeDate = new Date();
    this.isLoaded = false;
    this.deal = deal;
    this.customerCode = deal.customerCode;
    this.items.splice(0);
    this.preset = 'for_deals';
    if (deal.contract && deal.contract.code) {
      this.items.push(Object.assign({}, deal.contract));
    }
    this.svc.executeOne(this);
  }

  initialLoadForClient(): void {
    this.svc.loadContractStatuses(this);
    this.svc.loadContractKinds(this);
  }

  setContractStatusesResponse(ctx: AxiosCallContext, res: ContractStatusesResponse): void {
    this.contractStatuses = res.statuses;
  }
  setContractKindsResponse(ctx: AxiosCallContext, res: ContractKindsResponse): void {
    this.contractKindTypes = res.kinds;
  }

  setInitialOffice(code: string, name: string): void {
    this.initialBranchOffice = { code, name };
  }

  resetItems(): void {
    runInAction(() => {
      this.items = [];
    });
  }

  mergeRequestToClient(req: ContractsListRequest): void {
    set(this.request, req);
    runInAction(() => {
      this.request.page = undefined;
      this.isMoreLoading = false;
      this.isLoading = true;
    });
    this.svc.loadFromClientDocuments(this.request, this);
  }

  loadForClientResult(ctx: AxiosCallContext, req: ContractsListRequest, res: ContractsResponse): void {
    runInAction(() => {
      if (!this.isMoreLoading) {
        this.items.splice(0);
        this.filterBranchOffices = (res.contractsBranchOffices as AutocompleteValue[]) || [];
      } else {
        if (!this.filterBranchOffices.length) {
          this.filterBranchOffices = (res.contractsBranchOffices as AutocompleteValue[]) || [];
        }
      }
      this.filterBranchOffices = (res.contractsBranchOffices as AutocompleteValue[]) || [];
      if (!this.filterBranchOffices.some(item => item.code === this.initialBranchOffice.code)) {
        this.filterBranchOffices.push(this.initialBranchOffice);
      }
      if (this.filterBranchOffices.length > 1) {
        this.filterBranchOffices = [{ code: 'selectAll', name: 'Все филиалы' }, ...this.filterBranchOffices];
      }

      const newContracts = mapApiArray(res.contracts, mapClientContract);
      if (this.deal && this.deal.contract?.code) {
        // Договор сделки должен отображаться в списке, даже если он не пришел с бэка.
        const inList = newContracts.filter(c => c.code === this.deal.contract.code).length > 0;
        if (!inList) {
          newContracts.push(Object.assign({}, this.deal.contract));
        }
      }
      res.contracts.forEach(contract => {
        if (contract?.sendings?.length) {
          this.contractLogsByCode[contract.code] = [];
          contract?.sendings.reverse().forEach(item => {
            item.logs.reverse().forEach(log => {
              this.contractLogsByCode[contract.code].push(log);
            });
          });
        }
      });
      this.totalStoreEntity = totalStoreGenerate(res.contractsTotalCount || 0, null, null);
      this.isInitialLoading = false;
      runInAction(() => {
        this.items.splice(0, this.items.length, ...newContracts);
        this.isLoaded = true;
        this.isLoading = false;
      });
      this.hasMore = res.contracts.length >= req.count;
    });
  }

  loadListForCreateForm(clientCode: string, withDrafts = true, branchOfficeCode = undefined, preset?: string): void {
    this.isLoaded = false;
    this.ignoreBeforeDate = new Date();
    this.customerCode = clientCode;
    this.filterKind = undefined;
    this.withDrafts = withDrafts;
    this.branchOfficeCode = branchOfficeCode;
    this.preset = preset || this.preset;
    this.svc.executeOne(this);
  }

  setResult(ctx: AxiosCallContext, res: ClientsItemContractsResponse): void {
    if (this.ignoreBeforeDate && this.ignoreBeforeDate.getTime() > ctx.startTime.getTime()) {
      return;
    }
    const newContracts = mapApiArray(res.contracts, mapClientContract);
    if (this.deal && this.deal.contract?.code) {
      // Договор сделки должен отображаться в списке, даже если он не пришел с бэка.
      const inList = newContracts.filter(c => c.code === this.deal.contract.code).length > 0;
      if (!inList) {
        newContracts.push(Object.assign({}, this.deal.contract));
      }
    }
    res.contracts.forEach(contract => {
      if (contract?.sendings?.length) {
        this.contractLogsByCode[contract.code] = [];
        contract?.sendings.reverse().forEach(item => {
          item.logs.reverse().forEach(log => {
            this.contractLogsByCode[contract.code].push(log);
          });
        });
      }
    });
    runInAction(() => {
      this.items.splice(0, this.items.length, ...newContracts);
      this.isLoaded = true;
      this.isLoading = false;
    });
  }

  setEmptyList(): void {
    this.items.splice(0, this.items.length);
    this.contractLogsByCode = {};
    this.isLoaded = true;
    this.isLoading = false;
  }
}

export class ContractListStoreService {
  executeOne(store: ContractListStore): void {
    if (!store.customerCode) {
      store.setEmptyList();
      return;
    }
    store.isLoading = true;
    store.apiStore
      .apiClientCustomer()
      .clientsItemContracts(store.customerCode, store.filterKind, store.branchOfficeCode, undefined, undefined, store.preset)
      .then((res: AxiosResponse<ClientsItemContractsResponse>): void => {
        store.setResult(getCallContext(res), res.data);
      });
  }

  loadFromClientDocuments(r: ContractsListRequest, store: ContractListStore): void {
    store.isLoading = true;
    set(store.request, r);
    const req = toJS(store.request);
    req.count = req.count || defaultPageSize;
    store.apiStore
      .apiClientCustomer()
      .contracts(
        undefined,
        req.clientCode,
        req.kind,
        undefined,
        store.isInitialLoading ? undefined : req.officeCodes,
        undefined,
        undefined,
        req.statusCode || undefined,
        undefined,
        undefined,
        undefined,
        undefined
      )
      .then((res): void => {
        store.loadForClientResult(getCallContext(res), req, res.data);
      });
  }

  loadContractStatuses(store: ContractListStore): void {
    store.apiStore
      .apiClientCustomer()
      .contractStatuses()
      .then(res => {
        store.setContractStatusesResponse(getCallContext(res), res.data);
      });
  }

  loadContractKinds(store: ContractListStore): void {
    store.apiStore
      .apiClientCustomer()
      .contractKinds()
      .then(res => {
        store.setContractKindsResponse(getCallContext(res), res.data);
      });
  }
}
