import { AppDeal } from '../slices/AppDeal';
import { makeAutoObservable, runInAction } from 'mobx';
import { CatalogWarehousesResponse, ContractCostAgreement, Freeze, Warehouse } from '../api/marketx';
import { AxiosResponse } from 'axios';
import { AxiosCallContext, getCallContext } from '../utils/axiosInit';
import { mapApiArray, mapWarehouse } from '../views/clients/lib';
import { AuthStore } from './AuthStore';
import { AppWarehouse } from '../types/AppWarehouse';
import { RootStore } from './StoreManager';
import { ApiStore } from './Global/ApiStore';

/**
 * Список складов.
 * К сделке или к профилю для каталога.
 */
export class WarehouseListStore {
  apiStore: ApiStore;
  svc: WarehouseService;

  dealCode?: string;
  items: AppWarehouse[] = new Array<AppWarehouse>();

  activeWarehouseCode?: string;
  authStore: AuthStore;

  isLoaded = false;
  isLoading = false;
  loadedEpoch = 0;

  constructor(rootStore: RootStore) {
    this.svc = new WarehouseService();
    this.apiStore = rootStore.getApiStore();
    this.authStore = rootStore.getAuth();
    makeAutoObservable(this, {
      svc: false,
      apiStore: false,
      authStore: false,
    });
  }
  get warehouseGroupCodes(): string[] {
    return this.items.filter(i => i.isGroup).map(i => i.code);
  }
  setResult(ctx: AxiosCallContext, res: CatalogWarehousesResponse): void {
    runInAction(() => {
      this.items.splice(0, this.items.length, ...mapApiArray(res.warehouses, mapWarehouse));
      this.isLoaded = true;
      this.isLoading = false;
      this.loadedEpoch++;
    });
  }
  setActiveWarehouseCode(warehouseCode: string): void {
    this.activeWarehouseCode = warehouseCode;
  }

  loadListForAgreement(agr: ContractCostAgreement): void {
    runInAction(() => {
      if (!this.isLoading) {
        this.isLoading = true;
        const branchOfficeCode =
          agr.branchOfficeCode || this.authStore.profile?.chosenBranchOfficeCode || this.authStore.profile?.branchOfficeCode;
        this.svc.executeOne(this, branchOfficeCode);
      }
    });
  }

  loadListForDeal(deal: AppDeal): void {
    runInAction(() => {
      if (deal?.code) {
        this.dealCode = deal.code;
      }
      this.setActiveWarehouseCode(deal?.warehouseCode);
      if (deal?.warehouseCode) {
        const inList = this.items && this.items.find(w => w.code === deal.warehouseCode);
        if (!inList) {
          this.items.push(<AppWarehouse>{
            code: deal?.warehouseCode,
            name: '---',
          });
        }
      }
      if (!this.isLoading) {
        this.isLoading = true;
        const branchOfficeCode =
          deal.branchOfficeCode || this.authStore.profile?.chosenBranchOfficeCode || this.authStore.profile?.branchOfficeCode;
        this.svc.executeOne(this, branchOfficeCode);
      }
    });
  }

  loadListForFreeze(freeze: Freeze): void {
    runInAction(() => {
      this.setActiveWarehouseCode(freeze?.warehouseCode);
      if (freeze?.warehouseCode) {
        const inList = this.items && this.items.find(w => w.code === freeze.warehouseCode);
        if (!inList) {
          this.items.push(<AppWarehouse>{
            code: freeze?.warehouseCode,
            name: '---',
          });
        }
      }
      if (!this.isLoading) {
        this.isLoading = true;
        const branchOfficeCode =
          freeze.branchOfficeCode || this.authStore.profile?.chosenBranchOfficeCode || this.authStore.profile?.branchOfficeCode;
        this.svc.executeOne(this, branchOfficeCode);
      }
    });
  }

  loadForProfile(): void {
    const { profile } = this.authStore;
    runInAction(() => {
      this.dealCode = undefined;
      this.activeWarehouseCode = profile?.warehouseCode;
      if (profile?.warehouseName && profile?.warehouseCode) {
        const inList = this.items && this.items.find(w => w.code === profile?.warehouseCode);
        if (!inList) {
          this.items.push(<AppWarehouse>{
            code: profile?.warehouseCode,
            name: profile?.warehouseName,
          });
        }
      }
      if (!this.isLoading) {
        this.isLoading = true;
        const branchOfficeCode = this.authStore.profile?.chosenBranchOfficeCode || this.authStore.profile?.branchOfficeCode;
        this.svc.executeOne(this, branchOfficeCode);
      }
    });
  }
}
export class WarehouseListStoreNew {
  apiStore: ApiStore;
  svc: WarehouseService;
  isLoaded = false;
  isLoading = false;
  items: Warehouse[] = [];
  branchOfficeCode: string;
  constructor(rootStore: RootStore) {
    this.svc = new WarehouseService();
    this.apiStore = rootStore.getApiStore();
    makeAutoObservable(this, {
      svc: false,
      apiStore: false,
    });
  }
  loadListWithWarehouseCode(chosenBranchOfficeCode: string, warehouseCode?: string, isVirtual?: boolean, isGroup?: boolean): Promise<void> {
    runInAction(() => {
      this.isLoading = true;
      this.branchOfficeCode = chosenBranchOfficeCode;
    });
    const isV = typeof isVirtual === 'boolean' ? isVirtual : undefined;
    const isG = typeof isGroup === 'boolean' ? isGroup : undefined;
    return this.apiStore
      .apiClientCatalog()
      .catalogWarehouses(chosenBranchOfficeCode, warehouseCode ? [warehouseCode] : undefined, isV, isG)
      .then((res: AxiosResponse<CatalogWarehousesResponse>) => {
        this.setResult(res.data);
      });
  }
  loadList(branchOfficeCode: string): Promise<void> {
    runInAction(() => {
      this.isLoading = true;
      this.branchOfficeCode = branchOfficeCode;
    });
    return this.apiStore
      .apiClientCatalog()
      .catalogWarehouses(branchOfficeCode)
      .then((res: AxiosResponse<CatalogWarehousesResponse>) => {
        this.setResult(res.data);
      });
  }
  setResult(data: CatalogWarehousesResponse): void {
    runInAction(() => {
      this.items = data.warehouses || [];
      this.isLoaded = true;
      this.isLoading = false;
    });
  }
}

class WarehouseService {
  executeOne(store: WarehouseListStore, branchOfficeCode: string = undefined): void {
    store.apiStore
      .apiClientCatalog()
      .catalogWarehouses(branchOfficeCode)
      .then((res: AxiosResponse<CatalogWarehousesResponse>): void => {
        store.setResult(getCallContext(res), res.data);
      });
  }
}
