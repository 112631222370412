import { RootStore } from '../StoreManager';
import { ApiStore } from '../Global/ApiStore';
import { makeAutoObservable, runInAction } from 'mobx';
import { AxiosResponse } from 'axios';
import { CatalogBatchCertificatesResponse, WsCatalogBatchCertificateLoadingAnswer } from '../../api/marketx';
import { IncomeMessage, IncomeMessageOf } from '../Global/EventManager';
import { debounce } from 'lodash';

const MsgTypeShopBatchCertificateLoadingAnswer = 'shop.batch.certificate.loading.answer';

export class BatchCertificateStore {
  apiStore: ApiStore;

  /**
   * Код партии, к которой грузится сертификат.
   */
  awaitingBatchCode: string;
  /**
   * Код отгрузки
   */
  awaitingShipmentCode: string;

  isLoaded = false;
  isLoading = false;
  isAwaiting = false;

  certificateError: string;
  certificateHref: string;
  certificateSize: number;

  stateCode: string;
  stateTitle: string;

  /**
   * Последние GUID-ы сообщений, полученных по сокету.
   * Иногда ответ по вебсокету приходит быстрее, чем по http,
   * в таких случаях http ответ нужно игнорировать.
   */
  processedGuids = new Array<string>();

  /**
   * Текущее количество повторных запросов
   */
  requestRepeatCount = 0;

  /**
   * Лимит повторных запросов
   */
  requestRepeatLimit = 10;

  validateAwaitingStateDebounced: () => void;

  constructor(rootStore: RootStore) {
    this.apiStore = rootStore.getApiStore();
    this.handleWs = this.handleWs.bind(this);
    this.validateAwaitingStateDebounced = debounce(() => {
      this.validateAwaitingState();
    }, 5000);
    makeAutoObservable(this, {
      apiStore: false,
      handleWs: false,
    });
  }

  loadBatchCertificate(batchCode: string, shipmentCode: string): void {
    this.awaitingBatchCode = batchCode;
    this.awaitingShipmentCode = shipmentCode;
    this.isLoading = true;
    this.stateCode = 'loading';
    this.apiStore
      .apiClientCatalog()
      .catalogBatchCertificates(batchCode, shipmentCode)
      .then((res: AxiosResponse<CatalogBatchCertificatesResponse>) => {
        this.setBatchCertificate(batchCode, res.data);
      });
    // Через 5 секунд проверяем, что статус обновился.
    this.validateAwaitingStateDebounced();
  }

  /**
   * Проверка текущего состояния.
   * Если в течении указанного интервала сохраняется статус "Ожидание",
   * то принудительно выполняется повторный запрос сертификата.
   */
  validateAwaitingState(): void {
    if (!(this.awaitingBatchCode || this.awaitingShipmentCode) || !this.isAwaiting) {
      return;
    }
    if (this.requestRepeatCount < this.requestRepeatLimit) {
      this.requestRepeatCount++;
      this.loadBatchCertificate(this.awaitingBatchCode, this.awaitingShipmentCode);
    }
  }

  setBatchCertificate(requestBatchCode: string, data: CatalogBatchCertificatesResponse): void {
    if (this.awaitingShipmentCode) {
      this.setAwaitingShipmentCertificate(data);
    }

    if (this.awaitingBatchCode) {
      this.setAwaitingBatchCertificate(requestBatchCode, data);
    }
  }

  setAwaitingBatchCertificate(requestBatchCode, data: CatalogBatchCertificatesResponse): void {
    if (this.stateCode === 'answer_ok' && !this.isLoading && this.isLoaded) {
      // Уже загружено
      return;
    }

    const cert = data.certificates?.find(cert => cert.batchCode === this.awaitingBatchCode);

    if (!cert) {
      if (requestBatchCode === this.awaitingBatchCode) {
        this.setAwaitingNoCertificateResult();
      }
      return;
    }

    this.isLoading = false;
    this.isLoaded = true;
    if (cert.loadingGuid && this.processedGuids.indexOf(cert.loadingGuid) >= 0) {
      return;
    }
    this.isAwaiting = cert.stateCode !== 'answer_ok' && cert.stateCode !== 'answer_error';
    if (!this.isAwaiting) {
      this.requestRepeatCount = 0;
    }
    this.stateCode = cert.stateCode;
    this.stateTitle = cert.stateTitle;
    this.certificateError = cert.error;
    this.certificateHref = cert.downloadHref;
    this.certificateSize = cert.filesize;
  }

  setAwaitingShipmentCertificate(data: CatalogBatchCertificatesResponse): void {
    data.certificates.every(cert => {
      if (cert.error) {
        this.certificateError = cert.error;
        return false;
      }
      return true;
    });
    this.isAwaiting = data.certificates.some(cert => cert.stateCode !== 'answer_ok' && cert.stateCode !== 'answer_error');
    if (!this.isAwaiting) {
      this.requestRepeatCount = 0;
    }
    if (data.certificates.length) {
      const answerError = data.certificates.find(cert => cert.stateCode === 'answer_error');
      const answerTimeout = data.certificates.find(cert => cert.stateCode === 'timeout');
      const answerInit = data.certificates.find(cert => cert.stateCode === 'init');
      const answerRequestSent = data.certificates.find(cert => cert.stateCode === 'request_sent');
      const answerOk = data.certificates.find(cert => cert.stateCode === 'answer_ok');
      if (answerError) {
        this.stateCode = answerError.stateCode;
        this.stateTitle = answerError.stateTitle;
      } else if (answerTimeout) {
        this.stateCode = answerTimeout.stateCode;
        this.stateTitle = answerTimeout.stateTitle;
      } else if (answerInit) {
        this.stateCode = answerInit.stateCode;
        this.stateTitle = answerInit.stateTitle;
      } else if (answerRequestSent) {
        this.stateCode = answerRequestSent.stateCode;
        this.stateTitle = answerRequestSent.stateTitle;
      } else if (answerOk) {
        this.stateCode = answerOk.stateCode;
        this.stateTitle = answerOk.stateTitle;
      }
    }
    this.isLoading = false;
    this.isLoaded = true;
    this.certificateHref = data.zipHref;
    this.certificateSize = 1;
  }

  isNoCert(): boolean {
    return this.stateCode === 'no_cert';
  }

  setAwaitingNoCertificateResult(): void {
    this.stateCode = 'no_cert';
    this.stateTitle = 'Нет сертификата';
    this.certificateHref = '';
    this.certificateSize = 0;
    this.isLoading = false;
    this.isLoaded = true;
  }

  handleWs(msg: IncomeMessage): void {
    if (msg.msgType === MsgTypeShopBatchCertificateLoadingAnswer && !this.awaitingShipmentCode) {
      this.handleWsLoadingAnswer(msg);
    }
  }

  handleWsLoadingAnswer(msg: IncomeMessageOf<WsCatalogBatchCertificateLoadingAnswer>): void {
    // if (this.awaitingBatchCode === msg.data?.batchCode) {
    runInAction(() => {
      if (msg.data?.requestGuid) {
        if (this.processedGuids.length > 9) {
          this.processedGuids.splice(0, this.processedGuids.length - 9);
        }
        this.processedGuids.push(msg.data.requestGuid);
      }
      if (this.stateCode === 'answer_ok' && !this.isLoading && this.isLoaded) {
        // Уже загружено
        return;
      }
      this.isAwaiting = msg.data?.stateCode !== 'answer_ok' && msg.data?.stateCode !== 'answer_error';
      if (!this.isAwaiting) {
        this.isLoading = false;
        this.isLoaded = true;
        this.requestRepeatCount = 0;
      }
      this.stateCode = msg.data?.stateCode;
      this.stateTitle = msg.data?.stateTitle;
      this.certificateHref = msg.data?.url;
      this.certificateError = msg.data?.error;
      this.certificateSize = msg.data?.size || 0;
    });
    // }
  }
}
