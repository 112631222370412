import React, { FC, useMemo } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import QueryBuilderOutlinedIcon from '@mui/icons-material/QueryBuilderOutlined';
import { observer } from 'mobx-react-lite';
import PaymentIcon from '@mui/icons-material/Payment';
import { renderPriceCur } from '../../utils/renderFormat';
import { ValueStore } from '../../store/ValueStore';
import { PaymentRangeInput } from './PaymentRangeInput';
import InfoIcon from '@mui/icons-material/Info';
import LightTooltip from '../ToolTip/LightTooltip';
import { CreditStateStore } from 'src/store/Clients/CreditStateStore';
import { pluralRus } from '@mx-ui/helpers';

interface PaymentRangesInputProps {
  paymentStore: PaymentRangesStore;
  isDateNotValid?: boolean;
}

export interface PaymentRangesStore {
  isViewOnly(): boolean;
  store: {
    getCreditStateStore(): CreditStateStore;
  };

  getPrepayPct(): ValueStore;
  getPrepayPctMin(): number;
  getPrepayPctMax(): number;
  getLimitPostPayDays(): number;
  getPrepayPctStep(): number;

  getPostpayDays(): ValueStore;
  getPostpayDaysMax(): number;

  /**
   * Стоимость кредита (если применимо)
   */
  creditCost(): number | undefined;
}

export const PaymentRangesBlock: FC<PaymentRangesInputProps> = observer(({ paymentStore, isDateNotValid = false }): JSX.Element => {
  const paymentIconMemoEl = useMemo(() => <PaymentIcon />, []);
  const queryBuilderOutlinedIconMemoEl = useMemo(() => <QueryBuilderOutlinedIcon />, []);
  const prepayPct = paymentStore.getPrepayPct().asString() || 0;
  const creditCost = paymentStore.creditCost();
  const showLabelForTooltip =
    Number(paymentStore.getPostpayDays().asString()) > paymentStore.getLimitPostPayDays() || !paymentStore.getLimitPostPayDays();
  const warningIcon = (
    <LightTooltip
      placement="left"
      title={
        showLabelForTooltip || !paymentStore.getLimitPostPayDays() ? (
          <Box p={0.6}>
            <Typography variant="body2" color="textPrimary">
              {(paymentStore.getLimitPostPayDays() || 0) === 0 || isDateNotValid
                ? 'Кредитный лимит не установлен'
                : `Превышен максимальный срок отсрочки (${paymentStore.getLimitPostPayDays() || 0} ${pluralRus(
                    paymentStore.getLimitPostPayDays() || 0,
                    ['день', 'дня', 'дней']
                  )})`}
            </Typography>
          </Box>
        ) : (
          <Box p={0.6}>
            <Typography variant="body2" color="textPrimary">
              {(paymentStore.getLimitPostPayDays() || 0) === 0 || isDateNotValid
                ? 'Кредитный лимит не установлен'
                : `Максимальный срок отсрочки (${paymentStore.getPostpayDaysMax() || 0} ${pluralRus(paymentStore.getPostpayDaysMax() || 0, [
                    'день',
                    'дня',
                    'дней',
                  ])})`}
            </Typography>
          </Box>
        )
      }
    >
      <Box sx={{ cursor: showLabelForTooltip ? 'help' : 'default' }}>
        <InfoIcon htmlColor={showLabelForTooltip || !paymentStore.getLimitPostPayDays() || isDateNotValid ? 'red' : 'green'} />
      </Box>
    </LightTooltip>
  );
  const marksAdded = [{ value: paymentStore.getLimitPostPayDays(), label: String(paymentStore.getLimitPostPayDays()) }];
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <PaymentRangeInput
          isViewOnly={paymentStore.isViewOnly()}
          value={paymentStore.getPrepayPct()}
          min={paymentStore.getPrepayPctMin() || 0}
          step={paymentStore.getPrepayPctStep()}
          max={paymentStore.getPrepayPctMax() || 100}
          label="Предоплата, %"
          icon={paymentIconMemoEl}
        />
      </Grid>

      <Grid item xs={12}>
        {Number(prepayPct) < 100 && (
          <>
            <PaymentRangeInput
              isViewOnly={paymentStore.isViewOnly()}
              value={paymentStore.getPostpayDays()}
              iconWarning={warningIcon}
              min={0}
              addMarks={marksAdded}
              step={1}
              showMarksMinMax
              max={paymentStore.getPostpayDaysMax()}
              label="Отсрочка, дней"
              icon={queryBuilderOutlinedIconMemoEl}
            />
            {creditCost !== undefined && (
              <Typography variant="body2" color="text.secondary" mt={1}>
                стоимость услуги: {renderPriceCur(creditCost)}
              </Typography>
            )}
          </>
        )}
      </Grid>
    </Grid>
  );
});
