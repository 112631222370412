import React, { FC } from 'react';
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  TimeScale,
  Title,
  LineController,
  BarController,
} from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import { Chart } from 'react-chartjs-2';
import { observer } from 'mobx-react';
import Box from '@mui/material/Box';
import 'chartjs-adapter-date-fns';
import { Plot, PlotPoint } from 'src/lib/plots';
import { formatPrice } from '@mx-ui/helpers';
import { useTheme } from '@mui/material/styles';
import { grey } from '@mui/material/colors';

ChartJS.register(
  TimeScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Title,
  Legend,
  annotationPlugin,
  CategoryScale,
  BarElement,
  LineController,
  BarController
);
const tsFactorA = 1000;

export interface ShipmentPriceBarChart {
  plot: Plot;
  height?: number;
  isPrice?: boolean;
}

type DataPoint = {
  t: number;
  y: number;
};

const annotation = {
  type: 'line' as const,
  borderColor: '#00A2E8',
  borderWidth: 1.5,
  display: true,
  label: {
    display: true,
    position: 'start' as const,
  },
  scaleID: 'x' as const,
  value: new Date().setUTCHours(0, 0, 0, 0),
};

const BarCharts: FC<ShipmentPriceBarChart> = observer(({ plot, height = 47, isPrice }): JSX.Element => {
  const dataPoints: DataPoint[] = [];
  const theme = useTheme();
  let lastPointX = Date.now() - 110 * 24 * 60 * 60 * tsFactorA;
  plot.points.forEach((point: PlotPoint): void => {
    lastPointX = point.x * tsFactorA;
    dataPoints.push({
      t: lastPointX,
      y: point.y,
    });
  });

  const data = {
    labels: dataPoints.map(i => i.t),
    datasets: [
      {
        data: dataPoints.map(() => plot.middleVal),
        fill: false,
        borderColor: theme.palette.mode === 'dark' ? grey[600] : grey[400],
        backgroundColor: theme.palette.mode === 'dark' ? grey[900] : grey[400],
        type: 'line' as const,
        borderWidth: 1,
        pointRadius: 0,
        lineTension: 0.3,
      },
      {
        data: dataPoints.map(i => i.y),
        fill: false,
        backgroundColor: '#00A5BC',
        type: 'bar' as const,
        pointRadius: 0.5,
        lineTension: 0.3,
      },
    ],
  };
  const unit = plot?.changeInValue?.unit || '';

  return (
    <Box sx={{ height: `${height}px`, position: 'relative', display: 'block' }}>
      <Chart
        type="bar"
        height={height}
        options={{
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false,
            },
            annotation: {
              annotations: {
                annotation,
              },
            },
            tooltip: {
              enabled: true,
              mode: 'index',
              intersect: false,
              displayColors: false,
              callbacks: {
                // Returns text to render as the title of the tooltip.
                title: function () {
                  return '';
                },
                // The label callback can change the text that displays for a given data point.
                label: function (ctx) {
                  if (ctx.dataset.type === 'line') return;
                  const { parsed, dataset } = ctx;
                  let label = dataset.label || '';

                  if (label) {
                    label += ': ';
                  }

                  label += new Date(parsed.x).toLocaleDateString().slice(0, 5);
                  label += ' - ';
                  label += formatPrice(Math.round(parsed.y * 100) / 100) + ' ' + unit;
                  return label;
                },
              },
            },
          },

          scales: {
            x: {
              display: false,
              type: 'time',
              time: {
                parser: 'x', // Unix ms timestamp
                unit: 'day',
                displayFormats: {
                  millisecond: 'mm:ss',
                  second: 'mm:ss',
                  minute: 'MMM dd',
                  hour: 'MMM dd',
                  day: 'MMM dd',
                  week: 'MMM dd',
                  month: 'MMM dd',
                  quarter: 'MMM dd',
                  year: 'MMM dd',
                },
              },
            },
            y: {
              offset: false,
              display: true,
              border: {
                display: true,
                color: theme.palette.mode === 'dark' ? grey[600] : grey[300],
              },
              grid: {
                color: theme.palette.mode === 'dark' ? grey[600] : grey[300],
              },
              ticks: {
                maxTicksLimit: Math.floor(height / 15),
                color: theme.palette.text.secondary,
                // Делаем все надписи на шкалах одной ширины = выравниваем графики.
                callback: function (value: number): string {
                  if (isPrice) {
                    return `${formatPrice(value)} ₽`;
                  }
                  let valueFixed = '' + value.toFixed(3);
                  for (let i = 0, cnt = 10 - valueFixed.length; i < cnt; i++) {
                    valueFixed = '\u00A0' + valueFixed;
                  }
                  return ' ' + ' ' + valueFixed + ` ${plot?.changeInValue?.unit ? plot?.changeInValue?.unit : ''}`;
                },
              },
            },
          },
        }}
        data={data}
      />
    </Box>
  );
});

export default BarCharts;
