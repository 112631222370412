import ruLocale from 'date-fns/locale/ru';
import React from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateRange } from './DateRange';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { getNormalizedDateRange } from '@mx-ui/helpers';
import DialogContentText from '@mui/material/DialogContentText';
import { DialogContainer } from '../ui/dialogs/DialogContainer';
interface ModalOnCloseCallback {
  (): void;
}

type ModalProps = {
  isOpen?: boolean;
  onClose?: ModalOnCloseCallback;
  onSave?: any;
  text?: string;
  startDateDefault?: Date | null;
  endDateDefault?: Date | null;
};

const Modal = ({
  isOpen,
  onClose,
  onSave,
  text = 'Укажите период',
  startDateDefault = null,
  endDateDefault = null,
}: ModalProps): JSX.Element => {
  const [value, setValue] = React.useState<DateRange>({
    start: startDateDefault || new Date(),
    end: endDateDefault || new Date(),
  });

  const handleChangeValue = (propName: string, newDate: Date): void => {
    if (!isNaN(newDate.getTime())) {
      setValue({
        ...value,
        [propName]: newDate,
      });
    }
  };

  const handleSave = (): void => {
    const { start, end } = value;
    const normalizedValue = getNormalizedDateRange(start, end);
    onSave(normalizedValue);
    setTimeout(() => {
      onClose();
    }, 100);
  };

  const handleClose = (): void => {
    onClose();
  };

  return (
    <div>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ruLocale}>
        <DialogContainer
          open={isOpen}
          onClose={handleClose}
          dialogContent={
            <>
              <DialogContentText sx={{ m: 1 }}>{text}</DialogContentText>
              <div style={{ display: 'inline-block', marginRight: '8px', marginBottom: '12px' }}>
                <DatePicker format="dd.MM.yyyy" label="начало" value={value.start} onChange={date => handleChangeValue('start', date)} />
              </div>

              <div style={{ display: 'inline-block', marginRight: '8px' }}>
                <DatePicker format="dd.MM.yyyy" label="конец" value={value.end} onChange={date => handleChangeValue('end', date)} />
              </div>
            </>
          }
          canselBtnText={'Отмена'}
          successBtnText={'Сохранить'}
          handleSuccess={handleSave}
        />
      </LocalizationProvider>
    </div>
  );
};

export default Modal;
