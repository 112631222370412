import React, { FC, useCallback, useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import { TextButton } from 'src/views/deals/components/TextButton';
import { CollapseButton } from 'src/views/deals/components/CollapseButton';
import Skeleton from '@mui/material/Skeleton';
import useMediaQuery from '@mui/material/useMediaQuery';
import { SelectChangeEvent } from '@mui/material/Select';
import { DealItemStore } from '../../store/DealItemStore';
import { observer } from 'mobx-react-lite';
import { ContractCostAgreement } from 'src/api/marketx';
import AgreementCustomSelect from '../../components/Deals/AgreementCustomSelect/AgreementCustomSelect';
import { useAuthStore } from '../../store/MobxStoreProvider';
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';
import CardTravelOutlinedIcon from '@mui/icons-material/CardTravelOutlined';
import { HandShakeIcon } from '../../components/ui/icons/HandShakeIcon';
export const DealTabsSkeleton = (): JSX.Element => {
  const matches = useMediaQuery('(min-width:630px)');
  return (
    <Grid item xs={12} style={{ marginTop: '32px', marginBottom: '16px' }}>
      {matches ? (
        <Grid container justifyContent={'space-between'} alignItems={'center'}>
          <Grid item>
            <Grid container flexWrap={'nowrap'}>
              <Grid item sx={{ border: '1px solid rgb(211, 220, 225)', borderRadius: '4px' }} mr={1}>
                <Skeleton variant="rectangular" width={'120px'} height={28} />
              </Grid>
              <Grid item sx={{ border: '1px solid rgb(211, 220, 225)', borderRadius: '4px' }} mr={1}>
                <Skeleton variant="rectangular" width={'75px'} height={28} />
              </Grid>
              <Grid item sx={{ border: '1px solid rgb(211, 220, 225)', borderRadius: '4px' }} mr={1}>
                <Skeleton variant="rectangular" width={'100px'} height={28} />
              </Grid>
              <Grid item sx={{ border: '1px solid rgb(211, 220, 225)', borderRadius: '4px' }} mr={1}>
                <Skeleton variant="rectangular" width={'125px'} height={28} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item sx={{ border: '1px solid rgb(211, 220, 225)', borderRadius: '4px', position: 'relative', right: '67px' }}>
            <Skeleton variant="rectangular" width={'24px'} height={24} />
          </Grid>
        </Grid>
      ) : (
        <Grid container flexWrap={'nowrap'}>
          <Grid item sx={{ border: '1px solid rgb(211, 220, 225)', borderRadius: '4px' }} xs={12}>
            <Skeleton variant="rectangular" width={'100%'} height={40} />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

interface DealTabsProps {
  dealStore: DealItemStore;
  selectedTab: number;
  onPositionAddDialogClick?: (categoryCode?: string) => void;
  onSelectTab: (index: number) => void;
}

export const DealTabs: FC<DealTabsProps> = observer(({ dealStore, selectedTab, onPositionAddDialogClick, onSelectTab }): JSX.Element => {
  const auth = useAuthStore();
  const matches = useMediaQuery('(min-width:818px)');
  const { profile } = auth;
  const [archiveCount, setArchiveCount] = useState(0);
  const handleAddProductClick = (event): void => {
    event.stopPropagation();

    onPositionAddDialogClick();
  };

  useEffect(() => {
    if (dealStore.deal?.positions) {
      let newCount = 0;
      dealStore.deal?.positions.forEach(p => {
        if (p.isArchive) {
          newCount++;
        }
      });

      setArchiveCount(newCount);

      if (newCount == 0 && selectedTab == 2) {
        onSelectTab(0);
      }
    }
  }, [dealStore, dealStore.deal, dealStore.deal?.positions, selectedTab]);

  const handleCloseAllPositions = useCallback(() => {
    dealStore.closeAllPositions();
  }, [dealStore]);

  const handleChangeSelect = (event: SelectChangeEvent<typeof selectedTab>): void => {
    onSelectTab(event.target.value as number);
  };

  let productsCount = 0;

  if (dealStore.deal?.positions?.length) {
    productsCount = dealStore.deal?.positions?.length - archiveCount;
  }

  const isDealPositions = (!!productsCount && selectedTab === 0) || (!!archiveCount && selectedTab === 2);

  const isAllClosed = dealStore.isAllPositionsClosed();
  let agreement: ContractCostAgreement;
  let currentAgreementCode: string | null;
  if (dealStore.deal?.contractCostAgreements?.length > 0) {
    agreement = dealStore.deal?.contractCostAgreements[0];
    currentAgreementCode = agreement ? agreement?.code : null;
  }
  if (!dealStore.isLoaded) return <DealTabsSkeleton />;

  return (
    <Grid item mt={4} mb={2} xs={isDealPositions ? 10 : 12} alignItems="center">
      <Grid container spacing={1} alignItems="center">
        <Grid item flex={`0 1 ${matches ? 'auto' : '100%'}`}>
          {matches ? (
            <Grid container alignItems="center">
              <Grid item>
                <TextButton onClick={() => onSelectTab(0)} selected={selectedTab == 0}>
                  Товары{productsCount > 0 ? ` (${productsCount})` : ''}
                </TextButton>
              </Grid>
              {/* <TextButton onClick={() => onSelectTab(1)} selected={selectedTab == 1}> */}
              {/*  Потребности */}
              {/* </TextButton> */}
              <Grid item>
                <TextButton onClick={() => onSelectTab(2)} selected={selectedTab == 2} disabled={!archiveCount}>
                  Архив{archiveCount > 0 ? ` (${archiveCount})` : ''}
                </TextButton>
              </Grid>
              <Grid item>
                <TextButton onClick={() => onSelectTab(4)} selected={selectedTab == 4} disabled={!dealStore.billsStore?.items?.length}>
                  <CreditCardOutlinedIcon sx={{ marginRight: 0.5 }} /> Счета
                  {(dealStore.billsStore?.items?.length || 0) > 0 ? ` (${dealStore.billsStore?.items?.length})` : ''}
                </TextButton>
              </Grid>
              <Grid item>
                <TextButton
                  onClick={() => onSelectTab(3)}
                  selected={selectedTab == 3}
                  disabled={!dealStore.shipmentsListStore?.items?.length}
                >
                  <CardTravelOutlinedIcon sx={{ marginRight: 0.5 }} />
                  Отгрузки
                  {(dealStore.shipmentsListStore?.items?.length || 0) > 0 ? ` (${dealStore.shipmentsListStore?.items?.length})` : ''}
                </TextButton>
              </Grid>
              {!!agreement && (
                <Grid item>
                  <TextButton onClick={() => onSelectTab(5)} selected={selectedTab == 5}>
                    <HandShakeIcon />
                    <Typography sx={{ fontSize: 'inherit', fontWeight: 'inherit', pl: 0.5 }}>Соглашение {agreement.code}</Typography>
                  </TextButton>
                </Grid>
              )}
            </Grid>
          ) : (
            <>
              <Select
                labelId="label"
                id="select"
                value={selectedTab}
                sx={{ width: isDealPositions ? 'calc(100% - 65px)' : '100%', fontWeight: 500 }}
                size={'small'}
                onChange={handleChangeSelect}
              >
                <MenuItem value={0} sx={{ fontWeight: 500 }}>
                  Товары{productsCount > 0 ? ` (${productsCount})` : ''}
                </MenuItem>
                {/* <MenuItem value={1} sx={{ fontWeight: 500 }}>*/}
                {/*  Потребности*/}
                {/* </MenuItem>*/}
                <MenuItem value={2} sx={{ fontWeight: 500 }} disabled={!archiveCount}>
                  <Grid container alignItems={'center'}>
                    Архив{archiveCount > 0 ? ` (${archiveCount})` : ''}
                  </Grid>
                </MenuItem>
                <MenuItem value={4} sx={{ fontWeight: 500 }} disabled={!dealStore.billsStore?.items?.length}>
                  <Grid container alignItems={'center'}>
                    <CreditCardOutlinedIcon sx={{ marginRight: 1 }} /> Счета
                    {(dealStore.billsStore?.items?.length || 0) > 0 ? ` (${dealStore.billsStore?.items?.length})` : ''}
                  </Grid>
                </MenuItem>
                <MenuItem value={3} sx={{ fontWeight: 500 }} disabled={!dealStore.shipmentsListStore?.items?.length}>
                  <Grid container alignItems={'center'}>
                    <CardTravelOutlinedIcon sx={{ marginRight: 0.5 }} />
                    Отгрузки
                    {(dealStore.shipmentsListStore?.items?.length || 0) > 0 ? ` (${dealStore.shipmentsListStore?.items?.length})` : ''}
                  </Grid>
                </MenuItem>
                {!!agreement && (
                  <MenuItem value={5} sx={{ fontWeight: 500 }}>
                    <Grid container alignItems={'center'}>
                      <HandShakeIcon />
                      <Typography sx={{ fontSize: 'inherit', fontWeight: 'inherit', pl: 0.5 }}>Соглашение {agreement.code}</Typography>
                    </Grid>
                  </MenuItem>
                )}
              </Select>
            </>
          )}
        </Grid>
        <Grid item flex={'1 1 auto'}>
          <Grid container alignItems="center" spacing={1}>
            {!dealStore.isViewOnly() && profile.features?.canDealAgreement && (
              <Grid item>
                <AgreementCustomSelect
                  clientCode={dealStore.deal.customerCode}
                  currentCode={currentAgreementCode}
                  dealStore={dealStore}
                  onPositionAddDialogClick={onPositionAddDialogClick}
                  onSelectTab={onSelectTab}
                  selectedTab={selectedTab}
                />
              </Grid>
            )}

            {!dealStore.isViewOnly() && (
              <Grid item>
                <Button size="small" variant="contained" color="secondary" onClick={handleAddProductClick}>
                  + из каталога
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid position={'absolute'} sx={{ top: '40px', right: '68px' }}>
          {((!!productsCount && selectedTab === 0) || (!!archiveCount && selectedTab === 2)) && (
            <CollapseButton type={isAllClosed ? 'expand-all' : 'less-all'} onClick={handleCloseAllPositions} />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
});
