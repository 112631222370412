import { makeAutoObservable, runInAction } from 'mobx';
import { v4 } from 'uuid';
import { DevPanelStore } from './DevPanelStore';
import { RootStore } from '../StoreManager';
import { EventManager, IncomeMessage, SubscribeCallback, Subscribed } from './EventManager';
import Cookies from 'js-cookie';

export const MsgType = {
  WILDCARD: '*',
  // не удалось разобрать сообщение
  WEB_SOCKET_INVALID: 'ws.invalid',

  WEB_SOCKET_CALL_UPDATED: 'ws.call.updated',

  WEB_SOCKET_CALL_REQUEST_ACTUAL: 'ws.call.request-actual',

  WEB_SOCKET_CALL_SET_CONTACT: 'ws.call.set-contact',

  WEB_SOCKET_CALL_SET_CONTACT_SUCCESS: 'ws.call.set-contact-success',

  WEB_SOCKET_CALL_HANGUP: 'ws.call.hangup',

  // Тип сообщения не указан
  WEB_SOCKET_UNKNOWN: 'ws.unknown',

  // соединение установлено
  WEB_SOCKET_OPEN_BACK: 'ws.open.back',

  // соединение закрыто
  WEB_SOCKET_CLOSED_BACK: 'ws.closed.back',

  // авторизация устройства в чате
  WEB_SOCKET_CHAT_DEVICE_AUTH: 'ws.chat.device-auth',

  WEB_SOCKET_CHAT_DEVICE_AUTHORIZED: 'ws.chat.device-authorized',
};

interface WebConfigProvider {
  apiWsCollieUrl?: string;
  apiWsEnabled?: boolean;
}

/**
 * Стор вебсокетов и менеджер событий.
 * link: https://metalx.gitlab.yandexcloud.net/metalxdev/collie/-/blob/master/data/docs/websocket.md
 */
export class WebSocketCollieStore {
  conn: WebSocket;
  devPanel: DevPanelStore;
  eventMgr: EventManager;
  webConfig: WebConfigProvider;

  /**
   * Время в мс для переподключения после закрытия соединения
   */
  reconnectDefaultInterval = 5000;
  reconnectTimeout: NodeJS.Timeout;

  lastConnectTime?: Date;

  /**
   * Номер текущего соединения.
   * По сути количество переподключений.
   */
  lastConnectNumber = 0;
  isConnected = false;

  constructor(rootStore: RootStore) {
    this.devPanel = rootStore.getDevPanel();
    this.eventMgr = rootStore.getEventManager();
    this.webConfig = rootStore.getWebConfig();
    makeAutoObservable(this, {
      conn: false,
      devPanel: false,
      eventMgr: false,
      reconnectTimeout: false,
    });
    if (typeof window !== 'undefined') {
      this.reconnect(1000);
    }
  }

  /**
   * Устанавливает соединение, если оно еще не установлено
   */
  connect(): void {
    if (this.conn) {
      return;
    }
    if (this.webConfig.apiWsEnabled === false) {
      // Специально отключено, так задается в тестах.
      return;
    }
    if (!this.webConfig.apiWsEnabled) {
      console.log('WebSocket disabled by config');
      return;
    }

    if (typeof window === 'undefined') {
      console.log('WebSocket disabled while no window');
      this.reconnect(this.reconnectDefaultInterval);
      return;
    }
    if (!window['WebSocket']) {
      console.log('WebSocket no supported by browser');
      return;
    }
    // если в env не пропишут что сразу работало
    let wsUrl = this.webConfig.apiWsCollieUrl || 'ws://localhost:3022/ws';

    if (!wsUrl) {
      console.error('WebSocket url not defined');
      return;
    }
    if (wsUrl.indexOf('/') === 0) {
      const windowHost = window.location.host.replace(/^(www.|qa.)/, ''); // remove "www." or "qa." only if it is at the beginning
      if (window.location.protocol === 'https:') {
        wsUrl = 'wss://api.collie.' + windowHost + wsUrl;
      } else {
        wsUrl = 'ws://api.collie.' + windowHost + wsUrl;
      }
    }
    const conn = new WebSocket(wsUrl);
    this.conn = conn;

    conn.onopen = (): void => {
      runInAction(() => {
        this.isConnected = true;
        this.lastConnectTime = new Date();
        this.lastConnectNumber++;
        this.eventMgr.processMessages(<IncomeMessage>{
          msgType: MsgType.WEB_SOCKET_OPEN_BACK,
          data: {
            connectNumber: this.lastConnectNumber,
          },
        });
      });
      this.send(MsgType.WEB_SOCKET_CHAT_DEVICE_AUTH, {
        token: getAuthToken(),
      });
    };

    conn.onclose = (ev: CloseEvent): void => {
      runInAction(() => {
        this.isConnected = false;
        this.eventMgr.processMessages(<IncomeMessage>{
          msgType: MsgType.WEB_SOCKET_CLOSED_BACK,
          data: {
            code: ev.code,
            reason: ev.reason,
            wasClean: ev.wasClean,
            bubbles: ev.bubbles,
          },
        });
        if (this.conn === conn) {
          this.reconnect(this.reconnectDefaultInterval);
        }
      });
    };

    conn.onmessage = (evt: MessageEvent): void => {
      const messages = evt.data.split('\n');
      const parsed = new Array<IncomeMessage>();
      for (let i = 0; i < messages.length; i++) {
        const msg = <IncomeMessage>{
          msgType: MsgType.WEB_SOCKET_INVALID,
          data: messages[i],
        };
        try {
          const p = JSON.parse(messages[i]);
          msg.msgType = p.msgType || MsgType.WEB_SOCKET_UNKNOWN;
          msg.data = p.data || undefined;
        } catch (e) {
          console.warn('bad web socket income json', e, messages[i]);
        }

        parsed.push(msg);
      }
      this.eventMgr.processMessages(...parsed);
    };
  }

  reconnect(delayMs?: number): void {
    if (this.conn) {
      const conn = this.conn;
      this.conn = undefined;
      if (conn.readyState !== WebSocket.CLOSED) {
        conn.close();
      }
    }
    if (this.reconnectTimeout) {
      clearTimeout(this.reconnectTimeout);
      this.reconnectTimeout = null;
    }
    if (delayMs > 0) {
      this.reconnectTimeout = setTimeout(() => {
        this.connect();
      }, delayMs);
    } else {
      this.connect();
    }
  }

  /**
   * Отправка сообщения на сервер
   * @param msgType
   * @param data
   */
  send(msgType: string, data: {}): void {
    if (!this.conn) {
      console.error('no connection for sending msgType', msgType);
      return;
    }
    if (this.conn.readyState !== WebSocket.OPEN) {
      console.error('not connected (' + this.conn.readyState + ') for sending msgType', msgType);
      return;
    }
    this.conn.send(
      JSON.stringify({
        msgType: msgType,
        traceId: v4(),
        data: data,
      })
    );
  }

  /**
   * Запрос на наличие текущих звонков
   */
  getActualCall(): void {
    this.send(MsgType.WEB_SOCKET_CALL_REQUEST_ACTUAL, undefined);
    // this.send('ws.me', undefined);
  }
  sendContactNew(callCode: string, contactCode: string, customerCode: string): void {
    this.send(MsgType.WEB_SOCKET_CALL_SET_CONTACT, {
      callCode: callCode,
      contactCode: contactCode || undefined,
      customerCode: customerCode || undefined,
    });
    // this.send('ws.me', undefined);
  }
  callEnd(callCode: string): void {
    this.send(MsgType.WEB_SOCKET_CALL_HANGUP, {
      callCode: callCode,
    });
  }
  processMessages(...messages: IncomeMessage[]): void {
    this.eventMgr.processMessages(...messages);
  }

  subscribe(msgType: string, handler: SubscribeCallback): Subscribed {
    return this.eventMgr.subscribe(msgType, handler);
  }

  unsubscribe(s: Subscribed): void {
    this.eventMgr.unsubscribe(s);
  }
}

const getAuthToken = (): string => {
  if (typeof document === 'undefined' || !document.cookie) {
    return '';
  }
  return Cookies.get('ssoToken');
};
