import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import List from '@mui/material/List';
import { styled } from '@mui/material/styles';
import { FC, useState } from 'react';
import SendIcon from '@mui/icons-material/Send';
import { TaskCommentItem } from 'src/api/marketx';
import { formatYmdHiLZ } from '@mx-ui/helpers';
import { CollapsibleText } from '@mx-ui/ui';
import { observer } from 'mobx-react-lite';
import { UserAvatar } from '@mx-ui/ui';

interface CommentBlockProps {
  onSubmitComment: (value: string) => void;
  comments: TaskCommentItem[];
}
const PREFIX = 'CommentBlock';

const classes = {
  root: `${PREFIX}-root`,
  listItemText: `${PREFIX}-listItemText`,
  listItem: `${PREFIX}-listItem`,
  inline: `${PREFIX}-inline`,
  wrapForm: `${PREFIX}-wrapForm`,
};

const Root = styled(Box)(() => ({
  [`& .${classes.root}`]: {
    width: '100%',
    backgroundColor: 'background.paper',
  },
  [`& .${classes.listItemText}`]: {
    width: '66%', // color: 'red',
  },
  [`& .${classes.listItem}`]: {
    paddingRight: '8px',
  },
  [`& .${classes.inline}`]: {
    display: 'inline',
  },
  [`& .${classes.wrapForm}`]: {
    display: 'flex',
    marginBottom: '8px',
    justifyContent: 'center',
    margin: `${0} auto`,
  },
}));
export const CommentBlock: FC<CommentBlockProps> = observer(({ onSubmitComment, comments = [] }): JSX.Element => {
  const [comment, setComment] = useState<string>('');
  const handleChange = (e): void => {
    setComment(e.target.value);
  };
  const handleSubmitComment = (): void => {
    onSubmitComment(comment);
    setComment('');
  };
  return (
    <Root width={'100%'}>
      <Box sx={{ maxHeight: '300px', overflowY: 'auto', mx: 1 }}>
        <List className={classes.root}>
          {comments.map((comment, index) => {
            const fio = `${comment.initiator.surname || ''} ${comment.initiator.name || ''} ${comment.initiator.patronymic || ''}`;
            return (
              <ListItem
                alignItems="flex-start"
                key={comment.code}
                disableGutters
                className={classes.listItem}
                divider={index !== comments.length - 1}
                dense
              >
                <ListItemAvatar>
                  {comment.initiator && <UserAvatar name={comment.initiator.name} surname={comment.initiator.surname} />}
                </ListItemAvatar>
                <ListItemText
                  primary={fio}
                  secondaryTypographyProps={{ component: 'div' }}
                  secondary={
                    <CollapsibleText>
                      <>{comment.comment}</>
                    </CollapsibleText>
                  }
                  className={classes.listItemText}
                />
                <ListItemText secondary={formatYmdHiLZ(comment.createdAt)} secondaryTypographyProps={{ align: 'right' }} />
              </ListItem>
            );
          })}
        </List>
      </Box>
      <Box>
        <Box className={classes.wrapForm}>
          <TextField
            multiline
            maxRows={3}
            sx={{ width: '100%', marginRight: 1 }}
            value={comment}
            onChange={handleChange}
            label="Комментарий"
          />
          <Box alignSelf={'center'}>
            <IconButton color="primary" disabled={comment.length === 0} onClick={handleSubmitComment}>
              <SendIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>
    </Root>
  );
});
