import React, { FC, JSX } from 'react';
import { observer } from 'mobx-react-lite';
import { ProductItemReserve, ProductItemReserveStateCodeEnum } from '../../../api/marketx';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Grid, IconButton } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { formatNumberByUnit, formatYmdHiLZ } from '@mx-ui/helpers';
import { MxLabel } from '@mx-ui/ui';
import { paymentStateColor } from '../../Bills/BillListItem';
import Divider from '@mui/material/Divider';
import { ReserveListStore } from 'src/store/ReserveListStore';
import DoDisturbOnOutlinedIcon from '@mui/icons-material/DoDisturbOnOutlined';
import FilterListIcon from '@mui/icons-material/FilterList';
import { useRouter } from 'next/router';

interface DefaultReserveTableProps {
  reserveStore?: ReserveListStore;
  reserve: ProductItemReserve;
  selectedWarehouse?: string;
}
const boxGridStyleSoftReserve = {
  display: 'grid',
  minWidth: '700px',
  gridTemplateColumns: 'repeat(4, 1fr)',
  gridGap: '4px',
  padding: '8px 0',
};
export const DefaultReserveTable: FC<DefaultReserveTableProps> = observer(({ reserve, selectedWarehouse, reserveStore }): JSX.Element => {
  const matches = useMediaQuery('(min-width:865px)');
  const titleHeight = '40px';
  const isSelectedWarehouse = selectedWarehouse ? reserve.warehouseCode === selectedWarehouse : true;
  const colorText = isSelectedWarehouse ? 'textPrimary' : 'textSecondary';
  const fontWeight = selectedWarehouse && isSelectedWarehouse ? 500 : undefined;
  const isProductReserve = useRouter().asPath.includes('app/product');

  const handleStateCodeChange = (stateCode: ProductItemReserveStateCodeEnum): void => {
    if (reserveStore.request?.reserveStateCodes?.includes(stateCode)) {
      return;
    }
    reserveStore.mergeRequest({ reserveStateCodes: [stateCode] });
  };

  const handleStateCodeRemoveChange = (stateCode: ProductItemReserveStateCodeEnum): void => {
    if (!reserveStore.request?.reserveStateCodes?.includes(stateCode)) {
      return;
    }
    const newReserveStateCodes = reserveStore.request?.reserveStateCodes?.filter(i => i !== stateCode);
    reserveStore?.mergeRequest({ reserveStateCodes: newReserveStateCodes });
  };

  const handleWarehouseCodeChange = (warehouseCode?: string): void => {
    if (reserveStore.request?.warehouseCodes?.includes(warehouseCode)) {
      return;
    }
    reserveStore.mergeRequest({ warehouseCodes: [warehouseCode] });
  };

  const handleWarehouseCodeRemoveChange = (warehouseCode?: string): void => {
    if (!reserveStore.request?.warehouseCodes?.includes(warehouseCode)) {
      return;
    }
    const newWarehouseCodes = reserveStore.request?.warehouseCodes?.filter(i => i !== warehouseCode);
    reserveStore?.mergeRequest({ warehouseCodes: newWarehouseCodes });
  };

  return matches ? (
    <Box sx={boxGridStyleSoftReserve}>
      <Box display="grid">
        <Box minHeight={titleHeight}>
          <Typography fontSize={'12px'} color={'text.secondary'}>
            Дата резерва
          </Typography>
        </Box>
        <Box>
          <Typography variant="caption" color={colorText} fontWeight={fontWeight}>
            {reserve.reservedAt ? formatYmdHiLZ(reserve.reservedAt) : ''}
          </Typography>
        </Box>
      </Box>
      <Box display="grid">
        <Box minHeight={titleHeight}>
          <Typography fontSize={'12px'} color={'text.secondary'}>
            Статус резерва
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {reserve?.stateCode ? (
            <MxLabel color={paymentStateColor(reserve?.stateCode)} style={{ fontSize: '10px' }}>
              {reserve.stateTitle}
            </MxLabel>
          ) : reserve.stateTitle ? (
            <MxLabel color={'error'} style={{ fontSize: '10px' }}>
              {reserve.stateTitle}
            </MxLabel>
          ) : (
            <Typography variant="caption" color={colorText} fontWeight={fontWeight}>
              {''}
            </Typography>
          )}
          <Box>
            {reserveStore ? (
              reserveStore?.request?.reserveStateCodes?.includes(reserve?.stateCode) ? (
                <Box>
                  <IconButton
                    size={'small'}
                    onClick={() => handleStateCodeRemoveChange(reserve?.stateCode)}
                    title={`Убрать фильтрацию по статусу резерва ${reserve?.stateTitle}`}
                  >
                    <DoDisturbOnOutlinedIcon color={'primary'} sx={{ fontSize: '20px' }} />
                  </IconButton>
                </Box>
              ) : (
                <Box>
                  <IconButton
                    size={'small'}
                    onClick={() => handleStateCodeChange(reserve?.stateCode)}
                    title={`Найти счета по статусу резерва ${reserve?.stateTitle}`}
                  >
                    <FilterListIcon color={'primary'} sx={{ fontSize: '20px' }} />
                  </IconButton>
                </Box>
              )
            ) : null}
          </Box>
        </Box>
      </Box>
      <Box display="grid">
        <Box minHeight={titleHeight}>
          <Typography fontSize={'12px'} color={'text.secondary'}>
            Резерв
          </Typography>
        </Box>
        <Box>
          <Typography variant="caption" color={colorText} fontWeight={fontWeight}>
            {`${formatNumberByUnit(reserve.quantity || 0, reserve.unitCode, reserve.unit || 'т')} из ${formatNumberByUnit(
              reserve.plannedQuantity || 0,
              reserve.unitCode,
              reserve.unit || 'т'
            )}`}
          </Typography>
        </Box>
      </Box>

      <Box display="grid">
        <Box minHeight={titleHeight}>
          <Typography fontSize={'12px'} color={'text.secondary'}>
            Склад
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="caption" color={colorText} fontWeight={fontWeight}>
            {reserve.warehouseName}
          </Typography>
          <Box>
            {reserveStore && !isProductReserve ? (
              reserveStore?.request?.warehouseCodes?.includes(reserve?.warehouseCode) ? (
                <Box>
                  <IconButton
                    size={'small'}
                    onClick={() => handleWarehouseCodeRemoveChange(reserve?.warehouseCode)}
                    title={`Убрать фильтрацию по складу ${reserve?.warehouseName}`}
                  >
                    <DoDisturbOnOutlinedIcon color={'primary'} sx={{ fontSize: '20px' }} />
                  </IconButton>
                </Box>
              ) : (
                <Box>
                  <IconButton
                    size={'small'}
                    onClick={() => handleWarehouseCodeChange(reserve?.warehouseCode)}
                    title={`Найти счета по складу ${reserve?.warehouseName}`}
                  >
                    <FilterListIcon color={'primary'} sx={{ fontSize: '20px' }} />
                  </IconButton>
                </Box>
              )
            ) : null}
          </Box>
        </Box>
      </Box>
    </Box>
  ) : (
    <Grid container>
      <Grid item xs={12}>
        <Divider />
        <Grid container alignItems={'center'} spacing={1}>
          <Grid item xs={6}>
            <Typography variant={'body2'}> Дата резерва</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" color={colorText} fontWeight={fontWeight}>
              {reserve.reservedAt ? formatYmdHiLZ(reserve.reservedAt) : ''}
            </Typography>
          </Grid>
        </Grid>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <Grid container alignItems={'center'} spacing={1}>
          <Grid item xs={6}>
            <Typography variant={'body2'}>Статус резерва</Typography>
          </Grid>
          <Grid item xs={6}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {reserve?.stateCode ? (
                <MxLabel color={paymentStateColor(reserve?.stateCode)} style={{ fontSize: '10px' }}>
                  {reserve.stateTitle}
                </MxLabel>
              ) : reserve.stateTitle ? (
                <MxLabel color={'error'} style={{ fontSize: '10px' }}>
                  {reserve.stateTitle}
                </MxLabel>
              ) : (
                <Typography variant="caption" color={colorText} fontWeight={fontWeight}>
                  {''}
                </Typography>
              )}
              <Box>
                {reserveStore ? (
                  reserveStore?.request?.reserveStateCodes?.includes(reserve?.stateCode) ? (
                    <Box>
                      <IconButton
                        size={'small'}
                        onClick={() => handleStateCodeRemoveChange(reserve?.stateCode)}
                        title={`Убрать фильтрацию по статусу резерва ${reserve?.stateTitle}`}
                      >
                        <DoDisturbOnOutlinedIcon color={'primary'} sx={{ fontSize: '20px' }} />
                      </IconButton>
                    </Box>
                  ) : (
                    <Box>
                      <IconButton
                        size={'small'}
                        onClick={() => handleStateCodeChange(reserve?.stateCode)}
                        title={`Найти счета по статусу резерва ${reserve?.stateTitle}`}
                      >
                        <FilterListIcon color={'primary'} sx={{ fontSize: '20px' }} />
                      </IconButton>
                    </Box>
                  )
                ) : null}
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <Grid container alignItems={'center'} spacing={1}>
          <Grid item xs={6}>
            <Typography variant={'body2'}>Резерв</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" color={colorText} fontWeight={fontWeight}>
              {`${formatNumberByUnit(reserve.quantity || 0, reserve.unitCode, reserve.unit || 'т')} из ${formatNumberByUnit(
                reserve.plannedQuantity || 0,
                reserve.unitCode,
                reserve.unit || 'т'
              )}`}
            </Typography>
          </Grid>
        </Grid>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <Grid container alignItems={'center'} spacing={1}>
          <Grid item xs={6}>
            <Typography variant={'body2'}>Склад</Typography>
          </Grid>
          <Grid item xs={6}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="body2" color={colorText} fontWeight={fontWeight}>
                {reserve.warehouseName}
              </Typography>
              <Box>
                {reserveStore && !isProductReserve ? (
                  reserveStore?.request?.warehouseCodes?.includes(reserve?.warehouseCode) ? (
                    <Box>
                      <IconButton
                        size={'small'}
                        onClick={() => handleWarehouseCodeRemoveChange(reserve?.warehouseCode)}
                        title={`Убрать фильтрацию по складу ${reserve?.warehouseName}`}
                      >
                        <DoDisturbOnOutlinedIcon color={'primary'} sx={{ fontSize: '20px' }} />
                      </IconButton>
                    </Box>
                  ) : (
                    <Box>
                      <IconButton
                        size={'small'}
                        onClick={() => handleWarehouseCodeChange(reserve?.warehouseCode)}
                        title={`Найти счета по складу ${reserve?.warehouseName}`}
                      >
                        <FilterListIcon color={'primary'} sx={{ fontSize: '20px' }} />
                      </IconButton>
                    </Box>
                  )
                ) : null}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
});
