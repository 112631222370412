import { useLocalStorage } from './useLocalStorage';

interface DocItem {
  name: string;
  link: string;
  title: string;
}

export const useLocalStorageForSearchDocuments = (): [DocItem[], (item: DocItem) => void] => {
  const [documentsStorage, setDocumentsStorage] = useLocalStorage<DocItem[]>('searchDocumentsList');

  const setDocsMiddleware = (item: DocItem): void => {
    const { link, name, title } = item;
    if (!documentsStorage) {
      setDocumentsStorage([
        {
          name,
          link,
          title,
        },
      ]);
    } else if (documentsStorage.some(i => i.link === link)) {
      return;
    } else if (documentsStorage?.length < 10) {
      setDocumentsStorage([
        {
          name,
          link,
          title,
        },
        ...documentsStorage,
      ]);
    } else {
      setDocumentsStorage([
        {
          name,
          link,
          title,
        },
        ...documentsStorage.slice(0, 9),
      ]);
    }
  };

  return [documentsStorage, setDocsMiddleware];
};
