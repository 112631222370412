import { makeAutoObservable } from 'mobx';
import { AxiosResponse } from 'axios';
import { DealsItemConsigneesResponse, Consignee } from '../api/marketx';
import { AppDeal } from '../slices/AppDeal';
import { AxiosCallContext, getCallContext } from '../utils/axiosInit';
import { RootStore } from './StoreManager';
import { ApiStore } from './Global/ApiStore';

export class DealConsigneeListStore {
  apiStore: ApiStore;
  svc: DealConsigneeListService;

  dealCode: string;
  items: Consignee[] = [];
  // время, когда эти данные были запрошены (фронт компонентом, это не время ответа бэкенда)
  requestTime: Date;
  ignoreBeforeDate?: Date;

  isLoaded = false;
  loadedEpoch = 0;

  constructor(rootStore: RootStore) {
    this.apiStore = rootStore.getApiStore();
    this.svc = new DealConsigneeListService();
    makeAutoObservable(this, {
      apiStore: false,
      svc: false,
    });
  }

  loadListForDeal(deal: AppDeal): void {
    this.ignoreBeforeDate = new Date();
    this.isLoaded = false;
    this.items.splice(0);

    this.dealCode = deal?.code;
    if (deal?.consigneeCode) {
      this.items.push(<Consignee>{
        code: deal?.consigneeCode,
        title: deal?.consigneeTitle,
        mailingAddress: '',
      });
    }
    this.svc.requestBackendData(this);
  }

  setResult(ctx: AxiosCallContext, res: DealsItemConsigneesResponse): void {
    if (this.ignoreBeforeDate && this.ignoreBeforeDate.getTime() > ctx.startTime.getTime()) {
      console.log('ignore irrelevant consignee list response');
      return;
    }
    const newItems =
      res.consignees?.map((c: Consignee) => {
        return <Consignee>{
          code: c.code,
          title: c.title,
          mailingAddress: c.mailingAddress,
          isDeleted: c.isDeleted,
        };
      }) || [];
    this.items.splice(0, this.items.length, ...newItems);
    this.isLoaded = true;
    this.loadedEpoch++;
  }
}

export class DealConsigneeListService {
  requestBackendData(store: DealConsigneeListStore): void {
    if (!store.dealCode) {
      return;
    }
    store.apiStore
      .apiClientDeal()
      .dealsItemConsignees(store.dealCode)
      .then((res: AxiosResponse<DealsItemConsigneesResponse>): void => {
        store.setResult(getCallContext(res), res.data);
      });
  }
}
