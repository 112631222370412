import { makeAutoObservable, observable } from 'mobx';
import { AxiosResponse } from 'axios';
import { Address, ClientsItemAddressesResponse } from '../api/marketx';
import { ApiStore } from './Global/ApiStore';
import { RootStore } from './StoreManager';

export type AddressInfo = {
  code: string;
  title: string;
};

type GetAddressRequest = {
  clientCode: string;
  consigneeCode?: string;
  // Дополнительный элемент, который будет находиться в списке до загрузки реального списка
  defaultCode?: string;
  defaultTitle?: string;
  // Перезагрузить, даже если данные уже есть в кэше
  rewriteLoaded?: boolean;
  // Причина загрузки (может использоваться для отладки)
  reason: string;
};

export type StorageItem = {
  key: string;
  clientCode: string;
  consigneeCode?: string;
  items: AddressInfo[];
  // время, когда эти данные были запрошены (фронт компонентом, это не время ответа бэкенда)
  requestTime: Date;
  // Эпоха загруженных данных. По сути -- номер обновления.
  // Меняется каждый раз, когда данные загружаются с сервера.
  loadedEpoch: number;
};

export class DealAddressStore {
  apiStore: ApiStore;
  // хранилище загружаемых/загруженных данных
  storage: Record<string, StorageItem> = {};
  // Время, в течение которого не будут повторяться запросы к бэкенду для одного контрагента (мс)
  cacheTimeoutMs = 60000;
  // Задержка перед выполнением запроса к бэкенду
  interval: NodeJS.Timeout;
  // Очередь на загрузку грузополучателей
  requestQueue: StorageItem[] = [];

  constructor(rootStore: RootStore) {
    this.apiStore = rootStore.getApiStore();
    makeAutoObservable(this, {
      apiStore: false,
    });
  }

  executeQueue(): void {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = null;
    }
    const requested: Record<string, boolean> = {};
    while (this.requestQueue && this.requestQueue.length) {
      const req = this.requestQueue.shift();
      if (req.key && !requested[req.key]) {
        requested[req.key] = true;
        this.requestBackendData(req);
      }
    }
  }

  requestBackendData(item: StorageItem): void {
    this.apiStore
      .apiClientCustomer()
      .clientsItemAddresses(item.clientCode, item.consigneeCode)
      .then((request: AxiosResponse<ClientsItemAddressesResponse>): void => {
        this.setAddresses(item, request.data);
      });
  }

  setAddresses(item: StorageItem, data: ClientsItemAddressesResponse): void {
    this.storage[item.key].items = data.addresses.map((c: Address) => {
      return <AddressInfo>{
        code: c.code,
        title: c.addressFull,
      };
    });
    this.storage[item.key].loadedEpoch++;
    console.log('Addresses loaded for ', item.key, '(' + this.storage[item.key].items.length + ')');
  }

  getAddresses({ clientCode, consigneeCode, rewriteLoaded, defaultCode, defaultTitle }: GetAddressRequest): StorageItem {
    clientCode = clientCode || '';
    const key = clientCode + '--' + consigneeCode;
    let item = this.storage[key];
    const now = new Date();
    const isCacheValid = item && now.getTime() - item.requestTime.getTime() < this.cacheTimeoutMs && !rewriteLoaded;
    console.log('address cache valid =', isCacheValid);
    if (isCacheValid) {
      return item;
    }
    if (!item) {
      const loadingItems = new Array<AddressInfo>();
      if (defaultCode) {
        loadingItems.push(<AddressInfo>{
          code: defaultCode,
          title: defaultTitle,
        });
      }
      item = observable(<StorageItem>{
        key: key,
        clientCode: clientCode,
        consigneeCode: consigneeCode,
        items: loadingItems,
        requestTime: now,
        loadedEpoch: 0,
      });
      this.storage[item.key] = item;
    } else {
      item.requestTime = now;
    }
    this.requestQueue.push(item);
    this.runInterval();
    return item;
  }

  getAddressesDef(clientCode?: string, consigneeCode?: string, defaultCode?: string, defaultTitle?: string): StorageItem {
    return this.getAddresses(<GetAddressRequest>{
      clientCode: clientCode || '',
      consigneeCode: consigneeCode,
      defaultCode: defaultCode,
      defaultTitle: defaultTitle,
      reason: 'getDef',
    });
  }

  // вызывает принудительную загрузку адресов, даже если они уже есть в кэше
  reloadAddresses(clientCode?: string, consigneeCode?: string, reason?: string): StorageItem {
    return this.getAddresses(<GetAddressRequest>{
      clientCode: clientCode || '',
      consigneeCode: consigneeCode,
      rewriteLoaded: true,
      reason: reason || 'reload',
    });
  }

  runInterval(): void {
    if (this.interval) {
      return;
    }
    this.interval = setInterval(() => {
      this.executeQueue();
    }, 1000);
  }
}
