import { makeAutoObservable, runInAction } from 'mobx';
import { AxiosResponse } from 'axios';
import { AboutResponse } from '../../api/marketx';
import { RootStore } from '../StoreManager';
import { ApiStore } from './ApiStore';
import { MsgType, WebSocketStore } from './WebSocketStore';
import { F1ClientStore } from './F1ClientStore';
import { startTransaction } from '@sentry/nextjs';
import { IncomeMessage } from './EventManager';
import { WebSocketCollieStore, MsgType as MsgTypeCall } from './WebSocketCollieStore';

export class BackVersionStoreItem {
  requestTime: Date; // время последнего запроса, на который получен ответ
  isLoaded = false; // станет true после получения первых данных от бэкенда

  /**
   * Время сборки бэкенда
   */
  buildTime = '';
  lifetime = '';
  firstBuildTime = ''; // первая известная версия бэкенда
  lastError = '';
  env = '';

  constructor() {
    makeAutoObservable(this, {});
  }

  setLastError(err: unknown): void {
    this.lastError = err ? err.toString() : 'unknown';
  }
}

export class FrontVersionStoreItem {
  requestTime: Date; // время последнего запроса, на который получен ответ
  isLoaded = false; // станет true после получения первых данных от бэкенда

  actualImageTime = ''; // версия, которая сейчас отдается сервером
  firstImageTime = ''; // первая известная версия
  updateRequired = false; // Признак того, что фронт пора обновить
  lastError = '';

  constructor() {
    makeAutoObservable(this, {});
  }

  setLastError(err: unknown): void {
    this.lastError = err ? err.toString() : 'unknown';
  }
}

interface FrontBuildInfoStructure {
  branch?: string;
  commit?: string;
  imageTime?: string;
}

/**
 * Информация о версиях бэкенда и фронтенда
 */
export class VersionStore {
  // Задержка перед началом загрузки
  frontInterval: NodeJS.Timeout;
  backInterval: NodeJS.Timeout;
  apiStore: ApiStore;
  wsStore: WebSocketStore;
  wsStoreCollie: WebSocketCollieStore; // второй сокет прям тут вызываем для полноты сокетов
  f1ClientStore: F1ClientStore;
  back: BackVersionStoreItem;
  front: FrontVersionStoreItem;
  // Время получения разрешения на обновление
  refreshPermissionTime: Date;

  /**
   * Признак того, что версия бэкенда актуализирована через веб-сокет
   * и можно не делать постоянные http-запросы.
   */
  isBackWsUpdated = false;

  /**
   * Признак того, что версия серверной части фронтенда актуализирована через
   * веб-сокет и можно не делать постоянные http-запросы.
   */
  isFrontWsUpdated = false;

  constructor(rootStore: RootStore) {
    this.apiStore = rootStore.getApiStore();
    this.wsStore = rootStore.getWebSocket();
    this.wsStoreCollie = rootStore.getWebCollieSocket();
    this.f1ClientStore = rootStore.getF1ClientStore();
    // Первый запуск
    if (typeof window !== 'undefined') {
      setTimeout(() => {
        this.executeBack(this.getBack());
        this.executeFront(this.getFront());
      }, 1);

      // Периодическое обновление
      this.frontInterval = setInterval(() => {
        this.executeFront(this.getFront());
      }, 3 * 60 * 1000);
      this.backInterval = setInterval(() => {
        this.executeBack(this.getBack());
      }, 5 * 60 * 1000);
    } else {
      clearInterval(this.frontInterval);
      clearInterval(this.backInterval);
    }

    // Получение версии бэкенда через веб-сокет
    this.wsStore.subscribe(MsgType.WEB_SOCKET_OPEN_BACK, () => {
      this.handleWsBackSocketOpen();
    });

    this.wsStoreCollie.subscribe(MsgTypeCall.WEB_SOCKET_OPEN_BACK, () => {
      this.wsStoreCollie.subscribe(MsgTypeCall.WEB_SOCKET_CHAT_DEVICE_AUTHORIZED, () => {
        // this.wsStoreCollie.conn.readyState === WebSocket.OPEN
        if (this.wsStoreCollie.conn?.readyState === WebSocket.OPEN) {
          this.handleWsCallActual();
        }
      });
    });
    this.wsStore.subscribeRestApiResponse('shop', 'get', '/about', (msg: IncomeMessage) => {
      this.handleWsShopAboutResponse(msg);
    });
    this.wsStore.subscribe(MsgType.WEB_SOCKET_CLOSED_BACK, () => {
      this.handleWsBackSocketClosed();
    });

    // Получение версии фронтенда через веб-сокет
    this.wsStore.subscribe(MsgType.WEB_SOCKET_F1_OPEN, () => {
      this.handleWsFrontSocketOpen();
    });
    this.wsStore.subscribeRestApiResponse('f1', 'get', '/about', (msg: IncomeMessage) => {
      this.handleWsF1AboutResponse(msg);
    });
    this.wsStore.subscribe(MsgType.WEB_SOCKET_F1_CLOSED, () => {
      this.handleWsFrontSocketClosed();
    });
  }

  /**
   * Установлено WebSocket соединение, запрашиваем версию бэкенда
   */
  handleWsBackSocketOpen(): void {
    this.isBackWsUpdated = false;
    this.wsStore.restApiShopAbout();
  }
  /**
   * Получение текущих звонков
   */
  handleWsCallActual(): void {
    this.wsStoreCollie.getActualCall();
  }

  /**
   * Получена информация о версии бэк-приложения
   * @param msg
   */
  handleWsShopAboutResponse(msg: IncomeMessage): void {
    if (msg.data && msg.data.buildTime && msg.data.env) {
      this.setBackVersion(this.getBack(), new Date(), msg.data);
      this.isBackWsUpdated = true;
    }
  }

  /**
   * WebSocket соединение закрыто, снимаем флаг, чтобы начались
   * периодические запросы версии.
   */
  handleWsBackSocketClosed(): void {
    this.isBackWsUpdated = false;
  }

  handleWsFrontSocketOpen(): void {
    this.isFrontWsUpdated = false;
    this.wsStore.restApiShopAbout();
  }

  handleWsF1AboutResponse(msg: IncomeMessage): void {
    if (msg.data && msg.data.imageTime && msg.data.branch) {
      this.setFrontVersion(this.getFront(), new Date(), msg.data);
      this.isFrontWsUpdated = true;
    }
  }

  handleWsFrontSocketClosed(): void {
    this.isFrontWsUpdated = false;
  }

  /**
   * запрос версии бэкенда
   */
  executeBack(item: BackVersionStoreItem): void {
    const rqTime = new Date();
    if (typeof window === 'undefined') {
      return;
    }
    if (this.isBackWsUpdated) {
      return;
    }
    this.apiStore
      .apiClientSystem()
      .about()
      .then((response: AxiosResponse<AboutResponse>): void => {
        if (item.requestTime && item.requestTime.getTime() > rqTime.getTime()) {
          console.log('ignore irrelevant backend version response');
          return;
        }
        this.setBackVersion(item, rqTime, response.data);
      })
      .catch(e => {
        item.setLastError(e);
        console.warn('fail to get backend version', e);
      });
  }

  /**
   * Запрос версии фронта
   * @param item
   */
  executeFront(item: FrontVersionStoreItem): void {
    const rqTime = new Date();
    if (typeof window === 'undefined') {
      return;
    }
    const sentryTrx = startTransaction({ name: 'VersionStore.executeFront' });
    let sentrySpan = sentryTrx.startChild({ op: 'VersionStore.front.buildinfo.request' });
    this.apiStore
      .axios()
      .get('/buildinfo.json', {
        baseURL: '/',
        headers: { 'Cache-Control': 'no-cache' },
      })
      .then((response: AxiosResponse<string | Record<string, unknown>>): void => {
        sentrySpan.finish();
        sentrySpan = sentryTrx.startChild({ op: 'VersionStore.front.buildinfo.response' });

        if (item.requestTime && item.requestTime.getTime() > rqTime.getTime()) {
          console.log('ignore irrelevant frontend version response');
          return;
        }
        let data;
        try {
          if (typeof response.data === 'string') {
            data = JSON.parse(response.data);
          } else if (typeof response.data === 'object') {
            data = response.data;
          } else if (typeof data !== 'object') {
            console.warn('frontend get buildinfo.json invalid format', response.data);
            return;
          }
          this.setFrontVersion(item, rqTime, data);
        } catch (e) {
          console.warn('frontend get buildinfo.json fail', e);
        }
      })
      .catch(e => {
        item.setLastError(e);
        console.warn('fail to get frontend version', e);
      })
      .finally(() => {
        sentrySpan.finish();
        sentryTrx.finish();
      });
  }

  setBackVersion(item: BackVersionStoreItem, requestTime: Date, data: AboutResponse): void {
    runInAction(() => {
      item.lastError = undefined;
      item.requestTime = requestTime;
      if (data.buildTime && !item.isLoaded) {
        item.isLoaded = true;
        item.firstBuildTime = data.buildTime;
      }
      item.buildTime = data.buildTime;
      item.env = data.env;
      item.lifetime = data.lifetime;
    });
  }

  setFrontVersion(item: FrontVersionStoreItem, requestTime: Date, data: FrontBuildInfoStructure): void {
    if (!data.imageTime) {
      return;
    }
    runInAction(() => {
      item.lastError = undefined;
      item.requestTime = requestTime;
      if (data.imageTime && !item.isLoaded) {
        item.firstImageTime = data.imageTime;
        item.isLoaded = true;
      }
      item.actualImageTime = data.imageTime;
      item.updateRequired = item.firstImageTime && item.actualImageTime && item.firstImageTime !== item.actualImageTime;
    });
  }

  getBack(): BackVersionStoreItem {
    if (!this.back) {
      this.back = new BackVersionStoreItem();
    }
    return this.back;
  }

  /**
   * Возвращает объект, в который загружается версия фронта
   */
  getFront(): FrontVersionStoreItem {
    if (!this.front) {
      this.front = new FrontVersionStoreItem();
    }
    return this.front;
  }

  // Возвращает версию, указанную в теге meta html-документа
  getMetaBuildInfo(): FrontBuildInfoStructure {
    if (typeof document === 'undefined') {
      return {};
    }
    if (!document.querySelector) {
      console.log('empty document.querySelector');
      return {};
    }
    const metaEl = document.querySelector('meta[name^=mx\\:build-info]');
    if (!metaEl) {
      console.log('empty document meta build-info');
      return {};
    }
    if (!(metaEl instanceof HTMLMetaElement)) {
      console.log('empty document meta content build-info');
      return {};
    }
    const buildInfo = metaEl.content.split(';');
    return {
      imageTime: buildInfo[0],
      branch: buildInfo[1],
      commit: buildInfo[2],
    };
  }

  // Перезагрузить страницу (и обновить фронтенд), если это необходимо
  refreshIfRequired(): boolean {
    this.refreshPermissionTime = new Date();
    if (this.getFront().updateRequired) {
      this.refreshFront();
      return true;
    }
    return false;
  }

  // Перезагрузка текущей страницы = обновление фронта
  refreshFront(): void {
    if (typeof window === 'object' && typeof location === 'object') {
      window.location.href = location.href;
    } else {
      console.warn('front update impossible');
    }
  }
}
