import React, { FC, useMemo, JSX } from 'react';
import { observer } from 'mobx-react-lite';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import InfiniteScroll from 'react-infinite-scroll-component';
import { EmployeePaymentStore } from '../../../store/EmployeePaymentStore';
import { formatNumberSpacing, formatPriceCur } from '@mx-ui/helpers';
import { PaymentTabPanelClientItem } from './PaymentTabPanelClientItem';
interface PaymentTabPanelClientsProps {
  paymentStatStore: EmployeePaymentStore;
}

export const PaymentTabPanelClientsSkeleton: FC<{ isForClient?: boolean }> = ({ isForClient }): JSX.Element => {
  const arr = ['1'];
  if (!isForClient) {
    arr.push('2');
  }
  return (
    <>
      {arr.map(item => (
        <Paper key={item} sx={{ mb: 2 }}>
          <Grid container flexDirection="column" p={2}>
            <Grid item maxWidth="30%">
              <Skeleton />
            </Grid>
            <Grid item maxWidth="40%">
              <Skeleton />
            </Grid>
            <Grid item width="100%">
              <Skeleton />
            </Grid>
            <Grid item width="100%">
              <Skeleton />
            </Grid>
            <Grid item width="100%">
              <Skeleton />
            </Grid>
            <Grid item width="100%">
              <Skeleton />
            </Grid>
          </Grid>
        </Paper>
      ))}
    </>
  );
};

export const formatValueCurPayment = (value = 0): string => {
  if (!value || value === 0) return '-';
  const val = String(Math.round(parseFloat('' + value) * 100) / 100 || 0);
  return val.length <= 9 ? formatPriceCur(val) : formatNumberSpacing(val) + ` ₽`;
};
export const PaymentTabPanelClients: FC<PaymentTabPanelClientsProps> = observer(({ paymentStatStore }): JSX.Element => {
  const customersKeysList = useMemo(
    () => Object.keys(paymentStatStore.customersPaymentStatList),
    [paymentStatStore.customersPaymentStatList, Object.keys(paymentStatStore.customersPaymentStatList).length]
  );
  return (
    <>
      {(paymentStatStore.isLoading || !paymentStatStore.isLoaded) && !customersKeysList.length ? (
        <PaymentTabPanelClientsSkeleton />
      ) : !paymentStatStore.isLoading && paymentStatStore.isLoaded && !customersKeysList.length ? (
        <Grid>
          <Typography variant="body1" color="textPrimary">
            Нет данных
          </Typography>
        </Grid>
      ) : (
        <InfiniteScroll
          dataLength={customersKeysList.length}
          next={paymentStatStore.lazyLoadExpectations}
          hasMore={paymentStatStore.canLoadMore}
          loader={paymentStatStore.canLoadMore ? <Skeleton /> : <div />}
          scrollableTarget="scroll"
          scrollThreshold={0.7}
        >
          {customersKeysList.map(customer => {
            const billsKeys = Object.keys(paymentStatStore.customersPaymentStatList[customer].bills);
            return (
              <PaymentTabPanelClientItem
                key={customer}
                billsKeys={billsKeys}
                customersPaymentStatList={paymentStatStore.customersPaymentStatList}
                customerCode={customer}
              />
            );
          })}
        </InfiniteScroll>
      )}
    </>
  );
});
