import { AxiosCallContext, getCallContext } from '../../utils/axiosInit';
import { DealsItemDistributorsResponse, EmployeeSet } from '../../api/marketx';
import { AppDeal } from '../../slices/AppDeal';
import { ApiStore } from '../Global/ApiStore';
import { RootStore } from '../StoreManager';
import { AxiosResponse } from 'axios';
import { makeAutoObservable } from 'mobx';

/**
 * Список реализаторов для сделки.
 */
export class DealDistributorListStore {
  apiStore: ApiStore;

  dealCode?: string;
  items = new Array<EmployeeSet>();

  activeDistributorCode?: string;

  isLoaded = false;
  isLoading = false;
  loadedEpoch = 0;

  constructor(rootStore: RootStore) {
    this.apiStore = rootStore.getApiStore();
    makeAutoObservable(this);
  }

  setResult(ctx: AxiosCallContext, res: DealsItemDistributorsResponse): void {
    this.items.splice(0, this.items.length, ...res.distributors);
    this.isLoaded = true;
    this.isLoading = false;
    this.loadedEpoch++;
  }

  loadForDeal(deal: AppDeal): void {
    this.dealCode = deal.code;
    this.activeDistributorCode = deal?.distributor?.employeeCode;
    if (deal?.distributor?.employeeCode) {
      const inList = this.items && this.items.find(it => it.code === deal?.distributor?.employeeCode);
      if (!inList) {
        this.items.push(<EmployeeSet>{
          code: deal?.distributor?.employeeCode,
          title: (deal?.distributor?.surname || '') + ' ' + (deal?.distributor?.name || ''),
        });
      }
    }
    if (!this.dealCode) {
      return;
    }
    if (!this.isLoading) {
      this.isLoading = true;
      this.apiStore
        .apiClientDeal()
        .dealsItemDistributors(this.dealCode)
        .then((res: AxiosResponse<DealsItemDistributorsResponse>) => {
          this.setResult(getCallContext(res), res.data);
        });
    }
  }
}
