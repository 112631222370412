import { makeAutoObservable, runInAction } from 'mobx';
import { ClientsItemMarginalityPlotResponse } from '../api/marketx';
import { AxiosResponse } from 'axios';
import { Plot, PlotPoint } from '../lib/plots';
import { AxiosCallContext, getCallContext } from '../utils/axiosInit';
import { ApiStore } from './Global/ApiStore';
import { RootStore } from './StoreManager';

// Данные для графика маржинальности клиента
export class CustomerMarginalityChartsDataStore {
  svc: CustomerMarginalityChartsDataService;
  apiStore: ApiStore;

  dealCode: string;
  customerCode: string;
  plot: Plot = null;
  requestTime: Date;
  isLoaded = false;
  loadedEpoch = 0;

  // Время, в течение которого не будут повторяться запросы к бэкенду для одного контрагента (мс)
  cacheTimeoutMs = 10000;

  constructor(rootStore: RootStore) {
    this.svc = new CustomerMarginalityChartsDataService();
    this.apiStore = rootStore.getApiStore();
    makeAutoObservable(this, {
      svc: false,
      apiStore: false,
    });
  }

  // Установка значений для графика
  setMarginalityData(ctx: AxiosCallContext, res: ClientsItemMarginalityPlotResponse): void {
    const marginalityPlot = res.marginalityPlot;

    if (!marginalityPlot.series[0]?.data) {
      console.log('marginality plot has no data', this.customerCode, this.dealCode);
      return;
    }

    runInAction(() => {
      let midVal = 0;
      let i = 0;
      marginalityPlot.series[0]?.data?.forEach((point: number[], j: number, arr): void => {
        this.plot.points[j] = {
          x: point[0],
          y: point[1],
        } as PlotPoint;
        if (j >= arr.length - 20 && point[1] !== 0 && j !== arr.length - 1) {
          midVal += point[1];
          i += 1;
        }
      });
      if (i) {
        this.plot.middleVal = midVal / i;
      }
      this.plot.points.splice(marginalityPlot.series[0].data.length);
      if (marginalityPlot.xaxis) {
        this.plot.xaxis.minTicks = marginalityPlot.xaxis.minTicks ?? undefined;
        this.plot.xaxis.maxTicks = marginalityPlot.xaxis.maxTicks ?? undefined;
      } else {
        this.plot.xaxis = {};
      }
      this.plot.loaded = true;
      this.isLoaded = true;
      this.loadedEpoch++;
    });
  }

  // Возвращает данные для графика или заглушку и запускает процесс обновления
  loadPlot(customerCode: string, dealCode: string): void {
    const now = new Date();
    if (this.customerCode === customerCode && this.dealCode === dealCode) {
      const isCacheValid = this.requestTime && now.getTime() - this.requestTime.getTime() < this.cacheTimeoutMs;
      if (isCacheValid) {
        return;
      }
    }
    this.dealCode = dealCode;
    this.customerCode = customerCode;
    this.requestTime = now;
    this.isLoaded = false;

    if (!this.plot) {
      const key = this.dealCode + '-' + this.customerCode;
      const fakePoints = [];
      const fakeEnd = new Date().getTime();
      for (let i = 90; i > 0; i--) {
        fakePoints.push({ x: fakeEnd - 24 * 60 * 60 * 1000 * i, y: 0 });
      }
      this.plot = <Plot>{
        name: key,
        points: fakePoints,
        xaxis: {},
        yaxis: {},
      };
    }

    this.svc.requestBackendData(this);
  }
}

class CustomerMarginalityChartsDataService {
  // Запускает запрос данных графика у бэкенда
  requestBackendData(store: CustomerMarginalityChartsDataStore): void {
    if (!store.customerCode || !store.dealCode) {
      console.warn('Отсутствует customerCode и dealCode', store.customerCode, store.dealCode);
      store.isLoaded = true;
      return;
    }
    store.apiStore
      .apiClientCustomer()
      .clientsItemMarginalityPlot(store.customerCode, store.dealCode)
      .then((res: AxiosResponse<ClientsItemMarginalityPlotResponse>): void => {
        store.setMarginalityData(getCallContext(res), res.data);
      })
      .catch(r => {
        store.isLoaded = true;
        console.warn(r);
      });
  }
}
