import { grey } from '@mui/material/colors';
import { Theme } from '@mui/material/styles';

export const generateColorChartjs = (value: number, theme: Theme): any => {
  // #f44336 :-100
  // #d32f2f :-200
  // #66bb6a :100
  // #388e3c :200
  const backgroundColor =
    theme.palette.mode === 'dark'
      ? {
          // можно дописать добавить разных зеленых и красных но мне лень
          '-1': ['#d32f2f', '#f44336'],
          '-0': ['#f44336', grey[600]],
          '0': ['#66bb6a', grey[600]],
          '1': ['#388e3c', '#66bb6a'],
        }
      : {
          '-1': ['#d32f2f', '#f44336'],
          '-0': ['#f44336', '#e5e5e5'],
          '0': ['#66bb6a', '#e5e5e5'],
          '1': ['#388e3c', '#66bb6a'],
        };
  if (typeof value !== 'number') {
    return { data: [0, 0], backgroundColor: backgroundColor[0] };
  }
  const qntHundreds = Math.trunc(value / 100);
  let firstValue = 0;
  let secondValue = 0;

  if (qntHundreds === 0) {
    firstValue = value;
    secondValue = 100 - value;
  }

  if (qntHundreds > 0) {
    firstValue = value - qntHundreds * 100;
    secondValue = 100 - firstValue;
  }
  if (qntHundreds < 0 || String(qntHundreds) == '-0') {
    firstValue = Math.abs(value - qntHundreds * 100);
    secondValue = 100 - firstValue;
  }
  let index: string = qntHundreds as unknown as string;
  if (qntHundreds > 1) {
    index = '1';
  }
  if (qntHundreds < -1) {
    index = '-1';
  }
  if (qntHundreds === 0 && 1 / qntHundreds === -Infinity) {
    index = '-0';
  }
  const data = { data: [firstValue, secondValue], backgroundColor: backgroundColor[index] };
  return data;
};
