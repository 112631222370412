import { MsgType } from '../store/Global/WebSocketStore';
import { MsgType as MsgTypeCollie } from '../store/Global/WebSocketCollieStore';
import { useRootStore } from '../store/MobxStoreProvider';
import { DependencyList, useEffect, useRef } from 'react';
import { IncomeMessage } from '../store/Global/EventManager';

// Подписка на сообщения из веб-сокета.
// handler обязательно должен оставаться тем же самым, т.е. обязательно должен использоваться
// один из приведенных в примерах способов (или useCallback или статичная функция).
// Если на каждый вызов создается новая функция, то работать не будет.
// НЕ рабочий вариант: useWebSocketHandler(msg => handleWs(msg)) // Не работает
// Примеры корректного использования
// 1) для любой функции:
//    const wsCallback = useCallback(msg => deals.handleWs(msg), [deals.handleWs]);
//    useWebSocketHandler(wsCallback);
// 2) для стора с фиксом конструктора:
//    // в конструкторе стора
//    this.handleWs = this.handleWs.bind(this);
//    // в компоненте
//    useWebSocketHandler(deals.handleWs);
export const useWebSocketHandler = (handler: (msg: IncomeMessage) => void, deps?: DependencyList): void => {
  const wsStore = useRootStore().getWebSocket();
  const ref = useRef(null);
  useEffect(() => {
    if (ref.current) {
      wsStore.unsubscribe(ref.current);
      ref.current = null;
    }
    ref.current = wsStore.subscribe(MsgType.WILDCARD, handler);
    return () => {
      if (ref.current) {
        wsStore.unsubscribe(ref.current);
        ref.current = null;
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};
export const useWebSocketCollieHandler = (handler: (msg: IncomeMessage) => void, deps?: DependencyList): void => {
  const wsStore = useRootStore().getWebCollieSocket();
  const ref = useRef(null);
  useEffect(() => {
    if (ref.current) {
      wsStore.unsubscribe(ref.current);
      ref.current = null;
    }
    ref.current = wsStore.subscribe(MsgTypeCollie.WILDCARD, handler);
    return () => {
      if (ref.current) {
        wsStore.unsubscribe(ref.current);
        ref.current = null;
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};
