import React from 'react';

const ErrorPage = ({ height = '100px', width = '100px' }): JSX.Element => {
  return (
    <svg width={width} height={height} version="1.0" viewBox="0 0 752 752" fill={'currentColor'} xmlns="http://www.w3.org/2000/svg">
      <g transform="translate(-90 845) scale(.125 -.125)">
        <path d="m1923 6055c-34-8-95-31-134-51-130-68-209-161-268-312l-26-67v-1865-1865l22-70c56-179 208-323 389-370 51-13 286-15 1859-15 1986 0 1852-4 1979 63 89 47 186 148 229 238 19 41 40 98 46 127 8 37 11 596 11 1890 0 1617-2 1845-15 1896-52 199-219 359-420 401-100 21-3575 21-3672 0zm3748-136c85-41 160-110 202-187 43-79 57-159 57-329v-153h-2165-2165v168c0 124 4 183 16 223 42 143 150 254 292 300 55 17 134 18 1872 16l1815-2 76-36zm259-2368c0-1015-4-1608-10-1642-33-177-173-321-348-358-70-15-3534-16-3613-1-136 25-256 120-318 249l-36 76-3 1633-2 1632h2165 2165v-1589z" />
        <path d="m2107 5642c-22-24-21-55 1-75 16-15 47-17 225-17 194 0 208 1 227 20 11 11 20 27 20 35s-9 24-20 35c-19 19-33 20-228 20-190 0-210-2-225-18z" />
        <path d="m2756 5644c-23-22-20-71 3-84 12-6 103-10 225-10 193 0 207 1 226 20 11 11 20 27 20 35s-9 24-20 35c-19 19-33 20-229 20-177 0-212-2-225-16z" />
        <path d="m3408 5648c-28-22-31-55-9-77 20-20 33-21 229-21 228 0 245 4 245 55s-17 55-243 55c-150 0-211-3-222-12z" />
        <path d="m3206 4797c-35-26-23-65 37-125l56-56-60-63c-63-66-70-90-31-120 27-22 42-15 105 46l62 60 61-60c34-32 69-59 78-59s25 9 36 20 20 27 20 36-27 44-59 78l-60 61 60 62c32 33 59 68 59 77 0 21-35 56-56 56-9 0-44-27-78-59l-61-60-62 60c-64 62-77 68-107 46z" />
        <path d="m3977 4792c-28-31-20-54 40-114l57-57-57-60c-63-66-72-99-35-125 12-9 26-16 31-16s38 28 73 62l64 61 64-61c35-34 68-62 73-62s19 7 31 16c36 25 27 55-33 114-30 30-55 59-55 65s25 35 55 65c34 34 55 63 55 77 0 22-33 53-57 53-7 0-41-27-76-60l-64-59-58 59c-63 64-82 71-108 42z" />
        <path d="m3585 4279c-99-14-233-57-318-101-101-53-197-139-197-177 0-52 51-69 86-30 32 36 139 108 200 135 310 137 766 87 979-108 57-52 82-59 110-28 26 29 13 61-46 114-176 158-512 238-814 195z" />
        <path d="m2700 3651c-58-19-110-53-150-98-41-46-641-1073-702-1200-24-51-29-74-29-138s5-87 29-138c34-73 87-129 157-165l50-27h740 740l50 25c96 47 163 138 186 253 22 110 15 125-358 773-228 396-352 600-380 629-63 64-121 88-223 91-47 2-96 0-110-5zm214-125c25-14 53-38 64-54 33-47 663-1143 679-1180 38-92-8-219-101-277l-49-30h-711c-793 0-736-5-810 71-62 64-81 160-48 241 32 79 666 1164 700 1198 70 69 188 82 276 31z" />
        <path d="m2609 3084c-11-13-9-61 13-295 16-171 31-285 38-294 10-12 39-15 136-15 117 0 124 1 137 23 20 31 65 551 51 575-10 15-34 17-186 20-151 2-177 0-189-14zm270-121c0-21-8-114-17-208l-17-170-47-3c-43-3-48-1-52 20-5 22-36 361-36 386 0 9 24 12 85 12h85l-1-37z" />
        <path d="m2722 2410c-69-43-94-139-52-208 28-45 97-76 151-67 80 14 141 108 119 185-26 92-138 139-218 90zm102-96c19-18 21-45 4-62-39-39-100 17-63 58 22 24 38 25 59 4z" />
        <path d="m4651 3650c-404-85-694-413-726-822-13-164 34-362 123-513 56-96 209-254 294-303 91-54 207-100 297-118 114-22 314-15 421 16 302 87 537 327 622 635 17 65 22 107 22 225s-5 160-22 225c-45 164-126 298-253 421-108 103-228 173-371 215-82 24-328 35-407 19zm359-116c141-35 313-141 401-247 130-155 190-319 190-517 0-249-102-462-295-616-166-133-391-195-597-165-178 26-340 111-465 244-145 154-214 327-214 537s69 383 214 537c117 125 266 207 431 239 86 17 245 11 335-12z" />
        <path d="m4356 3218c-9-12-16-27-16-32 0-6 91-101 202-213l202-203-202-203c-111-112-202-207-202-213 0-23 33-54 57-54 21 0 68 41 225 197l198 198 198-198c130-130 204-197 218-197 25 0 54 29 54 55 0 11-82 102-197 217l-197 198 197 198c127 128 197 205 197 219 0 24-30 53-56 53-11 0-97-79-216-197l-198-198-198 198c-211 210-232 224-266 175z" />
      </g>
    </svg>
  );
};

export default ErrorPage;
