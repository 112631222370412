import { FC, JSX, useCallback, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import TextField from '@mui/material/TextField';
import { Theme } from '@mui/material/styles';
import Autocomplete from '@mui/material/Autocomplete';
import { SxProps } from '@mui/system';

export interface ICustomSelect {
  value?: string;
  text?: string;
  disabled?: boolean;
}

interface CustomSelectNewProps {
  size?: 'small' | 'medium';
  sx?: SxProps<Theme>;
  label?: string;
  options?: ICustomSelect[];
  isViewOnly?: boolean;
  defaultValue?: string;
  onChange?: (value: string | null) => void;
}

export const CustomSelectNew: FC<CustomSelectNewProps> = observer(
  ({ size = 'small', sx, label = '', options, isViewOnly = false, defaultValue, onChange }): JSX.Element => {
    const [value, setValue] = useState<ICustomSelect>(null);
    useEffect(() => {
      if (defaultValue && options.length) {
        setValue(options.filter(item => item.value === defaultValue)[0]);
      }
    }, [defaultValue, options]);
    const autocompleteOptions = useMemo(() => options || [], [options]);

    const handleOptionChange = useCallback(
      (e, status: ICustomSelect): void => {
        setValue(status);
        onChange(status.value);
      },
      [onChange]
    );

    return (
      <Autocomplete
        disabled={isViewOnly}
        value={value || null}
        fullWidth
        size={size}
        options={autocompleteOptions}
        getOptionLabel={i => i.text}
        getOptionDisabled={i => !!i.disabled}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        onChange={handleOptionChange}
        disableClearable
        sx={sx}
        renderInput={params => (
          <TextField
            sx={{
              '.MuiFormLabel-root': {
                fontSize: '16px',
              },
              '.MuiOutlinedInput-notchedOutline': {
                fontSize: '16px',
              },
              '.MuiInputBase-root': {
                padding: '4px 14px',
                fontSize: '14px',
              },
              '.MuiFormLabel-root[data-shrink="false"]': {
                fontSize: '14px',
              },
            }}
            {...params}
            label={label}
          />
        )}
      />
    );
  }
);
