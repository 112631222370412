import * as React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { closeSnackbar } from 'notistack';

import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';

export const action = ({ handleClick }) => {
  return (snackbarId: number): any => {
    return (
      <React.Fragment>
        <Button sx={{ mr: 1, color: 'white' }} color="inherit" variant="outlined" onClick={handleClick}>
          Включить
        </Button>
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={() => {
            closeSnackbar(snackbarId);
          }}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </React.Fragment>
    );
  };
};
