import { useRouter } from 'next/router';
import { usePathname } from 'next/navigation';
import { useMemo } from 'react';

type DocTypes = 'agreements' | 'contracts' | 'deals' | 'bills' | 'shipments' | 'returns' | 'payments' | 'debts';

// asQueryPath - то, что передается в URL для корректного возврата
// returnPath - ссылка на возврат с параметрами

export default function useDocumentsPath(docType: DocTypes): { returnPath: string; asQueryPath: string } {
  const router = useRouter();
  const pathname = usePathname();
  const { asPath, route } = router;
  const asQueryPath = route === `/app/${docType}` ? 'asPath=' + asPath : '';
  const returnPath = useMemo(() => {
    if (asPath.includes('asPath')) {
      router.replace(pathname);
      return asPath.slice(asPath.indexOf('asPath') + 7);
    }
    return `/app/${docType}`;
  }, []);
  return { returnPath, asQueryPath };
}
