import { entityType, TopBarEntityStore } from '../TopBarStore';
import { makeAutoObservable } from 'mobx';
import { AgreementItemStore } from './AgreementItemStore';

export class AgreementTopBarStore implements TopBarEntityStore {
  agreementStore: AgreementItemStore = null;

  constructor(agrStore: AgreementItemStore) {
    this.agreementStore = agrStore;
    makeAutoObservable(this);
  }

  customerTitle(): string {
    return this.agreementStore?.agreement?.customer?.shortTitle || this.agreementStore?.agreement?.customer?.title || '';
  }

  entityCode(): string {
    return this.agreementStore.agreementCode;
  }

  titleForCatalog(): string {
    return 'Выберите товарные позиции для добавления в соглашение';
  }

  titleForClient(): string {
    return 'Выберите клиента в соглашение';
  }

  typeName(): entityType {
    return 'agreement';
  }
}
