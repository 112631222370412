import { alpha } from '@mui/material/styles';
import { Theme } from '@mui/material';

export const deviationLevelsD = {
  extraLow: {
    name: 'Сильно ниже',
    code: -2,
  },
  negative: {
    name: 'Ниже',
    code: -1,
  },
  positive: {
    name: 'Выше',
    code: 1,
  },
  extraHigh: {
    name: 'Сильно выше',
    code: 2,
  },
};

export const agreementDeviationLevelColors = (level: number, theme: Theme): { color: string; bgColor: string; status: string } => {
  let color: string;
  let statusKey = '';

  if (!level || !theme) {
    return {
      color: 'transparent',
      bgColor: 'transparent',
      status: statusKey,
    };
  }

  if (level <= -2) {
    color = theme.palette.error.main;
    statusKey = 'extraLow';
  } else if (level > -2 && level <= 0) {
    color = theme.palette.warning.main;
    statusKey = 'negative';
  } else if (level < 2 && level > 0) {
    color = '#72b482';
    statusKey = 'positive';
  } else if (level >= 2) {
    color = theme.palette.success.main;
    statusKey = 'extraHigh';
  }
  return {
    color,
    bgColor: alpha(color, 0.08),
    status: deviationLevelsD[statusKey].name,
  };
};
