// import 'scripts/wdyr';
// import 'src/__mocks__';

import absoluteUrl from 'next-absolute-url';

import { SnackbarProvider } from 'notistack';

import Head from 'next/head';
import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import * as gtag from 'src/lib/gtag';

import { authConfig, buildInfoConfig, webConfig } from 'src/config';

import { AppProps, AppContext } from 'next/app';

import 'react-perfect-scrollbar/dist/css/styles.css';
import '../styles/global.css';

import axiosInstance from '../utils/axiosInit';
import AuthorizationGuard from '../auth/AuthorizationGuard';
import { initSentry } from '../utils/sentry';
import { CacheProvider } from '@emotion/react';
import { enableStaticRendering } from 'mobx-react';
import ym, { YMInitializer } from 'react-yandex-metrika';
import { AxiosError, AxiosResponse } from 'axios';
import { RootStore } from '../store/StoreManager';
import { MobxStoreProvider } from '../store/MobxStoreProvider';
import WsNotifies from 'src/components/DashboardLayout/NavBar/WsNotifies';
import { ApplicationErrorEvent } from 'src/store/ErrorStore';
import { DevPanel } from '../store/Global/DevPanel';
import createEmotionCache from 'src/styles/createEmotionCache';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { observer } from 'mobx-react-lite';
import { NextComponentType } from 'next';
import { Env, EnvContextProvider } from 'src/components/DashboardLayout/EnvContext';
import CssBaseline from '@mui/material/CssBaseline';
import { VersionStore } from 'src/store/Global/VersionStore';
import { ThemeProviderMui } from 'src/components/DashboardLayout/ThemeProviderMui';
import { getCurrentHub } from '@sentry/nextjs';
import * as Sentry from '@sentry/react';
import { ErrorBoundaryFallback } from 'src/components/ErrorBoundaryFallback';
import { SessionProvider } from 'next-auth/react';
enableStaticRendering(typeof window === 'undefined');

initSentry();

interface InitialProps {
  emotionCache?: any;
  origin?: string;
  err?: any;
}
const clientSideEmotionCache = createEmotionCache();
// ? https:github.com/mui/material-ui/issues/35287#issuecomment-1337250566
declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace React {
    interface DOMAttributes<T> {
      onResize?: React.ReactEventHandler<T> | undefined;
      onResizeCapture?: React.ReactEventHandler<T> | undefined;
      nonce?: string | undefined;
    }
  }
}
declare module '@mui/material/Tooltip' {
  interface TooltipComponentsPropsOverrides {
    bgwhite?: true;
  }
}
//* переопределение (добавление) типов и добавление новыйх пропсов для mui
declare module '@mui/material/styles' {
  interface Palette {
    successMx: Palette['primary'];
    greyMx: Palette['primary'];
  }
  interface TypeText {
    light: string;
  }

  interface PaletteOptions {
    successMx?: PaletteOptions['primary'];
    greyMx?: PaletteOptions['primary'];
  }
}

// Update the Button's color options to include a successMx option
declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    successMx: true;
    greyMx: true;
  }
}
const App: NextComponentType<AppContext, InitialProps, AppProps> = observer((props: AppProps & InitialProps): JSX.Element => {
  const { Component, pageProps, err, origin, emotionCache = clientSideEmotionCache } = props;
  const router = useRouter();

  const [rootStore] = useState<RootStore>(new RootStore({}, null));
  const apiStore = rootStore.getApiStore();
  apiStore.initializeEnv('prod', webConfig.apiUrl?.replace('/api', ''));
  rootStore.getError().setErrorHandler((e: ApplicationErrorEvent): void => {
    console.error('early error:', e);
  });
  const [versionStore] = useState<VersionStore>(rootStore.getVersion());
  const versionBackStore = versionStore.getBack();
  const [env, setEnv] = useState<Env>((buildInfoConfig.env || versionBackStore?.env || 'prod') as Env);
  useEffect(() => {
    if (versionBackStore?.env && !buildInfoConfig.env) {
      setEnv(versionBackStore?.env as Env);
    }
  }, [versionBackStore, versionBackStore?.env]);
  rootStore.getLaunch(launch => {
    launch.setOrigin(origin);
    // подключение обработки ошибок
    axiosInstance.interceptors.response.use(
      (response: AxiosResponse) => {
        // console.log('backend response SSS', response);
        return response;
      },
      (error: AxiosError) => {
        // console.log('axios response error', error);
        const isFrontVersionRequest = error.config && error.config.url === '/buildinfo.json';
        const isBackVersionRequest = error.config && error.config.url === '/api/about';
        if (!isFrontVersionRequest && !isBackVersionRequest) {
          rootStore.getError().pushAxiosError(error);
        }
        return Promise.reject(error);
      }
    );
  });

  useEffect(() => {
    const handleRouteChangeStart = (url: URL): void => {
      const transaction = getCurrentHub()?.getScope()?.getTransaction();
      if (transaction) {
        transaction.setTag('routeStart', String(url));
      }
    };

    const handleRouteChange = (url: URL): void => {
      if (typeof window !== 'undefined' && process.env.NODE_ENV === 'production') {
        gtag.pageview(url);
        ym('hit', url);
      }
      const transaction = getCurrentHub()?.getScope()?.getTransaction();
      if (transaction) {
        transaction.setTag('routeChange', String(url));
      }
    };

    const handleRouteChangeError = (err, url): void => {
      const transaction = getCurrentHub()?.getScope()?.getTransaction();
      if (transaction) {
        transaction.setTag('routeErrorReason', err);
        transaction.setTag('routeErrorUrl', String(url));
      }
    };

    router.events.on('routeChangeStart', handleRouteChangeStart);
    router.events.on('routeChangeComplete', handleRouteChange);
    router.events.on('routeChangeError', handleRouteChangeError);

    return () => {
      router.events.off('routeChangeStart', handleRouteChangeStart);
      router.events.off('routeChangeComplete', handleRouteChange);
      router.events.off('routeChangeError', handleRouteChangeError);
    };
  }, [router.events]);

  useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }, []);

  // Use sentry workaround for https://github.com/vercel/next.js/issues/8592
  return (
    <ThemeProviderMui>
      <SessionProvider session={pageProps.session} basePath="/api/f1/auth" refetchInterval={authConfig.sessionRefetchInterval}>
        <CacheProvider value={emotionCache}>
          <Head>
            <title>MarketX</title>
            <meta property="og:title" content="MarketX" key="title" />
            <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width" />
            <meta name="mx:build-info" content={buildInfoConfig.imageTime + ';' + buildInfoConfig.branch + ';' + buildInfoConfig.commit} />
          </Head>
          {/* https://github.com/narkq/react-yandex-metrika/issues/47 */}
          {process.env.NODE_ENV === 'production' && (
            <YMInitializer
              accounts={[parseInt(process.env.NEXT_PUBLIC_YM_TRACKING_ID as string)]}
              options={{
                clickmap: true,
                trackLinks: true,
                accurateTrackBounce: true,
                webvisor: true,
                trackHash: true,
              }}
              version="2"
            />
          )}
          <SnackbarProvider dense maxSnack={3}>
            <EnvContextProvider value={env}>
              <CssBaseline enableColorScheme />

              <MobxStoreProvider root={rootStore}>
                <Sentry.ErrorBoundary
                  fallback={({ error, componentStack, resetError }) => (
                    <ErrorBoundaryFallback error={error} componentStack={componentStack} resetError={resetError} />
                  )}
                >
                  <>
                    <DevPanel />
                    <AuthorizationGuard err={err}>
                      <WsNotifies />
                      <Component {...pageProps} err={err} />
                    </AuthorizationGuard>
                  </>
                </Sentry.ErrorBoundary>
              </MobxStoreProvider>
            </EnvContextProvider>
          </SnackbarProvider>
        </CacheProvider>
      </SessionProvider>
    </ThemeProviderMui>
  );
});

App.getInitialProps = async (context: AppContext) => {
  const { origin } = absoluteUrl(context?.ctx?.req);
  let pageProps = {};

  if (context.Component.getInitialProps) {
    pageProps = await context.Component.getInitialProps(context.ctx);
  }

  return {
    pageProps,
    origin,
  };
};

export default App;
