import * as Sentry from '@sentry/nextjs';
import { replayIntegration } from '@sentry/react';
import { RewriteFrames } from '@sentry/integrations';
import { BrowserTracing } from '@sentry/tracing';

// https://docs.sentry.io/platforms/javascript/guides/nextjs/manual-setup/

export const initSentry = (): void => {
  if (!process.env.NEXT_PUBLIC_SENTRY_DSN) {
    console.warn('sentry disabled by empty NEXT_PUBLIC_SENTRY_DSN');
    return;
  }
  const integrations = [];
  if (process.env.NEXT_IS_SERVER === 'true' && process.env.NEXT_PUBLIC_SENTRY_SERVER_ROOT_DIR) {
    // For Node.js, rewrite Error.stack to use relative paths, so that source
    // maps starting with ~/_next map to files in Error.stack with path
    // app:///_next
    integrations.push(
      new RewriteFrames({
        iteratee: frame => {
          frame.filename = frame.filename.replace(process.env.NEXT_PUBLIC_SENTRY_SERVER_ROOT_DIR, 'app:///');
          frame.filename = frame.filename.replace('.next', '_next');
          return frame;
        },
      })
    );
  }

  integrations.push(new BrowserTracing());
  integrations.push(
    replayIntegration({
      // настройки конфиденциальности (личными данными, "данные" на сайте, не назовешь, так что можно и их просматривать):
      maskAllText: false, // блокирует текст
      blockAllMedia: false, // блокирует картинки и т.д.
    })
  );

  Sentry.init({
    enabled: true, // process.env.NODE_ENV === 'production',
    integrations,
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    release: 'shop-react@' + process.env.NEXT_PUBLIC_VERSION,
    // To set a uniform sample rate
    tracesSampleRate: 0.01,
    // @ts-ignore (Sentry nextjs пока не имеет таких свойств, но уже по факту используется sentry react)
    eplaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    ignoreErrors: ['Non-Error exception captured', 'Request aborted', 'Non-Error promise rejection captured'], // ? https://github.com/getsentry/sentry-javascript/issues/2546 https://marketx.sentry.io/issues/4502313751/?project=5565572&query=is%3Aunresolved&referrer=issue-stream&stream_index=1
  });
};
