import React, { FC, JSX, Suspense, useCallback, useMemo } from 'react';
import Grid from '@mui/material/Grid';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import ConstructionIcon from '@mui/icons-material/Construction';
import BarChartIcon from '@mui/icons-material/BarChart';
import StoreOutlinedIcon from '@mui/icons-material/StoreOutlined';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import Skeleton from '@mui/material/Skeleton';
import CustomCard from 'src/views/deals/components/CustomCard';
import LockPersonRoundedIcon from '@mui/icons-material/LockPersonRounded';
import { AppDealPosition } from '../../../slices/AppDeal';
import { observer } from 'mobx-react-lite';
import { DealItemStore } from '../../../store/DealItemStore';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import { FileUpload } from 'src/components/UploadFile/types';
import { CustomIconTabs } from '../../../components/Deals/CustomIconTabs/CustomIconTabs';
import { ProductPacksStore } from '../../../store/ProductPacksStore';
import { useRootStore } from '../../../store/MobxStoreProvider';
import FindReplaceOutlinedIcon from '@mui/icons-material/FindReplaceOutlined';
import { DealAlternativeProducts } from './DealAlternativeProducts';
import { DealPositionAdditionalTabServices } from './DealPositionAdditionalTabServices';
import { DealPositionAdditionalTabProductInfo } from './DealPositionAdditionalTabProductInfo';
import { DealPositionAdditionalCrarts } from './DealPositionAdditionalCrarts';
import { DealPositionAdditionalTabComment } from './DealPositionAdditionalTabComment';
import { DealPositionAdditionalTabPacks } from './DealPositionAdditionalTabPacks';
import { DealPositionAdditionalTabPrices } from './DealPositionAdditionalTabPrices';
import { DealPositionAdditionalTabReserves } from './DealPositionAdditionalTabReserves';
import { DealPositionAdditionalTabFiles } from './DealPositionAdditionalTabFiles';
import { DealPositionValidateTab } from './DealPositionValidateTab';

export const LoadingSkeleton = (): JSX.Element => {
  return (
    <CustomCard
      fullWidth
      md={6}
      xs={12}
      style={{
        position: 'relative',
        paddingTop: '0px',
        marginTop: '0px',
        paddingLeft: '0px',
        borderRadius: '0px',
      }}
    >
      <Grid container spacing={0}>
        <Grid
          item
          xs={12}
          sx={{
            padding: '4px',
            paddingLeft: '14px',
            paddingRight: '14px',
            height: '32px',
          }}
        >
          <Skeleton variant="text" width={'50%'} />
        </Grid>

        <Grid item xs={12} sx={{ paddingTop: '4px', paddingLeft: '8px' }}>
          <Grid container spacing={0}>
            <Grid item>
              <Skeleton variant="rectangular" width={38} height={38} /> <br />
              <Skeleton variant="rectangular" width={38} height={38} />
            </Grid>

            <Grid item xs={10} sx={{ paddingLeft: '8px' }}>
              <Grid container spacing={0}>
                <Grid item xs={12} sx={{ paddingBottom: '12px' }}>
                  <Grid container spacing={0}>
                    <Grid item xs={6}>
                      <Grid container spacing={0}>
                        <Grid item xs={12}>
                          <Skeleton variant="text" width={'50%'} />
                          <Skeleton variant="text" width={'50%'} />
                        </Grid>
                        <Grid item />
                        <Grid item />
                      </Grid>
                    </Grid>
                    <Grid item xs={6}>
                      <Skeleton variant="rectangular" width={'100%'} />
                      <Skeleton variant="rectangular" width={'100%'} height={40} />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Grid container spacing={0}>
                    <Grid item xs={6}>
                      <Grid container spacing={0}>
                        <Grid item xs={12}>
                          <Skeleton variant="text" width={'50%'} />
                        </Grid>
                        <Grid item />
                        <Grid item>
                          <Skeleton variant="text" width={'50%'} />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={6}>
                      <Skeleton variant="rectangular" width={'100%'} />
                      <Skeleton variant="text" width={'50%'} />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Grid container spacing={0}>
                    <Grid item xs={6}>
                      <Grid container spacing={0}>
                        <Grid item xs={12}>
                          <Skeleton variant="text" width={'50%'} />
                        </Grid>
                        <Grid item />
                        <Grid item />
                      </Grid>
                    </Grid>
                    <Grid item xs={6} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </CustomCard>
  );
};

interface DealPositionAdditionalProps {
  dealStore: DealItemStore;
  dealPosition: AppDealPosition;
  isViewOnly?: boolean;
  onPositionAddDialogClick?: (categoryCode?: string) => void;
  setSelectedAdditionalTab: React.Dispatch<React.SetStateAction<number>>;
  selectedAdditionalTab: number;
}

export const DealPositionAdditional: FC<DealPositionAdditionalProps> = observer(
  ({ dealStore, dealPosition, isViewOnly, onPositionAddDialogClick, selectedAdditionalTab, setSelectedAdditionalTab }): JSX.Element => {
    const rootStore = useRootStore();
    const readOnlyList = dealPosition.isInReplacing || dealStore.isViewOnly();
    const packsStore = useMemo(() => {
      return new ProductPacksStore(rootStore);
    }, [rootStore]);
    const hasPrices = dealPosition.product?.prices?.length > 0;
    const isPs = !!dealPosition?.usePsUnitCost || !isViewOnly;
    const documents = dealStore.deal?.documents
      ?.filter(i => i.positionCode === dealPosition.code)
      .map(i => ({ ...i, name: i.filename, size: i.filesize ?? 0, type: i.mime })) as FileUpload[];
    const isCommentBadgeVisible = dealPosition?.comments?.some(item => item.comment?.length > 0);
    let isCurrentBillInReserve =
      (dealPosition.reservationSummary?.inHardReserveQuantity || 0) +
        (dealPosition.reservationSummary?.inSoftReserveQuantity || 0) +
        (dealPosition.reservationSummary?.inWaybillQuantity || 0) >
      0;
    if (
      dealPosition.reservationSummary?.inHardReserveQuantity &&
      dealPosition.reservationSummary?.shippedQuantity &&
      dealPosition.reservationSummary?.inHardReserveQuantity === dealPosition.reservationSummary?.shippedQuantity
    ) {
      isCurrentBillInReserve = false;
    }
    const isPsBadgeVisible = dealPosition?.usePsUnitCost && dealPosition?.psUnitCost > 0;
    const headerTabsValue = useMemo(() => {
      return [
        { label: ``, title: 'Альтернативы', tabPanelIndex: 0, disabled: false, icon: <FindReplaceOutlinedIcon />, badgeOn: false },
        { label: ``, title: 'Услуги', tabPanelIndex: 1, disabled: false, icon: <ConstructionIcon />, badgeOn: false },
        { label: ``, title: 'Детали', tabPanelIndex: 2, disabled: false, icon: <InfoOutlinedIcon />, badgeOn: false },
        { label: ``, title: 'Аналитика', tabPanelIndex: 3, disabled: false, icon: <BarChartIcon />, badgeOn: false },
        {
          label: ``,
          title: 'Комментарий',
          tabPanelIndex: 4,
          disabled: false,
          icon: <ChatOutlinedIcon />,
          badgeOn: isCommentBadgeVisible,
          isHide: isViewOnly && !dealPosition.comments?.length,
        },
        { label: ``, title: 'Остатки и пачки', tabPanelIndex: 5, disabled: false, icon: <StoreOutlinedIcon />, badgeOn: false },
        { label: ``, title: 'Цены', tabPanelIndex: 6, disabled: false, icon: '₽/т', badgeOn: false, isHide: !hasPrices },
        {
          label: ``,
          title: 'Резервы',
          tabPanelIndex: 7,
          disabled: false,
          icon: <LockPersonRoundedIcon />,
          badgeOn: isCurrentBillInReserve,
        },
        {
          label: ``,
          title: 'Файлы',
          tabPanelIndex: 8,
          disabled: false,
          icon: <AttachFileIcon />,
          badgeOn: !!documents.length,
          isHide: isViewOnly && !documents?.length,
        },
        {
          label: ``,
          title: 'Акция',
          tabPanelIndex: 9,
          disabled: false,
          icon: <MonetizationOnIcon />,
          badgeOn: isPsBadgeVisible,
          isHide: !isPs,
        },
      ];
    }, [isPsBadgeVisible, documents.length, isCommentBadgeVisible, isCurrentBillInReserve]);
    const handleTabChange = useCallback(
      (event: React.ChangeEvent<unknown>, newValue: number) => {
        setSelectedAdditionalTab(newValue);
      },
      [setSelectedAdditionalTab]
    );
    const onDocsSave = useCallback(
      (files: FileUpload[], docType: string) => {
        dealStore.uploadDocuments(files, dealPosition.code, docType);
      },
      [dealStore, dealPosition.code]
    );
    const handleDeleteFile = useCallback(
      (code: string): void => {
        dealStore.deleteDocuments([code]);
      },
      [dealStore]
    );
    if (!dealStore.isLoaded) {
      return <LoadingSkeleton />;
    }

    return !dealPosition.isService ? (
      <Grid container flexDirection="column">
        <Grid item sx={{ width: '100%', overflow: 'hidden' }}>
          <Grid
            pl={2}
            className="withCustomScroll"
            sx={{ display: 'grid', gridTemplateColumns: 'minmax(310px, 1fr)', MozPaddingBottom: '15px' }}
          >
            <CustomIconTabs
              handleToggleButtonChange={handleTabChange}
              toggleButtonValue={selectedAdditionalTab}
              tabsValue={headerTabsValue}
            />
          </Grid>
        </Grid>

        <Grid item pl={1} height="207px" sx={{ overflow: 'hidden' }}>
          <Suspense fallback={<p>Is Loading...</p>}>
            {selectedAdditionalTab === 0 && (
              <DealAlternativeProducts dealStore={dealStore} position={dealPosition} readOnlyList={readOnlyList} />
            )}
            {selectedAdditionalTab === 1 && (
              <DealPositionAdditionalTabServices dealStore={dealStore} position={dealPosition} isViewOnly={isViewOnly} />
            )}
            {selectedAdditionalTab === 2 && (
              <DealPositionAdditionalTabProductInfo
                dealStore={dealStore}
                dealPosition={dealPosition}
                onPositionAddDialogClick={onPositionAddDialogClick}
                isViewOnly={isViewOnly}
                setSelectedAdditionalTab={setSelectedAdditionalTab}
              />
            )}
            {selectedAdditionalTab === 3 && <DealPositionAdditionalCrarts position={dealPosition} />}
            {selectedAdditionalTab === 4 && (
              <DealPositionAdditionalTabComment dealStore={dealStore} dealPosition={dealPosition} isViewOnly={isViewOnly} />
            )}
            {selectedAdditionalTab === 5 && (
              <DealPositionAdditionalTabPacks dealStore={dealStore} dealPosition={dealPosition} packsStore={packsStore} />
            )}
            {selectedAdditionalTab === 6 && <DealPositionAdditionalTabPrices dealPosition={dealPosition} />}
            {selectedAdditionalTab === 7 && (
              <DealPositionAdditionalTabReserves
                dealPosition={dealPosition}
                branchOfficeCode={dealStore.deal.branchOfficeCode}
                documentNumber={dealStore.deal.billDocumentNumber}
              />
            )}
            {selectedAdditionalTab === 8 && (
              <DealPositionAdditionalTabFiles
                onSave={onDocsSave}
                isViewOnly={isViewOnly}
                uploadedFiles={documents}
                onDelete={handleDeleteFile}
              />
            )}
            {selectedAdditionalTab === 9 && isPs && (
              <DealPositionValidateTab dealStore={dealStore} isViewOnly={isViewOnly} dealPosition={dealPosition} />
            )}
          </Suspense>
        </Grid>
      </Grid>
    ) : (
      <div></div>
    );
  }
);
