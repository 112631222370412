import { observer } from 'mobx-react-lite';
import React, { FC, useMemo } from 'react';
import { AppDealPositionService } from '../../../slices/AppDeal';
import { formatPrice } from '@mx-ui/helpers';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ReplyAllRoundedIcon from '@mui/icons-material/ReplyAllRounded';
import { DealItemStore, initialServicesWithCopyBtnValue } from '../../../store/DealItemStore';
import Grid from '@mui/material/Grid';
interface DealPositionServicesItemProps {
  service: AppDealPositionService;
  isViewOnly?: boolean;
  dealStore: DealItemStore;
}
export const DealPositionServicesItem: FC<DealPositionServicesItemProps> = observer(({ service, isViewOnly, dealStore }): JSX.Element => {
  let serviceCost = '';

  if (service.cost) {
    serviceCost = ` ${formatPrice(service?.totalCost)}\u00A0₽ (${formatPrice(service?.cost)}\u00A0₽/т)`;
  }

  const isCopyToAllBtnShown = useMemo(() => {
    return (
      Object.keys(initialServicesWithCopyBtnValue).includes(service.code) &&
      service.enabled !== initialServicesWithCopyBtnValue[service.code]
    );
  }, [service]);

  const handleCopyServiceToAllPositions = (serviceCode: string): void => {
    dealStore.copyServiceToAllPositions(serviceCode, service.enabled).catch(r => {
      console.warn('copyServiceToAllPositions', r);
    });
  };
  if (service.code === 'manual_position_services_cost' || service.canSetManualTotalCost) {
    return null;
  }
  return (
    <Grid container justifyContent={'space-between'} key={service.code}>
      <Grid item>
        <FormControlLabel
          key={service.code}
          control={<Checkbox disabled={isViewOnly} checked={service.enabled} name={service.code} />}
          label={
            <>
              <Typography component={'span'} variant={'body1'} style={{ cursor: isViewOnly ? 'not-allowed' : 'pointer' }}>
                {service.title}
                {service.enabled && (
                  <Typography
                    component={'span'}
                    sx={{
                      cursor: isViewOnly ? 'not-allowed' : 'pointer',
                    }}
                    variant="body2"
                  >
                    {serviceCost}
                  </Typography>
                )}
              </Typography>
              {isCopyToAllBtnShown && (
                <IconButton
                  aria-label="copy to all positions"
                  size={'small'}
                  sx={{ marginLeft: '8px' }}
                  title="Копировать услугу на все позиции"
                  onClick={() => handleCopyServiceToAllPositions(service.code)}
                  disabled={dealStore.isSaving || dealStore.isViewOnly()}
                >
                  <ReplyAllRoundedIcon sx={{ transform: 'scale(-1, 1)' }} />
                </IconButton>
              )}
            </>
          }
        />
      </Grid>
    </Grid>
  );
});
