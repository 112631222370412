import React, { FC, ReactElement, useCallback, useMemo, useRef, useState } from 'react';
import Collapse from '@mui/material/Collapse';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Fade from '@mui/material/Fade';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import { formatNumber02, formatNumber3, formatNumberByUnit, formatPriceCur } from '@mx-ui/helpers';
import { observer } from 'mobx-react-lite';
import { ItemPack } from './ProductPackTable';
import { AppDealPosition } from 'src/slices/AppDeal';
import { DealItemStore, UpdateQueueItem } from 'src/store/DealItemStore';
import { ProductItemPack } from 'src/api/marketx';
import { ProductsNormalType } from '../../../slices/AppCatalog';
import LightTooltip from '../../../components/ToolTip/LightTooltip';
import ProductIndicators, { BatchT } from '../../../components/Product/ProductIndicators';
import DownloadIcon from '@mui/icons-material/Download';
import { red } from '@mui/material/colors';
import { calculateBatchIndicators } from '../../../store/BatchesListStore';
import { useTheme } from '@mui/material/styles';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionDetails from '@mui/material/AccordionDetails';
import Divider from '@mui/material/Divider';
import Accordion from '@mui/material/Accordion';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Link as MxLink } from '@mx-ui/ui';

interface ProductPackTableDetailsProps {
  dealStore: DealItemStore;
  dealPosition: AppDealPosition;
  product: ProductsNormalType;
  batch: ItemPack;
  handleModalOpen: (code: string, shipmentCode: string, number: string) => void;
}

const ProductPackItem: FC<{
  packItem: ProductItemPack;
  onClick: (pack: ProductItemPack) => Promise<UpdateQueueItem>;
  isShowButton: boolean;
  itemsLength?: number;
}> = observer(({ packItem, isShowButton, onClick, itemsLength = 0 }) => {
  const matches = useMediaQuery('(min-width:840px)');
  const [added, setAdded] = useState<boolean>(false);
  const handleClick = (pack: ProductItemPack): void => {
    setAdded(true);
    onClick(pack).catch(() => {
      setAdded(false);
    });
  };
  return (
    <Grid
      container
      sx={{
        ...(itemsLength > 1 ? { borderBottom: '1px solid rgba(224,224,224,1)' } : {}),
        paddingTop: 0.5,
        paddingBottom: 0.5,
      }}
      key={packItem.number}
    >
      <Grid item xs={matches ? 10 : 12} width={matches ? '70%' : '100%'}>
        <Grid container justifyContent={'space-between'}>
          <Grid item xs={matches ? 5 : 6}>
            <Typography variant={'body2'}>Код</Typography>
          </Grid>
          <Grid item xs={matches ? 7 : 6} textAlign={matches ? 'left' : 'right'}>
            <Typography variant={'body2'}>{packItem.number || '-'}</Typography>
          </Grid>
        </Grid>
        {!matches && <Divider />}
        <Grid container justifyContent={'space-between'} py={matches ? 0 : 0.5}>
          <Grid item xs={matches ? 5 : 6}>
            <Typography variant={'body2'}>Кассета</Typography>
          </Grid>
          <Grid item xs={matches ? 7 : 6} textAlign={matches ? 'left' : 'right'}>
            <Typography variant={'body2'}>{packItem.cassette || '-'}</Typography>
          </Grid>
        </Grid>
        {!matches && <Divider />}
        <Grid container justifyContent={'space-between'} py={matches ? 0 : 0.5}>
          <Grid item xs={matches ? 5 : 6}>
            <Typography variant={'body2'}>Сертификат</Typography>
          </Grid>
          <Grid item xs={matches ? 7 : 6} textAlign={matches ? 'left' : 'right'}>
            <Typography variant={'body2'}>{packItem.numberInCertificate || '-'}</Typography>
          </Grid>
        </Grid>
        {!matches && <Divider />}
        <Grid container justifyContent={'space-between'} py={matches ? 0 : 0.5}>
          <Grid item xs={matches ? 5 : 6}>
            <Typography variant={'body2'}>Упаковка</Typography>
          </Grid>
          <Grid item xs={matches ? 7 : 6} textAlign={matches ? 'left' : 'right'}>
            <Typography variant={'body2'}>{packItem.boxingNumber || '-'}</Typography>
          </Grid>
        </Grid>
        {!matches && <Divider />}
        <Grid container justifyContent={'space-between'} py={matches ? 0 : 0.5}>
          <Grid item xs={matches ? 5 : 6}>
            <Typography variant={'body2'}>Параметр</Typography>
          </Grid>
          <Grid item xs={matches ? 7 : 6} textAlign={matches ? 'left' : 'right'}>
            <Typography variant={'body2'}>{packItem.parameter || '-'}</Typography>
          </Grid>
        </Grid>
        {!matches && <Divider />}
        <Grid container justifyContent={'space-between'} py={matches ? 0 : 0.5}>
          <Grid item xs={matches ? 5 : 6}>
            <Typography variant={'body2'}>Вес</Typography>
          </Grid>
          <Grid item xs={matches ? 7 : 6} textAlign={matches ? 'left' : 'right'}>
            <Typography variant={'body2'}>{formatNumberByUnit(packItem.weight, 'TNE', 'т')}</Typography>
          </Grid>
        </Grid>
        {!matches && <Divider />}
        <Grid container justifyContent={'space-between'} py={matches ? 0 : 0.5}>
          <Grid item xs={matches ? 5 : 6}>
            <Typography variant={'body2'}>Остаток</Typography>
          </Grid>
          <Grid item xs={matches ? 7 : 6} textAlign={matches ? 'left' : 'right'}>
            <Typography variant={'body2'}>{formatNumberByUnit(packItem.inStock, packItem.unitCode, packItem.unitShortName)}</Typography>
          </Grid>
        </Grid>
        {!matches && isShowButton && <Divider />}
      </Grid>
      <Grid item container alignItems={'flex-end'} xs={matches ? 2 : 12}>
        {isShowButton && (
          <Box ml={'auto'} pt={1}>
            {!added ? (
              <Button
                variant="outlined"
                size="small"
                title="Добавить запись с номером пачки в комментарий позиции"
                sx={{ borderColor: '#bdbdbd' }}
                onClick={() => handleClick(packItem)}
              >
                В&nbsp;комментарий
              </Button>
            ) : (
              <Typography style={{ fontSize: '12px', textAlign: 'center' }}>Добавлено</Typography>
            )}
          </Box>
        )}
      </Grid>
    </Grid>
  );
});
const ProductPackItems: FC<{
  items: ProductItemPack[];
  onClick: (pack: ProductItemPack) => Promise<UpdateQueueItem>;
  isShowButton: boolean;
}> = observer(({ items = [], onClick, isShowButton }) => {
  return (
    <Box>
      {items.map(packItem => (
        <ProductPackItem
          key={packItem.number}
          packItem={packItem}
          itemsLength={items.length}
          onClick={onClick}
          isShowButton={isShowButton}
        />
      ))}
    </Box>
  );
});
const valueTODHByDate = (days: number): { text: string; color: string } => {
  switch (true) {
    case days >= 30 && days < 60:
      return {
        text: 'Т30',
        color: red[100],
      };
    case days >= 60 && days < 90:
      return {
        text: 'Т60',
        color: red[200],
      };
    case days >= 90 && days < 180:
      return {
        text: 'Т90',
        color: red[300],
      };
    case days >= 180 && days < 240:
      return {
        text: 'Т180',
        color: red[400],
      };
    case days >= 240 && days < 360:
      return {
        text: 'Т240',
        color: red[500],
      };
    case days >= 360 && days < 600:
      return {
        text: 'Т360',
        color: red[600],
      };
    case days >= 600 && days < 1000:
      return {
        text: 'Т600',
        color: red[700],
      };
    case days >= 1000:
      return {
        text: 'Т1000',
        color: red[800],
      };

    default:
      return {
        text: undefined,
        color: undefined,
      };
  }
};

const batchColor = (batch: ItemPack): string => {
  if (batch.batchDoNotSell) {
    return red[100];
  }
  if (batch.isSafeCustody) {
    return '#FFFBD8';
  }
  return undefined;
};

/* eslint complexity: ["error", 51]*/
export const ProductPackTableDetails: FC<ProductPackTableDetailsProps> = observer(
  ({ dealStore, dealPosition, product, batch, handleModalOpen }): ReactElement => {
    const matches = useMediaQuery('(min-width:840px)');
    const [open, setOpen] = useState<boolean>(false);
    const tableCellRef = useRef();

    const handleToggleRowClick = useCallback((): void => {
      setOpen(open => !open);
    }, [setOpen]);

    const handleAddToCommentClick = useCallback(
      (pack: ProductItemPack): Promise<UpdateQueueItem> => {
        return dealStore.addPackToPositionComment(dealPosition, pack);
      },
      [dealStore, dealPosition]
    );
    const theme = useTheme();
    const { color: todhColor } = valueTODHByDate(batch.todhDays);

    let indicatorsCosts = calculateBatchIndicators(null, batch);
    if (dealPosition) {
      indicatorsCosts = calculateBatchIndicators(dealPosition, batch);
    } else if (product) {
      indicatorsCosts = calculateBatchIndicators(product, batch);
    }

    const hasAltUnits = product.multiplicityUnitCode !== 'TNE' && product.multiplicity > 0;
    const altFactor = hasAltUnits ? 1 / product.multiplicity : 1;

    const indicatorsBatch = useMemo<BatchT>(() => {
      return {
        todhDiscountPct: batch?.todhDiscountPct,
        todhDiscountIlliquidBlocked: batch?.todhDiscountIlliquidBlocked,
        illiquidDefectCode: batch?.illiquidDefectCode,
        illiquidDefectTitle: batch?.illiquidDefectTitle,
        illiquidRustCode: batch?.illiquidRustCode,
        illiquidRustTitle: batch?.illiquidRustTitle,
        illiquidDiscountPct: batch?.illiquidDiscountPct,
        isSafeCustody: batch?.isSafeCustody,
        selfPurchase: batch?.batchSelfPurchase,
      };
    }, [batch]);
    return (
      <>
        <TableRow
          sx={{
            '& > td': { borderBottom: 'unset' },
            cursor: 'pointer',
            backgroundColor: batchColor(batch),
          }}
          onClick={handleToggleRowClick}
        >
          <TableCell align="left">{batch.warehouseName || '–'}</TableCell>
          <TableCell align="left">
            {batch.batchNumber ? (
              <MxLink href={`/app/batches/${batch.batchNumber}`} color="inherit" title="Перейти к партии">
                {batch.batchNumber}
              </MxLink>
            ) : (
              '-'
            )}
          </TableCell>
          <TableCell align="right">{batch.wagonNumber || '-'}</TableCell>
          <TableCell align="right">
            {batch.weight > 0 && hasAltUnits ? (
              <LightTooltip
                arrow
                placement="bottom"
                TransitionComponent={Fade}
                title={<Box p={2}>{formatNumberByUnit(batch.weight * altFactor, 'TNE', 'т')}</Box>}
              >
                <span>{`${formatNumber3(batch.weight)} т`}</span>
              </LightTooltip>
            ) : (
              `${formatNumber3(batch.weight)} т`
            )}
          </TableCell>
          <TableCell align="right">
            {batch.inStockTne ? (
              hasAltUnits ? (
                <LightTooltip
                  arrow
                  placement="bottom"
                  TransitionComponent={Fade}
                  title={<Box p={2}>{formatNumberByUnit(batch.inStockTne * altFactor, batch.unitCode, batch.unitShortName)}</Box>}
                >
                  <span>{formatNumberByUnit(batch.inStockTne, batch.unitCode, batch.unitShortName)}</span>
                </LightTooltip>
              ) : (
                formatNumberByUnit(batch.inStockTne, batch.unitCode, batch.unitShortName)
              )
            ) : (
              '-'
            )}
          </TableCell>
          <TableCell align="center">
            {batch.todhDiscountPct > 0 ? (
              <Box
                display={'inline'}
                px={0.5}
                py={0.2}
                bgcolor={todhColor}
                color={todhColor ? theme.palette.getContrastText(todhColor) : undefined}
                title={[
                  'Скидка ' + formatNumber02(batch.todhDiscountPct) + '%',
                  'МРЦ-' + formatNumber02(batch.todhDiscountPct) + '% = ' + formatPriceCur(indicatorsCosts.todhMrcUnitCost),
                ].join('\n')}
              >
                {batch.todhText || '–'}
              </Box>
            ) : (
              <Box
                display={'inline'}
                px={0.5}
                py={0.2}
                bgcolor={todhColor}
                color={todhColor ? theme.palette.getContrastText(todhColor) : undefined}
              >
                {batch.todhText || '–'}
              </Box>
            )}
          </TableCell>
          <TableCell align="right">{batch.receiptDate}</TableCell>
          <TableCell align="right" ref={tableCellRef}>
            <ProductIndicators batch={indicatorsBatch} tableCellRef={tableCellRef} costs={indicatorsCosts} />
          </TableCell>
          <TableCell align="right">
            {Boolean(batch.certificateName) ? (
              <Grid item ml={0.5} title={'Скачать сертификат'}>
                <IconButton
                  size="small"
                  sx={{ p: '4px' }}
                  onClick={e => {
                    e.stopPropagation();
                    handleModalOpen(batch.batchCode, '', batch.batchCode);
                  }}
                >
                  <DownloadIcon fontSize="small" />
                </IconButton>
              </Grid>
            ) : (
              '-'
            )}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ padding: 0 }} colSpan={9}>
            <Collapse in={open} unmountOnExit timeout="auto">
              <Box pb={1} pl={2}>
                <Grid
                  container
                  sx={{
                    paddingTop: 0.5,
                    paddingBottom: 0.5,
                  }}
                >
                  <Grid item xs={matches ? 10 : 12} width={matches ? '70%' : '100%'}>
                    {batch.purchaseUnitCost > 0 && (
                      <Grid container justifyContent={'space-between'}>
                        <Grid item xs={matches ? 5 : 6}>
                          <Typography variant={'body2'}>Цена закупки</Typography>
                        </Grid>
                        <Grid item xs={matches ? 7 : 6} textAlign={matches ? 'left' : 'right'}>
                          <Typography variant={'body2'}>{formatPriceCur(batch.purchaseUnitCost)}</Typography>
                        </Grid>
                      </Grid>
                    )}{' '}
                    {batch.tpcUnitCost > 0 && (
                      <Grid container justifyContent={'space-between'}>
                        <Grid item xs={matches ? 5 : 6}>
                          <Typography variant={'body2'}>ТЗР</Typography>
                        </Grid>
                        <Grid item xs={matches ? 7 : 6} textAlign={matches ? 'left' : 'right'}>
                          <Typography variant={'body2'}>
                            {formatPriceCur(batch.tpcUnitCost, '₽/' + (batch?.unitShortName ?? 'т'))}
                          </Typography>
                        </Grid>
                      </Grid>
                    )}{' '}
                    {batch.lwhUnitCost > 0 && (
                      <Grid container justifyContent={'space-between'}>
                        <Grid item xs={matches ? 5 : 6}>
                          <Typography variant={'body2'}>Лог-скл. затраты</Typography>
                        </Grid>
                        <Grid item xs={matches ? 7 : 6} textAlign={matches ? 'left' : 'right'}>
                          <Typography variant={'body2'}>
                            {formatPriceCur(batch.lwhUnitCost, '₽/' + (batch?.unitShortName ?? 'т'))}
                          </Typography>
                        </Grid>
                      </Grid>
                    )}{' '}
                    {batch.financialUnitCost > 0 && (
                      <Grid container justifyContent={'space-between'}>
                        <Grid item xs={matches ? 5 : 6}>
                          <Typography variant={'body2'}>Фин. затраты</Typography>
                        </Grid>
                        <Grid item xs={matches ? 7 : 6} textAlign={matches ? 'left' : 'right'}>
                          <Typography variant={'body2'}>
                            {formatPriceCur(batch.financialUnitCost, '₽/' + (batch?.unitShortName ?? 'т'))}
                          </Typography>
                        </Grid>
                      </Grid>
                    )}
                    {(batch.financialUnitCost > 0 || batch.lwhUnitCost > 0 || batch.tpcUnitCost > 0 || batch.purchaseUnitCost > 0) && (
                      <Divider />
                    )}
                  </Grid>
                </Grid>
                <ProductPackItems
                  items={batch.items}
                  onClick={handleAddToCommentClick}
                  isShowButton={dealStore && !dealStore.isViewOnly() && !batch.batchDoNotSell}
                />
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    );
  }
);

export const ProductPackTableDetailsMobile: FC<ProductPackTableDetailsProps> = observer(
  ({ dealStore, dealPosition, product, batch, handleModalOpen }): ReactElement => {
    const tableCellRef = useRef();

    const handleAddToCommentClick = useCallback(
      (pack: ProductItemPack): Promise<UpdateQueueItem> => {
        return dealStore.addPackToPositionComment(dealPosition, pack);
      },
      [dealStore, dealPosition]
    );
    const theme = useTheme();
    const { color: todhColor } = valueTODHByDate(batch.todhDays);

    let indicatorsCosts = calculateBatchIndicators(null, batch);
    if (dealPosition) {
      indicatorsCosts = calculateBatchIndicators(dealPosition, batch);
    } else if (product) {
      indicatorsCosts = calculateBatchIndicators(product, batch);
    }

    const hasAltUnits = product.multiplicityUnitCode !== 'TNE' && product.multiplicity > 0;
    const altFactor = hasAltUnits ? 1 / product.multiplicity : 1;

    const indicatorsBatch = useMemo<BatchT>(() => {
      return {
        todhDiscountPct: batch?.todhDiscountPct,
        todhDiscountIlliquidBlocked: batch?.todhDiscountIlliquidBlocked,
        illiquidDefectCode: batch?.illiquidDefectCode,
        illiquidDefectTitle: batch?.illiquidDefectTitle,
        illiquidRustCode: batch?.illiquidRustCode,
        illiquidRustTitle: batch?.illiquidRustTitle,
        illiquidDiscountPct: batch?.illiquidDiscountPct,
        isSafeCustody: batch?.isSafeCustody,
        selfPurchase: batch?.batchSelfPurchase,
      };
    }, [batch]);
    return (
      <>
        <Accordion
          sx={{
            margin: '0px !important',
            boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%)',
            ':before': {
              display: 'none',
            },
          }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ borderTop: '1px solid #DBDBDB' }}>
            <Grid container alignItems={'center'} spacing={1}>
              <Grid item>
                <Typography variant={'body2'}>{batch.warehouseName || '–'}</Typography>
              </Grid>
              <Grid item>
                <Typography variant={'body2'}>
                  {batch.batchNumber ? (
                    <MxLink href={`/app/batches/${batch.batchNumber}`} color="inherit" title="Перейти к партии">
                      {batch.batchNumber}
                    </MxLink>
                  ) : (
                    '-'
                  )}
                </Typography>
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container>
              <Grid item xs={12}>
                <Divider />
                <Grid py={0.5} container alignItems={'center'} spacing={1}>
                  <Grid item xs={6}>
                    <Typography variant={'body2'}>Склад</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant={'body2'} textAlign={'right'}>
                      {batch.warehouseName || '–'}
                    </Typography>
                  </Grid>
                </Grid>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <Grid py={0.5} container alignItems={'center'} spacing={1}>
                  <Grid item xs={6}>
                    <Typography variant={'body2'}>Партия</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant={'body2'} textAlign={'right'}>
                      {batch.batchNumber ? (
                        <MxLink href={`/app/batches/${batch.batchNumber}`} color="inherit" title="Перейти к партии">
                          {batch.batchNumber}
                        </MxLink>
                      ) : (
                        '-'
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <Grid py={0.5} container alignItems={'center'} spacing={1}>
                  <Grid item xs={6}>
                    <Typography variant={'body2'}>Вагон</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant={'body2'} textAlign={'right'}>
                      {batch.wagonNumber || '-'}
                    </Typography>
                  </Grid>
                </Grid>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <Grid py={0.5} container alignItems={'center'} spacing={1}>
                  <Grid item xs={6}>
                    <Typography variant={'body2'}>Вес, т</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant={'body2'} textAlign={'right'}>
                      {batch.weight > 0 && hasAltUnits ? (
                        <LightTooltip
                          arrow
                          placement="bottom"
                          TransitionComponent={Fade}
                          title={<Box p={2}>{formatNumberByUnit(batch.weight * altFactor, 'TNE', 'т')}</Box>}
                        >
                          <span>{`${formatNumber3(batch.weight)} т`}</span>
                        </LightTooltip>
                      ) : (
                        `${formatNumber3(batch.weight)} т`
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <Grid py={0.5} container alignItems={'center'} spacing={1}>
                  <Grid item xs={6}>
                    <Typography variant={'body2'}>Остаток</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant={'body2'} textAlign={'right'}>
                      {batch.inStockTne ? (
                        hasAltUnits ? (
                          <LightTooltip
                            arrow
                            placement="bottom"
                            TransitionComponent={Fade}
                            title={<Box p={2}>{formatNumberByUnit(batch.inStockTne * altFactor, batch.unitCode, batch.unitShortName)}</Box>}
                          >
                            <span>{formatNumberByUnit(batch.inStockTne, batch.unitCode, batch.unitShortName)}</span>
                          </LightTooltip>
                        ) : (
                          formatNumberByUnit(batch.inStockTne, batch.unitCode, batch.unitShortName)
                        )
                      ) : (
                        '-'
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <Grid py={0.5} container alignItems={'center'} spacing={1}>
                  <Grid item xs={6}>
                    <Typography variant={'body2'}>ТОДХ</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant={'body2'} textAlign={'right'}>
                      {batch.todhDiscountPct > 0 ? (
                        <Box
                          display={'inline'}
                          px={0.5}
                          py={0.2}
                          bgcolor={todhColor}
                          color={todhColor ? theme.palette.getContrastText(todhColor) : undefined}
                          title={[
                            'Скидка ' + formatNumber02(batch.todhDiscountPct) + '%',
                            'МРЦ-' + formatNumber02(batch.todhDiscountPct) + '% = ' + formatPriceCur(indicatorsCosts.todhMrcUnitCost),
                          ].join('\n')}
                        >
                          {batch.todhText || '–'}
                        </Box>
                      ) : (
                        <Box
                          display={'inline'}
                          px={0.5}
                          py={0.2}
                          bgcolor={todhColor}
                          color={todhColor ? theme.palette.getContrastText(todhColor) : undefined}
                        >
                          {batch.todhText || '–'}
                        </Box>
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <Grid py={0.5} container alignItems={'center'} spacing={1}>
                  <Grid item xs={6}>
                    <Typography variant={'body2'}>Дата поступления</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant={'body2'} textAlign={'right'}>
                      {batch.receiptDate}
                    </Typography>
                  </Grid>
                </Grid>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <Grid py={0.5} container alignItems={'center'} spacing={1} ref={tableCellRef}>
                  <Grid item xs={6}>
                    <Typography variant={'body2'}>Признак</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant={'body2'} textAlign={'right'}>
                      <ProductIndicators batch={indicatorsBatch} tableCellRef={tableCellRef} costs={indicatorsCosts} />
                    </Typography>
                  </Grid>
                </Grid>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <Grid py={0.5} container alignItems={'center'} spacing={1}>
                  <Grid item xs={6}>
                    <Typography variant={'body2'}>Сертификат</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant={'body2'} textAlign={'right'}>
                      {Boolean(batch.certificateName) ? (
                        <Grid item ml={0.5} title={'Скачать сертификат'}>
                          <IconButton
                            size="small"
                            sx={{ p: '4px' }}
                            onClick={e => {
                              e.stopPropagation();
                              handleModalOpen(batch.batchCode, '', batch.batchCode);
                            }}
                          >
                            <DownloadIcon fontSize="small" />
                          </IconButton>
                        </Grid>
                      ) : (
                        '-'
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                <Divider />
              </Grid>

              <Grid item xs={12}>
                <Grid py={0.5} container alignItems={'center'} spacing={1} ref={tableCellRef}>
                  <Grid item xs={6}>
                    <Typography variant={'body2'}>Цена закупки</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant={'body2'} textAlign={'right'}>
                      {formatPriceCur(batch.purchaseUnitCost)}
                    </Typography>
                  </Grid>
                </Grid>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <Grid py={0.5} container alignItems={'center'} spacing={1} ref={tableCellRef}>
                  <Grid item xs={6}>
                    <Typography variant={'body2'}>ТЗР</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant={'body2'} textAlign={'right'}>
                      {formatPriceCur(batch.tpcUnitCost, '₽/' + (batch?.unitShortName ?? 'т'))}
                    </Typography>
                  </Grid>
                </Grid>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <Grid py={0.5} container alignItems={'center'} spacing={1} ref={tableCellRef}>
                  <Grid item xs={6}>
                    <Typography variant={'body2'}>Лог-скл. затраты</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant={'body2'} textAlign={'right'}>
                      {formatPriceCur(batch.lwhUnitCost, '₽/' + (batch?.unitShortName ?? 'т'))}
                    </Typography>
                  </Grid>
                </Grid>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <Grid py={0.5} container alignItems={'center'} spacing={1} ref={tableCellRef}>
                  <Grid item xs={6}>
                    <Typography variant={'body2'}>Фин. затраты</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant={'body2'} textAlign={'right'}>
                      {formatPriceCur(batch.financialUnitCost, '₽/' + (batch?.unitShortName ?? 'т'))}
                    </Typography>
                  </Grid>
                </Grid>
                <Divider />
              </Grid>

              <Grid item xs={12}>
                <ProductPackItems
                  items={batch.items}
                  onClick={handleAddToCommentClick}
                  isShowButton={dealStore && !dealStore.isViewOnly() && !batch.batchDoNotSell}
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </>
    );
  }
);
