import { AxiosResponse } from 'axios';
import { DealDeliveryType, DealsItemDeliveryTypesResponse } from '../api/marketx';
import { AxiosCallContext, getCallContext } from '../utils/axiosInit';
import { AppDealDeliveryType } from '../slices/AppDeal';
import { makeAutoObservable, runInAction } from 'mobx';
import { mapApiArray } from '../views/clients/lib';
import { ApiStore } from './Global/ApiStore';
import { RootStore } from './StoreManager';

/**
 * Типы доставки для сделки
 */
export class DeliveryTypeListStore {
  svc: DeliveryTypeListService;
  apiStore: ApiStore;
  dealCode?: string;

  isLoaded = false;
  isLoading = false;
  loadedEpoch = 0;

  items: AppDealDeliveryType[] = new Array<AppDealDeliveryType>();

  constructor(rootStore: RootStore) {
    this.svc = new DeliveryTypeListService();
    this.apiStore = rootStore.getApiStore();
    makeAutoObservable(this, {
      svc: false,
      apiStore: false,
    });
  }

  loadListForDeal(dealCode: string): void {
    const newDealCode = dealCode || undefined;
    if (this.dealCode === newDealCode) {
      return;
    }
    runInAction(() => {
      this.dealCode = newDealCode;
      if (!newDealCode) {
        this.setEmptyList();
        return;
      }
      this.svc.debounceRequest(this);
    });
  }

  setResult(ctx: AxiosCallContext, res: DealsItemDeliveryTypesResponse): void {
    const mapDeliveryType = (d: DealDeliveryType): AppDealDeliveryType =>
      <AppDealDeliveryType>{
        name: d.name,
        code: d.code,
        priceBu: d.priceBu || 0,
        priceTotal: d.priceTotal || 0,
        plannedShipmentDate: d.plannedShipmentDate || 0,
      };

    runInAction(() => {
      this.items.splice(0, this.items.length, ...mapApiArray(res.deliveryTypes, mapDeliveryType));
      this.isLoaded = true;
      this.isLoading = false;
      this.loadedEpoch++;
    });
  }

  setEmptyList(): void {
    this.items.splice(0, this.items.length);
    this.isLoaded = true;
    this.isLoading = false;
    this.loadedEpoch++;
  }
}

class DeliveryTypeListService {
  requestDebounceTimeout: NodeJS.Timeout;

  debounceRequest(store: DeliveryTypeListStore): void {
    if (this.requestDebounceTimeout) {
      clearTimeout(this.requestDebounceTimeout);
    }
    this.requestDebounceTimeout = setTimeout(() => {
      this.requestDebounceTimeout = undefined;
      this.executeRequest(store);
    }, 100);
  }

  executeRequest(store: DeliveryTypeListStore): void {
    if (!store.dealCode) {
      return;
    }
    store.apiStore
      .apiClientDeal()
      .dealsItemDeliveryTypes(store.dealCode)
      .then((res: AxiosResponse<DealsItemDeliveryTypesResponse>): void => {
        store.setResult(getCallContext(res), res.data);
      })
      .catch(r => {
        console.warn('Не удалось загрузить типы доставки', r);
      });
  }
}
