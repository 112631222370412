import { RootStore } from '../StoreManager';
import { observable, runInAction } from 'mobx';
import { ApiStore } from '../Global/ApiStore';
import { AxiosResponse } from 'axios';
import { CatalogProductsExtensionsResponse, ProductExtension } from '../../api/marketx';

/**
 * Стор помогает загрузить расширенную информацию по продукту.
 * Расширенная информация подгружается уже после загрузки списка товаров
 * с основной информацией.
 * Следует делать глобальным, тогда на разных страницах можно будет
 * переиспользовать уже загруженные данные.
 */
export class ProductExtensionsStore {
  private apiStore: ApiStore;

  /**
   * Кэш загруженных данных.
   */
  private wrappersIndex = new Map<string, ProductExtensionWrapper>();

  /**
   * Таймаут для группировки загрузок в один запрос
   */
  private loadDebounce: NodeJS.Timeout;

  /**
   * Задержка перед началом загрузки, мс.
   */
  private loadDebounceTimeout = 150;

  /**
   * Период кэширования информации по продукту.
   */
  private loadCacheTimeout = 30 * 1000;

  /**
   * Список артикулов для следующей загрузки.
   */
  private loadQueue = new Set<ProductExtensionWrapper>();

  constructor(rootStore: RootStore) {
    this.apiStore = rootStore.getApiStore();
  }

  getExtension(productCode: string, warehouseCode: string, officeCode?: string): ProductExtensionWrapper {
    const wrapperCode = productCode + '~' + warehouseCode + '~' + (officeCode || '');
    let pew = this.wrappersIndex.get(wrapperCode); // пиу пиу
    if (!pew) {
      pew = <ProductExtensionWrapper>{
        loaded: false,
        loadStartTime: new Date(),
        productCode: productCode,
        branchOfficeCode: officeCode,
        warehouseCode: warehouseCode,
      };
      pew = observable(pew);
      this.wrappersIndex.set(wrapperCode, pew);
    }

    if (!pew.loadSuccessTime || new Date().getTime() - pew.loadSuccessTime.getTime() > this.loadCacheTimeout) {
      this.planRefresh(pew);
    }

    return pew;
  }

  planRefresh(pew: ProductExtensionWrapper): void {
    this.loadQueue.add(pew);
    clearTimeout(this.loadDebounce);
    this.loadDebounce = setTimeout(() => {
      clearTimeout(this.loadDebounce);
      this.runQueue();
    }, this.loadDebounceTimeout);
  }

  runQueue(): void {
    const chunks = new Map<string, LoadChunk>();
    this.loadQueue.forEach((pew: ProductExtensionWrapper) => {
      const chunkKey = pew.warehouseCode + '~' + (pew.branchOfficeCode || '');
      let c = chunks.get(chunkKey);
      if (!c) {
        c = <LoadChunk>{
          branchOfficeCode: pew.branchOfficeCode,
          warehouseCode: pew.warehouseCode,
          productsCodes: [],
          wrappers: new Map<string, ProductExtensionWrapper>(),
        };
        chunks.set(chunkKey, c);
      }
      c.wrappers.set(pew.productCode, pew);
      c.productsCodes.push(pew.productCode);
    });
    this.loadQueue.clear();

    chunks.forEach(chunk => {
      this.apiStore
        .apiClientCatalog()
        .catalogProductsExtensions({
          productsCodes: chunk.productsCodes,
          branchOfficeCode: chunk.branchOfficeCode,
          warehouseCode: chunk.warehouseCode,
        })
        .then((res: AxiosResponse<CatalogProductsExtensionsResponse>) => {
          this.setLoadResult(chunk, res.data);
        })
        .catch(r => {
          console.warn('error extensions', r);
        });
    });
  }

  setLoadResult(chunk: LoadChunk, data: CatalogProductsExtensionsResponse): void {
    const now = new Date();
    data.productsExtensions.filter(pe => {
      const pew = chunk.wrappers.get(pe.code);
      if (pew) {
        runInAction(() => {
          pew.data = observable(pe);
          pew.loadSuccessTime = now;
          pew.loaded = true;
        });
      }
    });
  }
}

/**
 * Вспомогательная версия для загрузки данных блоками.
 */
interface LoadChunk {
  branchOfficeCode: string;
  warehouseCode: string;
  productsCodes: string[];
  wrappers: Map<string, ProductExtensionWrapper>;
}

/**
 * Сами дополнительные данные.
 */
export interface ProductExtensionWrapper {
  /**
   * Данные от бэкенда получены
   */
  loaded: boolean;

  loadStartTime: Date;
  loadSuccessTime?: Date;

  /**
   * Филиал, для которого загружены данные
   */
  branchOfficeCode: string;

  /**
   * Склад, для которого загружены данные
   */
  warehouseCode: string;

  /**
   * Артикул
   */
  productCode: string;

  data?: ProductExtension;
}
