import InfoIcon from '@mui/icons-material/InfoOutlined';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import Typography from '@mui/material/Typography';
import TableRow from '@mui/material/TableRow';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import Skeleton from '@mui/material/Skeleton';
import { styled } from '@mui/material/styles';
import { observer } from 'mobx-react-lite';
import React, { FC, JSX, useCallback, useMemo, useState } from 'react';
import CalculateIcon from '@mui/icons-material/Calculate';
import { Product } from 'src/api/marketx';
import LightTooltip from 'src/components/ToolTip/LightTooltip';
import { NewCatalogStore } from 'src/store/Catalog/NewCatalogStore';
import { ColumnsMode } from 'src/store/CatalogStore';
import { useAuthStore, useRootStore } from 'src/store/MobxStoreProvider';
import { formatNumber3, formatNumberByUnit, formatPrice02 } from '@mx-ui/helpers';
import { ProductPeculiaritiesIndicators } from 'src/views/catalog/Listing/ProductPeculiaritiesIndicators';
import { CatalogCellAddedProduct } from './CatalogCellAddedProduct';
import ProductConverter from 'src/components/Product/ProductConverter/ProductConverter';
import { ProductPacksStore } from 'src/store/ProductPacksStore';
import { renderPriceCur } from 'src/utils/renderFormat';
import { ProductPackTable } from 'src/views/catalog/Listing/ProductPackTable';
import { PricesTable } from 'src/components/Product/PricesTable';
import ChromeReaderModeRoundedIcon from '@mui/icons-material/ChromeReaderModeRounded';
import { CatalogItemNomenclatureStore } from 'src/store/Catalog/CatalogItemNomenclatureStore';
import { ProductExtensionWrapper } from 'src/store/Catalog/ProductExtensionsStore';
import { MeasureRef } from 'react-cool-virtual';
import { ReservesStore } from '../../../store/ReservesStore';
import { TooltipForReserveList } from '../../Product/Reserve/TooltipForReseveList';
import { TooltipForWarehouseDetails } from '../../Product/TooltipForWarehouseDetails';
import { Divider } from '@mui/material';
import { DialogContainer } from '../../ui/dialogs/DialogContainer';
import { PositionsManufacturersListStore } from '../../../store/Deals/PositionsManufacturersListStore';
import { AlternativeProductsTableForCatalog } from '../../../views/deals/DealPosition/AlternativeProductsTableForCatalog';
import { Link as MxLink } from '@mx-ui/ui';

const FormControlCustom = styled(FormControl)(({ theme }) => ({
  position: 'relative',
  width: '30ch',
  display: 'flex',
  flexWrap: 'wrap',
  margin: theme.spacing(1),
}));

interface ITableRowTitleProps {
  item: Product;
  catalogStore?: NewCatalogStore;
}

export const TableRowTitle: FC<ITableRowTitleProps> = observer(({ item, catalogStore }): JSX.Element => {
  const warehouseCode = catalogStore?.request.warehouseCode;
  const warehouse = catalogStore?.warehouseListStore.items.find(i => i.code === catalogStore.request.warehouseCode) ?? null;

  return (
    <>
      <MxLink
        href={
          warehouse?.isGroup || warehouse === null
            ? `/app/product?productCode=${encodeURIComponent(item.code)}`
            : `/app/product?productCode=${encodeURIComponent(item.code)}&warehouseCode=${encodeURIComponent(warehouseCode)}`
        }
        title="Перейти к продукту"
        onClick={e => e.stopPropagation()}
        fontSize={'14px'}
        color={'text'}
      >
        {`${item.code || ''} ${item.title}`}
      </MxLink>
    </>
  );
});
interface TooltipForCatalogCellProps {
  catalogStore: NewCatalogStore;
  title: (value: any) => JSX.Element;
  icon?: (fn: () => void) => JSX.Element;
  item: Product;
  loadFunction: (value: string) => Promise<any>;
}
const loadFunction = (): any => Promise.resolve(true);
interface TableRowItemExpandRowProps {
  item: Product;
  catalogStore: NewCatalogStore;
  packsStore: ProductPacksStore;
  productExtension: ProductExtensionWrapper;
  catalogItemNomenclatureStore: CatalogItemNomenclatureStore;
  positionsManufacturersStore: PositionsManufacturersListStore;
  onSearch: (value: string) => void;
}
const TableRowItemExpandRow: FC<TableRowItemExpandRowProps> = observer(
  ({
    item: row,
    packsStore,
    catalogStore,
    catalogItemNomenclatureStore,
    productExtension,
    onSearch,
    positionsManufacturersStore,
  }): JSX.Element => {
    const itemNomenclature = catalogItemNomenclatureStore.item || {};
    return (
      <Box width={'100%'} display="flex" flexWrap="wrap">
        <Grid sx={{ m: 1, p: 1, width: '100%' }}>
          <Typography color="text.secondary" fontSize={'12px'}>
            <TableRowTitle item={row} catalogStore={catalogStore} />
          </Typography>
          <Divider />
        </Grid>
        <Paper sx={{ m: 1, p: 2, width: '100%' }}>
          <AlternativeProductsTableForCatalog product={row} manufacturersStore={positionsManufacturersStore} onSearch={onSearch} />
        </Paper>
        <Paper
          sx={{
            // backgroundColor: '#f2f8fc',
            padding: '16px',
            margin: 1,
            borderRadius: '5px',
            flex: '1',
            minWidth: '500px',
          }}
        >
          <Typography variant="h6" gutterBottom component="div">
            Цены
          </Typography>
          <Box display="flex" mb={1}>
            <Typography variant="body2" sx={{ display: 'flex', width: '150px' }}>
              Базовая МРЦ
            </Typography>
            <Box component="span">{renderPriceCur(row?.baseMinRetailUnitCost || 0)}</Box>
          </Box>
          {productExtension.data?.hasCustodyBatches && (
            <Box display="flex" mb={1}>
              <Typography variant="body2" sx={{ display: 'flex', width: '150px' }}>
                Цена ОХ
              </Typography>
              <Box component="span">
                {[row].map(row => {
                  if (!(row.custodyTotalWeight > 0)) {
                    return '-';
                  }
                  let custodyUnitCost = row.custodyAvgUnitCost;
                  for (let i = 0; i < row.custodyWarehouses?.length; i++) {
                    const cw = row.custodyWarehouses[i];
                    if (cw.warehouseCode === catalogStore.selectedValueByWarehouse && cw.unitCost > 0) {
                      custodyUnitCost = cw.unitCost;
                    }
                  }
                  return custodyUnitCost > 0 ? renderPriceCur(custodyUnitCost) : '-';
                })}
              </Box>
            </Box>
          )}

          <PricesTable value={row.prices} />
        </Paper>
        <Paper sx={{ m: 1, p: 2, flex: '1' }}>
          <TableContainer
            sx={{ maxHeight: '220px', width: '100%', display: 'grid', gridTemplateColumns: 'minmax(1fr, 1fr)' }}
            className="withCustomScroll"
            id={'catalogTableRowProductPack'}
          >
            <Typography variant="h6" gutterBottom component="div">
              Партии прихода
            </Typography>
            <Grid container spacing={0}>
              <ProductPackTable
                stickyHeader={false}
                packsStore={packsStore}
                product={row}
                infiniteScrollId={'catalogTableRowProductPack'}
              />
            </Grid>
          </TableContainer>
        </Paper>
        <Paper sx={{ m: 1, p: 2, flex: '1' }}>
          <TableContainer>
            <Typography variant="h6" gutterBottom component="div">
              {catalogItemNomenclatureStore.item?.name}
            </Typography>
            <Table size={'small'} sx={{ width: '300px' }}>
              <TableHead>
                <TableRow>
                  <TableCell>Остаток</TableCell>
                  <TableCell>Резерв</TableCell>
                  <TableCell>В пути</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell sx={{ borderBottom: '0' }}>
                    {itemNomenclature?.inStockForSale ? formatNumber3(itemNomenclature?.inStockForSale) : '-'}
                  </TableCell>
                  <TableCell sx={{ borderBottom: '0' }}>
                    {itemNomenclature?.inReserve ? formatNumber3(itemNomenclature?.inReserve) : '-'}
                  </TableCell>
                  <TableCell sx={{ borderBottom: '0' }}>
                    {(itemNomenclature?.inTransitQuantity !== null && itemNomenclature?.inTransitQuantity !== undefined) ||
                    (itemNomenclature?.distributedQuantity !== null && itemNomenclature?.distributedQuantity !== undefined) ? (
                      <>
                        {itemNomenclature?.inTransitQuantity ? formatNumber3(itemNomenclature?.inTransitQuantity) : '0'} (
                        {itemNomenclature?.distributedQuantity ? formatNumber3(itemNomenclature?.distributedQuantity) : '0'})
                      </>
                    ) : (
                      '-'
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
    );
  }
);
const TooltipForCatalogCell: FC<TooltipForCatalogCellProps> = observer(({ catalogStore, item, title, loadFunction, icon }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(null);
  const handleTooltipClose = (): void => {
    setOpen(false);
  };
  const handleTooltipOpen = useCallback((): void => {
    setOpen(true);
    setLoading(true);
    loadFunction(item.code)
      .then(r => {
        setValue(r);
        setLoading(false);
      })
      .catch(r => {
        console.warn('error loadFunction', r);
        setLoading(false);
      });
  }, [catalogStore, item]);
  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Box sx={{ marginTop: '-4px', marginBottom: '-4px', marginRight: '-4px' }}>
        <LightTooltip
          PopperProps={{
            disablePortal: true,
          }}
          onClose={handleTooltipClose}
          open={open}
          arrow
          disableFocusListener
          disableHoverListener
          disableTouchListener
          title={
            loading ? (
              <Box p={'4px'}>
                <CircularProgress size={30} />
              </Box>
            ) : (
              title(value)
            )
          }
        >
          {icon ? (
            icon(handleTooltipOpen)
          ) : (
            <IconButton size="small" onClick={handleTooltipOpen}>
              <InfoIcon fontSize="small" color={'primary'} />
            </IconButton>
          )}
        </LightTooltip>
      </Box>
    </ClickAwayListener>
  );
});
interface CatalogTableRowItemViewProps {
  catalogStore: NewCatalogStore;
  reserveStore: ReservesStore;
  item: Product;
  measureRef?: MeasureRef;
}

export const CatalogTableRowVirtualizeItemView: FC<CatalogTableRowItemViewProps> = observer(
  ({ item, catalogStore, measureRef, reserveStore }): JSX.Element => {
    const rootStore = useRootStore();
    const auth = useAuthStore();
    const selectedOfficeCode = catalogStore.request?.branchOfficeCode || auth.profile?.chosenBranchOfficeCode;
    const productExtensionsStore = rootStore.getProductExtensionsStore();
    const [openAccordion, setOpenAccordion] = useState<boolean>(false);
    const selectedWhCode = useMemo<string>(() => {
      return catalogStore.request?.warehouseCode || auth.profile?.warehouseCode || undefined;
    }, [catalogStore.request?.warehouseCode, auth.profile?.warehouseCode]);
    const packsStore = useMemo(() => {
      return new ProductPacksStore(rootStore);
    }, [rootStore]);
    const catalogItemNomenclatureStore = useMemo(() => {
      return new CatalogItemNomenclatureStore(rootStore);
    }, [rootStore]);
    const positionsManufacturersStore = useMemo(() => {
      return new PositionsManufacturersListStore(rootStore);
    }, [rootStore]);
    const handleToggleRowClick = useCallback((): void => {
      if (!(packsStore.isLoading || packsStore.isLoaded)) {
        packsStore.loadForProduct(item.code, selectedWhCode);
      }
      if (!(catalogItemNomenclatureStore.isLoading || catalogItemNomenclatureStore.isLoaded)) {
        catalogItemNomenclatureStore.loadForProduct(item.code, selectedWhCode);
      }
      positionsManufacturersStore.loadForProduct(item.code, selectedWhCode);
      setOpenAccordion(open => !open);
    }, [selectedWhCode]);
    const setSearchCatalog = useCallback(
      (value: string) => {
        catalogStore.setSearchQuery(value);
        setOpenAccordion(open => !open);
      },
      [catalogStore]
    );
    const productExtension = useMemo(() => {
      return productExtensionsStore.getExtension(item.code, catalogStore.request?.warehouseCode, selectedOfficeCode);
    }, [item.code, catalogStore.request?.warehouseCode, selectedOfficeCode]);
    const pricesSpaces = useMemo(() => {
      return item.prices?.slice(1);
    }, [item, item.prices]);

    if ((catalogStore.isLoading && !productExtension.loaded) || !productExtension.loaded) {
      return (
        <Grid
          container
          justifyContent="space-between"
          alignItems={'center'}
          sx={{ height: '67px', borderBottom: '1px solid', borderColor: 'divider' }}
          spacing={1}
        >
          {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((item, index) => {
            if (index === 0) {
              return (
                <Grid key={index} item flex="0 0 240px">
                  <Skeleton />
                </Grid>
              );
            }
            return (
              <Grid key={index} item flex="1 1 calc((100% - 240px) / 8)">
                <Skeleton />
              </Grid>
            );
          })}
        </Grid>
      );
    }

    const altUnits = item.units?.filter(unit => unit.code !== 'TNE');
    const itemReserve = (item.inReserve || 0) + (item.inSoftReserve || 0) + (item.inFreeze || 0);

    return (
      <Grid item ref={measureRef} sx={{ borderBottom: '1px solid', borderColor: 'divider' }}>
        <Grid container justifyContent="space-between" alignItems={'center'} flexWrap={'nowrap'}>
          {catalogStore.isAddMode && (
            <Grid item flex="0 0 48px">
              <CatalogCellAddedProduct item={item} catalogStore={catalogStore} />
            </Grid>
          )}
          <Grid item flex="1 1 calc(100% - 680px)">
            <Typography fontSize={'14px'}>
              <TableRowTitle item={item} catalogStore={catalogStore} />
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'flex-end', marginBottom: '3px' }}>
              <ProductPeculiaritiesIndicators
                extension={productExtension}
                isOld={!!item?.isOldestInNomenclature}
                isNew={!!item.isNewestInNomenclature}
              />
            </Box>
          </Grid>
          <Grid item flex="0 0 40px">
            <IconButton onClick={handleToggleRowClick}>
              <ChromeReaderModeRoundedIcon color="primary" />
            </IconButton>
          </Grid>
          <Grid item flex="0 0 90px">
            {/* Базовый размер */}
            <Typography fontSize={'14px'} textAlign={'right'}>
              {item.baseSize?.valueText !== null && item.baseSize?.valueText !== undefined ? item.baseSize.valueText : '-'}
            </Typography>
          </Grid>
          <Grid item flex="0 0 130px">
            {/* кратность */}
            <Grid container justifyContent={'flex-end'}>
              {altUnits.length > 0 ? (
                altUnits.map((unit, index) => (
                  <div key={index} style={{ margin: 0 }}>
                    <Typography fontSize={'14px'} textAlign={'right'}>
                      {unit.weight} <span style={{ whiteSpace: 'nowrap' }}>{`т/${unit.name}`}</span>
                    </Typography>
                  </div>
                ))
              ) : (
                <Typography fontSize="14px" textAlign="right" mr="25%">
                  -
                </Typography>
              )}

              {!catalogStore?.isAddMode && altUnits.length > 0 && (
                <TooltipForCatalogCell
                  catalogStore={catalogStore}
                  item={item}
                  icon={onClick => (
                    <Box sx={{ marginTop: '0', marginBottom: '-4px', marginRight: '-4px' }}>
                      <IconButton
                        title="Калькулятор/конвертор"
                        color="inherit"
                        size="small"
                        aria-label="unit converter"
                        onClick={onClick}
                        sx={{
                          padding: '5px',
                          position: 'relative',
                          marginTop: '-1px',
                          marginBottom: '-4px',
                          marginRight: '-4px',
                        }}
                      >
                        <CalculateIcon fontSize="small" sx={{ color: 'secondary.light' }} />
                      </IconButton>
                    </Box>
                  )}
                  title={() => (
                    <FormControlCustom variant="standard" sx={{ width: '45ch' }}>
                      <Grid item flexDirection="column">
                        {item.units.map((unit, index) => (
                          <ProductConverter key={index} unit={unit} pricesSpaces={pricesSpaces} />
                        ))}
                      </Grid>
                    </FormControlCustom>
                  )}
                  loadFunction={loadFunction}
                />
              )}
            </Grid>
          </Grid>
          <Grid item flex="0 0 90px">
            {/* остаток */}
            <Grid container justifyContent={'flex-end'}>
              <TooltipForWarehouseDetails item={item} catalogStore={catalogStore} />
            </Grid>
          </Grid>
          <Grid item flex="0 0 90px">
            {/* Резерв */}
            <Grid container justifyContent={'flex-end'} alignItems={'center'}>
              {itemReserve ? (
                <>
                  <Box>
                    <Typography fontSize={'14px'} textAlign={'right'}>
                      {formatNumberByUnit(itemReserve, item.baseUnitCode)}
                    </Typography>
                  </Box>
                  <TooltipForReserveList
                    reserveStore={reserveStore}
                    product={item}
                    branchOfficeCode={
                      catalogStore.authStore.profile.chosenBranchOfficeCode || catalogStore.authStore.profile.branchOfficeCode
                    }
                  />
                </>
              ) : (
                <Box width="100%">
                  <Typography fontSize={'14px'} textAlign={'center'} width="100%" ml="20%">
                    -
                  </Typography>
                </Box>
              )}
            </Grid>
          </Grid>
          <Grid item flex="0 0 90px">
            {/* В пути */}
            {(item.inTransitQuantity !== null && item.inTransitQuantity !== undefined) ||
            (item.distributedQuantity !== null && item.distributedQuantity !== undefined) ? (
              <Grid container>
                <Grid item xs={12}>
                  <Typography fontSize={'14px'} lineHeight={'16px'} textAlign={'right'}>
                    {formatNumber3(item.inTransitQuantity)}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography fontSize={'14px'} lineHeight={'16px'} textAlign={'right'}>
                    {`(${formatNumber3(item.distributedQuantity)})`}
                  </Typography>
                </Grid>
              </Grid>
            ) : (
              <Typography fontSize={'14px'} textAlign={'center'} ml="50%">
                -
              </Typography>
            )}
            {/* {item.inTransitQuantity ? formatNumber3(item.inTransitQuantity) : '0'} ( */}
            {/* {item.distributedQuantity ? formatNumber3(item.distributedQuantity) : '0'}) */}
          </Grid>
          {catalogStore.columnsMode === ColumnsMode.WIDE && (
            <Grid item flex="0 0 90px">
              {/* Цена ОХ */}
              <Typography fontSize={'14px'} textAlign={'right'}>
                {[item].map(row => {
                  if (!(row.custodyTotalWeight > 0)) {
                    return '-';
                  }
                  let custodyUnitCost = row.custodyAvgUnitCost;
                  for (let i = 0; i < row.custodyWarehouses?.length; i++) {
                    const cw = row.custodyWarehouses[i];
                    if (cw.warehouseCode === catalogStore.selectedValueByWarehouse && cw.unitCost > 0) {
                      custodyUnitCost = cw.unitCost;
                    }
                  }
                  return custodyUnitCost > 0 ? formatPrice02(custodyUnitCost) : '-';
                })}
              </Typography>
            </Grid>
          )}
          <Grid item flex="0 0 90px">
            {/* Цена + */}
            <Typography fontSize={'14px'} textAlign={'right'}>
              {item.topCostValue ? formatPrice02(item.topCostValue) : '-'}
            </Typography>
          </Grid>
          <Grid item flex="0 0 90px">
            {/* МРЦ */}
            <Typography fontSize={'14px'} textAlign={'right'}>
              {item.minRetailUnitCost !== null && item.minRetailUnitCost !== undefined ? formatPrice02(item.minRetailUnitCost) : '-'}
            </Typography>
          </Grid>
        </Grid>
        <DialogContainer
          open={openAccordion}
          onClose={handleToggleRowClick}
          maxWidth={'lg'}
          classes={{ paper: 'withCustomScroll' }}
          dialogContent={
            <TableRowItemExpandRow
              item={item}
              packsStore={packsStore}
              productExtension={productExtension}
              catalogStore={catalogStore}
              catalogItemNomenclatureStore={catalogItemNomenclatureStore}
              positionsManufacturersStore={positionsManufacturersStore}
              onSearch={setSearchCatalog}
            />
          }
        />
      </Grid>
    );
  }
);
