import { observer } from 'mobx-react-lite';
import React, { FC, useCallback, useMemo } from 'react';
import { DealItemStore } from '../../../store/DealItemStore';
import { CustomSelectNew } from '../components/CustomSelectNew';

interface DealWarehouseSelectProps {
  dealStore: DealItemStore;
}

export const DealWarehouseSelect: FC<DealWarehouseSelectProps> = observer(({ dealStore }): JSX.Element => {
  const warehouses = dealStore.getWarehousesStore();

  const handleWarehouseChange = useCallback(
    (value: string | null) => {
      dealStore
        .updateDeal({ warehouseCode: value })
        .then(() => {
          // нужно перезагрузить списки производителей на товарах
          dealStore.reload();
        })
        .catch(r => console.warn('handleWarehouseChange', r));
    },
    [dealStore]
  );

  const warehouseOptions = useMemo<Array<{ value; text }>>(() => {
    return warehouses.items.map(wh => ({
      value: wh.code,
      text: wh.name,
    }));
  }, [warehouses, warehouses.loadedEpoch]);

  return (
    <CustomSelectNew
      label={'Склад'}
      options={warehouseOptions.filter(ele => ele.value != 'spb-total')}
      isViewOnly={dealStore.isViewOnly() || warehouses.isLoading}
      defaultValue={dealStore.deal?.warehouseCode}
      onChange={handleWarehouseChange}
    />
  );
});
