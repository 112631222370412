import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import Slider from '@mui/material/Slider';
import Input from '@mui/material/Input';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { observer } from 'mobx-react-lite';
import { ValueStore } from '../../store/ValueStore';

interface RangeInputProps {
  isViewOnly?: boolean;
  showMarksMinMax?: boolean;
  label?: React.ReactNode;
  value: ValueStore;
  icon: React.ReactNode;
  iconWarning?: React.ReactNode;
  max?: number;
  min?: number;
  addMarks?: { value: number; label: string }[];
  step?: number | null;
}

export const PaymentRangeInput: FC<RangeInputProps> = observer(
  ({ value, isViewOnly, label, icon, min, max, step, iconWarning, showMarksMinMax = false, addMarks = [] }): JSX.Element => {
    const [localValue, setLocalValue] = useState<number>(Number(value.asString()));

    // Движение слайдера, не отпуская кнопку.
    // Не сохраняет состояние, меняется только отображение слайдера.
    const handleSliderChange = useCallback(
      (event, newValue): void => {
        setLocalValue(newValue);
      },
      [setLocalValue]
    );

    // Изменение бэкендом
    useEffect(() => {
      setLocalValue(Number(value.asString()));
    }, [setLocalValue, value.asString()]);

    // Отпуск кнопки мыши после движения слайдера.
    const handleSliderChangeCommitted = useCallback(
      (event, newValue): void => {
        value.handleInputChange(newValue);
      },
      [value]
    );

    // Изменение значения в инпуте.
    const handleInputChange = useCallback(
      (event): void => {
        const newValue = event.target.value === '' ? '' : Number(event.target.value);
        value.handleInputChange(String(newValue));
      },
      [value]
    );

    // Потеря фокуса на инпуте.
    const handleBlur = useCallback((): void => {
      const numValue = Number(value.asString());
      if (min !== undefined && numValue < min) {
        value.handleInputChange(String(min));
      } else if (max !== undefined && numValue > max) {
        value.handleInputChange(String(max));
      }
    }, [value]);
    const marks = useMemo(
      () => [
        {
          value: min,
          label: min,
        },
        {
          value: max,
          label: max,
        },
        ...addMarks,
      ],
      [min, max]
    );
    const inputProps = useMemo(() => {
      return {
        step: step,
        min: min,
        max: max,
        // type: 'number',
        'aria-labelledby': 'input-slider',
      };
    }, [step, min, max]);

    return (
      <div>
        <Box display="flex" justifyContent={'space-between'}>
          <Typography gutterBottom>{label}</Typography>
          {iconWarning && <Box>{iconWarning}</Box>}
        </Box>

        <Grid container spacing={2} alignItems="center">
          <Grid item>{icon}</Grid>
          <Grid item xs>
            <Slider
              disabled={isViewOnly}
              value={typeof localValue === 'number' ? localValue : 0}
              onChange={handleSliderChange}
              marks={showMarksMinMax && marks}
              onChangeCommitted={handleSliderChangeCommitted}
              step={step}
              min={min}
              max={max}
            />
          </Grid>
          <Grid item>
            <Input
              disabled={isViewOnly}
              style={{ width: 42 }}
              value={localValue}
              margin="dense"
              onChange={handleInputChange}
              onBlur={handleBlur}
              inputProps={inputProps}
            />
          </Grid>
        </Grid>
      </div>
    );
  }
);
