import { RootStore } from '../StoreManager';
import { makeAutoObservable } from 'mobx';

const localStorageVisibleFlag = 'devPanelVisible';

export class DevPanelStore {
  rootStore: RootStore;
  visible = false;
  data = {};

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    try {
      if (typeof window !== 'undefined' && window.localStorage && JSON.parse(localStorage.getItem(localStorageVisibleFlag) || '0')) {
        this.visible = true;
      }
    } catch (e) {
      console.warn('dev panel get ls flag', e);
    }

    makeAutoObservable(this, {
      rootStore: false,
    });
  }

  hide(): void {
    this.setVisible(false);
  }

  setKV(key: string, val: any): void {
    this.data[key] = val;
  }

  setVisible(visible: boolean): void {
    this.visible = visible;
    if (typeof window !== 'undefined' && window.localStorage) {
      if (this.visible) {
        localStorage.setItem(localStorageVisibleFlag, '1');
      } else {
        localStorage.removeItem(localStorageVisibleFlag);
      }
    }
  }

  show(): void {
    this.setVisible(true);
  }
}
