import { OptionsObject, ProviderContext, SnackbarKey, SnackbarMessage } from 'notistack';
import { RootStore } from './StoreManager';
import { MsgType } from './Global/WebSocketStore';

/**
 * Для отображения всплывающих окон.
 * Используется другими сторами.
 */
export class SnackbarStore {
  snackbarPCtx: ProviderContext;

  constructor(rootStore: RootStore) {
    const eventMgr = rootStore.getEventManager();
    eventMgr.subscribe(MsgType.WEB_SOCKET_SNACKBAR_SUCCESS, msg => {
      this.showSuccess(msg.data?.message || '');
    });
    eventMgr.subscribe(MsgType.WEB_SOCKET_SNACKBAR_ERROR, msg => {
      this.showError(msg.data?.message || '');
    });
  }

  hasProviderContext(): boolean {
    return !!this.snackbarPCtx;
  }

  setProviderContext(pc: ProviderContext): void {
    this.snackbarPCtx = pc;
  }

  showError(message: SnackbarMessage): void {
    this.show(message, { variant: 'error' });
  }

  showInfo(message: SnackbarMessage): void {
    this.show(message, { variant: 'info' });
  }

  showSuccess(message: SnackbarMessage): void {
    this.show(message, { variant: 'success' });
  }

  show(message: SnackbarMessage, options?: OptionsObject): SnackbarKey {
    if (!this.snackbarPCtx) {
      console.error('NoSnackBarPCtx', message, options);
      return;
    }
    return this.snackbarPCtx.enqueueSnackbar(message, options);
  }
}
