import { createContext, ReactNode } from 'react';

export type Env = 'prod' | 'dev' | 'qa' | 'demo';
type EnvContextValue = Env;

export const EnvContext = createContext<Env>('prod');

export const EnvContextProvider = ({ children, value }: { children: ReactNode; value: EnvContextValue }): JSX.Element => (
  <EnvContext.Provider value={value}>{children}</EnvContext.Provider>
);
