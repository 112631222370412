import React, { FC, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { NewCatalogStore } from '../../../store/Catalog/NewCatalogStore';
import { ContractCostPosition } from '../../../api/marketx';
import { Box } from '@mui/system';
import { getQuantityPercentAndStock, StockLinearProgress } from '../../Agreements/AgreementPositionItem/StockLinearProgress';

interface CatalogAdditionalInfoForDealAgreementProps {
  catalogStore: NewCatalogStore;
  productCode: string;
}

export const CatalogAdditionalInfoForDealAgreement: FC<CatalogAdditionalInfoForDealAgreementProps> = observer(
  ({ productCode, catalogStore }) => {
    const agreements = catalogStore.dealStore.deal.contractCostAgreements;

    let agreementPosition: ContractCostPosition;

    if (agreements) {
      agreements.forEach(agr => {
        agr.positions.forEach(pos => {
          if (pos.productCode === productCode) {
            agreementPosition = pos;
            return false;
          }
        });
      });
    }

    let dealQuantity = 0;
    if (agreementPosition) {
      catalogStore.dealStore.deal.positions.forEach(pos => {
        if (pos.isArchive) {
          return;
        }
        if (pos.agreementPositionCode === agreementPosition.code) {
          if (pos.unitCode === agreementPosition.unitCode) {
            dealQuantity += Number(pos.amount) || 0;
          } else if (pos.baseUnitCode === agreementPosition.unitCode) {
            dealQuantity += pos.baseQuantity;
          }
        }
      });
    }

    const propsStockLinearProgress = useMemo(
      () => getQuantityPercentAndStock(agreementPosition?.quantity, (agreementPosition?.reservation?.usedQuantity || 0) + dealQuantity),
      [dealQuantity, agreementPosition?.quantity, agreementPosition?.reservation?.usedQuantity]
    );

    if (!agreementPosition) {
      return null;
    }

    const unit = agreementPosition?.unitShortName || 'т';

    return (
      <Grid item>
        <Box p={0.6}>
          <Typography variant="body2" color="textPrimary" component="div">
            Остаток по соглашению
          </Typography>
          <Box display="flex">
            <Box width="100%" alignSelf={'center'}>
              <StockLinearProgress {...propsStockLinearProgress} unit={unit} />
            </Box>
          </Box>
        </Box>
      </Grid>
    );
  }
);
