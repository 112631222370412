import {
  ClientContract,
  ClientDocFlowStateCodeEnum,
  ContactsResponse,
  Customer,
  DealsCreateRequest,
  DealsCreateResponse,
  DealsItemCloneResponse,
  DealsItemCreateAgreementResponse,
  DealUpdates,
  Warehouse,
} from '../../api/marketx';
import { ClientType } from '../../components/Clients/ClientCard/types';
import { AppClientContract } from '../../slices/AppDeal';
import { AppWarehouse } from '../../types/AppWarehouse';
import { ApiStore } from '../../store/Global/ApiStore';
import { AxiosResponse } from 'axios';
import { ContactsByCustomerCodeType } from '../../store/ClientListStore';
import { LabelColor } from '@mx-ui/ui';

export const getMarginalityColorClass = (percent: number | string | any, simpleColor?: boolean): LabelColor => {
  if (isNaN(percent) || percent < 0) {
    return 'error';
  }

  if (percent <= 5) {
    return simpleColor ? 'warningSimpleColor' : 'warning';
  }

  return simpleColor ? 'successSimpleColor' : 'success';
};

interface CustomerRatingProps {
  title: string;
  color: LabelColor;
}

export const getCustomerApplicationStatus = (status: string): LabelColor => {
  if (status === 'new' || status === 'sent' || status === 'under_review' || status === 'approved' || status === 'transferred_to_nsi') {
    return 'warning';
  }

  if (status === 'sending_error' || status === 'rejected') {
    return 'error';
  }

  if (status === 'created') {
    return 'success';
  }

  return 'info';
};

export const getCustomerChangeRequestStatus = (status: string | undefined): LabelColor => {
  if (
    status === 'awaiting_customer_editor_approval' ||
    status === 'awaiting_office_chief_approval' ||
    status === 'awaiting_seller_chief_approval' ||
    status === 'awaiting_seller_chief_set_employee'
  ) {
    return 'warning';
  }

  if (status === 'rejected') {
    return 'error';
  }

  if (status === 'all_approved') {
    return 'success';
  }

  return 'info';
};

export const multicoloredPopoverBg = (color: string): { border: string; backgroundColor: string; color: string } => {
  const multicoloredStyle = {
    color: '#333',
    border: 'none',
    backgroundColor: 'none',
  };
  if (color === 'green') {
    multicoloredStyle.backgroundColor = '#CCFFCC';
    multicoloredStyle.border = '#009900 1px solid';
  }
  if (color === 'red') {
    multicoloredStyle.backgroundColor = '#ffcccc';
    multicoloredStyle.border = '#c74952 1px solid';
  }
  if (color === 'yellow') {
    multicoloredStyle.backgroundColor = '#ffffcc';
    multicoloredStyle.border = '#cccc00 1px solid';
  }
  return multicoloredStyle;
};

// export type customerRatingColor = 'red' | 'yellow' | 'green';
export const getCustomerRating = (rating: string): CustomerRatingProps => {
  if (rating === 'green') {
    return {
      color: 'success',
      title: 'Зеленый',
    };
  }

  if (rating === 'yellow') {
    return {
      color: 'warning',
      title: 'Желтый',
    };
  }

  return {
    color: 'error',
    title: 'Красный',
  };
};
export const getCustomerStatus = (statusCode: string): LabelColor => {
  if (statusCode === 'potential' || statusCode === 'unshipped' || statusCode === 'inactive' || statusCode === 'gone') {
    return 'warning'; // 'orange'
  } else if (statusCode === 'new' || statusCode === 'active' || statusCode === 'restored') {
    return 'success'; // 'green'
  } else if (statusCode === 'deleted' || statusCode === 'archived') {
    return 'info'; // 'gray'
  }
};
export const getRatingColor = (code: string): LabelColor => {
  if (code === 'yellow') {
    return 'warning'; // 'orange'
  } else if (code === 'green') {
    return 'success'; // 'green'
  } else if (code === 'red') {
    return 'error'; // 'red'
  } else {
    return 'info'; // 'gray'
  }
};

export const getDocFlowStatesColor = (statusCode: ClientDocFlowStateCodeEnum): LabelColor => {
  if (statusCode === 'invited') {
    return 'warning'; // 'orange'
  } else if (statusCode === 'active') {
    return 'success'; // 'green'
  } else {
    return 'info'; // 'gray'
  }
};

export const getContractStatus = (statusCode?: string): LabelColor => {
  if (!statusCode) {
    return 'inherit';
  }
  if (
    statusCode === 'approval' ||
    statusCode === 'new' ||
    statusCode === 'sent' ||
    statusCode === 'accepted' ||
    statusCode === 'under_review' ||
    statusCode === 'transferred_to_call_center' ||
    statusCode === 'accepted_with_comments' ||
    statusCode === 'unloading_from_nsi' ||
    statusCode === 'transferred_to_call_center_review'
  ) {
    return 'warning'; // 'orange'
  } else if (statusCode === 'active' || statusCode === 'accepted_need_sign' || statusCode === 'call_center_accepted') {
    return 'success'; // 'green'
  } else if (statusCode === 'draft') {
    return 'info'; // 'gray'
  } else if (
    statusCode === 'inactive' ||
    statusCode === 'draft_inactive' ||
    statusCode === 'sending_error' ||
    statusCode === 'rejected' ||
    statusCode === 'transferred_to_call_center_rejected' ||
    statusCode === 'call_center_rejected' ||
    statusCode === 'finished_rejected' ||
    statusCode === 'finished_aborted'
  ) {
    return 'error'; // 'red'
  }
};

export const getFio = (client: ClientType): string => {
  return `${client?.contactPersonName || ''} ${client?.contactPersonPatronymic || ''} ${client?.contactPersonSurname || ''}`
    .trim()
    .split('  ')
    .join(' ');
};

export const normalizeCompanyTitle = (title = ''): string => {
  if (title === undefined || title === null) {
    return '';
  }
  return title
    .replace(/ПУБЛИЧНОЕ АКЦИОНЕРНОЕ ОБЩЕСТВО /i, 'ПАО ')
    .replace(/ЗАКРЫТОЕ АКЦИОНЕРНОЕ ОБЩЕСТВО /i, 'ЗАО ')
    .replace(/АКЦИОНЕРНОЕ ОБЩЕСТВО /i, 'АО ')
    .replace(/ИНДИВИДУАЛЬНЫЙ ПРЕДПРИНИМАТЕЛЬ /i, 'ИП ')
    .replace(/ТОВАРИЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ /i, 'ТОО ')
    .replace(/ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ /i, 'ООО ')
    .replace(/АВТОНОМНАЯ НЕКОММЕРЧЕСКАЯ ОРГАНИЗАЦИЯ /i, 'АНО ');
};

export const createDeal = (
  apiStore: ApiStore,
  clientCode: string,
  frontId: string,
  warehouseCode: string
): Promise<AxiosResponse<DealsCreateResponse>> => {
  return apiStore.apiClientDeal().dealsCreate(<DealsCreateRequest>{
    frontCode: frontId,
    source: {
      kind: 'customer',
      itemCode: clientCode,
    },
    updates: <DealUpdates>{
      warehouseCode: warehouseCode,
      customerCode: clientCode,
      positions: [],
    },
  });
};

export const duplicateDeal = (
  apiStore: ApiStore,
  frontId: string,
  sourceDealCode: string
): Promise<AxiosResponse<DealsItemCloneResponse>> => {
  return apiStore.apiClientDeal().dealsItemClone(sourceDealCode, {
    frontCode: frontId,
    sourceDealCode: sourceDealCode,
    products: true,
  });
};

export const deactivateDeal = async (apiStore: ApiStore, dealCode: string): Promise<AxiosResponse<void>> => {
  return apiStore.apiClientDeal().dealsItemDeactivate(dealCode);
};

export const createAgreement = async (
  apiStore: ApiStore,
  frontCode: string,
  dealCode: string
): Promise<AxiosResponse<DealsItemCreateAgreementResponse>> => {
  return apiStore.apiClientDeal().dealsItemCreateAgreement(dealCode, {
    frontCode: frontCode,
  });
};

export const mapClient = (client: Customer | any): ClientType => {
  return <ClientType>{
    ...client,
    title: normalizeCompanyTitle(client.title || ''),
    shortTitle: normalizeCompanyTitle(client.shortTitle || ''),
  };
};

export const mapClientContract = (contract: ClientContract): AppClientContract => {
  const res = <ClientContract>{};
  return Object.assign(res, contract);
};

export const mapClients = (clients: Customer[]): ClientType[] => {
  const result: ClientType[] = [];

  clients.forEach(client => {
    result.push(mapClient(client));
  });

  return result;
};

export const mapContactsByCustomerCode = (data: AxiosResponse<ContactsResponse, any>): ContactsByCustomerCodeType => {
  if (!data.data.contacts.length) {
    return null;
  }
  const result: ContactsByCustomerCodeType = {};
  const customers = data.data?.customers || [];
  const contacts = data.data?.contacts || [];
  contacts.forEach(contact => {
    if (!result[contact.customerCode]) {
      result[contact.customerCode] = {
        contacts: [],
        customer: null,
      };
      result[contact.customerCode].contacts.push(contact);
    } else {
      !result[contact.customerCode].contacts.some(item => item.code === contact.code) &&
        result[contact.customerCode].contacts.push(contact);
    }
  });

  customers.forEach(customer => {
    if (result[customer.code]) {
      if (!result[customer.code].customer) {
        result[customer.code].customer = mapClient(customer);
      }
    }
  });
  return result;
};

export const mapApiArray: <T, U>(data: T[], mapper: (T) => U) => U[] = (data, mapper) => {
  const result = [];
  if (!data || !data.length) {
    return result;
  }
  data.forEach(v => {
    result.push(mapper(v));
  });
  return result;
};

export const mapWarehouse = (wh: Warehouse): AppWarehouse => {
  return Object.assign(<AppWarehouse>{}, wh);
};
