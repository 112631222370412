import { Chart, DoughnutController } from 'chart.js';
export const DoughnutChartBackground = {
  id: 'doughnutChartBackground',
  beforeDatasetsDraw: (chart: Chart<'doughnut'>) => {
    const { ctx, width, height } = chart;
    const { doughnutBackground } = chart.config.options.plugins as any;
    const { innerRadius } = chart.getDatasetMeta(chart.data.datasets.length - 1).controller as DoughnutController;
    const { outerRadius } = chart.getDatasetMeta(0).controller as DoughnutController;
    const radiusLength = outerRadius - innerRadius;

    if (doughnutBackground?.enabled) {
      const x = width / 2,
        y = height / 2;

      ctx.beginPath();
      ctx.arc(x, y, outerRadius - radiusLength / 2, 0, 2 * Math.PI);
      ctx.lineWidth = radiusLength;
      ctx.strokeStyle = doughnutBackground.color;
      ctx.stroke();
    }
  },
};
