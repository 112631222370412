import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { v4 as uuidV4 } from 'uuid';

const axiosInstance = axios.create({
  timeout: 55021,
});

// Поле конфига для сохранения данных контекста
export const CUSTOM_CONFIG_CONTEXT_DATA = 'contextData';

axiosInstance.interceptors.request.use(
  config => {
    config[CUSTOM_CONFIG_CONTEXT_DATA] = new AxiosCallContext(config.url);
    return config;
  },
  error => {
    console.warn('backend api request error', error);
    return Promise.reject(error);
  }
);

export const setAxiosOfficeHeaders = (officeCode: string, axios: AxiosInstance): void => {
  if (officeCode) {
    const officeHeader = officeCode;
    setAxiosHeadersByName(axios, 'Branch-Office', officeHeader);
  } else {
    clearAxiosHeadersByName(axios, 'Branch-Office');
  }
};
export const setAxiosTabGuidHeaders = (tabGuid: string, axios: AxiosInstance): void => {
  if (tabGuid) {
    const tabGuidHeader = tabGuid;
    setAxiosHeadersByName(axios, 'Tab-Guid', tabGuidHeader);
  } else {
    clearAxiosHeadersByName(axios, 'Tab-Guid');
  }
};
const clearAxiosHeadersByName = (axios: AxiosInstance, nameHeader: string): void => {
  clearByNameHeader(axios.defaults.headers.get, nameHeader);
  clearByNameHeader(axios.defaults.headers.post, nameHeader);
  clearByNameHeader(axios.defaults.headers.put, nameHeader);
  clearByNameHeader(axios.defaults.headers.delete, nameHeader);
  clearByNameHeader(axios.defaults.headers.common, nameHeader);
};
const setAxiosHeadersByName = (axios: AxiosInstance, nameHeader: string, value: string): void => {
  setByNameHeader(axios.defaults.headers.get, nameHeader, value);
  setByNameHeader(axios.defaults.headers.post, nameHeader, value);
  setByNameHeader(axios.defaults.headers.put, nameHeader, value);
  setByNameHeader(axios.defaults.headers.delete, nameHeader, value);
  setByNameHeader(axios.defaults.headers.common, nameHeader, value);
};
const clearByNameHeader = (method: Record<string, unknown>, nameHeader: string): void => {
  method[nameHeader] = '';
  delete method[nameHeader];
};
const setByNameHeader = (method: Record<string, unknown>, nameHeader: string, value: string): void => {
  if (value) {
    method[nameHeader] = value;
  }
};

export class AxiosCallContext {
  startTime: Date;
  url: string;
  traceId: string;

  constructor(url: string) {
    this.url = url;
    this.startTime = new Date();
    this.traceId = uuidV4();
  }
}

export const getCallContext = (r: AxiosResponse): AxiosCallContext => {
  return (r.config[CUSTOM_CONFIG_CONTEXT_DATA] || {}) as AxiosCallContext;
};

export default axiosInstance;
