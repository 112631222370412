import { NextRouter } from 'next/router';
import { UrlObject } from 'url';
import { RootStore } from './StoreManager';
import { SnackbarStore } from './SnackbarStore';
import { ParsedUrlQuery } from 'querystring';

declare type Url = UrlObject | string;

export class RouterStore {
  router: NextRouter;
  snackbar: SnackbarStore;

  constructor(rootStore: RootStore) {
    this.snackbar = rootStore.getSnackbar();
  }

  asPath(): string {
    return this.router.asPath;
  }

  query(): ParsedUrlQuery {
    return this.router.query;
  }

  /**
   * Редирект
   * @param url
   */
  push(url: Url): void {
    this.router.push(url).catch((r: any): void => {
      console.warn('router.push r=', r, 'u=', url);
      this.snackbar.showError('Не удалось выполнить переход ' + url.toString());
    });
  }

  replace(url: Url, as?: Url, options?: any): void {
    this.router.replace(url, as, options).catch(r => console.warn('Router.replace', r));
  }

  setBaseRouter(router: NextRouter): void {
    this.router = router;
  }
}
