import { RootStore } from './StoreManager';
import { makeAutoObservable, runInAction } from 'mobx';
import { ApiStore } from './Global/ApiStore';
import { AuthStore } from './AuthStore';
import { ClientsItemResponse, ParticipantRelationship, ParticipantRelationshipPartner } from '../api/marketx';
import { ClientType } from '../components/Clients/ClientCard/types';
import { formatDateSwaggerZ } from '@mx-ui/helpers';
import { AxiosResponse } from 'axios';
import { mapClient } from '../views/clients/lib';

export class ClientRelationshipsStore {
  apiStore: ApiStore;
  authStore: AuthStore;
  customerDetails: ClientType = null;
  draftPartnerDetails: ClientType = null;
  relationshipTypes: ParticipantRelationship[] = [];
  relationshipTypesForClientAccess: ParticipantRelationship[] = [];
  isLoading = false;
  isLoaded = false;
  participantRelationshipList: ParticipantRelationshipPartner[] = [];
  branchOfficeFilterList: { title: string; code: string }[] = [];
  constructor(rootStore: RootStore) {
    this.apiStore = rootStore.getApiStore();
    this.authStore = rootStore.getAuth();
    makeAutoObservable(this, {
      apiStore: false,
      authStore: false,
    });
  }

  setCustomerDetails(customerDetails: ClientType): void {
    this.customerDetails = customerDetails;
  }

  loadDraftPartner(partnerCode: string): void {
    this.apiStore
      .apiClientCustomer()
      .clientsItem(partnerCode, formatDateSwaggerZ(new Date()))
      .then((res: AxiosResponse<ClientsItemResponse>): void => {
        this.setDraftResult(res.data);
      });
  }

  setDraftResult(res: ClientsItemResponse): void {
    if (!res.customer) return;
    const details = mapClient(res.customer);
    const currentRelationships = details?.relationships?.filter(r => r.branchOfficeCode === this.authStore.profile.chosenBranchOfficeCode);
    details.relationships = currentRelationships?.length ? currentRelationships : undefined;
    this.draftPartnerDetails = details;
  }

  loadParticipantRelationshipTypes(): void {
    this.apiStore
      .apiClientParticipant()
      .participantRelationshipTypes('for_partners')
      .then(res => {
        runInAction(() => {
          this.relationshipTypes = res.data.relationshipTypes;
        });
      })
      .catch(e => {
        console.warn('loadParticipantRelationshipTypesRequest', e);
      });
    this.apiStore
      .apiClientParticipant()
      .participantRelationshipTypes('for_link_partners')
      .then(res => {
        runInAction(() => {
          this.relationshipTypesForClientAccess = res.data.relationshipTypes;
        });
      })
      .catch(e => {
        console.warn('loadParticipantRelationshipTypesRequest', e);
      });
  }

  removeDraftPartner(): void {
    runInAction(() => {
      this.draftPartnerDetails = null;
    });
  }

  saveDraftPartner(customerCode: string, partnerCustomerCode: string, relationshipTypeCodes: string[], isDefault = true): Promise<void> {
    runInAction(() => {
      this.draftPartnerDetails = null;
      this.isLoading = true;
    });
    return this.apiStore
      .apiClientParticipant()
      .participantRelationshipPartnerSave({
        branchOfficeCode: this.authStore.profile?.chosenBranchOfficeCode || this.authStore.profile?.branchOfficeCode,
        customerCode,
        partnerCustomerCode,
        relationshipTypeCodes,
        isDefault,
      })
      .then(() => {
        this.loadParticipantRelationshipList(customerCode);
      })
      .catch(e => {
        console.warn('saveDraftPartnerRequest', e);
      });
  }

  deletePartner(relationshipCode: string): Promise<void> {
    return this.apiStore
      .apiClientParticipant()
      .participantRelationshipPartnerDelete(relationshipCode)
      .then(() => {
        this.loadParticipantRelationshipList(this.customerDetails.code);
      })
      .catch(e => {
        console.warn('deletePartnerRequest', e);
      });
  }

  loadParticipantRelationshipList(customerCode?: string): void {
    runInAction(() => {
      this.isLoading = true;
    });
    this.apiStore
      .apiClientParticipant()
      .participantRelationshipPartners(customerCode || this.customerDetails.code)
      .then(res => {
        this.setParticipantRelationshipListResult(res.data.partners);
      })
      .catch(e => {
        console.warn('loadParticipantRelationshipListRequest', e);
      });
  }
  setParticipantRelationshipListResult(partners: ParticipantRelationshipPartner[]): void {
    this.participantRelationshipList = [...partners];
    this.branchOfficeFilterList = [];
    partners.forEach(p => {
      if (!this.branchOfficeFilterList.some(i => i.code === p.branchOfficeCode)) {
        this.branchOfficeFilterList.push({ code: p.branchOfficeCode, title: p.branchOfficeName });
      }
    });
    if (!this.branchOfficeFilterList.some(i => i.code === this.authStore.profile.chosenBranchOfficeCode)) {
      this.branchOfficeFilterList.push({
        code: this.authStore.profile.chosenBranchOfficeCode,
        title: this.authStore.profile.chosenBranchOfficeName,
      });
    }
    if (this.branchOfficeFilterList.length > 1) {
      this.branchOfficeFilterList.push({
        code: 'all',
        title: 'Все филиалы',
      });
    }
    this.isLoading = false;
    this.isLoaded = true;
  }
}
