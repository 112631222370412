import { AxiosPromise } from 'axios';
import { makeAutoObservable, runInAction } from 'mobx';
import { ClientsChangeRequestCreateRequest } from 'src/api/marketx';
import { ApiStore } from './Global/ApiStore';
import { SnackbarStore } from './SnackbarStore';
import { RootStore } from './StoreManager';

export class ChangeRequestStore {
  apiStore: ApiStore;
  snackbarStore: SnackbarStore;
  hasDaneRequest = false;

  constructor(rootStore: RootStore) {
    this.snackbarStore = rootStore.getSnackbar();
    this.apiStore = rootStore.getApiStore();
    makeAutoObservable(this, {
      apiStore: false,
    });
  }
  send(request: ClientsChangeRequestCreateRequest): AxiosPromise<ClientsChangeRequestCreateRequest> {
    return this.apiStore
      .apiClientCustomer()
      .clientsChangeRequestsCreate(request)
      .then(res => {
        runInAction(() => {
          this.hasDaneRequest = true;
        });
        return res;
      });
  }
}
