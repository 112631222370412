import { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { AppDealPosition } from '../../../slices/AppDeal';
import Typography from '@mui/material/Typography';
import { PricesTable } from 'src/components/Product/PricesTable';
import { ContractCostPosition } from '../../../api/marketx';
import { Grid } from '@mui/material';
import { TabSelectWrapper } from '../../../components/Deals/CustomIconTabs/TabSelectWrapper';

interface DealPositionAdditionalTabPricesProps {
  dealPosition?: AppDealPosition;
  agreementPosition?: ContractCostPosition;
}

// Закладка с информацией о товаре в строке товара в сделке
export const DealPositionAdditionalTabPrices: FC<DealPositionAdditionalTabPricesProps> = observer(
  ({ dealPosition, agreementPosition }): JSX.Element => {
    return (
      <TabSelectWrapper>
        <Grid container className={'withCustomScroll'} sx={{ height: '100%' }}>
          <Typography variant="h5" gutterBottom component="div">
            Цены
          </Typography>
          <PricesTable value={dealPosition?.product?.prices || agreementPosition?.product?.prices} />
        </Grid>
      </TabSelectWrapper>
    );
  }
);
