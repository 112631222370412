import React, { FC } from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ViewListIcon from '@mui/icons-material/ViewList';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import ViewHeadlineIcon from '@mui/icons-material/ViewHeadline';
import GridOnIcon from '@mui/icons-material/GridOn';

export type ViewModeType = 'module' | 'list' | 'list-shortcut' | 'table-view';
export type TypeTitle =
  | 'clients'
  | 'deals'
  | 'bills'
  | 'shipments'
  | 'batches'
  | 'agreements'
  | 'returns'
  | 'approvals'
  | 'refusals'
  | 'freeze';

interface ViewModeToogleProps {
  value: ViewModeType;
  type?: TypeTitle;
  onChange: (event: React.MouseEvent<HTMLElement>, newView: string) => void;
}

const ViewModeToogle: FC<ViewModeToogleProps> = ({ value = 'list', onChange, type = 'clients' }): JSX.Element => {
  let entityTitle = '';
  switch (type) {
    case 'clients':
      entityTitle = 'клиентов';
      break;
    case 'deals':
      entityTitle = 'заявки';
      break;
    case 'bills':
      entityTitle = 'счета';
      break;
    case 'shipments':
      entityTitle = 'отгрузки';
      break;
    case 'batches':
      entityTitle = 'партии';
      break;
    case 'agreements':
      entityTitle = 'соглашения';
      break;
    case 'returns':
      entityTitle = 'возвраты';
      break;
    case 'approvals':
      entityTitle = 'документы';
      break;
    case 'refusals':
      entityTitle = 'отказы';
      break;
    case 'freeze':
      entityTitle = 'фризы';
      break;
    default:
      console.warn('Переданный type не подходит для ViewModeToogle');
      break;
  }

  return (
    <ToggleButtonGroup size="small" exclusive value={value} onChange={onChange}>
      {type === 'clients' && (
        <ToggleButton title={entityTitle ? `Показать ${entityTitle} блоками` : null} value="module" aria-label="module">
          <ViewModuleIcon />
        </ToggleButton>
      )}
      <ToggleButton title={entityTitle ? `Показать ${entityTitle} списком` : null} value="list" aria-label="list">
        <ViewListIcon />
      </ToggleButton>
      {type !== 'clients' && (
        <ToggleButton
          title={entityTitle ? `Показать ${entityTitle} сокращенным списком` : null}
          value="list-shortcut"
          aria-label="list-shortcut"
        >
          <ViewHeadlineIcon />
        </ToggleButton>
      )}
      {type !== 'clients' && (
        <ToggleButton title={entityTitle ? `Показать ${entityTitle} в табличном виде` : null} value="table-view" aria-label="table-view">
          <GridOnIcon />
        </ToggleButton>
      )}
    </ToggleButtonGroup>
  );
};

export { ViewModeToogle };
