import React, { FC, useState } from 'react';
import { observer } from 'mobx-react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import LinearProgress from '@mui/material/LinearProgress';
import { createAgreement } from '../lib';
import { useRouter } from 'next/router';
import { v4 as uuidV4 } from 'uuid';
import { useRootStore } from '../../../store/MobxStoreProvider';

interface CreateAgreementFromDealButtonProps {
  dealCode: string;
}

const CreateAgreementFromDealButton: FC<CreateAgreementFromDealButtonProps> = observer(({ dealCode }): JSX.Element => {
  const router = useRouter();
  const apiStore = useRootStore().getApiStore();

  const [isCreateAgreement, setIsCreateAgreement] = useState(false);
  const [frontCode] = useState(uuidV4());
  const [confirmationOpen, setConfirmationOpen] = useState(false);

  const handleClickConfirmationOpen = (): void => {
    setConfirmationOpen(true);
  };
  const handleConfirmationClose = (): void => {
    setConfirmationOpen(false);
  };
  const handleConfirmationConfirm = (): void => {
    setIsCreateAgreement(true);

    const createAgreementAction = createAgreement(apiStore, frontCode, dealCode);
    createAgreementAction.catch(e => {
      console.error(e);
      setConfirmationOpen(false);
      setIsCreateAgreement(false);
    });
    createAgreementAction.then((res): void => {
      setConfirmationOpen(false);
      setIsCreateAgreement(false);
      router.push(`/app/agreements/` + encodeURIComponent(res.data.agreementCode));
    });
  };

  return (
    <>
      <div onClick={handleClickConfirmationOpen}>Создать соглашение</div>

      <Dialog
        open={confirmationOpen}
        onClose={handleConfirmationClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Создать соглашение?'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <span>Будет создано новое соглашение на основе заявки {dealCode}.</span>
          </DialogContentText>
          {isCreateAgreement && <LinearProgress />}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmationClose} color="primary" disabled={isCreateAgreement}>
            Отмена
          </Button>
          <Button onClick={handleConfirmationConfirm} color="primary" autoFocus disabled={isCreateAgreement}>
            Создать
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
});
export { CreateAgreementFromDealButton };
