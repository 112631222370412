export type PlotPoint = {
  x: number;
  y: number;
};

class PlotChangeInValue {
  value?: number;
  unit?: string;
}

export type Plot = {
  changeInValue?: PlotChangeInValue;
  name: string;
  loaded: boolean;
  xaxis?: PlotAxis;
  yaxis?: PlotAxis;
  points: PlotPoint[];
  middleVal?: number;
  firstPositiveY?: number;
  lastPositiveY?: number;
  lastPositiveX?: number;
};

export type PlotAxis = {
  title?: string;
  minTicks?: number;
  maxTicks?: number;
};

export type TimestampDataPoint = {
  t: number;
  y: number;
  i: number;
};

export const plotToTimestampSeries = (plot: Plot): TimestampDataPoint[] => {
  const dataPoints: TimestampDataPoint[] = [];
  plot?.points?.forEach((point: PlotPoint, index): void => {
    const lastPointX = point.x * 1000; // seconds to ms
    dataPoints.push({
      t: lastPointX,
      y: point.y,
      i: index,
    });
  });
  return dataPoints;
};

export const generateDashesArr = (points: TimestampDataPoint[]): number[] => {
  const dashes: number[] = [];
  points.forEach((): void => {
    dashes.push(2);
  });
  return dashes;
};
export type TimestampPaymentDataPoint = {
  t: string | number;
  y: number;
  i: number;
};
export const plotToTimestampPaymentSeries = (plot: Plot): TimestampPaymentDataPoint[] => {
  const dataPoints: TimestampPaymentDataPoint[] = [];
  plot?.points.forEach((point: PlotPoint, index): void => {
    dataPoints.push({
      t: point.x * 1000,
      y: point.y,
      i: index,
    });
  });
  return dataPoints;
};
