import React, { FC, JSX, useCallback, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { CorrectionShipment, SaleShipment } from '../../api/marketx';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { formatDateSwagger, formatNumber3 } from '@mx-ui/helpers';
import { ThreadLink } from '../Bills/ThreadLink';
import { renderPriceCur } from '../../utils/renderFormat';
import CardTravelOutlinedIcon from '@mui/icons-material/CardTravelOutlined';
import { ShipmentsListStore } from '../../store/Documents/ShipmentsListStore';
import IconButton from '@mui/material/IconButton';
import DoDisturbOnOutlinedIcon from '@mui/icons-material/DoDisturbOnOutlined';
import FilterListIcon from '@mui/icons-material/FilterList';
import { Link as MxLink } from '@mx-ui/ui';
import useDocumentsPath from '../../hooks/useDocumentsPath';
import Box from '@mui/material/Box';
import { ColorChangedPropertyDeal } from '../../views/deals/DealContainer';
import LightTooltip from '../ToolTip/LightTooltip';

interface ShipmentItemTableViewProps {
  shipment: SaleShipment & CorrectionShipment;
  shipmentsListStore?: ShipmentsListStore;
  isCorrection?: boolean;
}

export const ShipmentItemTableView: FC<ShipmentItemTableViewProps> = observer(
  ({ shipment, shipmentsListStore, isCorrection }): JSX.Element => {
    const [tooltipType, setTooltipType] = useState('');
    const { asQueryPath } = useDocumentsPath('shipments');
    const handleCustomerChange = useCallback(
      (customerCode?: string) => {
        if (shipmentsListStore.request?.customerCodes?.includes(customerCode)) {
          return;
        }
        shipmentsListStore.mergeRequest({ customerCodes: customerCode ? [customerCode] : undefined });
      },
      [shipmentsListStore]
    );

    const handleCustomerRemoveChange = useCallback(
      (customerCode?: string) => {
        if (!shipmentsListStore.request?.customerCodes?.includes(customerCode)) {
          return;
        }
        const newCustomerCodes = shipmentsListStore.request?.customerCodes?.filter(i => i !== customerCode);
        shipmentsListStore.mergeRequest({ customerCodes: newCustomerCodes });
      },
      [shipmentsListStore]
    );

    const totalCostOld = shipment.positionsTotalCostOld;
    const totalCost = shipment.positionsTotalCost;

    const quantityTheReduceOld = shipment.positionsTotalWeightOld / 1000;
    const quantityTneReduce = shipment.positionsTotalWeight / 1000;
    return (
      <Paper sx={{ p: 1, mb: 1.5, width: '100%' }}>
        <Grid container alignItems="center" justifyContent={'space-between'} spacing={1}>
          <Grid item flex={'0 0 400px'}>
            <Grid container alignItems="center" flexWrap={'nowrap'}>
              <Grid item mr={1}>
                <CardTravelOutlinedIcon fontSize="medium" color="primary" />
              </Grid>
              <Grid item>
                <MxLink
                  href={`/app/shipments${isCorrection ? '/correction' : ''}/${encodeURIComponent(shipment.documentNumber)}?${asQueryPath}`}
                  color="primary"
                  typographySx={{ fontWeight: 600 }}
                >
                  {`${shipment.documentNumber}`}
                </MxLink>
              </Grid>

              <Grid item>
                <ThreadLink color="primary" link={`/app/thread/shipments/${encodeURIComponent(shipment.documentNumber)}`} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item flex={'0 1 220px'} textAlign={'left'}>
            <Typography color="text.secondary">{' от ' + formatDateSwagger(shipment.documentDate)}</Typography>
          </Grid>
          <Grid
            item
            flex={'0 1 90px'}
            textAlign={'right'}
            onMouseEnter={() => setTooltipType('quantityTneReduce')}
            onMouseLeave={() => setTooltipType('')}
          >
            <LightTooltip
              placement="right"
              open={tooltipType === 'quantityTneReduce'}
              title={
                !isNaN(quantityTheReduceOld) && !isNaN(quantityTneReduce) && quantityTheReduceOld !== quantityTneReduce ? (
                  <Box p={0.6}>
                    <Typography variant="subtitle2" component="span" fontWeight={500}>
                      Старое значение: {formatNumber3(quantityTheReduceOld)} т
                    </Typography>
                  </Box>
                ) : undefined
              }
              arrow
            >
              <Typography
                variant="body2"
                color="text.secondary"
                sx={
                  !isNaN(quantityTheReduceOld) && !isNaN(quantityTneReduce) && quantityTheReduceOld !== quantityTneReduce
                    ? {
                        backgroundColor: ColorChangedPropertyDeal,
                        px: 0.5,
                        borderRadius: '5px',
                        display: 'inline-block',
                      }
                    : {}
                }
              >
                {formatNumber3(quantityTneReduce)} т
              </Typography>
            </LightTooltip>
          </Grid>
          <Grid
            item
            flex={'0 1 25%'}
            textAlign={'right'}
            onMouseEnter={() => setTooltipType('totalCost')}
            onMouseLeave={() => setTooltipType('')}
          >
            <LightTooltip
              placement="right"
              open={tooltipType === 'totalCost'}
              title={
                !isNaN(totalCostOld) && !isNaN(totalCost) && totalCostOld !== totalCost ? (
                  <Box p={0.6}>
                    <Typography variant="subtitle2" component="span" fontWeight={500}>
                      Старое значение: {renderPriceCur(totalCostOld, '₽')}
                    </Typography>
                  </Box>
                ) : undefined
              }
              arrow
            >
              <Typography
                fontWeight={700}
                color="textPrimary"
                sx={
                  !isNaN(totalCostOld) && !isNaN(totalCost) && totalCostOld !== totalCost
                    ? { backgroundColor: ColorChangedPropertyDeal, px: 0.5, borderRadius: '5px', display: 'inline-block' }
                    : {}
                }
              >
                {renderPriceCur(totalCost, '₽')}
              </Typography>
            </LightTooltip>
          </Grid>
          {shipment.recipientCustomer && (
            <Grid item xs={12} mt={1}>
              <Grid container alignItems={'center'}>
                <Grid item xs={12} md={'auto'} mr={1}>
                  <Typography variant="body2">ИНН {shipment.recipientCustomer?.inn || '—'}</Typography>
                </Grid>
                <Grid item xs={12} md={'auto'}>
                  <MxLink
                    href={`/app/clients/${encodeURIComponent(shipment.recipientCustomer?.code)}`}
                    color="text.secondary"
                    typographySx={{ fontSize: 15, fontWeight: 600 }}
                  >
                    {shipment.recipientCustomer?.shortTitle || shipment.recipientCustomer?.title}
                  </MxLink>
                </Grid>
                {shipmentsListStore && shipment.recipientCustomer.code ? (
                  shipmentsListStore.request?.customerCodes?.includes(shipment.recipientCustomer.code) ? (
                    <Grid item ml={1}>
                      <IconButton
                        size={'small'}
                        onClick={() => handleCustomerRemoveChange(shipment.recipientCustomer.code)}
                        title={`Убрать фильтрацию по ${shipment.recipientCustomer.shortTitle || shipment.recipientCustomer.title}`}
                      >
                        <DoDisturbOnOutlinedIcon color={'primary'} sx={{ fontSize: '20px' }} />
                      </IconButton>
                    </Grid>
                  ) : (
                    <Grid item ml={1}>
                      <IconButton
                        size={'small'}
                        onClick={() => handleCustomerChange(shipment.recipientCustomer.code)}
                        title={`Найти отгрузки ${shipment.recipientCustomer.shortTitle || shipment.recipientCustomer.title}`}
                      >
                        <FilterListIcon color={'primary'} sx={{ fontSize: '20px' }} />
                      </IconButton>
                    </Grid>
                  )
                ) : null}
              </Grid>
            </Grid>
          )}
        </Grid>
      </Paper>
    );
  }
);
