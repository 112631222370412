import { numberWithSpaces, roundNumber } from '@mx-ui/helpers';

export const displayNumber = (value: number | any, defaultIfZeo?: string): string => {
  if (value * 1 !== 0 && !value) {
    if (defaultIfZeo) return defaultIfZeo;
    return '0';
  } else if (value * 1 === 0) {
    if (defaultIfZeo) return defaultIfZeo;
    return '0';
  } else {
    return numberWithSpaces(roundNumber(value));
  }
};
export const getMarginalityColorRUB = (value: number | any): string => {
  const num = value * 1;
  if (isNaN(num) || num < 0) {
    return '#EE505A';
  }

  return '#19B28D';
};

export const getMarginalityColor = (value: number | any): string => {
  const num = value * 1;
  if (isNaN(num) || num < 0) {
    return '#EE505A';
  }

  if (num == 0) {
    return '#CCCCCC';
  }

  if (num <= 5) {
    return '#F88545';
  }

  return '#19B28D';
};

export const getRusDate = (date: string): string => {
  date = date || '';
  const year = date.substr(0, 4);
  const month = date.substr(5, 2);
  const day = date.substr(8, 2);

  return `${day}.${month}.${year}`;
};
