import React, { FC, JSX, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { CustomerMarginalityChart } from '../../../components/charts/CustomerMarginalityChart';
import { DealItemStore } from '../../../store/DealItemStore';
import { useRootStore } from '../../../store/MobxStoreProvider';
import Typography from '@mui/material/Typography';
import { formatPrice02 } from '@mx-ui/helpers';
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import { TabSelectWrapper } from '../../../components/Deals/CustomIconTabs/TabSelectWrapper';

interface DealMarginalityChartProps {
  dealStore: DealItemStore;
  minHeightForTabSections: string;
}

export const DealMarginalityChart: FC<DealMarginalityChartProps> = observer(({ dealStore, minHeightForTabSections }): JSX.Element => {
  const customerMarginalityPlot = useRootStore().getCustomerMarginalityChartsDataStore();
  useEffect(() => {
    if (dealStore.deal?.customerCode && dealStore?.dealCode) {
      customerMarginalityPlot.loadPlot(dealStore.deal?.customerCode, dealStore?.dealCode);
    }
  }, [dealStore, dealStore.deal?.customerCode, dealStore?.dealCode]);
  return (
    <TabSelectWrapper>
      <Grid container minHeight={minHeightForTabSections}>
        <Grid item xs={12}>
          {' '}
          <Grid container>
            <Grid item xs={12}>
              <Grid container alignItems={'center'} spacing={1} sx={{ paddingBottom: '10px', position: 'relative' }}>
                <Grid item>
                  <Typography style={{ fontSize: '14px' }}>Маржинальность сделок, %</Typography>
                </Grid>
                <Grid item>
                  {customerMarginalityPlot.plot?.middleVal && (
                    <Typography style={{ fontSize: '14px', fontStyle: 'italic' }}>
                      Средняя {formatPrice02(customerMarginalityPlot.plot?.middleVal)} %
                    </Typography>
                  )}
                </Grid>
                <Grid position={'absolute'} sx={{ width: '100%', bottom: '-5px', left: '0px' }}>
                  {!customerMarginalityPlot.isLoaded && <LinearProgress title={'Идет сохранение...'} />}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <CustomerMarginalityChart currentPct={dealStore.deal?.marginalityPct} height={156} plotStore={customerMarginalityPlot} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </TabSelectWrapper>
  );
});
