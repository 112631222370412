import { makeAutoObservable, runInAction } from 'mobx';
import { MsgType } from './WebSocketStore';
import { RootStore } from '../StoreManager';
import { DevPanelStore } from './DevPanelStore';

export interface IncomeMessageOf<T> {
  channel?: string;
  msgType: string;
  data: T;
}

export type IncomeMessage = IncomeMessageOf<any>;

export interface SubscribeCallback {
  (msg: IncomeMessage): void;
}

/**
 * Факт подписки на событие.
 * Используется для удаления подписки
 */
export class Subscribed {
  msgType: string;
  handler: SubscribeCallback;
}

export class EventManager {
  devPanel: DevPanelStore;

  handlers = new Map<string, SubscribeCallback[]>();
  handlersCount = 0;

  /**
   * Этот массив перезаписывается при получении новых данных
   */
  lastMessages: IncomeMessage[] = [];
  lastMessagesEpoch = 0;

  constructor(rootStore: RootStore) {
    this.devPanel = rootStore.getDevPanel();
    makeAutoObservable(this, {
      devPanel: false,
      handlers: false,
      lastMessages: false,
    });
  }

  setHandlersCount(cnt: number): void {
    runInAction(() => {
      this.handlersCount = cnt;
      this.devPanel.setKV('wsHandlersCount', cnt);
    });
  }

  subscribe(msgType: string, handler: SubscribeCallback): Subscribed {
    const handlers = this.handlers.get(msgType);
    if (!handlers) {
      this.handlers.set(msgType, [handler]);
    } else {
      handlers.push(handler);
    }
    this.setHandlersCount(this.handlersCount + 1);
    return <Subscribed>{
      msgType: msgType,
      handler: handler,
    };
  }

  unsubscribe(s: Subscribed): void {
    const handlers = this.handlers.get(s.msgType);
    const index = handlers.indexOf(s.handler, 0);
    if (index > -1) {
      this.setHandlersCount(this.handlersCount - 1);
      handlers.splice(index, 1);
    }
  }

  processMessages(...messages: IncomeMessage[]): void {
    runInAction(() => {
      this.lastMessages.splice(0);
      this.lastMessages.push(...messages);
      this.lastMessagesEpoch++;
      messages.forEach(msg => {
        const all = [];
        const handlers = this.handlers.get(msg.msgType);
        if (handlers) {
          all.push(...handlers);
        }
        const wildcards = this.handlers.get(MsgType.WILDCARD);
        if (wildcards) {
          all.push(...wildcards);
        }
        if (all.length) {
          // Возможно стоит обернуть try/catch чтобы обработчики не влияли друг на друга
          all.forEach(cb => cb(msg));
        }
      });
    });
  }
}
