import React, { FC, JSX, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { AppDealPosition } from '../../../slices/AppDeal';
import { DealItemStore } from '../../../store/DealItemStore';
import { formatPrice } from '@mx-ui/helpers';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import PublishedWithChangesOutlinedIcon from '@mui/icons-material/PublishedWithChangesOutlined';
import {
  CatalogProductsAlternativesNomenclature,
  CatalogProductsAlternativesPositionProduct,
  CatalogProductsAlternativesSegmentationGroup,
} from '../../../api/marketx';
import { ProductIndicatorsByValueForAccordion } from '../../catalog/Listing/ProductPeculiaritiesIndicators';
import { multicoloredPopoverBg } from '../../clients/lib';
import Box from '@mui/material/Box';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import Divider from '@mui/material/Divider';
import { PositionsManufacturersListStore } from '../../../store/Deals/PositionsManufacturersListStore';
import Skeleton from '@mui/material/Skeleton';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ProductLinkBtn } from '../../../components/Product/ProductLinkBtn/ProductLinkBtn';
import AccordionDetails from '@mui/material/AccordionDetails';
import Button from '@mui/material/Button';
import { findQuantityFromList } from './DealAlternativeProducts';
import AddShoppingCartRoundedIcon from '@mui/icons-material/AddShoppingCartRounded';
import { alternativeInTransitText } from './AlternativeProductsTableForCatalog';

interface DealAlternativeAccordionProps {
  manufacturersStore: PositionsManufacturersListStore;
  alternativeObj: CatalogProductsAlternativesSegmentationGroup | CatalogProductsAlternativesNomenclature;
  dealStore: DealItemStore;
  position: AppDealPosition;
  readOnlyList: boolean;
  canViewPurchasePrice: boolean;
}
export const DealAlternativeAccordion: FC<DealAlternativeAccordionProps> = observer(
  ({ alternativeObj, dealStore, position, readOnlyList, canViewPurchasePrice, manufacturersStore }): JSX.Element => {
    return (
      <Grid item xs={12} container>
        <Grid item xs={12} p={1}>
          <Typography component="span" variant="body2" fontWeight={500}>
            {/* @ts-ignore*/}
            {alternativeObj?.name || alternativeObj?.title || ''}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        {(!manufacturersStore.loaded || manufacturersStore.loading) && (
          <Grid item xs={12} container minWidth={'80vw'}>
            <Grid item xs={12} container alignItems={'center'} flexWrap={'nowrap'}>
              <Skeleton width={'100%'} height={'60px'} />
            </Grid>
            <Grid item xs={12} container alignItems={'center'} flexWrap={'nowrap'}>
              <Skeleton width={'100%'} height={'60px'} />
            </Grid>
            <Grid item xs={12} container alignItems={'center'} flexWrap={'nowrap'}>
              <Skeleton width={'100%'} height={'60px'} />
            </Grid>
          </Grid>
        )}
        {manufacturersStore.loaded && !alternativeObj?.products?.length && (
          <Typography sx={{ py: 1, px: 2, mb: 1 }} variant="body2" component="div">
            Альтернативных товаров нет
          </Typography>
        )}
        {manufacturersStore.loaded &&
          alternativeObj?.products?.length &&
          alternativeObj?.products.map((product, index) => (
            <React.Fragment key={product?.productCode || index}>
              <DealAlternativeAccordionItem
                dealStore={dealStore}
                product={product}
                position={position}
                canViewPurchasePrice={canViewPurchasePrice}
                withChangeBtn={!readOnlyList || alternativeObj?.products?.length > 1 || product.productCode !== position.productCode}
              />
            </React.Fragment>
          ))}
      </Grid>
    );
  }
);

interface DealAlternativeAccordionItemProps {
  dealStore: DealItemStore;
  product: CatalogProductsAlternativesPositionProduct;
  position: AppDealPosition;
  canViewPurchasePrice: boolean;
  withChangeBtn: boolean;
}

export const DealAlternativeAccordionItem: FC<DealAlternativeAccordionItemProps> = observer(
  ({ product, position, canViewPurchasePrice, dealStore, withChangeBtn }): JSX.Element => {
    const isViewOnly = dealStore.isViewOnly();
    const isCurrent = product.productCode === position.productCode;
    const { colorForStock, titleForStock } = findQuantityFromList(position, product, position.unitCode);
    const isPositionInList = Boolean(
      dealStore.deal.positions.filter(i => i.productCode === product?.productCode && i.productCode !== position?.product?.code).length
    );
    const handlePositionManufacturerChange = useCallback((): void => {
      dealStore.replacePositionProduct(position?.code, product?.productCode).catch(r => console.warn('replacePositionProduct', r));
    }, [dealStore, position]);
    const handlePositionAdd = useCallback((): void => {
      dealStore
        .addPosition(
          { ...product, code: product.productCode },
          1,
          position.calculatedBareUnitCost,
          undefined,
          position.unitCode,
          dealStore.deal.warehouseCode
        )
        .catch(r => console.warn('DealAlternativeProductItem addPosition', r));
    }, [dealStore, product, position]);
    return (
      <Accordion
        sx={{
          width: '100%',
          margin: '0px !important',
          boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%)',
          ':before': {
            display: 'none',
          },
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{
            borderTop: '1px solid #DBDBDB',
            background: theme => (isCurrent ? (theme.palette.mode === 'dark' ? '#090909' : '#EDEEF5') : 'inherit'),
          }}
        >
          <Grid container sx={{ position: 'relative' }}>
            <Grid item xs={12} container alignItems={'center'}>
              <Grid item>
                <Typography component="span" variant="caption">
                  {`${product?.productCode || ''} ${product.productTitle}`}
                </Typography>
              </Grid>
              <Grid item>
                <Grid container alignItems={'center'} spacing={1}>
                  <Grid item>
                    <Box
                      display="flex"
                      sx={{
                        borderRadius: 1,
                        mr: 0.5,
                        height: '20px',
                        ...multicoloredPopoverBg(colorForStock),
                      }}
                    >
                      <Typography variant="caption" color="inherit" fontWeight="500">
                        {titleForStock}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item>
                    {((product?.inTransitQuantity || product?.distributedQuantity) && (
                      <Grid item>
                        <Box
                          display="flex"
                          sx={{
                            borderRadius: 1,
                            mr: 0.5,
                            height: '20px',
                            backgroundColor: '#D6D6D6',
                            border: '1px solid #242424',
                          }}
                        >
                          <Typography variant="caption" sx={{ color: '#333' }} fontWeight="500">
                            {alternativeInTransitText(
                              product?.inTransitQuantity,
                              product?.distributedQuantity,
                              product?.stockUnitCode,
                              product?.stockUnitShortName
                            )}
                          </Typography>
                        </Box>
                      </Grid>
                    )) ||
                      null}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <ProductLinkBtn productCode={position.productCode} />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <ProductIndicatorsByValueForAccordion
                isNew={product?.batches && !!product.isNew}
                isOld={product?.batches && !!product.isOld}
                hasCustody={!!product.hasCustody}
                hasSelfPurchase={!!product.hasSelfPurchase}
                hasTodh={!!product.hasTodhDiscount}
                hasIlliquid={!!product.hasIlliquid}
              />
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems={'center'} justifyContent="space-between" mb={1}>
            <Grid item></Grid>
            {withChangeBtn && !isCurrent && !isPositionInList && !isViewOnly && (
              <Grid item>
                <Grid container>
                  <Grid item mr={2}>
                    <Button
                      variant="contained"
                      color="secondary"
                      title={'Подменить товар'}
                      size={'small'}
                      onClick={handlePositionManufacturerChange}
                      startIcon={<PublishedWithChangesOutlinedIcon sx={{ fontSize: '20px' }} />}
                    >
                      Подменить
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="secondary"
                      title={'Добавить товар'}
                      size={'small'}
                      onClick={handlePositionAdd}
                      startIcon={<AddShoppingCartRoundedIcon sx={{ fontSize: '20px' }} />}
                    >
                      Добавить
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
          <Divider />
          <Grid container alignItems={'center'} justifyContent="space-between">
            <Grid item>
              <Typography fontSize={'12px'}>Цена закупки</Typography>
            </Grid>
            <Grid item>
              {!!canViewPurchasePrice ? (
                <Typography component="span" variant="caption">
                  {`${formatPrice(product?.purchasePrice)} ₽`}
                </Typography>
              ) : (
                <VisibilityOffOutlinedIcon />
              )}
            </Grid>
          </Grid>
          <Divider />
          <Grid container alignItems={'center'} justifyContent="space-between">
            <Grid item>
              <Typography fontSize={'12px'}>МРЦ</Typography>
            </Grid>
            <Grid item>
              <Typography component="span" variant="caption">
                {`${formatPrice(product?.minRetailUnitCost)} ₽`}
              </Typography>
            </Grid>
          </Grid>
          <Divider />
          <Grid container alignItems={'center'} justifyContent="space-between">
            <Grid item>
              <Typography fontSize={'12px'}>Срок хранения</Typography>
            </Grid>
            <Grid item>
              <Typography component="span" variant="caption">
                {`${product?.oldShelfLife || '-'}`}
              </Typography>
            </Grid>
          </Grid>
          {product.batches?.length && <Divider />}
          {product.batches?.length
            ? product.batches.map((batch, batchIndex) => {
                return (
                  <Grid container key={batch.code}>
                    <Grid item xs={12} container alignItems={'center'} justifyContent="space-between" pt={1}>
                      <Grid item>
                        <Typography component="span" variant="body2" fontWeight={500}>
                          {batch.code || ''}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <ProductIndicatorsByValueForAccordion
                          hasCustody={!!batch.hasCustody}
                          hasSelfPurchase={!!batch.hasSelfPurchase}
                          hasTodh={!!batch.hasTodhDiscount}
                          hasIlliquid={!!batch.hasIlliquid}
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={12} container alignItems={'center'} justifyContent="space-between" pl={2}>
                      <Grid item>
                        <Typography fontSize={'12px'}>Цена закупки</Typography>
                      </Grid>
                      <Grid item>
                        {!!canViewPurchasePrice ? (
                          <Typography component="span" variant="caption">
                            {`${formatPrice(batch?.purchasePrice)} ₽`}
                          </Typography>
                        ) : (
                          <VisibilityOffOutlinedIcon />
                        )}
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                    <Grid item xs={12} container alignItems={'center'} justifyContent="space-between" pl={2}>
                      <Grid item>
                        <Typography fontSize={'12px'}>Срок хранения</Typography>
                      </Grid>
                      <Grid item>
                        <Typography component="span" variant="caption">
                          {`${batch?.shelfLife || '-'}`}
                        </Typography>
                      </Grid>
                    </Grid>
                    {batchIndex !== product.batches.length - 1 && (
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                    )}
                  </Grid>
                );
              })
            : null}
        </AccordionDetails>
      </Accordion>
    );
  }
);
