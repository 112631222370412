import { makeAutoObservable } from 'mobx';
import { quickDateRanges, quickDateRangesTypes, RangeVariant } from 'src/components/SelectDateRange/MenuButtonNew';
import { TasksStore, TasksStoreRequest } from './TasksStore';
const defaultQuickRangeValue = 'not_selected' as const;
const defaultQuickDate = quickDateRanges.find(t => t.value === defaultQuickRangeValue);
const selectedFiltersTypes = <const>[
  'employees-a',
  'assignedToMe-b',
  'expiresTomorrow-b',
  'expiresToday-b',
  'overdue-b',
  'employeeSet',
  ...quickDateRangesTypes.map(i => `dataRange-i:${i}` as const),
];

export type SelectedFilters = (typeof selectedFiltersTypes)[number];
export class FilterForTaskStore {
  taskStore: TasksStore = null;
  overdue = false;
  employeeCodes: string[] = [];
  employeeSetCode = '';
  expiresToday = false;
  labelCodes: string[] = [];
  statusCodes: string[] = [];
  filterCode: 'all' | 'initiator' | 'executor' = 'executor';
  quickRange: RangeVariant = defaultQuickDate;
  expiresTomorrow = false;
  branchOfficeCodes: string[] = [];
  constructor(taskStore: TasksStore) {
    this.taskStore = taskStore;

    makeAutoObservable(this, { taskStore: false });
    // this.syncAndRequest(taskStore);
  }
  /**
   * созависимые фильтры, т.к. одно значение может отправляться под разными фильтрами
   * @returns фильтры для урла
   * @example `assignedToMe` и `employeeCodes` отправляют на бек фильтр employeeCodes
   * @example `quickRange` и `expiresTomorrow` и `expiresToday` и `overdue` отправляют на бек фильтры deadlineFrom deadlineTo
   */
  selectedDependentFilters(): string[] {
    const selectedFilters: SelectedFilters[] = [];
    //* выбранные фильтры для executorCodes
    if (this.employeeCodes.length) {
      selectedFilters.push('employees-a');
    }
    //* выбранные фильтры для deadlineFrom deadlineTo
    if (this.quickRange.value !== 'not_selected') {
      selectedFilters.push(`dataRange-i:${this.quickRange.value}`);
    }
    if (this.overdue) {
      selectedFilters.push('overdue-b');
    }
    if (this.expiresTomorrow) {
      selectedFilters.push('expiresTomorrow-b');
    }
    if (this.expiresToday) {
      selectedFilters.push('expiresToday-b');
    }
    if (this.employeeSetCode) {
      selectedFilters.push('employeeSet');
    }
    return selectedFilters;
  }
  /**
   * тут уже собираем из урла фильтры (обратная логика selectedDependentFilters)
   */
  setFilterLocal(store: TasksStore): void {
    this.branchOfficeCodes = store.branchOfficeByItems.map(i => i.value);
  }
  setFilterInitByUrl(selectedFilters: string[], req: TasksStoreRequest): void {
    this.statusCodes = req.statusCodes;
    this.labelCodes = req.labelCodes;
    this.filterCode = req.filterCode ?? 'executor';
    this.employeeSetCode = req.employeeSetCode;

    if (selectedFilters.includes('employees-a')) {
      this.employeeCodes = req.executorCodes;
    }
    const dataRangeFilterItem = selectedFilters.find(i => i.indexOf('dataRange-i') !== -1);
    if (dataRangeFilterItem) {
      const [, value] = dataRangeFilterItem.split(':');
      this.quickRange = quickDateRanges.find(t => t.value === value);
    }
    if (selectedFilters.includes('overdue-b')) {
      this.overdue = true;
    }
    if (selectedFilters.includes('expiresToday-b')) {
      this.expiresToday = true;
    }
    if (selectedFilters.includes('expiresTomorrow-b')) {
      this.expiresTomorrow = true;
    }
  }
  changeFilterCode(v: 'all' | 'initiator' | 'executor'): void {
    this.filterCode = v;
  }

  changeLabelCodes(v: any[]): void {
    this.labelCodes = v;
  }
  changeStatusesCode(v: any[]): void {
    this.statusCodes = v;
  }
  changeBranchOfficeCodes(v: string[]): void {
    this.branchOfficeCodes = v;
  }
  changeCodeEmployees(v: string[]): void {
    this.employeeCodes = v;
  }
  changeQuickRange(v: RangeVariant): void {
    this.quickRange = v;
  }
  changeOverdue(v: boolean): void {
    this.overdue = v;
  }
  changeExpiresToday(v: boolean): void {
    this.expiresToday = v;
  }
  changeExpiresTomorrow(v: boolean): void {
    this.expiresTomorrow = v;
  }
  changeEmployeeSetCode(v: string): void {
    this.employeeSetCode = v;
  }
}
