import { RootStore } from '../StoreManager';
import { ApiStore } from '../Global/ApiStore';
import { makeAutoObservable } from 'mobx';
import { ClientsItemCreditStateResponse, CreditState, WsClientsCreditStateSyncResponse } from '../../api/marketx';
import { AxiosResponse } from 'axios';
import { setClear } from '../../utils/mobx';
import { MsgType } from '../Global/WebSocketStore';
import { IncomeMessage } from '../Global/EventManager';

export class CreditStateStore {
  apiStore: ApiStore;
  isLoaded = false;
  inRefreshing = false;

  clientCode = '';
  state = <CreditState>{};

  constructor(rootStore: RootStore) {
    this.apiStore = rootStore.getApiStore();
    // фикс для безопасной подстановки в InfiniteScroll и useWebSocketHandler
    this.handleWs = this.handleWs.bind(this);
    makeAutoObservable(this, {
      apiStore: false,
    });
  }

  loadForClient(clientCode: string, officeCode?: string): void {
    this.clientCode = clientCode;
    this.apiStore
      .apiClientCustomer()
      .clientsItemCreditState(clientCode, officeCode)
      .then((res: AxiosResponse<ClientsItemCreditStateResponse>) => {
        this.setState(res.data.creditState);
      });
  }

  refreshForClient(clientCode: string, officeCode?: string): void {
    if (this.inRefreshing && this.clientCode == clientCode) {
      // Уже обновляется
      return;
    }
    this.clientCode = clientCode;
    this.inRefreshing = true;
    this.apiStore
      .apiClientCustomer()
      .clientsItemCreditState(clientCode, officeCode, true)
      .then((res: AxiosResponse<ClientsItemCreditStateResponse>) => {
        this.setState(res.data.creditState);
      });
  }

  /**
   * Обновление статуса по сообщениям из веб-сокета
   * @param msg
   */
  handleWs(msg: IncomeMessage): void {
    if (msg.msgType === MsgType.WS_REST_SHOP_POST_WS_CLIENTS_CREDIT_STATE_SYNC_RESPONSE) {
      const data = msg.data as WsClientsCreditStateSyncResponse;
      if (data?.creditState?.customerCode === this.clientCode && this.clientCode) {
        this.setState(data.creditState, true);
      }
    }
  }

  setState(state: CreditState, resetRefresh?: boolean): void {
    setClear(this.state, state);
    this.isLoaded = true;
    if (resetRefresh) {
      this.inRefreshing = false;
    }
  }
}
