import React, { FC, JSX, useCallback, useRef, useState } from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import Popper from '@mui/material/Popper';
import Avatar from '@mui/material/Avatar';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { MxLabel } from '@mx-ui/ui';
import { formatNumber02, formatNumber3, formatPriceCur, pluralRus } from '@mx-ui/helpers';
import { AppDeal, AppDealDistributor } from '../../../slices/AppDeal';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import WarningIcon from '@mui/icons-material/Warning';
import AddCardOutlinedIcon from '@mui/icons-material/AddCardOutlined';
import { useAuthStore, useRootStore } from '../../../store/MobxStoreProvider';
import { observer } from 'mobx-react-lite';
import { formatDateSwaggerZ } from '@mx-ui/helpers';
import { PopupMenu } from 'src/components/ui/PopupMenu';
import { Box } from '@mui/system';
import { SettingsMenu } from 'src/views/deals/components/SettingMenu';
import { PositionWithDocuments, PreviewMenu } from 'src/components/UploadFile/PreviewMenu';
import { ApproveResolutionControl } from 'src/components/Deals/ApproveResolutionControl';
import { IconApproval } from 'src/components/Deals/IconApprovalDeal';
import { runInAction } from 'mobx';
import { MarginalityPctSum } from '../../../components/ui/MarginalityPctSum';
import { renderPriceCur } from 'src/utils/renderFormat';
import { OrderPositions } from '../../../components/Clients/OderPositions/OderPositions';
import { ThreadLink } from 'src/components/Bills/ThreadLink';
import { ColorChangedPropertyDeal } from 'src/views/deals/DealContainer';
import { formatContractTitle } from '../../../components/Deals/ContractSelect/ContractSelect';
import {
  ApproveHistoryWithUpdateLogsDropDown,
  groupsIcons,
  sxAvatar,
} from '../../../components/Deals/ApproveResolutionControl/ApproveHistoryWithUpdateLogsDropDown';
import { AttentionBlock } from '../../../components/Clients/AttentionBlock/AttentionBlock';
import { PositionMotivationTotal } from '../../../components/Deals/Motivation/PositionMotivationTotal';
import { ReservesStore } from '../../../store/ReservesStore';
import { LabelNonResident } from './CompanyExtInfo';
import { agreementDeviationLevelColors } from '../../../components/Agreements/agreementDeviationLevelColors';
import FilterListIcon from '@mui/icons-material/FilterList';
import IconButton from '@mui/material/IconButton';
import { DealListStore } from '../../../store/DealListStore';
import DoDisturbOnOutlinedIcon from '@mui/icons-material/DoDisturbOnOutlined';
import { ContractCostAgreement } from '../../../api/marketx';
import { Link as MxLink } from '@mx-ui/ui';
import useDocumentsPath from '../../../hooks/useDocumentsPath';

const PREFIX = 'Order';

const classes = {
  text: `${PREFIX}-text`,
};

const StyledPaper = styled(Paper)(({ theme }) => ({
  // border: '1px solid transparent',
  // background: '#fff',
  marginBottom: 16,
  padding: theme.spacing(1),
  [`& .${classes.text}`]: {
    fontSize: '14px',
    cursor: 'default',
  },
}));
export const getFormattedDocuments = (order: AppDeal | ContractCostAgreement): Record<string, PositionWithDocuments> => {
  let documents: Record<string, PositionWithDocuments> = {};

  const documentsByDeal = order.documents?.filter(i => i.positionCode === undefined);
  if (documentsByDeal?.length) {
    documents[order.code] = { name: 'Файлы заявки', documents: documentsByDeal, number: 0 };
  }
  const documentsPosition = order.documents?.filter(i => i.positionCode !== undefined);
  if (documentsPosition?.length) {
    const codesPosWithDocuments = documentsPosition.reduce((acc, file) => {
      if (acc[file.positionCode]?.files) {
        acc[file.positionCode].files.push(file);
      } else {
        acc[file.positionCode] = { files: [file] };
      }
      return acc;
    }, {});
    Object.keys(codesPosWithDocuments).reduce((acc, code) => {
      const pos = order.positions.find(pos => pos.code === code);
      if (pos) {
        acc[code] = {
          name: `${pos.lineNumber}. ${pos?.product?.title}`,
          number: pos.lineNumber,
          documents: codesPosWithDocuments[code]?.files,
        };
      }
      return acc;
    }, documents);
    documents = Object.fromEntries(Object.entries(documents).sort(([, a], [, b]) => a.number - b.number));
  }
  return documents;
};

export interface OrderProps {
  order: AppDeal;
  reserveStore?: ReservesStore;
  dealListStore?: DealListStore;
}
// ? MSOMS-3556 https://marketx.sentry.io/issues/4537598557/?project=5565572
if (!Object.hasOwn) {
  // @ts-ignore
  Object.prototype.hasOwn = function (object, property) {
    return Object.prototype.hasOwnProperty.call(object, property);
  };
}
// eslint-disable-next-line complexity
const Order: FC<OrderProps> = observer(({ order, reserveStore, dealListStore }): JSX.Element => {
  const { asQueryPath } = useDocumentsPath('deals');
  const theme = useTheme();
  const matchesXs = useMediaQuery('(min-width:450px)');
  const authStore = useAuthStore();
  const profile = authStore.profile;
  const currentRole = authStore.getDefaultDealsEmployeeFilter();
  const dealStore = useRootStore().getDealItem(null);
  runInAction(() => {
    dealStore.setDeal(order);
  });
  const documents = getFormattedDocuments(order);
  const [firstWarehouseName, ...restWarehouseNames] = [
    ...new Set([order.warehouseName, ...(order.positions?.map(position => position.warehouseName) || [])]),
  ].filter(i => !!i);
  const creditState = order.customer?.creditState;
  const isChangedPostpay = order.updateLog?.changed && Object.hasOwn(order.updateLog?.changed, 'oldPostpayDays');
  const isChangedPrepayPct = order.updateLog?.changed && Object.hasOwn(order.updateLog?.changed, 'oldPrepayPct');
  const [isPrevAgreedTotalWeightOpen, setIsPrevAgreedTotalWeightOpen] = useState(false);
  const anchorEl = useRef();
  const handleApprovalOpen = useCallback(() => {
    setIsPrevAgreedTotalWeightOpen(true);
  }, [setIsPrevAgreedTotalWeightOpen]);
  const handleApprovalClose = useCallback(() => {
    setIsPrevAgreedTotalWeightOpen(false);
  }, [setIsPrevAgreedTotalWeightOpen]);

  const actualMarketDeviationPct = order?.contractCostAgreements?.[0]?.actualMarketDeviationPct;
  const hasDeviationPct = typeof actualMarketDeviationPct === 'number';
  const { color, bgColor, status } = agreementDeviationLevelColors(actualMarketDeviationPct, theme);

  const handleEmployeeSetChange = useCallback(
    (emp?: AppDealDistributor) => {
      if (!emp) {
        dealListStore.mergeRequest({ employeeSetCode: currentRole });
        return;
      }
      let newVal = '';
      if (!dealListStore.distributorsStore.employees?.length) {
        return;
      }
      for (let i = 0; i < dealListStore.distributorsStore.employees?.length; i++) {
        if (
          dealListStore.distributorsStore.employees[i]?.code === emp?.employeeCode ||
          dealListStore.distributorsStore.employees[i]?.employee?.code === emp?.employeeCode
        ) {
          newVal = dealListStore.distributorsStore.employees[i]?.code;
          break;
        }
      }
      if (!newVal) {
        newVal = `~emp~2~${emp?.employeeCode}`;
      }
      runInAction(() => {
        const oldVal = dealListStore.request.employeeSetCode || null;
        if (newVal !== oldVal) {
          dealListStore.employeeSetMergeRequest({ employeeSetCode: newVal }, emp);
        }
      });
    },
    [dealListStore]
  );

  const handleCustomerChange = useCallback(
    (customerCode?: string) => {
      if (dealListStore.request?.customerCodes?.includes(customerCode)) {
        return;
      }
      dealListStore.mergeRequest({ customerCodes: customerCode ? [customerCode] : undefined });
    },
    [dealListStore]
  );

  const handleCustomerRemoveChange = useCallback(
    (customerCode?: string) => {
      if (!dealListStore.request?.customerCodes?.includes(customerCode)) {
        return;
      }
      const newCustomerCodes = dealListStore.request?.customerCodes?.filter(i => i !== customerCode);
      dealListStore.mergeRequest({ customerCodes: newCustomerCodes });
    },
    [dealListStore]
  );

  const handleChangeFilterByStatus = useCallback(
    (status?: string) => {
      if (dealListStore.request?.status?.includes(status)) {
        return;
      }
      dealListStore.mergeRequest({ status: [status] });
    },
    [dealListStore]
  );

  const handleRemoveState = useCallback(
    (status?: string) => {
      if (!dealListStore.request?.status?.includes(status)) {
        return;
      }
      const newStatusCodes = dealListStore.request?.status?.filter(i => i !== status);
      dealListStore.mergeRequest({ status: newStatusCodes });
    },
    [dealListStore]
  );

  return (
    <StyledPaper key={order.code}>
      <Grid spacing={2} container justifyContent="space-between" style={{ width: '100%', position: 'relative' }}>
        <Grid item md={5} xs={12}>
          <Grid container justifyContent="space-between" mb={1}>
            <Grid item container xs={11} alignItems="center">
              <Grid item mr={1}>
                <AddCardOutlinedIcon fontSize="medium" color={'primary'} />
              </Grid>
              <Grid item>
                <MxLink href={`/app/deals/${order.code}?${asQueryPath}`} color="primary" fontWeight={600}>
                  {`${order.code}${dealStore.deal?.createdAt && matchesXs ? ' от ' + formatDateSwaggerZ(dealStore.deal?.createdAt) : ''}`}
                </MxLink>
              </Grid>
              <Grid item>
                <ThreadLink link={`/app/thread/deals/${encodeURIComponent(order.code)}`} color={'primary'} />
              </Grid>
            </Grid>
          </Grid>
          <Grid container mb={2} flexDirection="column">
            <Grid item>
              <Typography variant="body2">ИНН {order.customer?.inn || '—'}</Typography>
            </Grid>
            <Grid
              item
              sx={{ maxWidth: matchesXs ? 'auto' : '320px', overflow: 'hidden', textOverflow: 'ellipsis' }}
              container
              alignItems={'center'}
              flexWrap={'nowrap'}
            >
              {!!order?.customer?.nonResident && (
                <Grid item mr={1}>
                  <LabelNonResident />
                </Grid>
              )}
              {!!order?.customer?.attentions?.length && (
                <Grid item mr={1}>
                  <AttentionBlock attentions={order?.customer?.attentions} />
                </Grid>
              )}
              <Grid item>
                {dealStore.deal.customerCode ? (
                  <MxLink
                    href={`/app/clients/${encodeURIComponent(dealStore.deal.customerCode)}`}
                    color="text.secondary"
                    typographySx={{ fontSize: 15, fontWeight: 600 }}
                  >
                    {order?.customer?.shortTitle || order?.customer?.title}
                  </MxLink>
                ) : (
                  <Typography fontSize="15px" fontWeight={600} color="text.secondary">
                    {order?.customer?.shortTitle || order?.customer?.title}
                  </Typography>
                )}
              </Grid>
              {dealListStore && dealStore.deal.customerCode ? (
                dealListStore.request?.customerCodes?.includes(order?.customer?.code) ? (
                  <Grid item ml={1}>
                    <IconButton
                      size={'small'}
                      onClick={() => handleCustomerRemoveChange(dealStore.deal.customerCode)}
                      title={`Убрать фильтрацию по ${order?.customer?.shortTitle || order?.customer?.title}`}
                    >
                      <DoDisturbOnOutlinedIcon color={'primary'} sx={{ fontSize: '20px' }} />
                    </IconButton>
                  </Grid>
                ) : (
                  <Grid item ml={1}>
                    <IconButton
                      size={'small'}
                      onClick={() => handleCustomerChange(dealStore.deal.customerCode)}
                      title={`Найти заявки ${order?.customer?.shortTitle || order?.customer?.title}`}
                    >
                      <FilterListIcon color={'primary'} sx={{ fontSize: '20px' }} />
                    </IconButton>
                  </Grid>
                )
              ) : null}
            </Grid>
          </Grid>
          <Grid item container>
            <Grid item xs={12}>
              <Grid container mb={1} alignItems="center">
                <Grid item md={4} xs={6}>
                  <Typography fontSize="14px" fontWeight={600} color="text.secondary">
                    Создана
                  </Typography>
                </Grid>
                <Grid item md={8} xs={6}>
                  <Typography fontSize="14px" fontWeight={600} color="text.secondary">
                    {formatDateSwaggerZ(order.createdAt || '')}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container mb={1} alignItems="center">
                <Grid item md={4} xs={6}>
                  <Typography fontSize="14px" fontWeight={600} color="text.secondary">
                    Действительна до
                  </Typography>
                </Grid>
                <Grid item md={8} xs={6}>
                  <Typography fontSize="14px" fontWeight={600} color="text.secondary">
                    {formatDateSwaggerZ(order.validUntil || '')}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container mb={1} alignItems="center">
                <Grid item md={4} xs={6}>
                  <Typography fontSize="14px" color="text.secondary">
                    Статус
                  </Typography>
                </Grid>
                <Grid item md={8} xs={6}>
                  <Grid container alignItems="center">
                    <MxLabel
                      style={{ display: 'inline', paddingTop: '4px' }}
                      color={order.state === 'not_active' ? 'error' : order.state === 'approval' ? 'warning' : 'success'}
                    >
                      {order?.stateTitle || 'Неизвестно'}
                    </MxLabel>
                    {dealListStore ? (
                      dealListStore.request?.status?.includes(order.state) ? (
                        <Box>
                          <IconButton
                            size={'small'}
                            onClick={() => handleRemoveState(order.state)}
                            title={`Убрать фильтрацию по статусу ${order?.stateTitle || ''}`}
                          >
                            <DoDisturbOnOutlinedIcon color={'primary'} sx={{ fontSize: '20px' }} />
                          </IconButton>
                        </Box>
                      ) : (
                        <Box>
                          <IconButton
                            size={'small'}
                            onClick={() => handleChangeFilterByStatus(order.state)}
                            title={`Найти заявки по статусу ${order?.stateTitle || ''}`}
                          >
                            <FilterListIcon color={'primary'} sx={{ fontSize: '20px' }} />
                          </IconButton>
                        </Box>
                      )
                    ) : null}
                  </Grid>
                </Grid>
              </Grid>
              <Grid container mb={1} alignItems="center">
                <Grid item md={4} xs={6}>
                  <Typography variant="body2" color={'text.secondary'}>
                    Счет
                  </Typography>
                </Grid>
                <Grid item md={8} xs={6}>
                  {Boolean(dealStore.deal?.billDocumentNumber) ? (
                    <Grid item xs={12}>
                      <Box position={'relative'}>
                        <Typography color="text.secondary" variant="body2">
                          <MxLink
                            href={`/app/bills/${dealStore.deal?.billDocumentNumber}`}
                            color="inherit"
                            title="Перейти к счету"
                            typographySx={{ fontSize: 14 }}
                          >
                            {dealStore.deal?.billDocumentNumber}
                          </MxLink>
                          <span>{` от`}</span> {formatDateSwaggerZ(dealStore.deal?.billDocumentDate)}
                        </Typography>
                        <Box position={'absolute'} left={'-30px'} sx={{ top: '50%', transform: 'translateY(-50%)' }}>
                          <ThreadLink link={`/app/thread/bills/${encodeURIComponent(dealStore.deal?.billDocumentNumber)}`} sx={{ py: 0 }} />
                        </Box>
                      </Box>
                    </Grid>
                  ) : (
                    '—'
                  )}
                </Grid>
              </Grid>
              <Grid container mb={1} alignItems="center">
                <Grid item md={4} xs={6}>
                  <Typography fontSize="14px" color="text.secondary">
                    {restWarehouseNames.length > 0 ? 'Склады' : 'Склад'}
                  </Typography>
                </Grid>
                <Grid item md={8} xs={6}>
                  {restWarehouseNames.length > 0 ? (
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Typography fontSize="14px" color="text.secondary">
                        {firstWarehouseName}, ...
                      </Typography>
                      <PopupMenu options={restWarehouseNames} />
                    </Box>
                  ) : (
                    <Typography fontSize="14px" color="text.secondary">
                      {firstWarehouseName}
                    </Typography>
                  )}
                </Grid>
              </Grid>
              <Grid container mb={1} alignItems="center">
                <Grid item md={4} xs={6}>
                  <Typography fontSize="14px" className={classes.text} color={'text.secondary'}>
                    Способ доставки
                  </Typography>
                </Grid>
                <Grid item md={8} xs={6}>
                  <Box display={'flex'}>
                    <Typography
                      fontSize="14px"
                      color={'text.secondary'}
                      sx={{ backgroundColor: order.updateLog?.changed?.oldDeliveryTypeCode ? ColorChangedPropertyDeal : undefined }}
                    >
                      {order.deliveryTypeName}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>

              {order.contract ? (
                <Grid container mb={1} alignItems="center">
                  <Grid item md={4} xs={6}>
                    <Typography fontSize="14px" color="text.secondary">
                      Договор
                    </Typography>
                  </Grid>
                  <Grid item md={8} xs={6}>
                    <Box display={'flex'}>
                      <Typography variant="body2" color="text.secondary">
                        <MxLink
                          href={`/app/contracts/${encodeURIComponent(order.contract.code)}?customerCode=${
                            order.customer?.code
                          }&branchOfficeCode=${order.contract.branchOfficeCode}`}
                          color="inherit"
                          title="Перейти к договору"
                        >
                          {formatContractTitle(order.contract)}
                        </MxLink>
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              ) : null}

              {order?.partners?.consignee?.title ? (
                <Grid container mb={1} alignItems="center">
                  <Grid item md={4} xs={6}>
                    <Typography fontSize="14px" color="text.secondary">
                      Грузополучатель
                    </Typography>
                  </Grid>
                  <Grid item md={8} xs={6}>
                    <Box display={'flex'}>
                      {order.partners.consignee?.customerCode ? (
                        <Typography fontSize="14px" color="text.secondary">
                          <MxLink
                            href={`/app/clients/${encodeURIComponent(order.partners.consignee?.customerCode)}`}
                            color="inherit"
                            title="Перейти к грузополучателю"
                          >
                            {order.partners.consignee?.title}
                          </MxLink>
                        </Typography>
                      ) : (
                        <Typography fontSize="14px" color="text.secondary">
                          {order.partners.consignee?.title}
                        </Typography>
                      )}
                    </Box>
                  </Grid>
                </Grid>
              ) : null}
              {order?.creator ? (
                <Grid container mb={1} alignItems="center">
                  <Grid item md={4} xs={6}>
                    <Typography fontSize="14px" color="text.secondary">
                      Создал
                    </Typography>
                  </Grid>
                  <Grid item md={8} xs={6}>
                    <Box display={'flex'}>
                      <Typography fontSize="14px" color="text.secondary">
                        {order.creator.surname || ''} {order.creator.name || ''} {order.creator.patronymic || ''}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              ) : null}
              {order?.distributor ? (
                <Grid container mb={1} alignItems="center">
                  <Grid item md={4} xs={6}>
                    <Typography fontSize="14px" color="text.secondary">
                      Реализатор
                    </Typography>
                  </Grid>
                  <Grid item md={8} xs={6}>
                    <Grid container alignItems="center">
                      <Typography fontSize="14px" color="text.secondary">
                        {order.distributor.surname || ''} {order.distributor.name || ''} {order.distributor.patronymic || ''}
                      </Typography>
                      {dealListStore ? (
                        dealListStore.request?.employeeSetCode === `~emp~2~${order?.distributor?.employeeCode}` ? (
                          <Box>
                            <IconButton
                              size={'small'}
                              onClick={() => handleEmployeeSetChange()}
                              title={`Убрать фильтрацию по ${order.distributor.surname || ''} ${order.distributor.name || ''} ${
                                order.distributor.patronymic || ''
                              }`}
                            >
                              <DoDisturbOnOutlinedIcon color={'primary'} sx={{ fontSize: '20px' }} />
                            </IconButton>
                          </Box>
                        ) : (
                          <Box>
                            <IconButton
                              size={'small'}
                              onClick={() => handleEmployeeSetChange(order?.distributor)}
                              title={`Найти заявки ${order.distributor.surname || ''} ${order.distributor.name || ''} ${
                                order.distributor.patronymic || ''
                              }`}
                            >
                              <FilterListIcon color={'primary'} sx={{ fontSize: '20px' }} />
                            </IconButton>
                          </Box>
                        )
                      ) : null}
                    </Grid>
                  </Grid>
                </Grid>
              ) : null}
              {order?.contractCostAgreements?.length > 0 ? (
                <Grid container mb={1} alignItems="center">
                  <Grid item md={4} xs={6}>
                    <Typography fontSize="14px" className={classes.text} color="text.secondary">
                      {order?.contractCostAgreements?.length > 1 ? 'Соглашения' : 'Соглашение'}
                    </Typography>
                  </Grid>
                  <Grid item md={8} xs={6}>
                    <Typography fontSize="14px" color="text.secondary">
                      {order?.contractCostAgreements.map(agr => (
                        <MxLink key={agr.code} href={`/app/agreements/${agr.code}`} variant="body1" color="textPrimary">
                          {agr.code}&#160;
                        </MxLink>
                      ))}
                    </Typography>
                  </Grid>
                </Grid>
              ) : null}

              {Boolean(order?.motivationEmployee && order?.motivationEmployee.code !== order?.distributor?.employeeCode) && (
                <Grid container mb={1} alignItems="center">
                  <Grid item md={4} xs={6}>
                    <Typography fontSize="14px" className={classes.text} color="text.secondary">
                      Реализатор по замещению
                    </Typography>
                  </Grid>
                  <Grid item md={8} xs={6}>
                    <Typography fontSize="14px" color="text.secondary">
                      {order.motivationEmployee?.surname || ''} {order.motivationEmployee?.name || ''}{' '}
                      {order.motivationEmployee?.patronymic || ''}
                    </Typography>
                  </Grid>
                </Grid>
              )}

              {order.customer?.occupationTitle ? (
                <Grid container mb={1} alignItems="center">
                  <Grid item md={4} xs={6}>
                    <Typography fontSize="14px" className={classes.text} color="text.secondary">
                      Отрасль
                    </Typography>
                  </Grid>
                  <Grid item md={8} xs={6}>
                    <Typography fontSize="14px" color="text.secondary">
                      {order.customer.occupationTitle}
                    </Typography>
                  </Grid>
                </Grid>
              ) : null}

              {order.customer?.segmentCode ? (
                <Grid container mb={1} alignItems="center">
                  <Grid item md={4} xs={6}>
                    <Typography fontSize="14px" className={classes.text} color="text.secondary">
                      Сегмент по потреблению
                    </Typography>
                  </Grid>
                  <Grid item md={8} xs={6}>
                    <Typography fontSize="14px" color="text.secondary">
                      {order.customer.segmentCode}
                    </Typography>
                  </Grid>
                </Grid>
              ) : null}
            </Grid>
          </Grid>
        </Grid>
        {/* <Grid item container md={2} xs={12} />*/}
        <Grid item container md={7} xs={12}>
          <Grid item xs={12}>
            <Grid container mb={1} alignItems="center">
              <Grid item xs={6}>
                <Typography fontWeight={700} color="textPrimary">
                  Сумма заявки
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Box display="flex" alignItems={'center'} flexDirection={'row'}>
                  <Typography fontWeight={700} color="textPrimary" marginRight={0.75}>
                    {formatPriceCur(order?.totalPrice)}
                  </Typography>
                  {!!order?.customer?.prevAgreedTotalWeight && (
                    <Box
                      p={0}
                      m={0}
                      display="flex"
                      alignItems={'center'}
                      sx={{ cursor: 'pointer' }}
                      onMouseEnter={handleApprovalOpen}
                      onMouseLeave={handleApprovalClose}
                      ref={anchorEl}
                    >
                      <WarningIcon color="warning" fontSize="medium" sx={{ marginRight: '5px' }} />
                      <Popper open={isPrevAgreedTotalWeightOpen} anchorEl={anchorEl.current}>
                        <Paper sx={{ p: 1 }}>
                          <Grid container>
                            <Grid item mr={1}>
                              <Avatar
                                sx={{ ...sxAvatar, backgroundColor: '#7CB342', color: '#fff' }}
                                title={groupsIcons?.[order.approval.currentAccessGroup]?.title || '-'}
                              >
                                {groupsIcons?.[order.approval.currentAccessGroup]?.code || '-'}
                              </Avatar>
                            </Grid>
                            <Grid item>
                              <Typography className={classes.text} color="text.secondary">
                                {`Ранее согласовали ${order?.customer?.prevAgreedTotalWeight || ''} ${
                                  order?.customer?.prevAgreedTotalWeightUnit || ''
                                }`}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Paper>
                      </Popper>
                    </Box>
                  )}
                  {order?.psTotalCost > 0 && <Chip label="Акция" size="small" icon={<MonetizationOnIcon />} />}
                </Box>
              </Grid>
            </Grid>
            <Grid container mb={1} alignItems="center">
              <Grid item xs={6}>
                <Typography className={classes.text} color="text.secondary">
                  Маржинальность от МРЦ
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <MarginalityPctSum pct={order?.marginalityPct} cost={order?.managerProfitMarga} />
              </Grid>
            </Grid>
            {profile?.features?.canSeeDealPrimeCostMargin && (
              <>
                <Grid container mb={1} alignItems="center">
                  <Grid item xs={6}>
                    <Typography className={classes.text} color="text.secondary">
                      Маржинальность от цены закупки
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <MarginalityPctSum pct={order?.purchaseCostMarginPct} cost={order?.purchaseCostMargin} />
                  </Grid>
                </Grid>

                <Grid container mb={1} alignItems="center">
                  <Grid item xs={6}>
                    <Typography className={classes.text} color="text.secondary">
                      Маржинальность от ПлС
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <MarginalityPctSum pct={order?.primeCostMarginPct} cost={order?.primeCostMargin} />
                  </Grid>
                </Grid>

                {hasDeviationPct && (
                  <Grid container mb={1} alignItems="center">
                    <Grid item xs={6}>
                      <Typography className={classes.text} color="text.secondary">
                        Соответствие соглашения рынку
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Grid container alignItems={'center'}>
                        <Grid item>
                          <Typography className={classes.text} style={{ color: color }}>
                            {formatNumber02(actualMarketDeviationPct)} %
                          </Typography>
                        </Grid>
                        <Grid item ml={1}>
                          <MxLabel
                            sx={{
                              cursor: 'pointer',
                              borderRadius: '16px',
                              fontWeight: '400',
                              color: color,
                              backgroundColor: bgColor,
                            }}
                          >
                            <Box fontSize={14}>{status}</Box>
                          </MxLabel>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </>
            )}

            <Grid container mb={1} alignItems="center">
              <Grid item xs={6}>
                <Typography fontSize="14px" className={classes.text} color="text.secondary">
                  Количество
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography fontSize="14px" variant={'body2'} color="text.secondary">
                  {formatNumber3((order?.totalWeight || 0) / 1000)}&nbsp;т
                </Typography>
              </Grid>
            </Grid>
            {order?.shipmentsTotalWeight > 0 && (
              <Grid container mb={1} alignItems="center">
                <Grid item xs={6}>
                  <Typography fontSize="14px" className={classes.text} color="text.secondary">
                    Отгружено
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography fontSize="14px" variant={'body2'} color="text.secondary">
                    {formatNumber3((order?.shipmentsTotalWeight || 0) / 1000)}&nbsp;т
                  </Typography>
                </Grid>
              </Grid>
            )}

            {!profile?.features?.canSeeDealPrimeCostMargin && <Grid container sx={{ height: '20px' }}></Grid>}

            {(order?.prepayPct > 0 || order?.postpayDays > 0) && (
              <>
                <Grid container mb={1} alignItems="center">
                  <Grid item xs={6}>
                    <Typography fontSize="14px" color="text.secondary">
                      Предоплата
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Box display={'flex'}>
                      <Typography
                        fontSize="14px"
                        color="text.secondary"
                        sx={{ backgroundColor: isChangedPrepayPct ? ColorChangedPropertyDeal : undefined }}
                      >
                        {order?.prepayPct} %
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>

                {order?.prepayPct < 100 && (
                  <Grid container mb={1} alignItems="center">
                    <Grid item xs={6}>
                      <Typography fontSize="14px" color="text.secondary">
                        Отсрочка
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Box display={'flex'}>
                        <Typography
                          fontSize="14px"
                          color="text.secondary"
                          sx={{ backgroundColor: isChangedPostpay ? ColorChangedPropertyDeal : undefined }}
                        >
                          {order?.postpayDays} дней
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                )}
              </>
            )}

            {creditState && (
              <Grid container mb={1} alignItems="center">
                <Grid item xs={6}>
                  <Typography fontSize="14px" className={classes.text} color="text.secondary">
                    Сумма кредитного лимита
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography fontSize="14px" color="text.secondary">
                    {renderPriceCur(creditState?.allowedSum)}
                  </Typography>
                </Grid>
              </Grid>
            )}

            {creditState && (
              <Grid container mb={1} alignItems="center">
                <Grid item xs={6}>
                  <Typography fontSize="14px" className={classes.text} color="text.secondary">
                    Сумма дебиторской задолженности
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography fontSize="14px" color="text.secondary">
                    {renderPriceCur(creditState?.unpaidSum)}
                  </Typography>
                </Grid>
              </Grid>
            )}

            {creditState && (
              <Grid container mb={1} alignItems="center">
                <Grid item xs={6}>
                  <Typography fontSize="14px" className={classes.text} color={creditState?.overdueSum > 0 ? 'red' : 'text.secondary'}>
                    Просрочено
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography fontSize="14px" color={creditState?.overdueSum > 0 ? 'red' : 'text.secondary'}>
                    {renderPriceCur(creditState?.overdueSum)}{' '}
                    {`${
                      creditState?.overdueDays > 0
                        ? `(${creditState?.overdueDays} ${pluralRus(creditState?.overdueDays || 0, ['день', 'дня', 'дней'])})`
                        : ''
                    }`}
                  </Typography>
                </Grid>
              </Grid>
            )}
            {(order?.motivation && Object.keys(order?.motivation || {}).length && (
              <Grid container mb={1} alignItems="center">
                <PositionMotivationTotal motivation={order?.motivation} isForDeal />
              </Grid>
            )) ||
              null}
          </Grid>
          <Grid item md={12} container alignItems="center" sx={{ position: 'relative' }}>
            <>
              <IconApproval approvalStore={dealStore.approvalStore} />
              <ApproveResolutionControl sizeButton="medium" approvalStore={dealStore.approvalStore} />
              <Box>
                <ApproveHistoryWithUpdateLogsDropDown approvalStore={dealStore.approvalHistoryStore} immediatelyOnStart />
              </Box>
            </>
            {documents[order.code] && <PreviewMenu documents={documents} positionCode={order.code} isWithBadge />}
          </Grid>
        </Grid>
        <Grid sx={{ position: 'absolute', top: '4px', right: '-23px' }}>
          <SettingsMenu dealStore={dealStore} />
        </Grid>
      </Grid>
      {!!order.positions?.length && <OrderPositions order={order} documents={documents} reserveStore={reserveStore} />}
    </StyledPaper>
  );
});

export default Order;
