import { makeAutoObservable, runInAction } from 'mobx';
import { CatalogProductsStatsCompetitorsPlotResponse, CatalogProductsStatsPlotResponse, ProductStatsPlot } from '../../../api/marketx';
import { AxiosResponse } from 'axios';
import { Plot, PlotPoint } from '../../../lib/plots';
import { ApiStore } from '../../../store/Global/ApiStore';
import { RootStore } from '../../../store/StoreManager';

type Request = {
  productCode: string;
};

export interface SelectOptionItem {
  value?: string;
  text?: string;
}

export class ChartsDataStore {
  apiStore: ApiStore;
  interval: NodeJS.Timeout;
  plots: Record<string, Plot> = {};
  lastRequestTime: Record<string, number> = {};
  // Очередь на загрузку графиков
  requestQueue: Request[] = [];
  isLoaded = false;
  isLoading = false;
  competitors: SelectOptionItem[] = null;

  constructor(rootStore: RootStore) {
    this.apiStore = rootStore.getApiStore();
    makeAutoObservable(this, {
      apiStore: false,
    });
  }

  executeQueue(): void {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = null;
    }
    const requested: Record<string, boolean> = {};
    while (this.requestQueue && this.requestQueue.length) {
      const req = this.requestQueue.shift();
      const key = req.productCode;
      if (key && !requested[key]) {
        requested[key] = true;
        this.requestBackendData(req.productCode);
      }
    }
  }

  // Запускает запрос данных графика у бэкенда
  requestBackendData(productCode: string): void {
    this.apiStore
      .apiClientCatalog()
      .catalogProductsStatsPlot(productCode)
      .then((request: AxiosResponse<CatalogProductsStatsPlotResponse>): void => {
        this.setProductsData(request.data.products);
      });
  }

  runInterval(): void {
    if (this.interval) {
      return;
    }
    this.interval = setInterval(() => {
      this.executeQueue();
    }, 1000);
  }

  // Установка значений для графика
  setProductsData(products: ProductStatsPlot[]): void {
    products.forEach((product: ProductStatsPlot): void => {
      const key = product.productCode + '-' + product.plotType;
      if (!this.plots[key]) {
        return;
      }
      const civ = product.plot.series[0].changeInValue;
      this.plots[key].changeInValue = {
        unit: civ?.unit || '',
        value: civ?.value || 0,
      };
      let firstPositiveY = 0;
      let lastPositiveY = 0;
      let lastPositiveX = 0;
      let midVal = 0;
      let i = 0;
      product.plot.series[0]?.data?.forEach((point: number[], j: number): void => {
        const valueX = point[0];
        const valueY = point[1];

        if (firstPositiveY == 0 && valueY !== 0) {
          firstPositiveY = valueY;
        }

        this.plots[key].points[j] = <PlotPoint>{
          x: valueX,
          y: valueY,
        };
        if (valueY !== 0) {
          lastPositiveY = valueY;
          lastPositiveX = valueX;

          midVal += valueY;
          i += 1;
        }
      });
      if (i) {
        this.plots[key].firstPositiveY = firstPositiveY;
        this.plots[key].lastPositiveX = lastPositiveX;
        this.plots[key].lastPositiveY = lastPositiveY;
        this.plots[key].middleVal = midVal / i;
      }
      this.plots[key].points.splice(product.plot.series[0].data.length);
      if (product.plot.xaxis) {
        this.plots[key].xaxis.minTicks = product.plot.xaxis.minTicks ?? undefined;
        this.plots[key].xaxis.maxTicks = product.plot.xaxis.maxTicks ?? undefined;
      } else {
        this.plots[key].xaxis = {};
      }
      this.plots[key].loaded = true;
    });
    if (!this.requestQueue.length) {
      this.isLoaded = true;
      this.isLoading = false;
    }
  }

  getCompetitorsPlot(productCode: string, plotTypeList: string[], competitorCode = undefined): Plot[] {
    if (!productCode) {
      return;
    }
    runInAction(() => {
      this.isLoading = true;
    });
    const plotTypeKeysList = [];
    plotTypeList.forEach(plotType => {
      const key = productCode + '-' + plotType;
      plotTypeKeysList.push(key);
      if (!this.plots[key]) {
        this.plots[key] = {
          name: key,
          points: [
            { x: 1, y: 0 },
            { x: 2, y: 0 },
            { x: 3, y: 0 },
            { x: 4, y: 0 },
            { x: 5, y: 0 },
            { x: 6, y: 0 },
            { x: 7, y: 0 },
            { x: 8, y: 0 },
            { x: 9, y: 0 },
            { x: 10, y: 0 },
          ],
          xaxis: {},
          yaxis: {},
        } as Plot;
      }
    });
    this.loadCompetitorsPlot(productCode, competitorCode);
    return [this.plots[plotTypeKeysList[0]], this.plots[plotTypeKeysList[1]]];
  }

  loadCompetitorsPlot(productCode: string, competitorCode = undefined): void {
    this.apiStore
      .apiClientCatalog()
      .catalogProductsStatsCompetitorsPlot(productCode, competitorCode)
      .then(res => {
        this.setCompetitorsResult(res);
      })
      .catch(err => {
        console.warn('loadCompetitorsPlot', err);
      });
  }

  setCompetitorsResult(data: AxiosResponse<CatalogProductsStatsCompetitorsPlotResponse>): void {
    if (data.data?.competitors?.length && !this.competitors) {
      this.competitors = data.data?.competitors.map(item => ({ value: item.code, text: item.title }));
    }
    if (data.data?.products?.length) {
      this.setProductsData(data.data?.products);
    }
  }

  // Возвращает данные для графика или заглушку и запускает процесс обновления
  getPlot(productCode: string, plotType: string): Plot {
    if (!productCode) {
      return;
    }
    const key = productCode + '-' + plotType;
    if (!this.plots[key]) {
      this.plots[key] = {
        name: key,
        points: [
          { x: 1, y: 0 },
          { x: 2, y: 0 },
          { x: 3, y: 0 },
          { x: 4, y: 0 },
          { x: 5, y: 0 },
          { x: 6, y: 0 },
          { x: 7, y: 0 },
          { x: 8, y: 0 },
          { x: 9, y: 0 },
          { x: 10, y: 0 },
        ],
        xaxis: {},
        yaxis: {},
      } as Plot;
    }
    if (!this.lastRequestTime[productCode]) {
      runInAction(() => {
        this.lastRequestTime[productCode] = Date.now();
        this.requestQueue.push(<Request>{
          productCode: productCode,
        });
        this.runInterval();
      });
    }
    return this.plots[key];
  }
}
