import { AgreementItemStore } from './AgreementItemStore';
import { makeAutoObservable } from 'mobx';
import { PaymentRangesStore } from '../../components/Documents/PaymentRangesBlock';
import { ValueStore } from '../ValueStore';
import { ContractCostAgreement } from '../../api/marketx';

export class AgreementPaymentStore implements PaymentRangesStore {
  store: AgreementItemStore = null;
  agreement: ContractCostAgreement = null;

  prepayPctValueStore: ValueStore;
  prepayPctMax = 100;
  prepayPctMin = 0;
  prepayPctStep = 100;

  postpayDaysValueStore: ValueStore;
  postpayDaysMax = 30;

  constructor(store: AgreementItemStore) {
    this.store = store;

    this.prepayPctValueStore = new ValueStore({
      value: String(this.store?.agreement?.payment?.prepayPct || ''),
      inputDelay: 1,
      onInputChangeDebounced: (value: any): void => {
        this.store.updateAgreement({ prepayPct: Number(value || 0) });
      },
    });

    this.postpayDaysValueStore = new ValueStore({
      value: String(this.store?.agreement?.payment?.postpayDays || ''),
      inputDelay: 1,
      onInputChangeDebounced: (value: any): void => {
        this.store.updateAgreement({ postpayDays: Number(value || 0) });
      },
    });
    this.setAgreement(store, new Date());

    makeAutoObservable(this);
  }

  /**
   * Нужно вызывать из стора при изменении соглашения
   * @param agr
   * @param requestTime Date Время, когда был отправлен запрос на бэк.
   */
  setAgreement(store: AgreementItemStore, requestTime: Date): void {
    const agr = store.agreement;
    this.agreement = agr;
    if (agr) {
      this.prepayPctMax = 100;
      this.prepayPctMin = 0;
      this.prepayPctStep = 100;
      this.postpayDaysMax = agr.payment?.officeMaxPostpayDays || 30;

      switch (agr.payment?.officePrepayPercents) {
        case '[0,50,100]': {
          this.prepayPctStep = 50;
          break;
        }
        case '[100]': {
          this.prepayPctMin = 100;
          this.prepayPctStep = 200;
          this.postpayDaysMax = 0;
          break;
        }
      }

      this.prepayPctValueStore.handleModelChange((agr.payment?.prepayPct as any | '') || '', requestTime);
      this.postpayDaysValueStore.handleModelChange((agr.payment?.postpayDays as any) || '', requestTime);
    }
  }
  creditCost(): number | undefined {
    return undefined;
  }

  isViewOnly(): boolean {
    return this.store.isViewOnly();
  }
  /**
   * Ограничитель для отсрочки
   * работает так же как и Знак ограничение скорости
   * можно превышать можно не превышать, но при превышении будет гореть красным айка
   */
  getLimitPostPayDays(): number {
    return this.store.getCreditStateStore().state?.delayDays;
  }
  getPostpayDays(): ValueStore {
    return this.postpayDaysValueStore;
  }

  getPostpayDaysMax(): number {
    return this.postpayDaysMax;
  }

  getPrepayPctMax(): number {
    return this.prepayPctMax;
  }

  getPrepayPctMin(): number {
    return this.prepayPctMin;
  }

  getPrepayPct(): ValueStore {
    return this.prepayPctValueStore;
  }

  getPrepayPctStep(): number {
    return this.prepayPctStep;
  }
}
