import React, { FC, JSX, useCallback, useState } from 'react';
import { observer } from 'mobx-react-lite';
import Grid from '@mui/material/Grid';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import { StyledTextField } from '../../../components/SyncedInputs/SyncedTextField';
import { DealItemStore } from '../../../store/DealItemStore';
import { AppDealPosition } from '../../../slices/AppDeal';

interface ServiceManualItemProps {
  dealStore: DealItemStore;
  dealPosition: AppDealPosition;
  name: string;
  field: string;
  isViewOnly?: boolean;
}

export const ServiceManualItem: FC<ServiceManualItemProps> = observer(
  ({ dealStore, dealPosition, name, field, isViewOnly }): JSX.Element => {
    const [useServiceVal, setUseServiceVal] = useState(!!dealPosition?.services?.filter(s => s.code === field)?.[0]?.enabled);
    const [useServiceCost, setUseServiceCost] = useState(
      dealPosition?.services?.filter(s => s.code === field)?.[0]?.totalCost.toString() || ''
    );
    const handleServiceChange = useCallback(
      (cost?: string) => {
        const update = [];
        if (typeof cost === 'string') {
          setUseServiceCost(cost);
          update.push({ code: field, manualTotalCost: Number(cost) });
        } else {
          const newValue = !useServiceVal;
          setUseServiceVal(newValue);
          if (!newValue) {
            setUseServiceCost('0');
          }
          update.push({
            code: field,
            enabled: newValue,
          });
        }
        dealStore
          .updatePosition(dealPosition, {
            services: update,
          })
          .catch(r => console.warn('handleServiceChange', r));
      },
      [dealStore, useServiceVal, setUseServiceVal, dealPosition, useServiceCost, setUseServiceCost]
    );
    return (
      <Grid container alignItems={'center'} px={1} justifyContent={'space-between'}>
        <Grid item>
          <FormControlLabel
            control={
              <Checkbox
                disabled={isViewOnly}
                checked={useServiceVal}
                name={`${field}-${dealPosition.code}`}
                onChange={() => handleServiceChange()}
              />
            }
            label={
              <Typography
                component={'span'}
                variant={'body1'}
                style={{ cursor: isViewOnly ? 'not-allowed' : 'pointer', color: isViewOnly && 'rgba(0, 0, 0, 0.38)' }}
              >
                {name}
              </Typography>
            }
          />
        </Grid>
        <Grid item>
          <Grid container alignItems={'center'} flexWrap={'nowrap'} maxWidth={100}>
            <Grid item>
              <StyledTextField
                value={useServiceCost}
                onChange={e => {
                  handleServiceChange(e.target.value || '');
                }}
                disabled={isViewOnly || !useServiceVal}
              />
            </Grid>
            <Grid item ml={1}>
              <Typography component={'span'} variant={'body2'} color={'text.secondary'}>
                ₽
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
);
