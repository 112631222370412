import { keys, remove, runInAction, set } from 'mobx';

export const setClear = (collection: any, values: { [key: string]: any }): void =>
  runInAction(() => {
    const srcKeys = keys(collection);
    set(collection, values);
    srcKeys.forEach(prop => {
      const k = prop.toString();
      if (values[k] === undefined) {
        remove(collection, k);
      }
    });
  });
