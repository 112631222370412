import { createContext, useState, useEffect, useMemo, useLayoutEffect } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { backgroundColor, themeByMode } from 'src/theme/newTheme';
import { PaletteMode } from '@mui/material';
// import { PaletteMode, useMediaQuery } from '@mui/material';
import { useLocalStorage } from 'src/hooks/useLocalStorage';
export const ColorModeContext = createContext({ toggleColorMode: () => {} });

const useEnhancedEffect = typeof window !== 'undefined' ? useLayoutEffect : useEffect;
export const ThemeProviderMui = ({ children }): JSX.Element => {
  // фича позволяет включать темную тему сразу, если в браузере она уже включена, но пока отключаем(
  // const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)', { noSsr: true });
  const [modeLs, setModeLs] = useLocalStorage<'light' | 'dark'>('mode');
  const [mode, setMode] = useState<'light' | 'dark'>(modeLs);
  useEffect(() => {
    if (!modeLs) {
      setModeLs('light');
      // setModeLs(prefersDarkMode ? 'dark' : 'light');
      setMode('light');
      // setMode(prefersDarkMode ? 'dark' : 'light');
    }
  }, []);
  useEnhancedEffect(() => {
    // To support light and dark mode images in the docs
    if (mode === 'dark') {
      document.body.classList.remove('mode-light');
      document.body.classList.add('mode-dark');
    } else {
      document.body.classList.remove('mode-dark');
      document.body.classList.add('mode-light');
    }

    const metas = document.querySelectorAll('meta[name="theme-color"]');
    const metasApple = document.querySelectorAll('meta[name="apple-mobile-web-app-status-bar"]');
    metas.forEach(meta => {
      meta.setAttribute('content', backgroundColor[mode]);
    });
    metasApple.forEach(meta => {
      meta.setAttribute('content', backgroundColor[mode]);
    });
  }, [mode]);
  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode(prevMode => {
          const v = prevMode === 'light' ? 'dark' : 'light';
          setModeLs(v);
          return v;
        });
        // setMode(prevMode => );
      },
    }),
    []
  );

  const theme = useMemo(() => themeByMode(mode as PaletteMode), [mode]);

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ColorModeContext.Provider>
  );
};
