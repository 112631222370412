import React, { useContext } from 'react';
import type { FC } from 'react';
import { AppBarProps } from '@mui/material/AppBar';
import { Env, EnvContext } from '../EnvContext';
import { styled } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';

type StyledAppBarProps = AppBarProps & {
  env?: Env;
};
const backgroundColorByEnv: Record<Env, string> = {
  // prod: '#505050',
  prod: '#080F72',
  dev: '#CC0099',
  qa: '#00695c',
  demo: '#111',
};

const backgroundColorByMode: Record<string, string> = {
  dark: '#242424',
  light: '#fff',
};

const getColorByEnv = (env: Env, mode: string): Record<string, string> => {
  const bgColor = env === 'prod' ? backgroundColorByMode[mode] : backgroundColorByEnv[env];
  return {
    backgroundColor: bgColor + ' !important',
    borderBottom: '2px solid #080F72 !important',
  };
};

const StyledAppBar = styled(AppBar, {
  shouldForwardProp: prop => prop !== 'env',
})<StyledAppBarProps>(({ theme, env }) => ({
  ...getColorByEnv(env, theme.palette.mode),
})) as React.ComponentType<StyledAppBarProps>;

interface AppBarColorProps {
  children: React.ReactNode;
  className?: string;
}
export const AppBarColor: FC<AppBarColorProps & StyledAppBarProps> = ({ children, className, ...rest }): JSX.Element => {
  const env = useContext(EnvContext);

  return (
    <StyledAppBar position="relative" className={className} env={env} {...rest}>
      {children}
    </StyledAppBar>
  );
};
