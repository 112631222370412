import Box from '@mui/material/Box';
import React, { FC } from 'react';

export interface TabPanelProps {
  children: React.ReactNode;
  value: number | string;
  index: number | string;
  style?: any;
  paddingY?: number;
}
export interface a11yPropsResult {
  id: string;
  'aria-controls': string;
}

export function a11yProps(index: number | string, name = 'full-width-tab'): a11yPropsResult {
  return {
    id: `${name}-${index}`,
    'aria-controls': `${name}-${index}`,
  };
}
export const TabPanel: FC<TabPanelProps> = ({ children, value, index, style, paddingY }): JSX.Element => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      // aria-labelledby={`full-width-tab-${index}`}
      // {...other}
      style={style ? style : null}
    >
      {(value === index && <Box py={paddingY == null ? 3 : paddingY}>{children}</Box>) || null}
    </div>
  );
};
