import { AxiosCallContext, getCallContext } from '../../utils/axiosInit';
import { ContractCostAgreement, ContractCostAgreementsItemDistributorsResponse, EmployeeSet } from '../../api/marketx';
import { ApiStore } from '../Global/ApiStore';
import { RootStore } from '../StoreManager';
import { AxiosResponse } from 'axios';
import { makeAutoObservable } from 'mobx';

/**
 * Список реализаторов для соглашения.
 */
export class AgreementDistributorListStore {
  apiStore: ApiStore;

  agreementCode?: string;
  items = new Array<EmployeeSet>();

  activeDistributorCode?: string;

  isLoaded = false;
  isLoading = false;
  loadedEpoch = 0;

  constructor(rootStore: RootStore) {
    this.apiStore = rootStore.getApiStore();
    makeAutoObservable(this);
  }

  setResult(ctx: AxiosCallContext, res: ContractCostAgreementsItemDistributorsResponse): void {
    this.items.splice(0, this.items.length, ...res.distributors);
    this.isLoaded = true;
    this.isLoading = false;
    this.loadedEpoch++;
  }

  loadForAgreement(agreement: ContractCostAgreement): void {
    this.agreementCode = agreement.code;
    this.activeDistributorCode = agreement?.distributor?.code;
    if (agreement?.distributor?.code) {
      const inList = this.items && this.items.find(it => it.code === agreement?.distributor?.code);
      if (!inList) {
        this.items.push(<EmployeeSet>{
          code: agreement?.distributor?.code,
          title: (agreement?.distributor?.surname || '') + ' ' + (agreement?.distributor?.name || ''),
        });
      }
    }

    if (!this.agreementCode) {
      return;
    }

    if (!this.isLoading) {
      this.isLoading = true;
      this.apiStore
        .apiClientCustomer()
        .contractCostAgreementsItemDistributors(this.agreementCode)
        .then((res: AxiosResponse<ContractCostAgreementsItemDistributorsResponse>) => {
          this.setResult(getCallContext(res), res.data);
        });
    }
  }
}
