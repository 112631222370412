import { RootStore } from './StoreManager';
import { makeAutoObservable, runInAction } from 'mobx';
import { ApiStore } from './Global/ApiStore';
import { UserNotificationsItem } from 'src/api/marketx';
import { SnackbarStore } from './SnackbarStore';
import { setClear } from 'src/utils/mobx';
import { RouterStore } from './RouterStore';
import { action } from 'src/components/DashboardLayout/TopBar/Notifications/NotificationAction';
import { UAParser } from 'ua-parser-js';

export type TypeMessage = 'info' | 'success' | 'error' | 'warning';

export type NotificationRequest = {
  page?: number;
  count?: number;
  date?: string | Date;
};
export const defaultPageSize = 20;

export class NotificationsStore {
  routerStore: RouterStore;
  notifications: Array<UserNotificationsItem> = [];
  total = 0;
  apiStore: ApiStore;
  isFocus = true; //* находится ли пользователь на странице
  isMoreLoading = false;
  hasLoadMore = false;
  hasOne = false;
  date = new Date();
  isLoaded = false;
  isLoading = false;
  hasMore = false;
  snackbarStore: SnackbarStore;
  request: NotificationRequest = { count: defaultPageSize, date: this.date };
  routerControlEnabled = false;

  constructor(rootStore: RootStore) {
    this.snackbarStore = rootStore.getSnackbar();
    this.apiStore = rootStore.getApiStore();
    this.routerStore = rootStore.getRouter();
    this.loadMore = this.loadMore.bind(this);

    if (typeof window !== 'undefined' && 'Notification' in window) {
      const uap = new UAParser(window.navigator.userAgent);
      // у Сафари свои приколы нужно показывать еще одно окно с кнопкой, по клику открывать окошко браузерное с уведомлениями
      if (uap.getBrowser().name === 'Safari') {
        if (Notification.permission === 'default') {
          const handleCLickSafafi = (): void => {
            if (Notification.permission === 'granted') {
              //* пользователь разрешил уведомления
            } else if (Notification.permission === 'denied') {
              //* пользователь запретил уведомления
            } else if (Notification.permission === 'default') {
              //* у пользователя еще не спрашивали разрешение на показ уведомлений

              Notification.requestPermission(permission => {
                //* смотрим разрешил ли пользователь показывать уведомления или нет
                if (permission === 'denied') {
                  this.snackbarStore.showInfo(`Включите push-уведомления чтобы получать информацию о заявках вне вкладки MarketX`);
                }
              });
            }
          };

          this.snackbarStore.show('Подключите push-уведомления чтобы получать информацию о заявках', {
            action: action({ handleClick: handleCLickSafafi }),
            autoHideDuration: 30000,
            variant: 'info',
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center',
            },
          });
        }
      } else {
        if (Notification.permission === 'granted') {
          //* пользователь разрешил уведомления
        } else if (Notification.permission === 'denied') {
          //* пользователь запретил уведомления
        } else if (Notification.permission === 'default') {
          //* у пользователя еще не спрашивали разрешение на показ уведомлений

          Notification.requestPermission(permission => {
            //* смотрим разрешил ли пользователь показывать уведомления или нет
            if (permission === 'denied') {
              this.snackbarStore.showInfo(`Включите push-уведомления чтобы получать информацию о заявках вне вкладки MarketX`);
            }
          });
        }

        // для браузеров кроме хрома
        window.onfocus = () => {
          this.setFocus(true);
        };
        window.onblur = () => {
          this.setFocus(false);
        };
        // для хрома
        document.addEventListener('visibilitychange', () => {
          this.setFocus(!document.hidden);
        });
      }
    } else {
      console.error('NotificationsStore window is undefined');
      // this.snackbarStore.showError(`Ваш браузер не поддерживает уведомления`);
    }
    makeAutoObservable(this, { apiStore: false, snackbarStore: false });
  }

  actualizeRouter(tab: string): void {
    if (!this.routerControlEnabled) {
      return;
    }
    const params = new URLSearchParams();

    if (tab) {
      params.set('selectedTabUrl', tab);
    }
    let paramsStr = params.toString();
    if (paramsStr) {
      paramsStr = '?' + paramsStr;
    }
    let url = '/app/profile';
    url += paramsStr;
    this.routerStore.replace(url, undefined, { shallow: true });
  }

  setRouterControl(enabled: boolean): void {
    runInAction(() => {
      this.routerControlEnabled = enabled;
    });
  }
  setHasLoadMore(v: boolean): void {
    this.hasLoadMore = v;
  }
  setHasOne(value: boolean): void {
    this.hasOne = value;
  }
  setRequest(req: NotificationRequest): void {
    setClear(this.request, req);
  }
  loadMore(): void {
    if (this.isLoading) {
      // уже загружается
      return;
    }
    this.isMoreLoading = true;
    if (this.request) {
      this.request.page = (this.request?.page || 1) + 1;
    }
    this.load();
  }
  setFocus(focus: boolean): void {
    this.isFocus = focus;
  }
  async load(withOutAt = false): Promise<void> {
    const req = Object.assign({}, this.request);
    runInAction(() => {
      this.isLoading = true;
    });
    req.page = req.page > 1 ? req.page : undefined;
    req.count = req.count || defaultPageSize;
    try {
      const { data } = await this.apiStore
        .apiClientNotification()
        .userNotificationsPaged(req.page, req.count, withOutAt ? undefined : (req.date as string));
      // withOutAt ? (req.date as string) : undefined
      runInAction(() => {
        if (this.isMoreLoading) {
          this.isMoreLoading = false;
          this.notifications.push(...(data.notifications || []));
        } else {
          this.notifications = data.notifications || [];
        }
        this.isLoaded = true;
        this.isLoading = false;
        this.total = data.totalCount;
        if (this.hasLoadMore) {
          this.isMoreLoading = false;
          this.hasMore = data.notifications?.length >= req.count;
        }
      });
    } catch (error) {
      console.warn('Notifications load error', error);
    }
  }
  // loadOne(): void {
  //   this.apiStore
  //     .apiClientNotification()
  //     .userNotificationsPaged(this.request.date as string, 1, 1)
  //     .then(({ data }) => {
  //       runInAction(() => {
  //         this.hasOne = !!data.totalCount;
  //       });
  //     })
  //     .catch(error => {
  //       console.warn('Notifications load error', error);
  //     });
  // }
  get lengthNotifications(): number {
    return this.notifications.length;
  }
  async readMessage(notification: UserNotificationsItem): Promise<void> {
    // notification.read = true;
    try {
      await this.apiStore.apiClientNotification().userNotificationsRead(notification.code);
      runInAction(() => {
        const index = this.notifications.indexOf(notification);
        this.notifications.splice(index, 1);
        this.hasOne = !!this.notifications.length;
      });
    } catch (error) {
      // notification.read = false;
      console.error('Notification error', error);
    }
  }
  showBrowserNotification(title: string, text: string, url: string): void {
    if (!title) {
      return;
    }
    if (typeof window === 'undefined') {
      console.error('showBrowserNotification error window');
      return;
    }
    if (this.isFocus) {
      console.error('showBrowserNotification ignore focused');
      return;
    }
    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const notification = new Notification(title, {
        body: text,
      });
      notification.onclick = function (event) {
        event.preventDefault();
        window.open(url, '_blank');
      };
    } catch (error) {
      console.error('Notifications API does not works on the device', error);
    }
  }
  async readAllMessages(): Promise<void> {
    try {
      await this.apiStore.apiClientNotification().userNotificationsReadAll();
      runInAction(() => {
        this.notifications.splice(0);
        this.isLoaded = true;
        this.isLoading = false;
        this.total = 0;
        this.isMoreLoading = false;
        this.hasMore = false;
        this.hasOne = false;
      });
    } catch (error) {
      console.error('Notifications error', error);
    }
  }
  addItem(item: UserNotificationsItem): void {
    if (item) {
      this.notifications.unshift(item);
      this.showBrowserNotification(item.title, item.text, item.url);
    }
  }
}
