import Button from '@mui/material/Button';
import React, { FC, useEffect, useState } from 'react';
import { DialogContainer } from './DialogContainer';
import { Box } from '@mui/material';

interface ConfirmDialogProps {
  onConfirm?: () => void;
  dialogTitle: string;
  minWidth?: string;
  control?: boolean;
  open?: boolean;
  onClose?: (isOk: boolean, value?: string) => void;
  dialogContent: string | JSX.Element;
  children?: (handle: () => void) => JSX.Element;
}
/**
 * Диалоговое окно для подтверждения чего угодно (удаления и т.д.)
 *
 * `control = false` управление диалоговым окном самостоятельно (прокидываем open, handleOpen)
 *
 * `control = true` открытие происходит по кнопке, которая уже в компоненте
 *
 * можно прокинуть children свою кнопку
 */
export const ConfirmDialog: FC<ConfirmDialogProps> = ({
  onConfirm,
  dialogTitle,
  dialogContent,
  children,
  open: openValue = false,
  control = false,
  onClose,
  minWidth = '350px',
}): JSX.Element => {
  const [open, setOpen] = useState<boolean>(openValue);
  useEffect(() => {
    if (control) {
      setOpen(openValue);
    }
  }, [openValue]);
  const handleConfirm = (): void => {
    if (control) {
      onClose(true);
    } else {
      setOpen(false);
      onConfirm();
    }
  };
  const handleClose = (): void => {
    if (control) {
      onClose(false);
    } else {
      setOpen(false);
    }
  };
  const handleOpen = (): void => {
    setOpen(true);
  };

  return (
    <div>
      {!children && !control && (
        <Button color="primary" onClick={handleOpen}>
          Открыть
        </Button>
      )}
      {children && children(handleOpen)}

      <DialogContainer
        open={open}
        onClose={handleClose}
        dialogTitle={dialogTitle}
        dialogContent={<Box minWidth={minWidth}>{dialogContent}</Box>}
        canselBtnText={'Отмена'}
        successBtnText={'Ок'}
        handleSuccess={handleConfirm}
      />
    </div>
  );
};
