import React, { FC } from 'react';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

interface PopupMenuProps {
  options: string[];
}
const ITEM_HEIGHT = 48;

export const PopupMenu: FC<PopupMenuProps> = ({ options = [] }): JSX.Element => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (): void => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        title="Посмотреть все"
        id="long-button"
        aria-controls="long-menu"
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <ArrowDropDownIcon />
      </IconButton>
      <Menu
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
          },
        }}
      >
        {options.map(option => (
          <MenuItem
            // sx={{ padding: '4px 16px' }}
            sx={{ pointerEvents: 'none' }}
            key={option}
            onClick={event => {
              event.stopPropagation();
            }}
          >
            {option}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};
