import { makeAutoObservable } from 'mobx';
import { AxiosResponse } from 'axios';
import { BranchOffice, CompanyPublicOfficesResponse } from '../../api/marketx';
import { AxiosCallContext, getCallContext } from '../../utils/axiosInit';
import { ApiStore } from '../Global/ApiStore';
import { RootStore } from '../StoreManager';

export class OfficeListStore {
  apiStore: ApiStore;

  variant: string;
  offices: BranchOffice[];

  isLoaded = false;

  constructor(rootStore: RootStore) {
    this.apiStore = rootStore.getApiStore();
    makeAutoObservable(this, {
      apiStore: false,
    });
  }

  load(variant: string): void {
    this.variant = variant;
    this.apiStore
      .apiClientCompany()
      .companyPublicOffices(this.variant)
      .then((res: AxiosResponse<CompanyPublicOfficesResponse>): void => {
        this.loadResponse(getCallContext(res), res.data);
      });
  }

  loadResponse(ctx: AxiosCallContext, res: CompanyPublicOfficesResponse): void {
    this.offices = res.offices;
    this.isLoaded = true;
  }
}
