import { Box, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { ConfirmDialog } from 'src/components/ui/dialogs/ConfirmDialog';
import { BillItemInvoicingStore } from './BillItemInvoicingStore';
interface BillItemInvoicingDialogProps {
  billStore: {
    invoicingStore: BillItemInvoicingStore;
    reconcileBill: (documentNumber: string, position?: Record<string, string>) => Promise<void>;
  };
}
const textConfirmPos =
  'Вы уверены, что хотите изменить количество товара в соответствии с выполненными отгрузками? Это необратимое изменение.';

const textConfirmBill =
  'Вы уверены, что хотите изменить количество всех товаров в счете в соответствии с выполненными отгрузками? Это необратимое изменение.';

const textChange =
  'По данному товару отгружено менее порога фактурирования, Вы уверены, что хотите изменить количество в счете? Это необратимое изменение.';

const textDelete = 'По данному товару еще не было отгрузок, Вы уверены, что хотите удалить его из счета ? Это необратимое изменение.';

export const BillItemInvoicingDialog: FC<BillItemInvoicingDialogProps> = observer(({ billStore }): JSX.Element => {
  const handleClose = (isOk = false): void => {
    billStore.invoicingStore.onCloseDialog();

    if (isOk) {
      const documentNumber = billStore.invoicingStore.billDocumentNumber;
      if (billStore.invoicingStore.typeEntity === 'bill') {
        billStore.reconcileBill(documentNumber).finally(() => {
          billStore.invoicingStore.resetStore();
        });
      } else if (billStore.invoicingStore.typeEntity === 'position') {
        billStore.reconcileBill(documentNumber, billStore.invoicingStore.position).finally(() => {
          billStore.invoicingStore.resetStore();
        });
      }
    } else {
      setTimeout(() => {
        billStore.invoicingStore.resetStore();
      }, 250);
    }
  };
  return (
    <Box>
      <ConfirmDialog
        control
        open={billStore.invoicingStore.isShowDialog}
        onClose={handleClose}
        dialogTitle={'Подтвердите фактурирование'}
        dialogContent={
          <>
            {billStore.invoicingStore.typeConfirm === 'change' && <Typography variant={'body2'}>{textChange}</Typography>}
            {billStore.invoicingStore.typeConfirm === 'delete' && <Typography variant={'body2'}>{textDelete}</Typography>}
            {billStore.invoicingStore.typeConfirm === 'confirm' && billStore.invoicingStore.typeEntity === 'bill' && (
              <Typography variant={'body2'}>{textConfirmBill}</Typography>
            )}
            {billStore.invoicingStore.typeConfirm === 'confirm' && billStore.invoicingStore.typeEntity === 'position' && (
              <Typography variant={'body2'}>{textConfirmPos}</Typography>
            )}
          </>
        }
      ></ConfirmDialog>
    </Box>
  );
});
