import React, { FC, useState, useCallback } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { AppDealPosition } from '../../slices/AppDeal';
import { DealItemStore } from '../../store/DealItemStore';
import { observer } from 'mobx-react-lite';
// import EditIcon from '@mui/icons-material/Edit';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import { CollapseButton } from './components/CollapseButton';
import { ChipHandleDnD, DealPositionPrices } from './DealPosition/DealPositionPrices';
import { useSnackbar } from 'notistack';
import { DealPositionAdditional } from './DealPosition/DealPositionAdditional';
import { displayNumber, getMarginalityColor } from './lib';
import { DealPositionTotal } from './DealPosition/DealPositionTotal';
import { ServicesDropDown } from './DealPosition/ServicesDropDown';
import { ContentSwapper } from 'src/components/ui/ContentSwapper';
import { DealPositionWarehouseSelect } from './DealPosition/DealPositionWarehouseSelect';
import { formatNumber3, formatPrice, formatPriceCur } from '@mx-ui/helpers';
import { Link as MxLink } from '@mx-ui/ui';

interface DealTabPositionsContainerProps {
  dealStore: DealItemStore;
  selectedTab?: number;
  position: AppDealPosition;
  dragHandleProps?: DraggableProvidedDragHandleProps;
  onPositionAddDialogClick?: (categoryCode?: string) => void;
}
export const DealTabPositionsContainer: FC<DealTabPositionsContainerProps> = observer(
  ({ dealStore, position, onPositionAddDialogClick, selectedTab, dragHandleProps }): JSX.Element => {
    // const [isSetNamePosition, setNamePosition] = useState<boolean>(false);
    const [selectedAdditionalTab, setSelectedAdditionalTab] = useState(1);
    const { enqueueSnackbar } = useSnackbar();
    const isClosed = dealStore.isPositionClosed(position);
    // const isLeadPosition = position.product.typeCode === 'lead'; //убрал мб в будущем пригодится, но сейчас не нужно

    const isShowDeleteBtn = !dealStore.isViewOnly() && selectedTab == 0;
    const isViewOnly = dealStore.isViewOnly();
    const isShowRestoreBtn = !dealStore.isViewOnly() && selectedTab == 2;
    const marginalityColor = getMarginalityColor(position.marginalityPct);

    const handlePositionDeleteClick = useCallback(() => {
      dealStore
        .deletePosition(position)
        .then(() => {
          enqueueSnackbar(`Товар удален "${position.title}"`, { variant: 'success' });
        })
        .catch(r => console.warn('handlePositionDeleteClick', r));
    }, [dealStore, position]);
    const handlePositionRestoreClick = useCallback((): void => {
      dealStore
        .restorePosition(position)
        .then(() => {
          enqueueSnackbar(`Товар восстановлен "${position.title}"`, { variant: 'success' });
        })
        .catch(r => console.warn('handlePositionRestoreClick', r));
    }, [dealStore, position]);
    const handlePositionClose = useCallback((): void => {
      dealStore.closePosition(position.code);
    }, [dealStore, position]);

    return (
      <Grid item xs={12}>
        <Grid container component={Paper}>
          <Grid item xs={12} pl={1}>
            <Grid container justifyContent="space-between" flexWrap="nowrap">
              <Grid item container width={'calc(100% - 100px)'}>
                {
                  <>
                    <Grid item mt={1}>
                      <ChipHandleDnD
                        dealStore={dealStore}
                        position={position}
                        dragHandleProps={dragHandleProps}
                        label={`#${position.lineNumber}`}
                      />
                    </Grid>
                    <Grid
                      item
                      mt={1}
                      mr={0.5}
                      sx={{
                        ['@media (max-width:600px)']: {
                          width: '100%',
                        },
                        display: 'flex',
                      }}
                    >
                      <Box ml={'4px'} mt={'-1px'}>
                        <MxLink
                          href={`/app/product?productCode=${encodeURIComponent(position.product.code)}`}
                          color="inherit"
                          fontSize={'14px'}
                        >
                          {`${position?.product?.code || ''} `} {position.product?.title || ''}
                        </MxLink>
                      </Box>
                    </Grid>
                    <Grid item mr={1} mt={1}>
                      <DealPositionWarehouseSelect dealStore={dealStore} position={position} />
                    </Grid>
                  </>
                }
              </Grid>
              <Grid
                item
                container
                width={100}
                wrap={'nowrap'}
                justifyContent={'flex-end'}
                alignItems="center"
                height={'31px'}
                pt={1}
                pr={0.5}
              >
                <Box mr={5}>
                  {isClosed ? (
                    <CollapseButton type="expand" onClick={handlePositionClose} />
                  ) : (
                    <CollapseButton type="less" onClick={handlePositionClose} />
                  )}
                </Box>
                {!dealStore.isViewOnly() && selectedTab == 2 && <CollapseButton type="restore" onClick={handlePositionRestoreClick} />}
                {isShowDeleteBtn ? (
                  <CollapseButton disabled={position.isInDeleting} type="delete" onClick={handlePositionDeleteClick} />
                ) : (
                  !isShowRestoreBtn && <Box width={'24px'} height={'24px'}></Box>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item container xl={3} lg={4} md={5} xs={12} p={1}>
            <DealPositionPrices dealStore={dealStore} isViewOnly={isViewOnly} position={position} />
          </Grid>
          <Grid item container xl={6} lg={5} md={7} xs={12} p={1}>
            <ContentSwapper
              checked={isClosed}
              childComponent={'div'}
              transitionName="cross-fade"
              style={{
                width: '100%',
              }}
              transitionEnterTimeout={300}
              transitionLeaveTimeout={300}
            >
              <DealPositionAdditionalWrapper
                key="long"
                dealStore={dealStore}
                position={position}
                isViewOnly={isViewOnly}
                onPositionAddDialogClick={onPositionAddDialogClick}
                selectedAdditionalTab={selectedAdditionalTab}
                setSelectedAdditionalTab={setSelectedAdditionalTab}
              />
              <DealPositionAdditionalWrapperMini key="short" position={position} isViewOnly={isViewOnly} dealStore={dealStore} />
            </ContentSwapper>
          </Grid>

          <Grid item container xl={3} lg={3} p={1}>
            <ContentSwapper
              checked={isClosed}
              childComponent={'div'}
              style={{
                borderRadius: '15px',
                width: '100%',
                border: `solid 1px ${marginalityColor}`,
              }}
              transitionName="cross-fade"
              transitionEnterTimeout={300}
              transitionLeaveTimeout={300}
            >
              <DealPositionTotalWrapper key="long" dealStore={dealStore} position={position} borderColor={marginalityColor} />
              <DealPositionTotalWrapperMini key="short" position={position} marginalityColor={marginalityColor} />
            </ContentSwapper>
          </Grid>
        </Grid>
      </Grid>
    );
  }
);

interface DealPositionTotalWrapperMiniProps {
  marginalityColor: string;
  position: AppDealPosition;
}
const DealPositionTotalWrapperMini = observer<DealPositionTotalWrapperMiniProps, HTMLDivElement>(
  React.forwardRef(({ marginalityColor, position }, ref) => {
    return (
      <Box ref={ref} minHeight={104 - 8 - 8} p={1} display={'flex'} alignContent={'flex-start'} flexDirection={'column'}>
        <Box display={'flex'} justifyContent="space-between" pb={1}>
          <Typography variant="subtitle2" fontWeight={600}>
            Сумма
          </Typography>
          <Typography variant="subtitle2" fontWeight={600}>
            {`${formatPrice(position?.totalCost || 0)}`}&nbsp;₽
          </Typography>
        </Box>
        {!position.isService && (
          <Box display={'flex'} justifyContent="space-between" pb={1}>
            <Typography variant="subtitle2" color={marginalityColor} fontWeight={600}>
              Маржа
            </Typography>
            <Typography variant="subtitle2" color={marginalityColor} fontWeight={600}>
              {`${displayNumber(position?.marginalityPct)}%`}
              <span style={{ fontSize: 12 }}>{` (${formatPriceCur(position?.managerFullCostMargin)})`}</span>
            </Typography>
          </Box>
        )}
      </Box>
    );
  })
);
interface DealPositionAdditionalWrapperMiniProps {
  dealStore: DealItemStore;
  position: AppDealPosition;
  isViewOnly: boolean;
}
const DealPositionAdditionalWrapperMini = observer<DealPositionAdditionalWrapperMiniProps, HTMLDivElement>(
  React.forwardRef(({ position, dealStore, isViewOnly }, ref) => {
    const serviceVisibility = !position.isService;
    const availableServices = [...(position?.services || [])];
    const baseUnit = position?.baseUnit || 'т';
    const enabledServices = availableServices.filter(s => (s.enabled || s.active) && s.totalCost > 0);
    return (
      <div ref={ref}>
        {serviceVisibility && (
          <Grid container item xs={12}>
            <Grid item md={12} container>
              <Grid container>
                <ServicesDropDown
                  dealStore={dealStore}
                  dealPosition={position}
                  isViewOnly={isViewOnly || position.isService}
                  enabledServices={enabledServices}
                />
              </Grid>
              <Grid container pt={2}>
                <Typography variant="body2" color="text.secondary">
                  Цена товара с услугами {formatPriceCur(position.baseFullUnitCost)} x {formatNumber3(position.baseQuantity)} {baseUnit}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        )}
      </div>
    );
  })
);
interface DealPositionTotalWrapperProps {
  dealStore: DealItemStore;
  position: AppDealPosition;
  borderColor?: string;
}
const DealPositionTotalWrapper = observer<DealPositionTotalWrapperProps, HTMLDivElement>(
  React.forwardRef(({ dealStore, position, borderColor }, ref) => {
    return (
      <div ref={ref}>
        <DealPositionTotal dealStore={dealStore} position={position} borderColor={borderColor} />
      </div>
    );
  })
);
interface DealPositionAdditionalWrapperProps {
  dealStore: DealItemStore;
  position: AppDealPosition;
  isViewOnly: boolean;
  onPositionAddDialogClick?: (categoryCode?: string) => void;
  setSelectedAdditionalTab: React.Dispatch<React.SetStateAction<number>>;
  selectedAdditionalTab: number;
}
const DealPositionAdditionalWrapper = observer<DealPositionAdditionalWrapperProps, HTMLDivElement>(
  React.forwardRef(
    ({ dealStore, position, isViewOnly, onPositionAddDialogClick, setSelectedAdditionalTab, selectedAdditionalTab }, ref) => {
      return (
        <Grid ref={ref}>
          <DealPositionAdditional
            dealStore={dealStore}
            dealPosition={position}
            isViewOnly={isViewOnly}
            onPositionAddDialogClick={onPositionAddDialogClick}
            selectedAdditionalTab={selectedAdditionalTab}
            setSelectedAdditionalTab={setSelectedAdditionalTab}
          />
        </Grid>
      );
    }
  )
);
