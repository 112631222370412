import { makeAutoObservable } from 'mobx';
import { AxiosError } from 'axios';

type ErrorType = 'axios' | 'runtime' | 'app';

type StorageItem = {
  // хэш-код поможет избежать дублирование сообщения для пользователя
  messageHash?: string;
  message?: string;
  errorType: ErrorType;
  axiosError?: AxiosError<any, any>;
  requestTime: Date;
};

export type ApplicationErrorEvent = {
  errorType: ErrorType;
  axiosError: AxiosError<any, any>;
  requestTime: Date;
  message: string;
};

interface ErrorHandler {
  (event: StorageItem): void;
}

export class ErrorStore {
  // Задержка перед реакцией на ошибку
  interval: NodeJS.Timeout;
  // Очередь на отображение ошибок
  requestQueue: StorageItem[] = [];
  // Внешний обработчик ошибок
  errorHandler: ErrorHandler;

  constructor() {
    makeAutoObservable(this);
  }

  catchWarn(msg: string): (e: any) => void {
    return () => {
      console.warn(msg);
      this.pushAppError(new Error(msg));
    };
  }

  executeQueue(): void {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = null;
    }
    const executed: Record<string, boolean> = {};
    while (this.requestQueue && this.requestQueue.length) {
      const req = this.requestQueue.shift();
      if (req.messageHash) {
        if (executed[req.messageHash]) {
          // такое сообщение пользователю уже показывалось
          continue;
        }
        // запомнить, чтобы в следующий раз не показывать
        executed[req.messageHash] = true;
      }
      this.executeOne(req);
    }
  }

  // Отображение ошибки клиенту
  executeOne(item: StorageItem): void {
    if (!this.errorHandler) {
      console.warn('no error handler for ', item.errorType, item.axiosError);
      return;
    }
    const e = <ApplicationErrorEvent>{
      errorType: item.errorType,
      axiosError: item.axiosError,
      requestTime: item.requestTime,
      message: 'Произошла ошибка',
    };
    if (e.axiosError) {
      const aErr = e.axiosError;
      const msg = aErr?.response?.data?.error?.message;
      if (msg) {
        e.message = msg;
      } else {
        const rsn = aErr?.response?.data?.error?.reason;
        if (rsn) {
          e.message = 'Ошибка ' + rsn;
        } else if (aErr?.code === 'ECONNABORTED') {
          const url = aErr?.config?.url;
          if (url && (url.indexOf('.ingest.sentry.io/') > 0 || url.indexOf('sentry.marketx.pro/') > 0)) {
            console.warn('ignore sentry axios error', aErr);
            return;
          }
          if (aErr?.message === 'Request aborted') {
            console.warn('ignore aborted request error', aErr);
            return;
          }
          if (aErr?.message && aErr?.message?.substr(0, 11) === 'timeout of ') {
            e.message = 'Превышен интервал для связи с сервером (' + aErr.config.url + ')';
          } else {
            e.message = 'Не удалось связаться с сервером (' + aErr.config.url + ')';
            if (aErr?.message) {
              e.message += ': ' + aErr.message;
            }
          }
        } else if (aErr?.code === undefined && typeof window !== 'undefined' && !window.navigator?.onLine) {
          e.message = 'Не удалось подключиться к серверу. Возможно, это временная проблема или отсутствует подключение к интернету.';
        }
      }
      const traceId = item?.axiosError?.response?.data?.error?.traceId;
      if (traceId) {
        e.message += ' (' + traceId + ')';
      }
    } else if (item.errorType === 'app' && item.message) {
      e.message = item.message;
    }
    this.errorHandler(e);
  }

  /**
   * Ошибка в логике приложения
   */
  pushAppError(error: Error): StorageItem {
    const item = <StorageItem>{
      errorType: 'app',
      message: error.message,
      requestTime: new Date(),
    };
    this.requestQueue.push(item);
    this.runInterval();
    return item;
  }

  /**
   * Добавляет ошибку в очередь для обработки / показа пользователю
   */
  pushAxiosError(error: AxiosError): StorageItem {
    const item = <StorageItem>{
      axiosError: error,
      errorType: 'axios',
      requestTime: new Date(),
    };
    if (item.axiosError) {
      const msg = item?.axiosError?.response?.data?.error?.reason;
      const rsn = item?.axiosError?.response?.data?.error?.message;
      if (msg && rsn) {
        item.messageHash = item.errorType + '--' + rsn + '--' + msg;
      } else {
        const aeMsg = item?.axiosError?.message;
        const aeCode = item?.axiosError?.code;
        if (aeMsg && aeCode) {
          item.messageHash = item.errorType + '--' + aeCode + '--' + aeMsg;
        }
      }
    }
    this.requestQueue.push(item);
    this.runInterval();
    return item;
  }

  setErrorHandler(handler: ErrorHandler): void {
    this.errorHandler = handler;
  }

  runInterval(): void {
    if (this.interval) {
      return;
    }
    this.interval = setInterval(() => {
      this.executeQueue();
    }, 1);
  }
}
