import React, { FC } from 'react';
import { observer } from 'mobx-react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import { formatPrice } from '@mx-ui/helpers';
import TrendingUp from '@mui/icons-material/TrendingUp';
import TrendingDown from '@mui/icons-material/TrendingDown';
import TrendingFlat from '@mui/icons-material/TrendingFlat';
import { Plot } from '../../../lib/plots';
import TodayOutlined from '@mui/icons-material/TodayOutlined';
import MiddleValueChartIcon from '../../../components/Deals/MiddleValueChartIcon';
import { Box } from '@mui/material';

export interface ChangingValueTypographyProps {
  plot: Plot;
  isMiddleVale?: boolean;
  middleBetweenFirstAndLast?: boolean;
}

const ChangingValueTypography: FC<ChangingValueTypographyProps> = observer(
  ({ plot, isMiddleVale, middleBetweenFirstAndLast }): JSX.Element => {
    let value = plot?.changeInValue?.value || 0;
    const unit = plot?.changeInValue?.unit || '';

    const points = plot?.points;
    const lastPoint = points[points.length - 1];

    const lastX = middleBetweenFirstAndLast ? plot.lastPositiveX : lastPoint.x;
    const lastY = middleBetweenFirstAndLast ? plot.lastPositiveY : lastPoint.y;

    if (middleBetweenFirstAndLast) {
      value = plot.lastPositiveY || 0 - plot.firstPositiveY || 0;
    }
    const date = new Date(lastX * 1000);

    let lastValue = lastX ? date.toLocaleDateString().slice(0, 5) : '0.00';
    lastValue += '\u00A0\u00A0\u00A0\u00A0';
    lastValue += formatPrice(Math.round(lastY * 100) / 100) + ' ' + unit;
    return (
      <>
        <Grid container>
          <TodayOutlined sx={{ color: 'text.secondary', width: '20px', height: '20px', margin: '2px', marginRight: '6px' }} />
          <Typography variant="caption" color="text.secondary">
            {lastValue}
          </Typography>
        </Grid>
        {isMiddleVale && (
          <Grid container alignItems="center">
            <Tooltip placement="right-start" title="Средняя">
              <Box sx={{ width: '18px', height: '18px', color: 'text.secondary', margin: '3px', marginRight: '7px' }}>
                <MiddleValueChartIcon />
              </Box>
            </Tooltip>
            <Typography variant="caption" color="text.secondary">{`${formatPrice(plot.middleVal)} ${unit}`}</Typography>
          </Grid>
        )}
        <Grid container alignItems={'center'}>
          <Grid item alignItems={'center'} sx={{ display: 'flex' }}>
            {value > 0 && <TrendingUp sx={{ width: '20px', height: '20px', color: 'text.secondary', margin: '2px', marginRight: '6px' }} />}
            {value < 0 && (
              <TrendingDown sx={{ width: '20px', height: '20px', color: 'text.secondary', margin: '2px', marginRight: '6px' }} />
            )}
            {(value == 0 || value === null) && (
              <TrendingFlat sx={{ width: '20px', height: '20px', color: 'text.secondary', margin: '2px', marginRight: '6px' }} />
            )}
          </Grid>
          <Grid item>
            <Typography variant="caption" color="text.secondary">
              {formatPrice(Math.abs(value))} {plot?.changeInValue?.unit || ''}
            </Typography>
          </Grid>
        </Grid>
      </>
    );
  }
);

export default ChangingValueTypography;
