import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { DealHeader } from './DealHeader';
import { DealSummaryInfo } from './DealSummary';
import { DealTabs } from './DealTabs';
import { DealTabPositionsContainer } from './DealTabPositionsContainer';
import { DealItemStore } from '../../store/DealItemStore';
import { observer } from 'mobx-react-lite';
import { Virtuoso } from 'react-virtuoso';
import { DealSummaryDelivery } from './DealSummary/DealSummaryDelivery';
import { DealSummaryTotal } from './DealSummary/DealSummaryTotal';
import { DragDropContext, Droppable, Draggable, DropResult, DroppableProvided, DraggableProvided } from 'react-beautiful-dnd';
import { DealPositionAddedManuallyContainer } from './DealPosition/DealPositionAddedManuallyContainer';
import { BillList } from 'src/components/Bills/BillList';
import { ShipmentsList } from 'src/components/Shipments/ShipmentsList';
import { AppDealPosition } from 'src/slices/AppDeal';
import { formatDateSwaggerZ } from '@mx-ui/helpers';
import { useLocalStorageForSearchDocuments } from '../../hooks/useLocalStorageForSearchDocuments';
import { ViewModeToogle, ViewModeType } from '../../components/Clients/ViewModeToggle';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { Theme } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { LoadingSkeleton as LoadingSkeletonDealPositionPrices } from './DealPosition/DealPositionPrices';
import { LoadingSkeleton as LoadingSkeletonDealPositionAdditional } from './DealPosition/DealPositionAdditional';
import { LoadingSkeleton as LoadingSkeletonDealPositionTotal } from './DealPosition/DealPositionTotal';
import { AgreementStackList, SalesListSkeleton } from '../../components/Agreements/AgreementList';
import { DocumentsErrorPage } from 'src/components/Documents/DocumentsErrorPage';

interface DealContainerProps {
  dealStore: DealItemStore;
  onPositionAddDialogClick?: (categoryCode?: string) => void;
}
const StackStyle = {
  '& > div:first-of-type': {
    marginTop: 2,
  },
};
if (typeof window !== 'undefined') {
  window.addEventListener('error', e => {
    if (
      e.message === 'ResizeObserver loop completed with undelivered notifications.' ||
      e.message === 'ResizeObserver loop limit exceeded'
    ) {
      e.stopImmediatePropagation();
    }
  });
}
export const DealPositionContainerSkeleton = (): JSX.Element => {
  return (
    <Grid item xs={12}>
      <Grid container component={Paper} sx={{ overflow: 'hidden' }}>
        <LoadingSkeletonDealPositionPrices />
        <LoadingSkeletonDealPositionAdditional />
        <LoadingSkeletonDealPositionTotal />
      </Grid>
    </Grid>
  );
};
export const ColorChangedPropertyDeal = (theme: Theme): string => (theme.palette.mode === 'dark' ? '#31687B' : '#AFDEEF');
interface StackDealPositionsContainerProps {
  provided: DroppableProvided;
  dealStore: DealItemStore;
  selectedTab: number;
  isDraggingOver: boolean;
  addedManuallyPositions: boolean;
  onPositionAddDialogClick?: (categoryCode?: string) => void;
}
interface ItemContentPositionProps {
  position: AppDealPosition;
  dealStore: DealItemStore;
  selectedTab: number;
  onPositionAddDialogClick?: (categoryCode?: string) => void;

  index?: number;
}

const ItemContentPositionDraggableDeal: FC<ItemContentPositionProps & { provided: DraggableProvided }> = observer(
  ({ position, provided, dealStore, selectedTab, onPositionAddDialogClick }) => {
    const code = position.code;
    return (
      <Box ref={provided.innerRef} {...provided.draggableProps} pb={2}>
        <DealTabPositionsContainer
          dealStore={dealStore}
          key={code}
          dragHandleProps={provided.dragHandleProps}
          selectedTab={selectedTab}
          position={position}
          onPositionAddDialogClick={onPositionAddDialogClick}
        />
      </Box>
    );
  }
);
const ItemContentPosition: FC<ItemContentPositionProps> = observer(
  ({ position, selectedTab, dealStore, onPositionAddDialogClick, index }) => {
    const code = position.code;
    // if (selectedTab === 0 && position.isArchive) return <Box key={code} pb={'1px'}></Box>;
    // if (selectedTab === 2 && !position.isArchive) return <Box key={code} pb={'1px'}></Box>;

    if (!(dealStore.isViewOnly() || selectedTab === 2)) {
      return (
        <Draggable key={code} draggableId={code} index={position?.lineNumber ?? index + 1}>
          {provided => (
            <ItemContentPositionDraggableDeal
              dealStore={dealStore}
              key={code}
              provided={provided}
              selectedTab={selectedTab}
              position={position}
              onPositionAddDialogClick={onPositionAddDialogClick}
            />
          )}
        </Draggable>
      );
    }
    return (
      <Box key={code} pb={2}>
        <DealTabPositionsContainer
          dealStore={dealStore}
          key={code}
          selectedTab={selectedTab}
          position={position}
          onPositionAddDialogClick={onPositionAddDialogClick}
        />
      </Box>
    );
  }
);

const StackDealPositionsContainer: FC<StackDealPositionsContainerProps> = observer(
  ({ provided, dealStore, selectedTab, onPositionAddDialogClick, addedManuallyPositions, isDraggingOver }) => {
    const positionsNotArchive = dealStore.deal?.positions?.filter(position => !position.isArchive) || [];
    const newLineNumber = (positionsNotArchive[(positionsNotArchive?.length || 0) - 1]?.lineNumber || 0) + 1;

    const items = useMemo(() => {
      if (selectedTab === 0) {
        return dealStore.deal?.positions?.filter(position => position.isArchive !== true) || [];
      }
      if (selectedTab === 2) {
        return dealStore.deal?.positions?.filter(position => position.isArchive === true) || [];
      }
      return dealStore.deal?.positions || [];
    }, [selectedTab, dealStore, dealStore.deal?.positions]);
    const HeightPreservingItem = React.useCallback(
      ({ children, ...props }) => {
        const knownSize = props['data-known-size'];
        const [size, setSize] = useState(knownSize);
        useEffect(() => {
          setSize(prevSize => {
            return knownSize == 0 ? prevSize : knownSize;
          });
        }, [knownSize]);
        return (
          <div
            {...props}
            className="height-preserving-container"
            style={{
              ...props.style,
              '--child-height': `${size}px`,
              // minHeight: `${size}px`,
              width: '100%',

              position: 'relative',
            }}
          >
            {children}
          </div>
        );
      },
      [dealStore]
    );
    return (
      <Stack {...provided.droppableProps} ref={provided.innerRef} sx={StackStyle} width="100%" spacing={2} p={'1px'}>
        <Virtuoso
          useWindowScroll
          overscan={500}
          scrollerRef={provided.innerRef}
          totalCount={items.length || 0}
          data={items}
          itemContent={(index, position) => {
            return (
              <ItemContentPosition
                dealStore={dealStore}
                position={position}
                index={index}
                selectedTab={selectedTab}
                onPositionAddDialogClick={onPositionAddDialogClick}
              />
            );
          }}
          //  <Draggable draggableId={item.code} index={index} key={item.code}>
          //       {provided => {
          //         return (
          //           <Box key={item.code} ref={provided.innerRef} pb={2} {...provided.draggableProps}>
          //             <DealTabPositionsContainer
          //               dealStore={dealStore}
          //               key={item.code}
          //               dragHandleProps={provided.dragHandleProps}
          //               selectedTab={selectedTab}
          //               position={item}
          //               onPositionAddDialogClick={onPositionAddDialogClick}
          //             />
          //           </Box>
          //         );
          //       }}

          //       {/* {provided => <Item provided={provided} item={item} isDragging={false} />} */}
          //     </Draggable>
          components={{
            Item: HeightPreservingItem as any,
            Footer: () => {
              return (
                addedManuallyPositions &&
                selectedTab === 0 &&
                !isDraggingOver && (
                  <Box pb={1}>
                    <DealPositionAddedManuallyContainer dealStore={dealStore} lineNumber={newLineNumber} />
                  </Box>
                )
              );
            },
          }}
        />

        {provided.placeholder}
      </Stack>
    );
  }
);
export const DealContainer: FC<DealContainerProps> = observer(({ dealStore, onPositionAddDialogClick }): JSX.Element => {
  const [, setDocsMiddleware] = useLocalStorageForSearchDocuments();
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [viewStorage, setViewStorage] = useLocalStorage<ViewModeType>('viewModeBills');
  const [viewShipmentsStorage, setViewShipmentsStorage] = useLocalStorage<ViewModeType>('viewModeShipments');
  const [viewAgreementStorage, setViewAgreementStorage] = useLocalStorage<ViewModeType>('viewModeAgreements');
  const [selectedDeliveryTab, setSelectedDeliveryTab] = useState(0);
  const handleChangeView = (_event: React.MouseEvent<HTMLElement>, nextView: ViewModeType): void => {
    if (nextView) {
      setViewStorage(nextView);
    }
  };
  const handleChangeAgreementView = (_event: React.MouseEvent<HTMLElement>, nextView: ViewModeType): void => {
    if (nextView) {
      setViewAgreementStorage(nextView);
    }
  };

  const handleChangeShipmentsView = (_event: React.MouseEvent<HTMLElement>, nextView: ViewModeType): void => {
    if (nextView) {
      setViewShipmentsStorage(nextView);
    }
  };

  const handleSelectTab = useCallback(
    (index: number): void => {
      setSelectedTab(index);
    },
    [setSelectedTab]
  );

  useEffect(() => {
    if (selectedTab) {
      // если deal.code поменялся - значит мы в другой сделке
      setSelectedTab(0);
    }
  }, [dealStore.deal.code]);
  const onDragEnd = useCallback(
    ({ source, draggableId, destination }: DropResult): void => {
      //* source.index откуда взяли позицию
      //* destination.index куда переместили позицию

      //* dropped outside the list
      if (!destination) {
        return;
      }
      if (source.index === destination.index) {
        return;
      }
      dealStore.movePositionOfDeal(draggableId, destination.index, source.index);
      // dealStore.movePositionOfDealManually(source.index - 1, destination.index - 1);
      // const items = reorder(itemss, result.source.index, result.destination.index);
    },
    [dealStore]
  );
  useEffect(() => {
    if (Object.keys(dealStore.deal).length) {
      const title = `${dealStore.deal.code}${dealStore.deal.createdAt ? ' от ' + formatDateSwaggerZ(dealStore.deal.createdAt) : ''}${
        dealStore.deal?.customer?.shortTitle ? `\u00A0\u00A0${dealStore.deal?.customer?.shortTitle}` : ''
      }`;
      const link = `/app/deals/${encodeURIComponent(dealStore.deal.code)}`;
      setDocsMiddleware({ name: 'deals', title, link });
    }
  }, [Object.keys(dealStore.deal).length]);
  if (dealStore.error) {
    // if (dealStore.error?.reason === 'not_found_document') {
    return <DocumentsErrorPage error={dealStore.error} documentType="deals" />;
  }
  return (
    <Grid container px={1.5} pb={'200px'} pt={1}>
      <DealHeader dealStore={dealStore} />
      <Grid item container spacing={2}>
        <DealSummaryInfo dealStore={dealStore} />
        <DealSummaryDelivery
          dealStore={dealStore}
          selectedDeliveryTab={selectedDeliveryTab}
          setSelectedDeliveryTab={setSelectedDeliveryTab}
        />
        <DealSummaryTotal dealStore={dealStore} setSelectedDeliveryTab={setSelectedDeliveryTab} />
      </Grid>
      <Grid item container position={'relative'}>
        <DealTabs
          dealStore={dealStore}
          selectedTab={selectedTab}
          onPositionAddDialogClick={onPositionAddDialogClick}
          onSelectTab={handleSelectTab}
        />
        {!dealStore.isLoaded && !dealStore.deal?.positionCodes?.length && <DealPositionContainerSkeleton />}
        {dealStore.isLoaded && (selectedTab == 0 || selectedTab == 2) && !!dealStore.deal && (
          <Box sx={{ width: '100%', overflow: 'auto' }} className="scrollHidden">
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable
                droppableId="droppable"
                mode="virtual"
                renderClone={(provided, snapshot, rubric) => {
                  const pos = dealStore.deal.positions.find(p => p.code === rubric.draggableId);
                  return (
                    <ItemContentPositionDraggableDeal
                      dealStore={dealStore}
                      key={pos.code}
                      provided={provided}
                      selectedTab={selectedTab}
                      position={pos}
                      onPositionAddDialogClick={onPositionAddDialogClick}
                    />
                  );
                }}
              >
                {(provided, snapshot) => (
                  <StackDealPositionsContainer
                    provided={provided}
                    isDraggingOver={snapshot.isDraggingOver}
                    dealStore={dealStore}
                    selectedTab={selectedTab}
                    onPositionAddDialogClick={onPositionAddDialogClick}
                    addedManuallyPositions={!dealStore.isViewOnly()}
                  />
                )}
              </Droppable>
            </DragDropContext>
          </Box>
        )}

        {dealStore.isLoaded && selectedTab == 3 && !!dealStore.deal && (
          <Grid container>
            <Grid item xs={12} container justifyContent={'flex-end'} mb={1}>
              <ViewModeToogle value={viewShipmentsStorage} type="shipments" onChange={handleChangeShipmentsView} />
            </Grid>
            <Grid item xs={12}>
              <ShipmentsList store={dealStore.shipmentsListStore} viewType={viewShipmentsStorage} />
            </Grid>
          </Grid>
        )}
        {dealStore.isLoaded && selectedTab == 4 && !!dealStore.deal && (
          <Grid container>
            <Grid item xs={12} container justifyContent={'flex-end'} mb={1}>
              <ViewModeToogle value={viewStorage} type="bills" onChange={handleChangeView} />
            </Grid>
            <Grid item xs={12}>
              <BillList bills={dealStore.billsStore} customer={dealStore.deal.customer} viewType={viewStorage} />
            </Grid>
          </Grid>
        )}

        {dealStore.isLoaded && selectedTab == 5 && !!dealStore.deal && (
          <Grid container>
            <Grid item xs={12} container justifyContent={'flex-end'} mb={1}>
              <ViewModeToogle value={viewAgreementStorage} type="agreements" onChange={handleChangeAgreementView} />
            </Grid>
            <Grid item xs={12}>
              <AgreementTabForeDeal dealStore={dealStore} viewType={viewAgreementStorage} />
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
});

const AgreementTabForeDeal: FC<{ dealStore: DealItemStore; viewType: string }> = observer(({ dealStore, viewType }): JSX.Element => {
  const dealAgreement = dealStore.agreementStore.agreement?.code ? [dealStore.agreementStore.agreement] : [];
  const agreementStore = dealStore.agreementStore;
  useEffect(() => {
    if (dealStore.deal.contractCostAgreements?.length) {
      agreementStore.setAgreementCode(dealStore.deal.contractCostAgreements[0].code);
      agreementStore.startIdleRefresh();
    }
  }, [dealStore.deal.contractCostAgreements?.length]);
  if (!agreementStore.isLoaded || agreementStore.isLoading) {
    return <SalesListSkeleton viewType={viewType} isSimpleDoc />;
  }
  return <AgreementStackList items={dealAgreement} viewType={viewType} />;
});
