import { DevPanelStore } from './DevPanelStore';
import { RootStore } from '../StoreManager';
import { makeAutoObservable, runInAction } from 'mobx';
import { MsgType, WebSocketStore } from './WebSocketStore';
import { v4 } from 'uuid';
import { IncomeMessage } from './EventManager';

/**
 * Клиент к серверу фронтенда
 */
export class F1ClientStore {
  conn: WebSocket;
  wsStore: WebSocketStore;
  devPanel: DevPanelStore;

  /**
   * Номер текущего соединения.
   * По сути количество переподключений.
   */
  lastConnectNumber = 0;
  isConnected = false;

  /**
   * Отключение на время отладки
   */
  disabled = true;

  lastConnectTime?: Date;

  /**
   * Время в мс для переподключения после закрытия соединения
   */
  reconnectDefaultInterval = 5000;
  reconnectTimeout: NodeJS.Timeout;

  constructor(rootStore: RootStore) {
    this.devPanel = rootStore.getDevPanel();
    this.wsStore = rootStore.getWebSocket();
    makeAutoObservable(this, {
      conn: false,
      devPanel: false,
      wsStore: false,
      reconnectTimeout: false,
    });
    if (typeof window !== 'undefined') {
      this.reconnect(1000);
    }
  }

  /**
   * Устанавливает соединение, если оно еще не установлено
   */
  connect(): void {
    if (this.disabled) {
      return;
    }
    if (this.conn) {
      return;
    }
    if (typeof window === 'undefined') {
      console.log('WebSocket disabled while no window');
      this.reconnect(this.reconnectDefaultInterval);
      return;
    }
    if (!window['WebSocket']) {
      console.log('WebSocket no supported by browser');
      return;
    }
    let wsUrl = '/api/f1/f1ws';
    if (!wsUrl) {
      console.error('WebSocket url not defined');
      return;
    }
    if (wsUrl.indexOf('/') === 0) {
      if (window.location.protocol === 'https:') {
        wsUrl = 'wss://' + window.location.host + wsUrl;
      } else {
        wsUrl = 'ws://' + window.location.host + wsUrl;
      }
    }

    const conn = new WebSocket(wsUrl);
    this.conn = conn;

    conn.onopen = (): void => {
      runInAction(() => {
        this.isConnected = true;
        this.lastConnectTime = new Date();
        this.lastConnectNumber++;
        this.processMessages(<IncomeMessage>{
          msgType: MsgType.WEB_SOCKET_F1_OPEN,
          data: {
            connectNumber: this.lastConnectNumber,
          },
        });
      });
    };

    conn.onclose = (ev: CloseEvent): void => {
      runInAction(() => {
        this.isConnected = false;
        this.processMessages(<IncomeMessage>{
          msgType: MsgType.WEB_SOCKET_F1_CLOSED,
          data: {
            code: ev.code,
            reason: ev.reason,
            wasClean: ev.wasClean,
            bubbles: ev.bubbles,
          },
        });
        if (this.conn === conn) {
          this.reconnect(this.reconnectDefaultInterval);
        }
      });
    };

    conn.onmessage = (evt: MessageEvent): void => {
      if (!evt.data?.split) {
        console.error('F1ClientStore invalid evt', evt.data);
        return;
      }
      const messages = evt.data.split('\n');
      const parsed = new Array<IncomeMessage>();
      for (let i = 0; i < messages.length; i++) {
        const msg = <IncomeMessage>{
          msgType: MsgType.WEB_SOCKET_INVALID,
          data: messages[i],
        };
        try {
          const p = JSON.parse(messages[i]);
          msg.msgType = p.msgType || MsgType.WEB_SOCKET_UNKNOWN;
          msg.data = p.data || undefined;
        } catch (e) {
          console.warn('bad web socket income json', e, messages[i]);
        }

        parsed.push(msg);
      }
      this.processMessages(...parsed);
    };
  }

  reconnect(delayMs?: number): void {
    if (this.conn) {
      const conn = this.conn;
      this.conn = undefined;
      if (conn.readyState !== WebSocket.CLOSED) {
        conn.close();
      }
    }
    if (this.disabled) {
      return;
    }
    if (this.reconnectTimeout) {
      clearTimeout(this.reconnectTimeout);
      this.reconnectTimeout = null;
    }
    if (delayMs > 0) {
      this.reconnectTimeout = setTimeout(() => {
        this.connect();
      }, delayMs);
    } else {
      this.connect();
    }
  }

  processMessages(...messages: IncomeMessage[]): void {
    console.log('f1 process', ...messages);
    this.wsStore.processMessages(...messages);
  }

  /**
   * Запрос аналогичный HTTP GET /api/about
   */
  requestAbout(): void {
    this.send('ws.f1.get.about.request', undefined);
  }

  /**
   * Отправка сообщения на сервер
   * @param msgType
   * @param data
   */
  send(msgType: string, data: {}): void {
    if (!this.conn) {
      console.error('no connection for sending msgType', msgType);
      return;
    }
    if (this.conn.readyState !== WebSocket.OPEN) {
      console.error('not connected (' + this.conn.readyState + ') for sending msgType', msgType);
      return;
    }
    this.conn.send(
      JSON.stringify({
        msgType: msgType,
        traceId: v4(),
        data: data,
      })
    );
  }
}
