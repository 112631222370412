import { makeAutoObservable, runInAction } from 'mobx';
import { EmployeeSet, MyApprovalsAskingResponse } from '../../api/marketx';
import { AxiosResponse } from 'axios';
import { AxiosCallContext, getCallContext } from '../../utils/axiosInit';
import { ApiStore } from '../Global/ApiStore';
import { RootStore } from '../StoreManager';

// Список сотрудников, кто может запрашивать согласования
export class MyApprovalsAskingStore {
  apiStore: ApiStore;
  svc: MyApprovalsAskingService;

  isLoaded = false;
  public ignoreBeforeDate?: Date;

  isLoading = false;

  employees: EmployeeSet[] = new Array<EmployeeSet>();

  constructor(rootStore: RootStore) {
    this.apiStore = rootStore.getApiStore();
    this.svc = new MyApprovalsAskingService();
    makeAutoObservable(this, {
      apiStore: false,
      svc: false,
    });
  }

  findByCode(code: string): EmployeeSet | undefined {
    if (!code) {
      console.log('findByCode empty', code);
      return undefined;
    }
    const res = this.employees.find(e => e.code === code);
    console.log('findByCode', code, res);
    return res;
  }

  setResult(ctx: AxiosCallContext, res: MyApprovalsAskingResponse): void {
    if (this.ignoreBeforeDate && this.ignoreBeforeDate.getTime() > ctx.startTime.getTime()) {
      console.log('ignore irrelevant consignee list response');
      return;
    }
    this.ignoreBeforeDate = ctx.startTime;
    runInAction(() => {
      this.employees = res.asking;
      this.isLoaded = true;
      this.isLoading = false;
    });
  }

  loadMyList(employeeCode?: string, withNonDistributor?: boolean): void {
    this.ignoreBeforeDate = new Date();
    runInAction(() => {
      if (!this.isLoading) {
        this.isLoading = true;
        this.svc.executeOne(this, employeeCode, withNonDistributor);
      }
    });
  }
}

class MyApprovalsAskingService {
  executeOne(store: MyApprovalsAskingStore, employeeCode?: string, withNonDistributor?: boolean): void {
    store.apiStore
      .apiClientMy()
      .myApprovalsAsking(employeeCode ? [employeeCode] : undefined, withNonDistributor)
      .then((res: AxiosResponse<MyApprovalsAskingResponse>): void => {
        store.setResult(getCallContext(res), res.data);
      });
  }
}
