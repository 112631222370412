import React, { FC, JSX } from 'react';
import { observer } from 'mobx-react-lite';
import Skeleton from '@mui/material/Skeleton';
import { formatPrice } from '@mx-ui/helpers';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import Divider from '@mui/material/Divider';
import Accordion from '@mui/material/Accordion';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import {
  CatalogProductsAlternativesNomenclature,
  CatalogProductsAlternativesPositionProduct,
  CatalogProductsAlternativesSegmentationGroup,
  Product,
} from '../../../api/marketx';
import { PositionsManufacturersListStore } from '../../../store/Deals/PositionsManufacturersListStore';
import { ProductLinkBtn } from '../../../components/Product/ProductLinkBtn/ProductLinkBtn';
import { ProductIndicatorsByValueForAccordion } from '../../catalog/Listing/ProductPeculiaritiesIndicators';
import ManageSearchRoundedIcon from '@mui/icons-material/ManageSearchRounded';
import Button from '@mui/material/Button';
import { multicoloredPopoverBg } from '../../clients/lib';
import Box from '@mui/material/Box';
import { alternativeInTransitText, alternativeStockText } from './AlternativeProductsTableForCatalog';

interface AlternativeProductsTableForCatalogMobileProps {
  manufacturersStore: PositionsManufacturersListStore;
  alternativeObj: CatalogProductsAlternativesSegmentationGroup | CatalogProductsAlternativesNomenclature;
  canViewPurchasePrice: boolean;
  position: Product;
  onSearch?: (value: string) => void;
}
export const AlternativeProductsTableForCatalogMobile: FC<AlternativeProductsTableForCatalogMobileProps> = observer(
  ({ alternativeObj, position, manufacturersStore, canViewPurchasePrice, onSearch }): JSX.Element => {
    const colorForStock = alternativeObj?.inStockForSale > 0 ? 'green' : 'red';
    return (
      <Grid item xs={12} container>
        <Grid item xs={12} p={1}>
          <Grid container alignItems={'center'}>
            <Grid item xs={12}>
              <Typography component="span" variant="body2" fontWeight={500}>
                {/* @ts-ignore*/}
                {alternativeObj?.name || alternativeObj?.title || ''}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container alignItems={'center'} spacing={1}>
                <Grid item>
                  <Box
                    display="flex"
                    sx={{
                      borderRadius: 1,
                      mr: 0.5,
                      height: '20px',
                      ...multicoloredPopoverBg(colorForStock),
                    }}
                  >
                    <Typography variant="caption" color="inherit" fontWeight="500">
                      {alternativeStockText(
                        alternativeObj?.inStockForSale,
                        alternativeObj?.inStock,
                        alternativeObj?.stockUnitCode,
                        alternativeObj?.stockUnitShortName
                      )}
                    </Typography>
                  </Box>
                </Grid>
                {((alternativeObj?.inTransitQuantity || alternativeObj?.distributedQuantity) && (
                  <Grid item>
                    <Box
                      display="flex"
                      sx={{
                        borderRadius: 1,
                        mr: 0.5,
                        height: '20px',
                        backgroundColor: '#D6D6D6',
                        border: '1px solid #242424',
                      }}
                    >
                      <Typography variant="caption" sx={{ color: '#333' }} fontWeight="500">
                        {alternativeInTransitText(
                          alternativeObj?.inTransitQuantity,
                          alternativeObj?.distributedQuantity,
                          alternativeObj?.stockUnitCode,
                          alternativeObj?.stockUnitShortName
                        )}
                      </Typography>
                    </Box>
                  </Grid>
                )) ||
                  null}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        {(!manufacturersStore.loaded || manufacturersStore.loading) && (
          <Grid item xs={12} container minWidth={'80vw'}>
            <Grid item xs={12} container alignItems={'center'} flexWrap={'nowrap'}>
              <Skeleton width={'100%'} height={'60px'} />
            </Grid>
            <Grid item xs={12} container alignItems={'center'} flexWrap={'nowrap'}>
              <Skeleton width={'100%'} height={'60px'} />
            </Grid>
            <Grid item xs={12} container alignItems={'center'} flexWrap={'nowrap'}>
              <Skeleton width={'100%'} height={'60px'} />
            </Grid>
          </Grid>
        )}
        {manufacturersStore.loaded && !alternativeObj?.products?.length && (
          <Typography sx={{ py: 1, px: 2, mb: 1 }} variant="body2" component="div">
            Альтернативных товаров нет
          </Typography>
        )}
        {manufacturersStore.loaded &&
          alternativeObj?.products?.length &&
          alternativeObj?.products.map((product, index) => (
            <React.Fragment key={product?.productCode || index}>
              <AlternativeProductsTableForCatalogItem
                product={product}
                position={position}
                canViewPurchasePrice={canViewPurchasePrice}
                onSearch={onSearch}
              />
            </React.Fragment>
          ))}
      </Grid>
    );
  }
);

interface AlternativeProductsTableForCatalogItemProps {
  product: CatalogProductsAlternativesPositionProduct;
  position: Product;
  canViewPurchasePrice: boolean;
  onSearch?: (value: string) => void;
}

const AlternativeProductsTableForCatalogItem: FC<AlternativeProductsTableForCatalogItemProps> = observer(
  ({ product, position, canViewPurchasePrice, onSearch }): JSX.Element => {
    const isCurrent = product.productCode === position.code;
    const colorForStock = product?.inStockForSale > 0 ? 'green' : 'red';
    const handleProductSearch = (value: string): void => {
      if (onSearch) {
        onSearch(value);
      }
    };
    return (
      <Accordion
        sx={{
          width: '100%',
          margin: '0px !important',
          boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%)',
          ':before': {
            display: 'none',
          },
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{
            borderTop: '1px solid #DBDBDB',
            background: theme => (isCurrent ? (theme.palette.mode === 'dark' ? '#090909' : '#EDEEF5') : 'inherit'),
          }}
        >
          <Grid container sx={{ position: 'relative' }}>
            <Grid item xs={12} container alignItems={'center'}>
              <Grid item>
                <Typography component="span" variant="caption">
                  {`${product?.productCode || ''} ${product.productTitle}`}
                </Typography>
              </Grid>
              <Grid item>
                <Grid container alignItems={'center'} spacing={1}>
                  <Grid item>
                    <Box
                      display="flex"
                      sx={{
                        borderRadius: 1,
                        mr: 0.5,
                        height: '20px',
                        ...multicoloredPopoverBg(colorForStock),
                      }}
                    >
                      <Typography variant="caption" color="inherit" fontWeight="500">
                        {alternativeStockText(
                          product?.inStockForSale,
                          product?.inStock,
                          product?.stockUnitCode,
                          product?.stockUnitShortName
                        )}
                      </Typography>
                    </Box>
                  </Grid>
                  {((product?.inTransitQuantity || product?.distributedQuantity) && (
                    <Grid item>
                      <Box
                        display="flex"
                        sx={{
                          borderRadius: 1,
                          mr: 0.5,
                          height: '20px',
                          backgroundColor: '#D6D6D6',
                          border: '1px solid #242424',
                        }}
                      >
                        <Typography variant="caption" sx={{ color: '#333' }} fontWeight="500">
                          {alternativeInTransitText(
                            product?.inTransitQuantity,
                            product?.distributedQuantity,
                            product?.stockUnitCode,
                            product?.stockUnitShortName
                          )}
                        </Typography>
                      </Box>
                    </Grid>
                  )) ||
                    null}
                </Grid>
              </Grid>
              <ProductLinkBtn productCode={position.code} />
            </Grid>
            <Grid item xs={12}>
              <ProductIndicatorsByValueForAccordion
                isNew={product?.batches && !!product.isNew}
                isOld={product?.batches && !!product.isOld}
                hasCustody={!!product.hasCustody}
                hasSelfPurchase={!!product.hasSelfPurchase}
                hasTodh={!!product.hasTodhDiscount}
                hasIlliquid={!!product.hasIlliquid}
              />
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          {(onSearch && (
            <Grid container alignItems={'center'} justifyContent="flex-end">
              <Grid item>
                <Button
                  variant={'outlined'}
                  size={'small'}
                  title={'Поиск по товару'}
                  onClick={() => handleProductSearch(product.productTitle)}
                  startIcon={<ManageSearchRoundedIcon />}
                >
                  Поиск по товару
                </Button>
              </Grid>
            </Grid>
          )) ||
            null}
          <Grid container alignItems={'center'} justifyContent="space-between">
            <Grid item>
              <Typography fontSize={'12px'}>Цена закупки</Typography>
            </Grid>
            <Grid item>
              {!!canViewPurchasePrice ? (
                <Typography component="span" variant="caption">
                  {`${formatPrice(product?.purchasePrice)} ₽`}
                </Typography>
              ) : (
                <VisibilityOffOutlinedIcon />
              )}
            </Grid>
          </Grid>
          <Divider />
          <Grid container alignItems={'center'} justifyContent="space-between">
            <Grid item>
              <Typography fontSize={'12px'}>МРЦ</Typography>
            </Grid>
            <Grid item>
              <Typography component="span" variant="caption">
                {`${formatPrice(product?.minRetailUnitCost)} ₽`}
              </Typography>
            </Grid>
          </Grid>
          <Divider />
          <Grid container alignItems={'center'} justifyContent="space-between">
            <Grid item>
              <Typography fontSize={'12px'}>Срок хранения</Typography>
            </Grid>
            <Grid item>
              <Typography component="span" variant="caption">
                {`${product?.oldShelfLife || '-'}`}
              </Typography>
            </Grid>
          </Grid>
          {product.batches?.length && <Divider />}
          {product.batches?.length
            ? product.batches.map((batch, batchIndex) => {
                return (
                  <Grid container key={batch.code}>
                    <Grid item xs={12} container alignItems={'center'} justifyContent="space-between" pt={1}>
                      <Grid item>
                        <Typography component="span" variant="body2" fontWeight={500}>
                          {batch.code || ''}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <ProductIndicatorsByValueForAccordion
                          hasCustody={!!batch.hasCustody}
                          hasSelfPurchase={!!batch.hasSelfPurchase}
                          hasTodh={!!batch.hasTodhDiscount}
                          hasIlliquid={!!batch.hasIlliquid}
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={12} container alignItems={'center'} justifyContent="space-between" pl={2}>
                      <Grid item>
                        <Typography fontSize={'12px'}>Цена закупки</Typography>
                      </Grid>
                      <Grid item>
                        {!!canViewPurchasePrice ? (
                          <Typography component="span" variant="caption">
                            {`${formatPrice(batch?.purchasePrice)} ₽`}
                          </Typography>
                        ) : (
                          <VisibilityOffOutlinedIcon />
                        )}
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                    <Grid item xs={12} container alignItems={'center'} justifyContent="space-between" pl={2}>
                      <Grid item>
                        <Typography fontSize={'12px'}>Срок хранения</Typography>
                      </Grid>
                      <Grid item>
                        <Typography component="span" variant="caption">
                          {`${batch?.shelfLife || '-'}`}
                        </Typography>
                      </Grid>
                    </Grid>
                    {batchIndex !== product.batches.length - 1 && (
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                    )}
                  </Grid>
                );
              })
            : null}
        </AccordionDetails>
      </Accordion>
    );
  }
);
