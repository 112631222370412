import React, { FC, useCallback, useState } from 'react';
import { observer } from 'mobx-react';
import Box from '@mui/material/Box';
import { MxLabel } from '@mx-ui/ui';
import { formatYmdHiLZ, isValidDateYear } from '@mx-ui/helpers';
import { ApproveResolutionDialog } from './ApproveResolutionDialog';
import { ApproveResolutionList } from './ApproveResolutionList';
import { Control, StyledBadge, MenuItem, DealApprovalActionType } from '.';
import { ApprovalStoreInterface, ConfirmDialogContent } from '../../../store/DealItemStore';
import { Paper, Popper } from '@mui/material';
interface ApproveResolutionLabelProps {
  approval: ApprovalStoreInterface;
  control: Control;
  menuItems: MenuItem[];
  onExecuteByType: (action: string, comment?: string) => void;
}

export const ApproveResolutionLabel: FC<ApproveResolutionLabelProps> = observer(
  ({ approval, control, menuItems, onExecuteByType }): JSX.Element => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [openMenu, setOpenMenu] = React.useState<boolean>(false);

    const [dialogContent, setDialogContent] = useState<ConfirmDialogContent>({
      text: '',
      header: '',
      contentHeader: '',
      isShowInput: false,
      isInputRequired: false,
      action: undefined,
    });
    const [openDialog, setOpenDialog] = useState(false);
    const handleCloseDialog = (isOk = false, action: string, newValue?: string): void => {
      setOpenDialog(false);
      if (isOk) {
        onExecuteByType(action, newValue);
      }
    };
    const handleClickMenuItem = (action: DealApprovalActionType): void => {
      const item = menuItems.find(item => item.action === action);
      if (item?.isShowModal) {
        setDialogContent(approval.buildContentForDialog(action, item.label));
        setOpenDialog(true);
        setOpenMenu(false);
        setAnchorEl(null);
      } else {
        onExecuteByType(action);
      }
    };
    const handleApproveResolutionOpen = useCallback(
      (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (!openDialog) {
          setOpenMenu(true);
          setAnchorEl(event.target);
        }
      },
      [approval, openDialog]
    );
    const handleApproveResolutionClose = useCallback(() => {
      setOpenMenu(false);
      setAnchorEl(null);
    }, [approval]);
    return (
      <Box onMouseEnter={handleApproveResolutionOpen} onMouseLeave={handleApproveResolutionClose}>
        <Box>
          <StyledBadge variant="dot" invisible={!approval.approval?.resolutionComment}>
            <MxLabel color={control.color} component="div" sx={{ flexShrink: 1 }}>
              {control.label} {isValidDateYear(approval.approval?.resolutionDate) && formatYmdHiLZ(approval.approval?.resolutionDate)}
            </MxLabel>
          </StyledBadge>
        </Box>
        <ApproveResolutionDialog
          keepMounted
          open={openDialog}
          dialogHeader={dialogContent.header}
          dialogContentHeader={dialogContent.contentHeader}
          dialogContentText={dialogContent.text}
          action={dialogContent.action}
          isShowInputComment={dialogContent.isShowInput}
          isInputCommentRequired={dialogContent.isInputRequired}
          onClose={handleCloseDialog}
          defaultCommentValue={approval.approval?.resolutionComment}
        />
        <Popper placement="top" open={openMenu} anchorEl={anchorEl} sx={{ zIndex: 1500 }}>
          <Paper>
            <ApproveResolutionList handleClickMenuItem={handleClickMenuItem} approval={approval} control={control} menuItems={menuItems} />
          </Paper>
        </Popper>
      </Box>
    );
  }
);
