// @ts-nocheck
import React, { useState, FC } from 'react';

// Components
import { convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import dynamic from 'next/dynamic';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState } from 'react-draft-wysiwyg';
import { styled } from '@mui/material/styles';
const Editor = dynamic(() => import('react-draft-wysiwyg').then(mod => mod.Editor), { ssr: false });

interface WYSIWYGEditorProps {
  onChange: (v: any) => void;
  value: EditorState;
  readOnly: boolean;
}
export const StyledEditor = styled('div')(({ theme }) => ({
  [`& .rdw-editor-toolbar`]: {
    backgroundColor: theme.palette.background.paper,
  },
  '.rdw-dropdown-wrapper': {
    backgroundColor: theme.palette.mode === 'dark' ? '#888787' : theme.palette.common.white,
  },
  '.rdw-option-wrapper': {
    backgroundColor: theme.palette.mode === 'dark' ? '#888787' : theme.palette.common.white,
  },
  '.rdw-dropdown-optionwrapper': {
    backgroundColor: theme.palette.mode === 'dark' ? '#6a6a6a' : theme.palette.common.white,
  },
  '.rdw-dropdownoption-highlighted': {
    backgroundColor: theme.palette.mode === 'dark' ? '#424242' : '#F1F1F1',
  },
  '.rdw-dropdownoption-active': {
    backgroundColor: theme.palette.mode === 'dark' ? '#242424' : '#f5f5f5',
  },
}));
const WYSIWYGEditorOptions = {
  options: [
    'inline',
    'blockType',
    'fontSize',
    // 'fontFamily',
    'list',
    'textAlign',
    // 'colorPicker',
    'link',
    // 'embedded',
    'emoji',
    // 'image',
    // 'remove',
    // 'history',
  ],
};
export const WYSIWYGEditor: FC<WYSIWYGEditorProps> = ({ value, readOnly = false, onChange }): JSX.Element => {
  // Warning: Can't call setState on a component that is not yet mounted. This is a no-op, but it might indicate a bug in your application. Instead, assign to `this.state` directly or define a `state = {};` class property with the desired state in the c component.
  // https://github.com/jpuri/react-draft-wysiwyg/issues/951
  const [editorState, setEditorState] = useState<EditorState>(value);
  const handleEditorStateChange = (editorState): void => {
    setEditorState(editorState);
    return onChange(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  };

  return (
    <StyledEditor className="editor">
      <Editor
        editorState={editorState}
        // toolbarStyle={{
        //   backgroundColor: theme.palette.background.paper,
        //   '.rdw-dropdown-wrapper': {
        //     backgroundColor: theme.palette.mode === 'dark' ? '#888787' : theme.palette.common.white,
        //   },
        //   '.rdw-option-wrapper': {
        //     backgroundColor: theme.palette.mode === 'dark' ? '#888787' : theme.palette.common.white,
        //   },
        //   // backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.default : theme.palette,
        // }}
        wrapperClassName="wrapper-class"
        editorClassName="editor-class"
        readOnly={readOnly}
        localization={{
          locale: 'ru',
        }}
        toolbar={WYSIWYGEditorOptions}
        onEditorStateChange={handleEditorStateChange}
      />
    </StyledEditor>
  );
};
