import { FileUpload } from './types';

export interface ErrorDuplicateFile {
  name: string;
  code: string;
  message: string;
}
//* c бека пришел type "text/plain; charset=utf-8" вместо "text/plain" поэтому проверяем на вхождение fileUpload.type
//* c бека пришло application/vnd.openxmlformats-officedocument.wordprocessingml.document а файл с типом text/plain
export const isIdenticalFile = (fileUpload: File, fileNew: File): boolean => {
  return fileUpload.name === fileNew.name && fileUpload.size === fileNew.size;
  // return fileUpload.name === fileNew.name && fileUpload.size === fileNew.size && fileUpload.type.indexOf(fileNew.type) !== -1;
};
export const checkDuplicateFiles = (
  existFiles: FileUpload[],
  files: File[] | FileUpload[]
): [File[] | FileUpload[], ErrorDuplicateFile[]] => {
  const acceptedFiles: File[] = [];
  const errors: ErrorDuplicateFile[] = [];
  files.forEach(file => {
    const duplicateFile = existFiles.find(existFile => isIdenticalFile(existFile, file));
    if (duplicateFile) {
      errors.push({
        name: file.name,
        code: 'duplicate-file',
        message: `Файл ${file.name} уже добавлен`,
      });
    } else {
      acceptedFiles.push(file);
    }
  });
  return [acceptedFiles, errors];
};
