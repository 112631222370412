import { makeAutoObservable, runInAction } from 'mobx';
import { Employee, EmployeesResponse, EmployeesSearchRequestFilter } from 'src/api/marketx';
import { ApiStore } from '../Global/ApiStore';
import { RootStore } from '../StoreManager';
import { AppUserGroupCodes, AppUserOfficeCodes } from 'src/types/AppUserGroup';
import { AuthStore } from '../AuthStore';
import { AxiosPromise } from 'axios';
import { setClear } from 'src/utils/mobx';
type EmployeesRequest = EmployeesSearchRequestFilter;
export class EmployeesStore {
  apiStore: ApiStore;
  authStore: AuthStore;

  svc: EmployeesService;

  items: Employee[] = [];
  request: EmployeesRequest = {};
  page = 0;
  total = 0;
  isLoaded = false;
  isLoading = false;

  constructor(rootStore: RootStore) {
    this.apiStore = rootStore.getApiStore();
    this.svc = new EmployeesService();
    this.authStore = rootStore.getAuth();
    makeAutoObservable(this, {
      apiStore: false,
      authStore: false,
      svc: false,
    });
  }
  loadEpmloyeesByAction(): void {
    runInAction(() => {
      this.isLoading = true;
    });
    this.svc
      .loadEpmloyeesByAction(this)
      .then(({ data }) => this.setItems(data.employees))
      .catch(r => {
        console.warn('error', r);
      });
  }
  setRequest(req: EmployeesRequest): void {
    setClear(this.request, req);
  }

  loadItems(): void {
    this.isLoaded = true;
    this.isLoading = false;
    this.svc
      .loadOffice(this)
      .then(({ data }) => this.setItems(data.employees))
      .catch(r => {
        console.warn('error', r);
      });
  }
  setItems(employees: Employee[]): void {
    this.items = employees || [];
  }
}

export class EmployeesService {
  loadOffice(store: EmployeesStore): AxiosPromise<EmployeesResponse> {
    const branchOfficeCode = store.authStore.profile?.chosenBranchOfficeCode;
    return store.apiStore.apiEmployee().employeesSearch({
      count: 999999,
      page: 1,
      filter: {
        branchOfficeCode,
        hasUser: true,
        query: undefined,
        userGroupCodes: [
          AppUserGroupCodes.SELLER_CHIEF,
          AppUserGroupCodes.DIVISION_CHIEF,
          AppUserGroupCodes.SELLER,
          AppUserGroupCodes.OFFICE_CHIEF,
          AppUserGroupCodes.CUSTOMER_EDITOR,
          AppUserGroupCodes.DIVISION_MARKETER,
          AppUserGroupCodes.COMPANY_MARKETER,
          AppUserGroupCodes.OFFICE_MARKETER,
        ],
      },
    });
  }
  loadCustomerEditor(store: EmployeesStore): AxiosPromise<EmployeesResponse> {
    const branchOfficeCode = AppUserOfficeCodes.MKMCENTER;
    return store.apiStore.apiEmployee().employeesSearch({
      filter: {
        branchOfficeCode,
        query: undefined,
        hasUser: true,
        userGroupCodes: [AppUserGroupCodes.CUSTOMER_EDITOR],
      },
    });
  }
  loadEpmloyeesByAction(store: EmployeesStore): AxiosPromise<EmployeesResponse> {
    const req = Object.assign({}, store.request);
    let filter = undefined;
    if (
      (req.specialAction !== null && req.specialAction !== undefined) ||
      (req.specialActionObjectKey !== null && req.specialActionObjectKey !== undefined)
    ) {
      filter = {
        specialAction: req.specialAction,
        specialActionObjectKey: req.specialActionObjectKey,
      };
    }
    return store.apiStore.apiEmployee().employeesSearch({
      count: 999,
      page: 1,
      filter,
    });
  }
}
