import { FC, useCallback, useState } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { AppDealPosition } from '../../../slices/AppDeal';
import { observer } from 'mobx-react-lite';
import { DealItemStore } from '../../../store/DealItemStore';
import { TabSelectWrapper } from '../../../components/Deals/CustomIconTabs/TabSelectWrapper';
import { customViewOnlyComment } from '../DealSummary/DealSummaryTabComment';
import { CustomInput } from '../components/CustomInput';

interface DealPositionAdditionalTabCommentProps {
  dealStore: DealItemStore;
  dealPosition: AppDealPosition;
  isViewOnly?: boolean;
}
// Закладка с информацией о товаре в строке товара в сделке
export const DealPositionAdditionalTabComment: FC<DealPositionAdditionalTabCommentProps> = observer(
  ({ dealStore, isViewOnly, dealPosition }): JSX.Element => {
    const approvalComments = dealPosition?.comments?.filter(item => item.typeCode === 'approval')[0]?.comment || '';
    const warehouseComments = dealPosition?.comments?.filter(item => item.typeCode === 'store')[0]?.comment || '';
    const commercialProposalComments = dealPosition?.comments?.filter(item => item.typeCode === 'commercial_proposal')[0]?.comment || '';
    const [currentValue, setCurrentValue] = useState(approvalComments);
    const [currentWarehouseComment, setCurrentWarehouseComment] = useState(warehouseComments);
    const [currentCommercialProposalComment, setCurrentCommercialProposalComment] = useState(commercialProposalComments);

    // обработчик без задержки
    const handleCommentChangeDirect = useCallback(
      (value: string): void => {
        setCurrentValue(value);
      },
      [setCurrentValue]
    );

    const handleWarehouseCommentChangeDirect = useCallback(
      (value: string): void => {
        setCurrentWarehouseComment(value);
      },
      [setCurrentWarehouseComment]
    );

    const handleCommercialProposalCommentChangeDirect = useCallback(
      (value: string): void => {
        setCurrentCommercialProposalComment(value);
      },
      [setCurrentCommercialProposalComment]
    );

    // обработчик с задержкой
    const handleCommentChangeDebounced = useCallback(
      (value: string, typeCode): void => {
        dealStore
          .updatePosition(dealPosition, {
            comments: [{ comment: value, typeCode }],
            ['typeCode' + typeCode]: typeCode,
          } as any)
          .catch(r => console.warn('handleCommentChange', r));
      },
      [dealStore, dealPosition]
    );

    return (
      <TabSelectWrapper>
        <Grid container className="withCustomScroll" pr={1} pt={1} sx={{ maxHeight: '100%' }}>
          <Grid item xs={12} sx={{ maxHeight: '100%' }} mb={1}>
            <Typography variant="body2" color="text.secondary">
              Комментарий для согласования
            </Typography>
            <CustomInput
              value={currentValue}
              isViewOnly={isViewOnly}
              onChangeDirect={handleCommentChangeDirect}
              onChangeDebounced={v => handleCommentChangeDebounced(v, 'approval')}
              width="100%"
              viewOnlyComponent={customViewOnlyComment}
              type="text"
              multiline
              maxLength={512}
              maxRows={3}
              sx={{ '& .MuiOutlinedInput-input': { fontWeight: '400!important' } }}
            />
          </Grid>
          <Grid item mb={1} xs={12}>
            <Typography variant="body2" color="text.secondary" sx={{ paddingLeft: 0 }}>
              Комментарий для склада
            </Typography>
            <CustomInput
              value={currentWarehouseComment}
              isViewOnly={isViewOnly}
              onChangeDirect={handleWarehouseCommentChangeDirect}
              onChangeDebounced={v => handleCommentChangeDebounced(v, 'store')}
              width="100%"
              viewOnlyComponent={customViewOnlyComment}
              type="text"
              multiline
              maxLength={512}
              maxRows={3}
              sx={{ '& .MuiOutlinedInput-input': { fontWeight: '400!important' } }}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" color="text.secondary" sx={{ paddingLeft: 0 }}>
              Комментарий для коммерческого предложения
            </Typography>
            <CustomInput
              value={currentCommercialProposalComment}
              isViewOnly={isViewOnly}
              onChangeDirect={handleCommercialProposalCommentChangeDirect}
              onChangeDebounced={v => handleCommentChangeDebounced(v, 'commercial_proposal')}
              width="100%"
              viewOnlyComponent={customViewOnlyComment}
              type="text"
              multiline
              maxLength={512}
              maxRows={3}
              sx={{ '& .MuiOutlinedInput-input': { fontWeight: '400!important' } }}
            />
          </Grid>
        </Grid>
      </TabSelectWrapper>
    );
  }
);
