import { Chart } from 'chart.js';
export const CenterText = {
  id: 'centerText',
  beforeDraw: function (chart: Chart<'doughnut'>) {
    const { centerText } = chart.config.options.plugins as any;
    if (centerText && typeof centerText.display !== 'undefined' && centerText.display) {
      const width = chart.width,
        height = chart.height,
        ctx = chart.ctx;
      ctx.restore();
      const fontSize = (height / 114).toFixed(2);
      ctx.font = fontSize + 'em sans-serif';
      ctx.textBaseline = 'middle';
      ctx.fillStyle = centerText.color;
      const text = centerText.text,
        textX = Math.round((width - ctx.measureText(text).width) / 2),
        textY = height / 2;

      ctx.fillText(text, textX, textY);
      ctx.save();
    }
  },
};
