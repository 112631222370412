import React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import RestoreIcon from '@mui/icons-material/Restore';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import { Theme } from '@mui/material';
import { SxProps } from '@mui/system';
const ButtonCustom = styled(Button, {
  shouldForwardProp: prop => prop !== 'customColor',
})<{ customColor: string }>(({ theme, customColor = '#00a5bc' }) => ({
  fontSize: theme.typography.caption.fontSize,
  padding: 0,
  height: '24px',
  [`&.MuiButton-text`]: {
    minWidth: '24px',
  },
  border: `${customColor} solid 2px`,
  // color: theme.typography.body1.color,
  color: theme.palette.grey[900],
  [`& svg`]: {
    width: '20px',
    color: customColor,
  },
}));

type CollapseButtonProps = {
  disabled?: boolean;
  onClick?: any;
  type?: string;
  color?: string;
  height?: string;
  sx?: SxProps<Theme>;
};

export const CollapseButton = (props: CollapseButtonProps): JSX.Element => {
  const { disabled, onClick, type, color, sx } = props;

  let icon = null;
  if (type === 'less-all') {
    icon = <KeyboardDoubleArrowDownIcon />;
  } else if (type === 'expand-all') {
    icon = <KeyboardDoubleArrowUpIcon />;
  } else if (type === 'expand') {
    icon = <ExpandMoreIcon />;
  } else if (type === 'less') {
    icon = <ExpandLessIcon />;
  } else if (type === 'restore') {
    icon = <RestoreIcon />;
  } else if (type === 'delete') {
    icon = <CloseIcon />;
  }
  return (
    <ButtonCustom
      disableRipple
      onClick={onClick}
      customColor={color ? color : type === 'delete' ? '#C74952' : '#00A5BC'}
      disabled={disabled}
      sx={sx}
    >
      {icon}
    </ButtonCustom>
  );
};
