import React, { FC, useCallback, useEffect, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { observer } from 'mobx-react';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { UploadFile } from './UploadFile';
import { FilePreviews } from './FilePreviews';
import { FileUpload } from './types';
import { useRootStore } from 'src/store/MobxStoreProvider';
import { checkDuplicateFiles, isIdenticalFile } from './checkDuplicateFiles';
import { DialogContainer } from '../ui/dialogs/DialogContainer';
import { DocumentType } from '../../api/marketx';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { DocumentsTable } from './DocumentsTable';

interface DialogUploadProps {
  uploadFiles: (files: File[], typeCode: string) => void;
  deleteFiles: (codes: string[]) => void;
  uploadedFiles: FileUpload[];
  size?: 'small' | 'medium' | 'large';
  disabled?: boolean;
}

export const DialogUpload: FC<DialogUploadProps> = observer(
  ({ uploadFiles, deleteFiles, uploadedFiles = [], size = 'large', disabled = false }): JSX.Element => {
    const rootStore = useRootStore();
    const sbStore = rootStore.getSnackbar();
    const apiStore = rootStore.getApiStore();

    const [localUploadedFiles, setLocalUploadedFiles] = useState<File[]>(uploadedFiles); //* файлы из store
    const [newFiles, setNewFiles] = useState<File[]>([]); //* файлы из react-dropzone
    const [files, setFiles] = useState<FileUpload[]>([]); //* файлы для отображения
    const [fileCodeToDelete, setFileCodeToDelete] = useState<string[]>([]); //* codes файлов для удаления

    const [fileTypes, setFileTypes] = useState<Array<DocumentType>>([]);
    const [hasAutocompleteError, setHasAutocompleteError] = useState(true);
    const [autocompleteValue, setAutocompleteValue] = useState(null);

    const handleChange = useCallback((event, value): void => {
      setAutocompleteValue(value);
      if (value) {
        setHasAutocompleteError(false);
      } else {
        setHasAutocompleteError(true);
      }
    }, []);

    useEffect(() => {
      if (apiStore) {
        apiStore
          .apiDocuments()
          .documentsTypes()
          .then(res => {
            setFileTypes(res.data.types);
          });
      }
    }, [apiStore]);
    useEffect(() => {
      setFiles(
        files =>
          files.map(file => {
            const findFile = uploadedFiles.find(uploadedFile => isIdenticalFile(uploadedFile, file));
            if (findFile) {
              // * докидываем в нужный файл code и остальное (но не url, который пришел с бека. если прокинуть url будет моргание картинки)
              return { ...file, ...findFile };
            } else {
              return file;
            }
          }) as FileUpload[]
      );
      setFileCodeToDelete([]);
      setNewFiles([]);
    }, [uploadedFiles]);
    const [open, setOpen] = React.useState(false);
    useEffect(() => {
      if (open) {
        setFiles(uploadedFiles);
        setFileCodeToDelete([]);
        setNewFiles([]);
      }
    }, [open, uploadedFiles]);
    const handleClickOpen = (): void => {
      setOpen(true);
    };
    const handleCloseDialog = (): void => {
      setOpen(false);
    };
    const handleSaveDialog = (): void => {
      if (!!newFiles.length) {
        uploadFiles(newFiles, autocompleteValue.code);
      }
      if (!!fileCodeToDelete.length) {
        deleteFiles(fileCodeToDelete);
      }
      setOpen(false);
    };
    const handleUploadFiles = (uploadableFiles: File[]): void => {
      const [acceptedFiles, errors] = checkDuplicateFiles(files, uploadableFiles);
      errors.forEach(error => {
        sbStore.showError(error.message);
      });
      setNewFiles(newFiles => [...newFiles, ...acceptedFiles]);
      setFiles(files => [...files, ...acceptedFiles]);
    };
    const handleDeleteFile = useCallback(
      (file: FileUpload): void => {
        if (file.code) {
          setFileCodeToDelete(codes => [...codes, file.code]);
          const tempFiles = [...files];
          const index = files.indexOf(file);
          tempFiles.splice(index, 1);
          setFiles(tempFiles);
        } else {
          const tempFiles = [...newFiles];
          const index = newFiles.indexOf(file);
          tempFiles.splice(index, 1);
          setNewFiles(tempFiles);
          setFiles([...uploadedFiles, ...tempFiles]);
        }
      },
      [files, newFiles, uploadedFiles]
    );
    const handleDeleteTemporaryFile = useCallback(
      (file: FileUpload): void => {
        setFileCodeToDelete(codes => [...codes, file.code]);
        const tempFiles = [...localUploadedFiles];
        const index = localUploadedFiles.indexOf(file);
        tempFiles.splice(index, 1);
        setLocalUploadedFiles(tempFiles);
      },
      [localUploadedFiles, fileCodeToDelete]
    );
    useEffect(() => {
      setLocalUploadedFiles(uploadedFiles || []);
    }, [uploadedFiles.length, open]);
    return (
      <>
        <IconButton
          disabled={disabled && !uploadedFiles.length}
          aria-label="upload picture"
          onClick={handleClickOpen}
          component="span"
          size={size}
        >
          <Badge badgeContent={uploadedFiles.length} color="primary">
            <AttachFileIcon />
          </Badge>
        </IconButton>
        <DialogContainer
          open={open}
          onClose={handleCloseDialog}
          dialogTitle={!disabled ? 'Загрузка файлов' : ''}
          maxWidth={'lg'}
          dialogContent={
            <>
              {!disabled ? (
                <Box sx={{ mt: 1, mb: 2 }}>
                  <Autocomplete
                    value={autocompleteValue}
                    onChange={handleChange}
                    disablePortal
                    getOptionLabel={i => {
                      return i.name;
                    }}
                    isOptionEqualToValue={(option, value) => {
                      return option.code === value.code;
                    }}
                    disabled={!fileTypes.length}
                    size="small"
                    options={fileTypes}
                    renderInput={params => <TextField {...params} label="Тип файла" helperText={''} error={!!hasAutocompleteError} />}
                  />
                </Box>
              ) : null}
              {!disabled && <UploadFile disabled={disabled} upload={handleUploadFiles} />}
              {newFiles?.length ? (
                <Box sx={{ my: 2 }}>
                  <Typography variant="body1" fontWeight={500}>
                    Временные файлы
                  </Typography>
                  <FilePreviews isDeletable={!disabled} files={newFiles} deleteFile={handleDeleteFile} />
                </Box>
              ) : null}
              {localUploadedFiles?.length ? (
                <Box sx={{ mt: 2 }}>
                  <Typography variant="body1" fontWeight={500}>
                    Прикрепленные файлы
                  </Typography>
                  <DocumentsTable files={localUploadedFiles} deleteFile={handleDeleteTemporaryFile} isDeletable={disabled} />
                </Box>
              ) : null}
            </>
          }
          disabled={disabled || (!newFiles.length && !fileCodeToDelete.length) || (hasAutocompleteError && !fileCodeToDelete.length)}
          successBtnText={disabled ? '' : 'Сохранить'}
          canselBtnText={disabled ? 'Закрыть' : 'Отменить'}
          handleSuccess={!disabled && handleSaveDialog}
        />
      </>
    );
  }
);
