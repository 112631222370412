import React, { FC, useRef, useState } from 'react';
import { Box } from '@mui/system';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import Typography from '@mui/material/Typography';

export const SelfPurchaseIcon: FC = (): JSX.Element => {
  const tooltipRef = useRef();
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const handleTooltipOpen = (): void => {
    setIsTooltipOpen(true);
  };
  const handleTooltipClose = (): void => {
    setIsTooltipOpen(false);
  };
  return (
    <Box
      sx={{
        borderRadius: '3px',
        fontSize: 16,
        verticalAlign: 'middle',
        fontFamily: 'Arial, Helvetica, sans-serif',
        backgroundColor: '#00a2e8',
        display: 'inline-block',
        width: 40,
        height: 20,
        textAlign: 'center',
        lineHeight: '20px',
        cursor: 'pointer',
      }}
      ref={tooltipRef}
      onMouseEnter={handleTooltipOpen}
      onMouseLeave={handleTooltipClose}
    >
      <Box display="flex" justifyContent="center" sx={{ color: '#f6eeee' }}>
        СЗ
      </Box>
      <Popper placement="top" open={isTooltipOpen} style={{ zIndex: 1300 }} anchorEl={tooltipRef.current}>
        <Paper sx={{ borderRadius: '3px' }}>
          <Box p={0.6}>
            <Typography variant="caption" color="inherit" fontWeight="500">
              Самозакуп
            </Typography>
          </Box>
        </Paper>
      </Popper>
    </Box>
  );
};
