import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import { styled } from '@mui/material/styles';
import { FC } from 'react';
import { TaskLogItem } from 'src/api/marketx';
import { formatDateSwaggerZ, formatYmdHiLZ } from '@mx-ui/helpers';
import { observer } from 'mobx-react-lite';
import { UserAvatar } from '@mx-ui/ui';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
interface TaskLogsProps {
  logs: TaskLogItem[];
}
const PREFIX = 'TaskLogs';

const classes = {
  root: `${PREFIX}-root`,
  listItemText: `${PREFIX}-listItemText`,
  listItem: `${PREFIX}-listItem`,
  inline: `${PREFIX}-inline`,
  wrapForm: `${PREFIX}-wrapForm`,
};

const Root = styled(Box)(() => ({
  [`& .${classes.root}`]: {
    width: '100%',
    backgroundColor: 'background.paper',
  },
  [`& .${classes.listItemText}`]: {
    width: '66%', // color: 'red',
  },
  [`& .${classes.listItem}`]: {
    paddingRight: '8px',
  },
  [`& .${classes.inline}`]: {
    display: 'inline',
  },
  [`& .${classes.wrapForm}`]: {
    display: 'flex',
    marginBottom: '8px',
    justifyContent: 'center',
    margin: `${0} auto`,
  },
}));
export const TaskLogs: FC<TaskLogsProps> = observer(({ logs = [] }): JSX.Element => {
  return (
    <Root width={'100%'}>
      <Box sx={{ maxHeight: '300px', overflowY: 'auto', mx: 1 }}>
        <List className={classes.root}>
          {logs.map((log, index) => {
            const fio = `${log.initiator.surname || ''} ${log.initiator.name || ''} ${log.initiator.patronymic || ''}`;
            return (
              <ListItem
                alignItems="flex-start"
                key={log.typeCode + index}
                disableGutters
                className={classes.listItem}
                divider={index !== logs.length - 1}
                dense
              >
                <ListItemAvatar>
                  {log.initiator && <UserAvatar name={log.initiator.name} surname={log.initiator.surname} src={log.initiator.avatarUrl} />}
                </ListItemAvatar>
                <ListItemText
                  primary={fio}
                  secondaryTypographyProps={{ component: 'div' }}
                  secondary={
                    <Box>
                      <Typography color="textPrimary" variant="body2">
                        {`${log.title}`}
                      </Typography>
                      {log.extra && (
                        <Box display={'flex'} alignItems={'center'}>
                          <Box display={'flex'} alignItems={'center'}>
                            <UserAvatar
                              name={log.extra.oldExecutor?.name}
                              surname={log.extra.oldExecutor?.surname}
                              src={log.extra.oldExecutor?.avatarUrl}
                            />
                            {log.extra?.oldStatus && (
                              <Typography color="text.secondary" variant="body2" ml={1}>
                                {log.extra?.oldStatus?.title || ''}
                              </Typography>
                            )}
                            {log.extra?.oldTitle && (
                              <Typography color="text.secondary" variant="body2" ml={1}>
                                {log.extra?.oldTitle || ''}
                              </Typography>
                            )}
                            {log.extra?.oldPriority && (
                              <Typography color="text.secondary" variant="body2" ml={1}>
                                {log.extra?.oldPriority?.title || ''}
                              </Typography>
                            )}
                            {log.extra?.oldPoints && (
                              <Typography color="text.secondary" variant="body2" ml={1}>
                                {log.extra?.oldPoints || ''}
                              </Typography>
                            )}
                            {log.extra?.oldExecutor && (
                              <Typography color="text.secondary" variant="body2" ml={1}>
                                {`${log.extra.oldExecutor?.surname || ''} ${log.extra.oldExecutor?.name || ''}`}
                              </Typography>
                            )}
                            {(log.extra?.oldBeginAt || log.extra?.oldDeadlineAt) && (
                              <Typography color="text.secondary" variant="body2" ml={1}>
                                {formatDateSwaggerZ(log.extra?.oldBeginAt || log.extra?.oldDeadlineAt) || ''}
                              </Typography>
                            )}
                            {log.extra?.oldDescription && (
                              <Typography color="text.secondary" variant="body2" ml={1}>
                                {log.extra?.oldDescription || ''}
                              </Typography>
                            )}
                          </Box>
                          <Box mx={0.5} display={'flex'} alignItems={'center'}>
                            <TrendingFlatIcon />
                          </Box>
                          <Box display={'flex'} alignItems={'center'}>
                            <UserAvatar
                              name={log.extra.newExecutor?.name}
                              surname={log.extra.newExecutor?.surname}
                              src={log.extra.newExecutor?.avatarUrl}
                            />
                            {log.extra?.newStatus && (
                              <Typography color="text.secondary" variant="body2" ml={1}>
                                {log.extra?.newStatus?.title || ''}
                              </Typography>
                            )}
                            {log.extra?.newTitle && (
                              <Typography color="text.secondary" variant="body2" ml={1}>
                                {log.extra?.newTitle || ''}
                              </Typography>
                            )}
                            {log.extra?.newPriority && (
                              <Typography color="text.secondary" variant="body2" ml={1}>
                                {log.extra?.newPriority?.title || ''}
                              </Typography>
                            )}
                            {log.extra?.newPoints && (
                              <Typography color="text.secondary" variant="body2" ml={1}>
                                {log.extra?.newPoints || ''}
                              </Typography>
                            )}
                            {log.extra?.newExecutor && (
                              <Typography color="text.secondary" variant="body2" ml={1}>
                                {`${log.extra.newExecutor?.surname || ''} ${log.extra.newExecutor?.name || ''}`}
                              </Typography>
                            )}
                            {(log.extra?.newBeginAt || log.extra?.newDeadlineAt) && (
                              <Typography color="text.secondary" variant="body2" ml={1}>
                                {formatDateSwaggerZ(log.extra?.newBeginAt || log.extra?.newDeadlineAt) || ''}
                              </Typography>
                            )}
                            {log.extra?.newDescription && (
                              <Typography color="text.secondary" variant="body2" ml={1}>
                                {log.extra?.newDescription || ''}
                              </Typography>
                            )}
                          </Box>
                        </Box>
                      )}
                    </Box>
                  }
                  className={classes.listItemText}
                />
                <ListItemText secondary={formatYmdHiLZ(log.createdAt)} secondaryTypographyProps={{ align: 'right' }} />
              </ListItem>
            );
          })}
          {logs.length === 0 && <Typography variant="body1">Отсутствует история для этой задачи</Typography>}
        </List>
      </Box>
    </Root>
  );
});
