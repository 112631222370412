import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import Box from '@mui/material/Box';
import React, { FC } from 'react';
import { ProductItemPrice } from 'src/api/marketx';
import { formatPrice } from '@mx-ui/helpers';
import { observer } from 'mobx-react-lite';
interface PricesTableProps {
  value: ProductItemPrice[];
  className?: string;
}
export const PricesTable: FC<PricesTableProps> = observer(({ value = [], className = '' }): JSX.Element => {
  return (
    <Table size="small" aria-label="purchases" className={className}>
      <TableHead>
        <TableRow>
          <TableCell align="left" component="th">
            Количество, тонны
          </TableCell>
          <TableCell align="right" component="th">
            НДС 20%, ₽
          </TableCell>
          <TableCell align="right" component="th">
            НДС 0%, ₽
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {value.map((ele, i) => {
          return (
            <TableRow key={i + '' + ele.rangeTitle}>
              <TableCell width={'250px'} align={'left'} component="th" scope="row">
                {ele.rangeTitle}
              </TableCell>
              <TableCell width={'250px'} align="right" component="td" scope="row">
                {ele.priceSu ? formatPrice(+ele.priceSu) : '-'}
              </TableCell>
              <TableCell width={'250px'} align="right" component="td" scope="row">
                {ele.wovSuCost ? formatPrice(+ele.wovSuCost) : '-'}
              </TableCell>
            </TableRow>
          );
        })}
        {!value.length && (
          <TableRow>
            <TableCell colSpan={3} scope="row">
              <Box width={'100%'} textAlign="center">
                Нет данных
              </Box>
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
});
