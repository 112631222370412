// Данные о запуске приложения
export class LaunchStore {
  startedAt: Date;
  origin: string;

  constructor() {
    this.startedAt = new Date();
  }

  setOrigin(origin: string): void {
    this.origin = origin;
  }
}
