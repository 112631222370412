import { makeAutoObservable, runInAction } from 'mobx';
import { ParticipantTimePoint, ParticipantTimePointsResponse, ParticipantTimePointsSaveRequest } from 'src/api/marketx';
import { ApiStore } from '../Global/ApiStore';
import { RootStore } from '../StoreManager';
import { AxiosCallContext, getCallContext } from '../../utils/axiosInit';
import { AxiosResponse } from 'axios';
export type TimePointsListRequest = {
  clientCode?: string; // код клиента
};
export class TimePointsListStore {
  apiStore: ApiStore;
  svc: TimePointsListService;
  clientCode = '';

  items: ParticipantTimePoint[] = [];
  request: TimePointsListRequest = { clientCode: '' };
  hasWriteAccess = false;
  isLoaded = false;
  isLoading = false;

  constructor(rootStore: RootStore) {
    this.apiStore = rootStore.getApiStore();
    this.svc = new TimePointsListService();
    makeAutoObservable(this, {
      apiStore: false,
    });
  }

  load(req: TimePointsListRequest): void {
    runInAction(() => {
      this.request = req;
      if (!this.clientCode && req.clientCode) {
        this.clientCode = req.clientCode;
      }

      this.svc.load(this);
    });
  }
  /**
   * Создание и редактирование значимых дат у контакта
   * если есть code то редактирование иначе создание
   */
  saveTimePoint(point: ParticipantTimePointsSaveRequest): Promise<AxiosResponse<ParticipantTimePoint>> {
    return this.apiStore.apiClientParticipant().participantTimePointsSave(point);
  }

  setResultTimePoints(ctx: AxiosCallContext, { data }: AxiosResponse<ParticipantTimePointsResponse>): void {
    runInAction(() => {
      this.isLoaded = true;
      this.items = data.timePoints || [];
      this.hasWriteAccess = data.hasWriteAccess;
      this.isLoading = false;
    });
  }
}

export class TimePointsListService {
  load(store: TimePointsListStore): void {
    runInAction(() => {
      store.isLoading = true;
    });
    store.apiStore
      .apiClientParticipant()
      .participantTimePoints(undefined, store.request.clientCode)
      .then((res): void => {
        store.setResultTimePoints(getCallContext(res), res);
      });
  }
}
