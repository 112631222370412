import { makeAutoObservable } from 'mobx';
import { RootStore } from './StoreManager';
import { MsgType, WebSocketCollieStore } from './Global/WebSocketCollieStore';
// import { makeAutoObservable, runInAction, set, toJS } from 'mobx';
// import { RootStore } from './StoreManager';

export class CallStore {
  data = null;

  wsStore: WebSocketCollieStore;
  // data: any = {
  // несколько клиентов и контакт
  // call: {
  //   code: '4135669e-6c3f-4178-97e3-771326972a04',
  //   callFlow: 'in',
  //   status: 'ANSWER',
  //   providerNumber: '73532487587',
  //   callerIDName: '+79164022711',
  //   callerIDNum: '+79164022711',
  //   actualCalledNumber: '3200',
  //   actualUserCode: 'pavlov_a',
  //   actualEmployeeCode: '75f46156-e7a8-4f34-b319-8db563a99b50',
  //   members: [
  //     {
  //       calledNumber: '3200',
  //       userCode: 'pavlov_a',
  //       employeeCode: '75f46156-e7a8-4f34-b319-8db563a99b50',
  //     },
  //   ],
  //   contact: null,
  //   contactVariants: [],
  //   dialedAt: '2023-05-17T22:04:08.514349Z',
  //   answerAt: '2023-05-17T22:04:33.364352Z',
  //   finishedAt: null,
  //   committedAt: '2023-05-17T22:06:14.15026537Z',
  // },
  // call: {
  //   code: '4135669e-6c3f-4178-97e3-771326972a04',
  //   callFlow: 'in',
  //   status: 'ANSWER',
  //   providerNumber: '73532487587',
  //   callerIDName: '+79164022711',
  //   callerIDNum: '+79164022711',
  //   actualCalledNumber: '3200',
  //   actualUserCode: 'pavlov_a',
  //   actualEmployeeCode: '75f46156-e7a8-4f34-b319-8db563a99b50',
  //   members: [
  //     {
  //       calledNumber: '3200',
  //       userCode: 'pavlov_a',
  //       employeeCode: '75f46156-e7a8-4f34-b319-8db563a99b50',
  //     },
  //   ],
  //   contact: {
  //     contactCode: '32e87e92-94c1-45ff-aa5a-0b590e87f696',
  //     contactFio: 'Тюрин Николай Александрович',
  //     customerCode: 'ddf356a2-d157-11e9-bba1-00505602c93b',
  //     customerTitle: 'Акционерное общество "3Д-МЕТАЛЛ"',
  //     customerShortTitle: 'АО "3Д-МЕТАЛЛ"',
  //   },
  //   contactVariants: [
  //     {
  //       contactCode: '32e87e92-94c1-45ff-aa5a-0b590e87f696',
  //       contactFio: 'Тюрин Николай ',
  //       customerCode: 'ddf356a2-d157-11e9-bba1-00505602c93b',
  //       customerTitle: 'Акционерное общество "3Д-МЕТАЛЛ"',
  //       customerShortTitle: 'АО "3Д-МЕТАЛЛ"',
  //     },
  //     {
  //       contactCode: '4b39fab2-1a0e-cf93-ddcd-8eca9921abbf',
  //       contactFio: 'Драчев Игорь Анатольевич',
  //       customerCode: 'ddf356a2-d157-11e9-bba1-00505602c93b',
  //       customerTitle: 'Акционерное общество "3Д-МЕТАЛЛ"',
  //       customerShortTitle: 'АО "3Д-МЕТАЛЛ"',
  //     },
  //   ],
  //   dialedAt: '2023-05-17T22:04:08.514349Z',
  //   answerAt: '2023-05-17T22:04:33.364352Z',
  //   finishedAt: null,
  //   committedAt: '2023-05-17T22:06:14.15026537Z',
  // },
  // несколько клиентов
  // call: {
  //   code: 'dd750faa-be0d-4341-a163-9a25819f77d2',
  //   callFlow: 'in',
  //   status: 'CALLING',
  //   providerNumber: '73532487587',
  //   callerIDName: '+79164022711',
  //   callerIDNum: '+79164022711',
  //   actualCalledNumber: '3200',
  //   actualUserCode: 'pavlov_a',
  //   actualEmployeeCode: '75f46156-e7a8-4f34-b319-8db563a99b50',
  //   members: [
  //     {
  //       calledNumber: '3200',
  //       userCode: 'pavlov_a',
  //       employeeCode: '75f46156-e7a8-4f34-b319-8db563a99b50',
  //     },
  //   ],
  //   // contact: {
  //   //   contactCode: '32e87e92-94c1-45ff-aa5a-0b590e87f696',
  //   //   contactFio: 'Тюрин Николай ',
  //   //   customerCode: 'ddf356a2-d157-11e9-bba1-00505602c93b',
  //   //   customerTitle: 'Акционерное общество "3Д-МЕТАЛЛ"',
  //   //   customerShortTitle: 'АО "3Д-МЕТАЛЛ"',
  //   // },
  //   contact: null,
  //   contactVariants: [
  //     {
  //       contactCode: '32e87e92-94c1-45ff-aa5a-0b590e87f696',
  //       contactFio: 'Тюрин Николай ',
  //       customerCode: 'ddf356a2-d157-11e9-bba1-00505602c93b',
  //       customerTitle: 'Акционерное общество "3Д-МЕТАЛЛ"',
  //       customerShortTitle: 'АО "3Д-МЕТАЛЛ"',
  //     },
  //     {
  //       contactCode: '4b39fab2-1a0e-cf93-ddcd-8eca9921abbf',
  //       contactFio: 'Драчев Игорь Анатольевич',
  //       customerCode: 'ddf356a2-d157-11e9-bba1-00505602c93b',
  //       customerTitle: 'Акционерное общество "3Д-МЕТАЛЛ"',
  //       customerShortTitle: 'АО "3Д-МЕТАЛЛ"',
  //     },
  //   ],
  //   dialedAt: '2023-05-16T14:44:01.667258Z',
  //   answerAt: '2023-05-18T09:12:56.517264Z',
  //   finishedAt: null,
  //   committedAt: '2023-05-16T14:44:02.1214591Z',
  // },
  // один клиент
  // call: {
  //   code: '00034807-f32d-4b97-a9e7-3edc2bb42dc1',
  //   callFlow: 'in',
  //   status: 'ANSWER',
  //   providerNumber: '73532487587',
  //   callerIDName: 'Назаров Руслан Матякупович (АО 3Д-МЕТАЛЛ)',
  //   callerIDNum: '+79081642926',
  //   actualCalledNumber: '3200',
  //   actualUserCode: 'pavlov_a',
  //   actualEmployeeCode: '75f46156-e7a8-4f34-b319-8db563a99b50',
  //   members: [
  //     {
  //       calledNumber: '3200',
  //       userCode: 'pavlov_a',
  //       employeeCode: '75f46156-e7a8-4f34-b319-8db563a99b50',
  //     },
  //   ],
  //   contact: {
  //     contactCode: 'ba901b7c-81c6-2586-7164-9407a5dc666f',
  //     contactFio: 'Назаров Руслан Матякупович',
  //     customerCode: 'ddf356a2-d157-11e9-bba1-00505602c93b',
  //     customerTitle: 'Акционерное общество "3Д-МЕТАЛЛ"',
  //     customerShortTitle: 'АО "3Д-МЕТАЛЛ"',
  //   },
  //   contactVariants: [
  //     {
  //       contactCode: 'ba901b7c-81c6-2586-7164-9407a5dc666f',
  //       contactFio: 'Назаров Руслан Матякупович',
  //       customerCode: 'ddf356a2-d157-11e9-bba1-00505602c93b',
  //       customerTitle: 'Акционерное общество "3Д-МЕТАЛЛ"',
  //       customerShortTitle: 'АО "3Д-МЕТАЛЛ"',
  //     },
  //   ],
  //   dialedAt: '2023-05-16T09:13:51.547259Z',
  //   answerAt: '2023-05-16T09:13:56.517264Z',
  //   finishedAt: null,
  //   committedAt: '2023-05-16T09:13:56.868462015Z',
  // },
  // join: false,
  // leave: false,
  // };
  //   data: any;
  constructor(rootStore: RootStore) {
    this.wsStore = rootStore.getWebCollieSocket();
    makeAutoObservable(this, {});
  }
  get callContact(): any {
    return this.data?.call?.contact || null;
  }
  get callMatchOne(): boolean {
    return this.data?.call ? !!this.data?.call?.contact : false;
  }
  get callMatchSeveral(): boolean {
    return this.data?.call ? this.data?.call?.contactVariants?.length > 1 && !this.data?.call?.contact : false;
  }
  get nameClientCall(): string {
    return this.data?.call?.contact?.contactFio || null;
  }
  get showCallDialog(): boolean {
    // return true;
    return this.data?.call ? this.data.leave === false : false;
  }
  // get showCallDialog(): boolean {
  //   return this.data?.call ? this.data.call.status === 'CALLING' || this.data.call.status === 'ANSWER' : false;
  // }
  get nameCompanyCall(): string {
    return this.data?.call?.contact?.customerShortTitle || null;
  }
  get status(): string {
    return this.data?.call?.status || null;
  }
  setContactForCall(contactCode: string, customerCode: string): Promise<any> {
    const promise = new Promise((resolve, reject) => {
      this.wsStore.sendContactNew(this.data.call.code, contactCode, customerCode);
      setTimeout(() => {
        reject('Не удалось установить контакт');
      }, 3000);
      this.wsStore.subscribe(MsgType.WEB_SOCKET_CALL_SET_CONTACT_SUCCESS, msg => {
        resolve(msg.data);
      });
    });
    return promise;
  }
  hangupCall(): void {
    this.wsStore.callEnd(this.data.call.code);
  }
  setData(value: any): void {
    this.data = value;
  }
}
