import { useCallback, useState } from 'react';
import { defaultValuesForm as defaultValuesFormTask, FormData as FormDataTask } from 'src/components/Home/TaskDialog';
import { TasksStore } from 'src/store/TasksStore';
import { formatDateYmdHis } from '@mx-ui/helpers';
import { EditorState, ContentState } from 'draft-js';
export interface TaskDialogHook {
  selectTask: FormDataTask;
  openTaskDialog: boolean;
  handleSaveTask: (data: FormDataTask) => Promise<void>;
  handleCreateTask: () => void;
  handleClickTask: (code: string) => void;
  handleCloseTaskDialog: () => void;
}
// * https://github.com/jpuri/html-to-draftjs/issues/78#issuecomment-1148466083
// eslint-disable-next-line @typescript-eslint/no-var-requires
const htmlToDraft = typeof window === 'object' && require('html-to-draftjs').default;
export const useTaskDialogCreate = (store: TasksStore, defaultValuesTask = defaultValuesFormTask): TaskDialogHook => {
  const [openTaskDialog, setOpenTaskDialog] = useState(false);
  const [isTaskDaving, setIsTaskDaving] = useState(false);
  const [selectTask, setSelectTask] = useState<FormDataTask>(defaultValuesTask);

  const handleCloseTaskDialog = (): void => {
    setOpenTaskDialog(false);
    setTimeout(() => {
      setSelectTask(defaultValuesTask);
    }, 100);
  };

  const handleCreateTask = (): void => {
    setOpenTaskDialog(true);
  };

  const handleClickTask = useCallback(
    (code: string): void => {
      const task = store.tasks.find(i => i.code === code);
      const v = {
        ...task,
        statusCode: task.status?.code,
        priorityCode: task.priority?.code,
        labelCodes: task.labels?.map(i => i.code),
        executorEmployeeCode: task.executor?.code,
        beginDate: new Date(task.beginAt),
        description: task.description
          ? EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(task.description)))
          : undefined,
        deadlineDate: new Date(task.deadlineAt),
        beginAt: undefined,
        initiatorEmployeeCode: task.initiator?.code,
        deadlineAt: undefined,
        priority: undefined,
        status: undefined,
        executor: undefined,
        followers: task.followers?.map(item => item.code) || [],
      };
      setSelectTask(v);

      setOpenTaskDialog(true);
    },
    [store.tasks]
  );
  const handleSaveTask = useCallback(
    (data: FormDataTask): Promise<void> => {
      if (isTaskDaving) {
        return;
      }
      setOpenTaskDialog(false);
      setIsTaskDaving(true);
      const dataToSave = {
        ...data,
        beginDate: data.beginDate ? formatDateYmdHis(data.beginDate) : undefined,
        deadlineDate: data.deadlineDate ? formatDateYmdHis(data.deadlineDate) : undefined,
        description: data.description && typeof data.description === 'string' ? data.description : undefined,
      };
      if (data.code) {
        return store
          .saveTask(dataToSave.code, dataToSave)
          .then(() => {
            store.loadTasks(store.request);
            store.snackbarStore.showSuccess(`Задача создана`);
          })
          .catch(r => {
            store.snackbarStore.showError(`Ошибка создании задачи`);
            console.warn(r);
          })
          .finally(() => {
            setIsTaskDaving(false);
          });
      } else {
        return store
          .createTask(dataToSave)
          .then(() => {
            store.loadTasks(store.request);
            store.snackbarStore.showSuccess(`Задача создана`);
          })
          .catch(r => {
            store.snackbarStore.showError(`Ошибка создании задачи`);
            console.warn(r);
          })
          .finally(() => {
            setIsTaskDaving(false);
          });
      }
    },
    [store, isTaskDaving]
  );
  return {
    selectTask,
    handleSaveTask,
    openTaskDialog,
    handleClickTask,
    handleCreateTask,
    handleCloseTaskDialog,
  };
};
