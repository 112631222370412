import React, { FC, useMemo } from 'react';
import {
  Chart as ChartJS,
  TimeScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  BarElement,
  Legend,
  CategoryScale,
  LineController,
  BarController,
} from 'chart.js';
import { Chart } from 'react-chartjs-2';
import { useTheme } from '@mui/material/styles';
import 'chartjs-adapter-date-fns';
import { plotToTimestampSeries } from '../../lib/plots';
import { CustomerMarginalityChartsDataStore } from '../../store/CustomerMarginalityChartsDataStore';
import { grey } from '@mui/material/colors';
import { observer } from 'mobx-react';
import annotationPlugin from 'chartjs-plugin-annotation';
ChartJS.register(
  TimeScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Title,
  Legend,
  annotationPlugin,
  CategoryScale,
  BarElement,
  LineController,
  BarController
);
interface CustomerMarginalityChartProps {
  plotStore: CustomerMarginalityChartsDataStore;
  currentPct: number;
  height: number;
}

export const CustomerMarginalityChart: FC<CustomerMarginalityChartProps> = observer(({ plotStore, currentPct, height }): JSX.Element => {
  let chartReference = null;
  const theme = useTheme();
  const data = useMemo<any>(() => {
    const lastTwentyPoints = {
      ...plotStore.plot,
      points: plotStore.plot?.points.slice(plotStore.plot.points.length - 20, plotStore.plot.points.length),
    };

    const dataPoints = plotToTimestampSeries(lastTwentyPoints);

    const data = {
      labels: dataPoints.map(item => item.i),
      datasets: [
        {
          data: dataPoints.map(() => lastTwentyPoints.middleVal),
          fill: false,
          borderColor: theme.palette.mode === 'dark' ? grey[600] : grey[400],
          backgroundColor: theme.palette.mode === 'dark' ? grey[900] : grey[400],
          type: 'line' as const,
          borderWidth: 1,
          pointRadius: 0,
          lineTension: 0.3,
        },
        {
          data: dataPoints.map(item => item.y),
          fill: false,
          backgroundColor: '#00A5BC',
          type: 'bar' as const,
          pointRadius: 0.5,
          lineTension: 0.3,
        },
      ],
    };
    const pointsCount = data.datasets[1].data.length;
    if (plotStore.isLoaded && pointsCount > 0) {
      data.datasets[1].data[pointsCount - 1] = currentPct;
    }
    // Нужен принудительный апдейт, иначе график не отреагирует на изменения
    if (chartReference && chartReference.chartInstance && chartReference.chartInstance.update) {
      chartReference.chartInstance.update();
    }
    return { data, lastTwentyPoints };
  }, [plotStore.isLoaded, plotStore.loadedEpoch, currentPct, chartReference]);

  return (
    <div
      style={{
        height: height + 'px',
        border: 'solid 0px #ccc',
        paddingTop: '0px',
        width: '100%',
        position: 'relative',
        display: 'block',
      }}
    >
      <Chart
        type="scatter"
        height={height}
        ref={reference => (chartReference = reference)}
        options={{
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              enabled: true,
              mode: 'index',
              intersect: false,
              displayColors: false,
              callbacks: {
                title: function (): string {
                  return '';
                },
                label: function (ctx): string {
                  // @ts-ignore
                  if (ctx.dataset.type === 'line') return;
                  const { parsed, dataset } = ctx;
                  let label = dataset.label || '';

                  if (label) {
                    label += ': ';
                  }
                  label += Math.round(parsed.y * 100) / 100;
                  label += ' ~ ' + new Date(data.lastTwentyPoints.points[parsed.x].x * 1000).toLocaleDateString();
                  return label;
                },
              },
            },
          },
          scales: {
            x: {
              display: false,
              offset: true,
              // ticks: {
              //   source: 'labels',
              // },
              // type: 'time' as const,
              // time: {
              //   unit: 'day',
              //   displayFormats: {
              //     millisecond: 'mm:ss',
              //     second: 'mm:ss',
              //     minute: 'MMM dd',
              //     hour: 'MMM dd',
              //     day: 'MMM dd',
              //     week: 'MMM dd',
              //     month: 'MMM dd',
              //     quarter: 'MMM dd',
              //     year: 'MMM dd',
              //   },
              // },
            },
            y: {
              display: true,
              ticks: {
                maxTicksLimit: 3,
              },
            },
          },

          responsive: true,
        }}
        data={data.data}
      />
    </div>
  );
});
