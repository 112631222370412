import { useLayoutEffect, useMemo, useState } from 'react';
import { useRootStore } from '../../../store/MobxStoreProvider';
import { AgreementListStore } from '../../../store/Clients/AgreementListStore';
import { AppUserRoleCodes } from '../../../types/AppUserGroup';
import { formatDateSwaggerZ } from '@mx-ui/helpers';
import { ContractCostAgreement, ContractCostPosition } from '../../../api/marketx';
import { DealItemStore } from '../../../store/DealItemStore';
import { useWebSocketHandler } from '../../../hooks/useWebSocketHandler';

interface UseAgreements {
  clientAgreements: ContractCostAgreement[];
  agreementsCodes: { value: string; text: string }[] | [];
  currentAgreement: ContractCostAgreement;
  handleChangeCurrentAgreement: (agreementCode: string) => void;
  agreementPosition: ContractCostPosition[];
  agreementsStore: AgreementListStore;
}

const useAgreements = (clientCode: string, dealStore: DealItemStore): UseAgreements => {
  const rootStore = useRootStore();
  const agreementsStore = useMemo(() => {
    const agreementsStore = new AgreementListStore(rootStore, clientCode);
    agreementsStore.loadList({
      customerCode: clientCode,
      employeeSetCode: AppUserRoleCodes.MY_OFFICE,
      approvalStateCodes: ['approved'],
    });
    return agreementsStore;
  }, [rootStore, clientCode]);

  useWebSocketHandler(agreementsStore.handleWs);
  const agreement = dealStore.deal?.contractCostAgreements.length ? dealStore.deal?.contractCostAgreements[0] : null;

  const [currentAgreement, setCurrentAgreement] = useState<ContractCostAgreement | null>(null);
  const [agreementPosition, setAgreementPosition] = useState<Array<ContractCostPosition>>([]);
  const [agreementsCodes, setAgreementsCodes] = useState<Array<{ value: string; text: string }> | []>([]);
  useLayoutEffect(() => {
    if (agreement) {
      setCurrentAgreement(agreement);
    }
  }, []);

  const handleChangeCurrentAgreement = (agreementCode: string): void => {
    agreementsStore.items.forEach(item => {
      if (item.code === agreementCode) {
        setCurrentAgreement(item);
      }
    });
  };

  useLayoutEffect(() => {
    if (currentAgreement) {
      setAgreementPosition(currentAgreement?.positions.filter(i => !i.isArchive));
    }
  }, [currentAgreement]);

  useLayoutEffect(() => {
    const agreementsCodes = agreementsStore.items.map(item => {
      return { value: item.code, text: `${item.code} ${item.name ? item.name : ''} от ${formatDateSwaggerZ(item.createdAt)}` };
    });

    setAgreementsCodes(agreementsCodes);
  }, [agreementsStore.items, agreementsStore.items?.length]);

  return {
    clientAgreements: agreementsStore.items,
    agreementsCodes,
    currentAgreement,
    handleChangeCurrentAgreement,
    agreementPosition,
    agreementsStore,
  };
};

export default useAgreements;
