import React, { FC, JSX, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { DealPositionMotivation, SaleShipmentPosition, SaleShipmentPositionMotivation } from '../../../api/marketx';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { formatPriceCur } from '@mx-ui/helpers';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { CustomPopper } from '../../../views/deals/components/CustomPopper';
import { productMotivationDictionary } from '../../../slices/AppDeal/lib';
import { useAuthStore } from '../../../store/MobxStoreProvider';
import { AppDealPosition } from '../../../slices/AppDeal';
import { motivationAccessFunc } from 'src/components/DashboardLayout/NavBar/mainSections';
interface PositionMotivationListProps {
  position: SaleShipmentPosition | AppDealPosition;
  isForDeal?: boolean;
}
// ? MSOMS-3556 https://marketx.sentry.io/issues/4537598557/?project=5565572
if (!Object.hasOwn) {
  // @ts-ignore
  Object.prototype.hasOwn = function (object, property) {
    return Object.prototype.hasOwnProperty.call(object, property);
  };
}
export const PositionMotivationList: FC<PositionMotivationListProps> = observer(({ position, isForDeal = false }): JSX.Element => {
  const authStore = useAuthStore();
  const motivationBtnRef = useRef();
  const [isMotivationDialogOpen, setIsMotivationDialogOpen] = useState(false);
  const handleMotivationDialogClose = (): void => {
    setIsMotivationDialogOpen(false);
  };
  const handleMotivationDialogClick = (): void => {
    setIsMotivationDialogOpen(isMotivationDialogOpen => !isMotivationDialogOpen);
  };

  const motivationAccess = motivationAccessFunc(authStore);
  const motivation = position.motivation || {};

  const optionalMotivation = {
    p1Amount: 0,
    p2Amount: 0,
    unitCostP2: (motivation.p2Amount || 0) / (motivation.quantity || 1),
    // @ts-ignore
    unitCostPurchase: (motivation?.selfPurchaseAmount || 0) / (motivation.quantity || 1),
    // @ts-ignore
    unitCostPct: (motivation?.centralPurchaseAmount || 0) / (motivation.quantity || 1),
    ...motivation,
  };
  let backgroundColor = '#19B28D';
  const p1 = optionalMotivation?.p1Amount || 0;
  const p2 = optionalMotivation?.p2Amount || 0;
  // @ts-ignore
  const bss = optionalMotivation?.selfPurchaseAmount || 0;
  // @ts-ignore
  const bsc = optionalMotivation?.centralPurchaseAmount || 0;
  if ((p1 === 0 && p2 + bss + bsc !== 0) || (p1 !== 0 && p2 + bss + bsc === 0)) {
    backgroundColor = '#F88545';
  }
  if (p1 === 0 && p2 + bss + bsc === 0) {
    backgroundColor = '#ee505a';
  }

  if (!Object.keys(motivation || {}).length) {
    return null;
  }

  return motivationAccess ? (
    <Grid item>
      <Box sx={{ backgroundColor, borderRadius: '5px' }} display={'inline-block'} ref={motivationBtnRef}>
        <Grid container flexWrap={'nowrap'} alignItems={'center'}>
          <Grid item>
            <Typography style={{ fontSize: '14px', padding: '10px 5px', color: '#fff' }}>{`${
              isForDeal ? 'Прогноз премии:' : 'Премия:'
            } ${formatPriceCur(motivation?.totalPremiumAmount || 0)}`}</Typography>
          </Grid>
          <Grid item ml={2}>
            <IconButton onClick={handleMotivationDialogClick}>
              <InfoOutlinedIcon sx={{ color: '#fff' }} />
            </IconButton>
          </Grid>
        </Grid>
      </Box>
      <CustomPopper
        isOpen={isMotivationDialogOpen}
        anchorEl={motivationBtnRef.current}
        onClose={handleMotivationDialogClose}
        style={{ width: '400px', maxWidth: '95vw' }}
        placement={'top-end'}
      >
        <Grid container>
          {Object.keys(productMotivationDictionary).map(block => {
            const condition = productMotivationDictionary[block].condition;
            const conditionKey = productMotivationDictionary[block].conditionKey;
            const items = productMotivationDictionary[block].items;
            if (condition(optionalMotivation?.[conditionKey]) || typeof conditionKey === 'boolean') {
              const positionMotivationItem = Object.keys(items).filter(i => Object.hasOwn(optionalMotivation, i));
              if (!positionMotivationItem.length) {
                return null;
              }
              return (
                <React.Fragment key={block}>
                  {block !== 'block' && block !== 'block1' && positionMotivationItem.length && (
                    <Grid item xs={12} py={0.5}>
                      <Divider />
                    </Grid>
                  )}
                  {Object.keys(items).map(motivationItem => (
                    <PositionMotivationItem
                      key={motivationItem}
                      item={motivationItem}
                      motivation={optionalMotivation}
                      dictionary={productMotivationDictionary[block].items}
                    />
                  ))}
                </React.Fragment>
              );
            }
            return null;
          })}
        </Grid>
      </CustomPopper>
    </Grid>
  ) : null;
});

export interface PositionMotivationItemProps {
  item: string;
  dictionary: any;
  motivation?: DealPositionMotivation | SaleShipmentPositionMotivation;
}

export const PositionMotivationItem: FC<PositionMotivationItemProps> = ({ item, dictionary, motivation }): JSX.Element => {
  const itemFontWeight =
    item === 'p1Amount' || item === 'p2Amount' || item === 'selfPurchaseAmount' || item === 'centralPurchaseAmount' ? 700 : 400;
  const color = motivation[item] ? (motivation[item] <= 0 ? '#ee505a' : 'text.secondary') : '#ee505a';

  if (!Object.hasOwn(motivation, item)) {
    return null;
  }
  return (
    <Grid container justifyContent={'space-between'}>
      <Grid item>
        <Typography color={'text.secondary'} sx={{ fontSize: '14px', fontWeight: itemFontWeight }}>
          {dictionary[item].value}
        </Typography>
      </Grid>
      <Grid item>
        <Typography sx={{ fontSize: '14px', color: color, fontWeight: 700 }}>
          {motivation[item] ? dictionary[item].handler(motivation[item]) + (dictionary[item].unit ? dictionary[item].unit : '') : '0.00 ₽'}
        </Typography>
      </Grid>
    </Grid>
  );
};
