import React, { FC } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { calculatePersent } from 'src/components/Home/ChartsBlock';
import { formatNumber3 } from '@mx-ui/helpers';
import { MxLinearProgress } from '@mx-ui/ui';
interface QuantityPercentAndStock {
  quantityPercent: number;
  stock: number;
  color: string;
}
interface StockLinearProgressProps extends QuantityPercentAndStock {
  unit: string;
}
export const ColorsQuantityPercentAndStockForLabel = {
  red: 'error',
  yellow: 'warning',
  green: 'success',
};
export const ColorsStockLinearProgress = {
  red: 'red',
  yellow: '#ffeb3b',
  green: 'green',
};
export const getQuantityPercentAndStock = (value = 0, reservationValue = 0): QuantityPercentAndStock => {
  let color = 'red';
  const stock = value - reservationValue;
  const quantityPercent = calculatePersent(stock, value, true);
  if (reservationValue > 0) {
    if (quantityPercent > 10 && quantityPercent <= 50) {
      color = 'yellow';
    } else if (quantityPercent > 50) {
      color = 'green';
    }
  } else {
    color = 'green';
  }
  return { quantityPercent, stock, color };
};
export const StockLinearProgress: FC<StockLinearProgressProps> = ({ quantityPercent = 0, stock = 0, color = 'red', unit }): JSX.Element => {
  return (
    <Box display="flex">
      <Box width="100%" alignSelf={'center'} mr={0.5}>
        <MxLinearProgress
          color="secondary"
          variant="determinate"
          htmlColor={ColorsStockLinearProgress[color]}
          value={Math.abs(quantityPercent) > 100 ? 100 : Math.abs(quantityPercent)}
        />
      </Box>
      <Typography whiteSpace="pre" variant="body2" component="div">
        {`${quantityPercent}% (${formatNumber3(stock)}\u00A0${unit})`}
      </Typography>
    </Box>
  );
};
