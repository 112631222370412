import { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { MxLabel } from '@mx-ui/ui';
import { getMarginalityColorClass } from '../../views/clients/lib';
import { renderPriceCur } from '../../utils/renderFormat';

const labelStyle = {
  fontSize: '14px',
  display: 'inline',
  paddingTop: '4px',
};

interface MarginalityPctSumProps {
  pct?: number;
  cost?: number;
}

export const MarginalityPctSum: FC<MarginalityPctSumProps> = observer(({ pct, cost }): JSX.Element => {
  return (
    <MxLabel sx={labelStyle} color={getMarginalityColorClass(pct, true)}>
      <span>{Math.round(pct * 100) / 100 || 0}</span>
      <span>% ({renderPriceCur(cost)})</span>
    </MxLabel>
  );
});
