import React, { FC, useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import { Theme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import { SxProps } from '@mui/system';
import DeleteIcon from '@mui/icons-material/Delete';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import type { FileUpload } from 'src/components/UploadFile/types';
import { ParticipantUpload } from '../Clients/ClientDocuments/ClientDocuments';
import CloseIcon from '@mui/icons-material/Close';
interface FilePreviewsProps {
  files: FileUpload[] | ParticipantUpload[];
  columns?: number;
  rootStyle?: SxProps<Theme>;
  isDeletable?: boolean;
  deleteFile: (file: FileUpload | ParticipantUpload) => void;
  hasPicturesPreview?: boolean;
}
export const FilePreviews: FC<FilePreviewsProps> = ({
  files = [],
  deleteFile,
  columns = 2,
  isDeletable = true,
  rootStyle,
  hasPicturesPreview = true,
}): JSX.Element => {
  const [isCheckedPreview, setIsCheckedPreview] = useState(false);
  const [imageTitle, setImageTitle] = useState('');

  const [imageSrc, setImageSrc] = useState<string | null>(null);
  const handleDeleteFile = (file: FileUpload | ParticipantUpload): void => {
    deleteFile(file);
  };
  const handleOpenPreview = (doc: FileUpload | ParticipantUpload): void => {
    setIsCheckedPreview(true);
    setImageTitle(doc?.name);
    // @ts-ignore
    setImageSrc(doc?.imageSrc || '');
  };
  const handleCloseZoom = (): void => {
    setIsCheckedPreview(false);
    setTimeout(() => {
      setImageSrc('');
      setImageTitle('');
    }, 200);
  };

  useEffect(() => {
    if (files?.length) {
      files?.forEach(file => {
        try {
          const reader = new FileReader();
          reader.onloadend = () => {
            file.imageSrc = reader.result as string;
          };
          reader?.readAsDataURL(file);
        } catch (e) {
          console.log('readAsDataURLError');
        }
      });
    }
  }, [files, files?.length]);

  return (
    <Box
      sx={rootStyle}
      display="grid"
      gridTemplateColumns={Array.from(new Array(columns))
        .map(() => '1fr')
        .join(' ')}
    >
      {files.map(file => {
        const size = Number((file.size / 1024).toFixed(1));
        const isFileImage =
          file?.url?.match(/\.(jpeg|jpg|gif|png|tiff|PNG|TIFF|GIF|JPG|JPEG)/) != null ||
          file?.name?.match(/\.(jpeg|jpg|gif|png|tiff|PNG|TIFF|GIF|JPG|JPEG)/) != null ||
          file?.path?.match(/\.(jpeg|jpg|gif|png|tiff|PNG|TIFF|GIF|JPG|JPEG)/) != null;
        return (
          <Paper
            sx={{ display: 'flex', width: '100%', maxWidth: '250px', margin: '4px' }}
            key={(file.code || file.name) + String(file.size)}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
                margin: '6px',
              }}
            >
              <Grid container>
                <Grid item>
                  {isFileImage && hasPicturesPreview ? (
                    <Avatar
                      src={file.imageSrc || file.url}
                      variant="rounded"
                      sx={{ cursor: 'pointer', color: '#fff' }}
                      onClick={() => handleOpenPreview(file)}
                    >
                      <InsertDriveFileIcon />
                    </Avatar>
                  ) : (
                    <Avatar src={file.url} variant="rounded" sx={{ color: '#fff' }}>
                      <InsertDriveFileIcon />
                    </Avatar>
                  )}
                </Grid>

                <Box sx={{ marginLeft: '8px' }} title="Скачать файл">
                  {file.code ? (
                    <Link download href={file.url} target="_blank" color="inherit">
                      <Typography
                        sx={{ width: '144px', cursor: 'pointer', fontSize: '14px !important' }}
                        variant="h6"
                        noWrap
                        title={file.name}
                      >
                        {file.name}
                      </Typography>
                    </Link>
                  ) : (
                    <Typography sx={{ width: '144px', fontSize: '14px !important' }} variant="h6" noWrap title={file.name}>
                      {file.name}
                    </Typography>
                  )}

                  {isNaN(size) && (
                    <Typography sx={{ color: 'rgb(101, 116, 139)' }} gutterBottom variant="body2">
                      {isNaN(size) ? 0 : size}Kb
                    </Typography>
                  )}
                </Box>
              </Grid>
              {/* {file.url && }*/}
              {isDeletable && (
                <>
                  <Box
                    title="Удалить файл"
                    onClick={() => handleDeleteFile(file)}
                    sx={{ display: 'flex', opacity: '0.7', cursor: 'pointer' }}
                  >
                    {!file.isSending ? (
                      <Avatar className="delete-button" variant="rounded" sx={{ color: '#fff', '&:hover': { backgroundColor: 'tomato' } }}>
                        <DeleteIcon />
                      </Avatar>
                    ) : (
                      <CircularProgress size={35} />
                    )}
                  </Box>
                </>
              )}
            </Box>
          </Paper>
        );
      })}
      <Dialog open={isCheckedPreview} onClose={handleCloseZoom}>
        <DialogTitle sx={{ padding: '10px' }}>
          <Grid container justifyContent="space-between" alignItems="center" flexWrap="nowrap">
            <Grid item sx={{ fontSize: '1rem' }} flex="1 1 90%">
              {imageTitle}
            </Grid>
            <Grid item>
              <IconButton
                aria-label="close"
                onClick={handleCloseZoom}
                sx={{
                  color: theme => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent sx={{ padding: '10px' }}>
          {imageSrc && (
            <img
              src={imageSrc}
              alt={imageTitle}
              style={{ height: '100%', maxHeight: '65vh', width: '100%', objectFit: 'contain' }}
              loading="lazy"
            />
          )}
        </DialogContent>
      </Dialog>
    </Box>
  );
};
