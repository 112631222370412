import React, { FC, useState, useEffect, useCallback } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { observer } from 'mobx-react-lite';
import { UploadFile } from 'src/components/UploadFile/UploadFile';
import { FilePreviews } from 'src/components/UploadFile/FilePreviews';
import { useRootStore } from 'src/store/MobxStoreProvider';
import type { FileUpload } from 'src/components/UploadFile/types';
import { checkDuplicateFiles } from 'src/components/UploadFile/checkDuplicateFiles';
import { TabSelectWrapper } from '../../../components/Deals/CustomIconTabs/TabSelectWrapper';
import { DocumentsTable } from '../../../components/UploadFile/DocumentsTable';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { DialogContainer } from '../../../components/ui/dialogs/DialogContainer';
import Button from '@mui/material/Button';
import { DocumentType } from '../../../api/marketx';
interface DealPositionAdditionalTabFilesProps {
  uploadedFiles: FileUpload[];
  isViewOnly?: boolean;
  onSave: (files: FileUpload[], docType: string) => void;
  onDelete: (fileCode: string) => void;
}

// Закладка с информацией о файлах позиции
export const DealPositionAdditionalTabFiles: FC<DealPositionAdditionalTabFilesProps> = observer(
  ({ isViewOnly, uploadedFiles, onSave, onDelete }): JSX.Element => {
    const rootStore = useRootStore();
    const sbStore = rootStore.getSnackbar();
    const apiStore = rootStore.getApiStore();
    const [open, setOpen] = React.useState(false);
    const [localUploadedFiles, setLocalUploadedFiles] = useState<File[]>(uploadedFiles); //* файлы из store
    const [files, setFiles] = useState<FileUpload[]>([]); //* файлы для отображения

    const [fileTypes, setFileTypes] = useState<Array<DocumentType>>([]);
    const [hasAutocompleteError, setHasAutocompleteError] = useState(true);
    const [autocompleteValue, setAutocompleteValue] = useState(null);

    const handleChange = useCallback((event, value): void => {
      setAutocompleteValue(value);
      if (value) {
        setHasAutocompleteError(false);
      } else {
        setHasAutocompleteError(true);
      }
    }, []);

    useEffect(() => {
      if (apiStore) {
        apiStore
          .apiDocuments()
          .documentsTypes()
          .then(res => {
            setFileTypes(res.data.types);
          });
      }
    }, [apiStore]);
    const handleCloseDialog = (): void => {
      setOpen(false);
      setTimeout(() => {
        setFiles([]);
      }, 200);
    };
    const handleClickOpen = (): void => {
      setOpen(true);
    };

    const handleUploadFiles = (uploadableFiles: File[]): void => {
      const [acceptedFiles, errors] = checkDuplicateFiles(files, uploadableFiles);
      errors.forEach(error => {
        sbStore.showError(error.message);
      });
      setFiles(files => [...files, ...acceptedFiles]);
    };

    const handleDocumentsSave = useCallback(() => {
      handleCloseDialog();
      onSave(files, autocompleteValue.code);
    }, [files, onSave, autocompleteValue]);

    const handleDeleteTemporaryFile = (file: FileUpload): void => {
      const tempFiles = [...files];
      const index = files.indexOf(file);
      tempFiles.splice(index, 1);
      setFiles(tempFiles);
    };

    const onDeleteDoc = useCallback(
      (file: FileUpload) => {
        onDelete(file.code);
      },
      [onDelete]
    );

    useEffect(() => {
      setLocalUploadedFiles(uploadedFiles || []);
    }, [uploadedFiles.length]);
    return (
      <>
        <TabSelectWrapper>
          <Box mt={0.5} pb={2} width="100%" display="flex" flexDirection="column" className="withCustomScroll" sx={{ height: '100%' }}>
            {!isViewOnly ? (
              <Box sx={{ mt: 2 }}>
                <Button size={'small'} variant={'contained'} onClick={handleClickOpen}>
                  Добавить
                </Button>
              </Box>
            ) : null}
            <Box sx={{ mt: 2 }}>
              <DocumentsTable files={localUploadedFiles} deleteFile={onDeleteDoc} isDeletable={isViewOnly} />
            </Box>
          </Box>
        </TabSelectWrapper>
        <DialogContainer
          open={open}
          onClose={handleCloseDialog}
          dialogTitle={'Загрузка файлов'}
          maxWidth={'lg'}
          dialogContent={
            <>
              <Box sx={{ mt: 1, mb: 2 }}>
                <Autocomplete
                  value={autocompleteValue}
                  onChange={handleChange}
                  disablePortal
                  getOptionLabel={i => {
                    return i.name;
                  }}
                  isOptionEqualToValue={(option, value) => {
                    return option.code === value.code;
                  }}
                  disabled={!fileTypes.length}
                  size="small"
                  options={fileTypes}
                  renderInput={params => <TextField {...params} label="Тип файла" helperText={''} error={!!hasAutocompleteError} />}
                />
              </Box>
              <UploadFile upload={handleUploadFiles} />
              {!!files.length && (
                <Box sx={{ marginTop: '6px', overflow: 'auto' }}>
                  <Typography variant="h6">Файлы</Typography>
                  <FilePreviews files={files} columns={1} deleteFile={handleDeleteTemporaryFile} />
                </Box>
              )}
            </>
          }
          disabled={!files.length || hasAutocompleteError}
          successBtnText={'Сохранить'}
          canselBtnText={'Отменить'}
          handleSuccess={handleDocumentsSave}
        />
      </>
    );
  }
);
