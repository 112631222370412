import { observer } from 'mobx-react-lite';
import React, { FC, useCallback, useMemo } from 'react';
import { DealItemStore } from '../../../store/DealItemStore';
import { CustomSelectNew } from '../components/CustomSelectNew';

interface DealDistributorSelectProps {
  dealStore: DealItemStore;
}

export const DealDistributorSelect: FC<DealDistributorSelectProps> = observer(({ dealStore }): JSX.Element => {
  const distributors = dealStore.getDistributorsStore();

  const handleDistributorChange = useCallback(
    (value: string | null) => {
      dealStore.updateDeal({ distributorCode: value }).catch(r => console.warn('handleDistributorChange', r));
    },
    [dealStore]
  );

  const options = useMemo<Array<{ value; text }>>(() => {
    return distributors.items.map(it => ({
      value: it.code,
      text: it.title,
    }));
  }, [distributors, distributors.loadedEpoch]);

  const isViewOnly = useMemo<boolean>(() => {
    return dealStore.isViewOnly() || distributors.isLoading;
  }, [dealStore.isViewOnly(), distributors.isLoading]);

  return (
    <CustomSelectNew
      label={'Реализатор'}
      options={options}
      isViewOnly={isViewOnly}
      defaultValue={dealStore.deal?.distributor?.employeeCode}
      onChange={handleDistributorChange}
    />
  );
});
