import { makeAutoObservable, runInAction } from 'mobx';
import { AxiosResponse } from 'axios';
import { FeedbackFormOpenRequest, FeedbackFormOpenResponse, FeedbackFormSendRequest } from '../api/marketx';
import { RootStore } from './StoreManager';
import { ApiStore } from './Global/ApiStore';

const formatYmdThis = (d: Date): string => {
  const yy = d.getFullYear();
  const mm = d.getMonth() + 1;
  const dd = d.getDate();
  const hh = d.getHours();
  const ii = d.getMinutes();
  const ss = d.getSeconds();
  return (
    yy +
    '-' +
    (mm > 9 ? mm : '0' + mm) +
    '-' +
    (dd > 9 ? dd : '0' + dd) +
    'T' +
    (hh > 9 ? hh : '0' + hh) +
    ':' +
    (ii > 9 ? ii : '0' + ii) +
    ':' +
    (ss > 9 ? ss : '0' + ss)
  );
};

export class FeedbackFormStoreItem {
  store: FeedbackFormStore;
  openRequestTime: Date; // время последнего запроса открытия формы, на который получен ответ
  isOpenSuccess: boolean; // станет true после завершения запроса feedbackFormOpen
  sendingCount: number; // количество выполняющихся запросов сохранения формы (на случай параллельного запуска)
  isSending: boolean; // // станет true на время отправки формы
  isSendSuccess: boolean; // станет true после успешного сохранения формы

  authorEmail: string;
  attachments: string[];
  formGuid: string;
  authorName: string;
  authorPhone: string;
  message: string;

  constructor(store: FeedbackFormStore) {
    makeAutoObservable(this, {
      store: false,
      send: false,
    });
    this.store = store;
  }

  onOpen(): void {
    this.store.onOpen(this);
  }

  async send(req: SendRequest): Promise<boolean> {
    const isSendedScreenShots = await this.store.sendScreenShots(this, req.files);
    if (isSendedScreenShots) {
      try {
        await this.store.send(this, req);
        return true;
      } catch (error) {
        return false;
      }
    } else {
      return false;
    }
  }

  logCancel(req: SendRequest): void {
    this.store.logCancel(this, req);
  }
}

export interface SendRequest {
  name?: string;
  phone?: string;
  attachments?: string[];
  files?: File[];
  email?: string;
  message?: string;
}

export class FeedbackFormStore {
  // Задержка перед началом загрузки
  interval: NodeJS.Timeout;

  item: FeedbackFormStoreItem;
  apiStore: ApiStore;
  constructor(rootStore: RootStore) {
    this.apiStore = rootStore.getApiStore();
  }
  getFormItem(): FeedbackFormStoreItem {
    if (!this.item) {
      this.item = new FeedbackFormStoreItem(this);
    }
    return this.item;
  }

  // дополнительные данные для отладки
  getExtraData(): Record<string, any> {
    try {
      const data: Record<string, any> = {};
      if (typeof document !== 'undefined' && document.referrer) {
        data.referrer = document.referrer;
      }
      if (typeof window !== 'undefined') {
        if (window.screen) {
          data.screenWidth = window.screen.width;
          data.screenHeight = window.screen.height;
        }
      }
      return data;
    } catch (e) {
      return { error: e };
    }
  }

  onOpen(item: FeedbackFormStoreItem): void {
    const data = this.getExtraData();
    const requestTime = new Date();

    this.apiStore
      .apiClientFeedback()
      .feedbackFormOpen(<FeedbackFormOpenRequest>{
        now: formatYmdThis(requestTime),
        page: typeof location !== 'undefined' ? location.href : undefined,
        other: data,
      })
      .then((res: AxiosResponse<FeedbackFormOpenResponse>) => {
        if (item.openRequestTime && item.openRequestTime.getTime() > requestTime.getTime()) {
          return;
        }
        if (res && res.data && res.data.formGuid) {
          runInAction(() => {
            item.isOpenSuccess = true;
            item.openRequestTime = requestTime;
            item.formGuid = res.data.formGuid;
            item.attachments = [];
          });
        }
      });
  }
  async sendScreenShots(item: FeedbackFormStoreItem, files: File[]): Promise<boolean> {
    if (!files.length) {
      return true;
    }
    const promises = files.map(file => this.apiStore.apiClientFeedback().feedbackUploadAttachment(file, item.formGuid));
    try {
      const res = await Promise.all(promises);
      runInAction(() => {
        item.attachments = res.map(({ data }) => data.attachmentCode);
      });
      return true;
    } catch (error) {
      return false;
    }
  }

  async send(item: FeedbackFormStoreItem, req: SendRequest): Promise<void> {
    const data = this.getExtraData();
    runInAction(() => {
      item.sendingCount += 1;
      item.isSending = true;
      item.isSendSuccess = false;
      item.authorEmail = req.email;
      item.authorPhone = req.phone;
      item.authorName = req.name;
      item.message = req.message;
    });

    return this.apiStore
      .apiClientFeedback()
      .feedbackFormSend(<FeedbackFormSendRequest>{
        now: formatYmdThis(new Date()),
        page: typeof location !== 'undefined' ? location.href : undefined,
        other: data,
        attachments: item.attachments,
        formGuid: item.formGuid,

        authorEmail: req.email,
        authorName: req.name,
        authorPhone: req.phone,
        message: req.message,
      })
      .then(() => {
        runInAction(() => {
          item.isSendSuccess = true;
          item.sendingCount -= 1;
          item.isSending = item.sendingCount > 0;
        });
      })
      .catch(() => {
        runInAction(() => {
          item.sendingCount -= 1;
          item.isSending = item.sendingCount > 0;
        });
      });
  }

  // логирование сообщений, которые пользователь передумал отправлять
  logCancel(item: FeedbackFormStoreItem, req: SendRequest): void {
    console.log('not implemented logCancel', item, req);
  }
}
