import { makeAutoObservable } from 'mobx';
import { AgreementItemStore } from './Clients/AgreementItemStore';
import { DealItemStore } from './DealItemStore';
import { ClientItemStore } from './ClientItemStore';
import { FreezeItemStore } from './FreezeItemStore';

export type entityType = 'deal' | 'agreement' | 'client' | 'freeze';

/**
 * Провайдер данных для подстановки в шапку страницы
 */
export interface TopBarEntityStore {
  typeName(): entityType;

  /**
   * Код-идентификатор сущности
   */
  entityCode(): string;

  /**
   * Заголовок для выбора клиента
   */
  titleForClient(): string;

  /**
   * Заголовок для добавления товара из каталога
   */
  titleForCatalog(): string;

  /**
   * Наименование контрагента
   */
  customerTitle(): string;
}

/**
 * Глобальный стор с состоянием верхней панели
 */
export class TopBarStore {
  /**
   * Стор текущей сделки или соглашения, если активирован режим добавления товаров в сделку
   */
  currentEntityStore: DealItemStore | AgreementItemStore | ClientItemStore | FreezeItemStore = null;
  commonEntityStore: TopBarEntityStore = null;
  entityCode = '';
  entityType: entityType = null;

  constructor() {
    makeAutoObservable(this);
  }
  resetStore(): void {
    this.entityType = null;
    this.entityCode = null;
    this.currentEntityStore = null;
    this.commonEntityStore = null;
  }

  setAgreementEntityStore(ds: AgreementItemStore): void {
    this.currentEntityStore = ds;
    this.commonEntityStore = ds.getTopBarEntityStore();
    this.entityType = this.commonEntityStore.typeName();
    this.entityCode = this.commonEntityStore.entityCode();
  }
  setClientItemStore(ds: ClientItemStore, customerCode: string): void {
    this.currentEntityStore = ds;
    this.commonEntityStore = ds.getTopBarEntityStore();
    this.entityType = this.commonEntityStore.typeName();
    this.entityCode = this.commonEntityStore.entityCode() || ds.details?.code || customerCode || '';
  }
  setDealEntityStore(ds: DealItemStore): void {
    this.currentEntityStore = ds;
    this.commonEntityStore = ds.getTopBarEntityStore();
    this.entityType = this.commonEntityStore.typeName();
    this.entityCode = this.commonEntityStore.entityCode();
  }
  setFreezeEntityStore(ds: FreezeItemStore): void {
    this.currentEntityStore = ds;
    this.commonEntityStore = ds.getTopBarEntityStore();
    this.entityType = this.commonEntityStore.typeName();
    this.entityCode = this.commonEntityStore.entityCode();
  }

  clearStore(): void {
    this.currentEntityStore = null;
    this.commonEntityStore = null;
    this.entityType = null;
    this.entityCode = '';
  }
}
