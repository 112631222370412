import React, { ChangeEvent, FC, useCallback, useEffect, useState, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { SendRequest } from '../../../store/FeedbackForm';
import { useAuthStore, useRootStore } from '../../../store/MobxStoreProvider';
import { UploadFile } from 'src/components/UploadFile/UploadFile';
import { FilePreviews } from 'src/components/UploadFile/FilePreviews';
import { checkDuplicateFiles } from 'src/components/UploadFile/checkDuplicateFiles';
import { FileUpload } from 'src/components/UploadFile/types';
import CloseIcon from '@mui/icons-material/Close';
interface FeedbackButtonProps {
  button?: (handle: () => void) => JSX.Element;
  themeMode?: string;
}

export const FeedbackButton: FC<FeedbackButtonProps> = observer(({ button, themeMode }) => {
  const [open, setOpen] = useState<boolean>(false);
  const form = useRootStore().getFeedbackForm().getFormItem();
  const auth = useAuthStore();
  const sbStore = useRootStore().getSnackbar();
  const dialogRed = useRef();
  const [email, setEmail] = useState<string>(auth?.profile?.email || '');
  const [phone, setPhone] = useState<string>('');
  const [name, setName] = useState<string>(auth?.profile?.name || '');
  const [message, setMessage] = useState<string>('');
  const [files, setFiles] = useState<File[]>([]);
  useEffect(() => {
    if (open) {
      setFiles([]);
    }
  }, [open]);
  const handleDeleteFile = useCallback(
    (file: File): void => {
      const tempFiles = [...files];
      const index = files.indexOf(file);
      tempFiles.splice(index, 1);

      setFiles(tempFiles);
    },
    [files]
  );
  useEffect(() => {
    if (!email && auth?.profile?.email) {
      setEmail(auth?.profile?.email);
    }
    if (!name && auth?.profile?.name) {
      setName(auth?.profile?.name);
    }
  }, [auth, auth.isLoaded, email, form, name]);

  const handleOpenClick = useCallback(() => {
    if (!email && auth?.profile?.email) {
      setEmail(auth?.profile?.email);
    }
    if (!name && auth?.profile?.name) {
      setName(auth?.profile?.name);
    }
    setOpen(true);
    form.onOpen();
  }, [setOpen, auth, email, name, form]);

  const handleCloseClick = useCallback(() => {
    form.logCancel({
      name: name,
      phone: phone,
      email: email,
      message: message,
    } as SendRequest);
    setOpen(false);
  }, [form, setOpen, name, phone, email, message]);

  const handleSendClick = useCallback(async () => {
    const isSended = await form.send({
      name,
      phone,
      email,
      message,
      files,
    } as SendRequest);
    if (isSended) {
      setOpen(false);
    } else {
      console.error('не удалось отправить форму');
    }
  }, [form, setOpen, name, phone, email, message, files]);

  const handleEmailChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setEmail(e.target.value);
    },
    [setEmail]
  );
  const handleUploadFiles = (uploadableFiles: File[]): void => {
    const [acceptedFiles, errors] = checkDuplicateFiles(files, uploadableFiles);
    errors.forEach(error => {
      sbStore.showError(error.message);
    });
    setFiles(files => [...files, ...acceptedFiles]);
  };
  const onLoadFileFromClipboard = (event): void => {
    const items = event.clipboardData.items;
    for (const item of items) {
      if (item.kind === 'file') {
        const blob = item.getAsFile() as FileUpload;
        const reader = new FileReader();
        reader.onload = event => {
          blob.url = event.target.result.toString();
          setFiles(files => {
            //* загружаем файл здесь, т.к. не видно обновлений "files" вне setFiles
            const [acceptedFiles, errors] = checkDuplicateFiles(files, [blob]);
            errors.forEach(error => {
              sbStore.showError(error.message);
            });
            return [...files, ...acceptedFiles];
          });
        };
        reader.readAsDataURL(blob);
      }
    }
  };
  const handlePhoneChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setPhone(e.target.value);
    },
    [setPhone]
  );

  const handleNameChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setName(e.target.value);
    },
    [setName]
  );

  const handleMessageChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setMessage(e.target.value);
    },
    [setMessage]
  );

  return (
    <>
      {button ? (
        button(handleOpenClick)
      ) : (
        <Button onClick={handleOpenClick} color={'primary'} variant={'text'} sx={{ color: themeMode === 'dark' ? '#fff' : 'primary' }}>
          Поддержка
        </Button>
      )}

      <Dialog
        ref={dialogRed}
        onClose={handleCloseClick}
        TransitionProps={{
          onEntering: node => node.addEventListener('paste', onLoadFileFromClipboard, false),
          onExiting: node => node.removeEventListener('paste', onLoadFileFromClipboard, false),
        }}
        aria-labelledby="simple-dialog-title"
        open={open}
      >
        <DialogTitle id="simple-dialog-title">
          Обратная связь
          <IconButton
            aria-label="close"
            onClick={handleCloseClick}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: theme => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText style={{ cursor: 'default' }}>
            <Typography color="text.secondary" component="span" style={{ fontSize: 12 }}>
              Если возникла ошибка, пожалуйста, опишите последовательность действий, которую вы совершали; ожидаемый результат и что
              получилось.
            </Typography>
            <br />
            <Typography color="text.secondary" component="span" style={{ fontSize: 12 }}>
              Ваши контактные данные помогут воспроизвести проблему.
            </Typography>
          </DialogContentText>
          <TextField margin="dense" id="name" label="Имя" type="text" value={name} onChange={handleNameChange} fullWidth />
          <TextField margin="dense" id="email" label="Email" type="email" value={email} onChange={handleEmailChange} fullWidth />
          <TextField margin="dense" id="phone" label="Телефон" type="text" value={phone} onChange={handlePhoneChange} fullWidth />
          <Box sx={{ marginY: '4px' }}>
            <UploadFile upload={handleUploadFiles} accept={['image']} />
            <FilePreviews files={files} deleteFile={handleDeleteFile} />
          </Box>
          <TextField
            autoFocus
            margin="dense"
            label="Сообщение"
            type="text"
            value={message}
            variant="outlined"
            rows={4}
            fullWidth
            multiline
            onChange={handleMessageChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseClick} color="primary" disabled={form.isSending}>
            Отмена
          </Button>
          <Button onClick={handleSendClick} color="primary" disabled={form.isSending}>
            Отправить
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
});
