import React, { FC } from 'react';

interface PieChartIconProps {
  width?: string;
  height?: string;
  color?: string;
}

const PieChartIcon: FC<PieChartIconProps> = ({ width = '18px', height = '18px', color = 'currentColor' }): JSX.Element => {
  return (
    <>
      <svg version="1.0" width={width} height={height} viewBox="0 0 64.000000 64.000000">
        <g transform="translate(0.000000,64.000000) scale(0.100000,-0.100000)" fill={color}>
          <path
            d="M340 485 l0 -145 145 0 c166 0 160 -5 129 100 -29 99 -149 190 -251
190 -23 0 -23 0 -23 -145z m125 98 c69 -35 126 -110 140 -186 l7 -37 -126 0
-126 0 0 126 0 126 37 -7 c21 -4 51 -14 68 -22z"
          />
          <path
            d="M211 559 c-114 -33 -201 -149 -201 -270 0 -115 87 -233 195 -265 170
-49 338 58 362 231 l6 45 -137 0 -136 0 0 135 0 135 -27 -1 c-16 0 -43 -5 -62
-10z m69 -144 l0 -135 136 0 136 0 -7 -37 c-14 -76 -68 -147 -140 -186 -31
-17 -58 -22 -115 -22 -85 0 -133 20 -186 77 -54 57 -69 95 -69 178 0 83 15
121 69 178 42 45 108 79 159 81 15 1 17 -12 17 -134z"
          />
        </g>
      </svg>
    </>
  );
};

export default PieChartIcon;
