import React, { FC, ReactElement, useState } from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid, { GridProps } from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Popper from '@mui/material/Popper';
import { formatNumber02, formatPriceCur } from '@mx-ui/helpers';
import { BatchIndicatorsCosts } from '../../store/BatchesListStore';

export type BatchT = {
  todhDiscountPct?: number;
  todhDiscountIlliquidBlocked?: boolean;
  illiquidDefectCode?: string;
  illiquidDefectTitle?: string;
  illiquidRustCode?: string;
  illiquidRustTitle?: string;
  illiquidDiscountPct?: number;
  isSafeCustody?: boolean;
  selfPurchase?: boolean;
};

interface ProductIndicatorsProps {
  batch: BatchT;
  tableCellRef: React.MutableRefObject<undefined>;
  costs: BatchIndicatorsCosts | null;
}

const ProductIndicators: FC<ProductIndicatorsProps & GridProps> = ({ batch, costs, tableCellRef, ...rest }): ReactElement => {
  const [isTitleTooltipOpen, setIsTitleTooltipOpen] = useState(false);
  const [tooltipType, setTooltipType] = useState<'OH' | 'NL' | 'NK' | 'SZ'>('OH');

  const handleTooltipOpen = (event: React.MouseEvent<any>, tooltipType: 'OH' | 'NL' | 'NK' | 'SZ'): void => {
    event.stopPropagation();
    setTooltipType(tooltipType);
    setIsTitleTooltipOpen(true);
  };
  const handleTooltipClose = (): void => {
    setIsTitleTooltipOpen(false);
  };

  const tooltipTitles = {
    OH: (
      <Grid container flexDirection="column">
        <Grid item>
          <Typography variant={'h6'}>Ответственное хранение</Typography>
        </Grid>
      </Grid>
    ),
    NL: (
      <Grid container flexDirection="column">
        <Grid item>
          <Typography variant={'h6'}>Неликвид</Typography>
        </Grid>
        <Grid item>
          <Typography>{`Скидка ${formatNumber02(batch.todhDiscountPct)} %`}</Typography>
        </Grid>
        {costs?.todhMrcUnitCost > 0 && (
          <Grid item>
            <Typography>{`МРЦ - ${formatNumber02(batch.todhDiscountPct)} % = ${formatPriceCur(costs.todhMrcUnitCost)}`}</Typography>
          </Grid>
        )}
      </Grid>
    ),
    NK: (
      <Grid container flexDirection="column">
        <Grid item>
          <Typography variant={'h6'}>Некондиция</Typography>
        </Grid>
        {Boolean(batch.illiquidDefectCode) && (
          <Grid item>
            <Typography>
              <span style={{ color: 'red' }}>{batch.illiquidDefectCode}</span>
              {` ${batch.illiquidDefectTitle}`}
            </Typography>
          </Grid>
        )}
        {Boolean(batch.illiquidRustCode) && (
          <Grid item>
            <Typography>
              <span style={{ color: 'red' }}>{batch.illiquidRustCode}</span>
              {` ${batch.illiquidRustTitle}`}
            </Typography>
          </Grid>
        )}
        <Divider />
        {Boolean(batch.illiquidDiscountPct) && (
          <>
            <Grid item>
              <Typography>{`Скидка ${batch.illiquidDiscountPct} %`}</Typography>
            </Grid>
            <Grid item>
              <Typography>
                {costs?.illiquidMrcUnitCost > 0 ? `\nМРЦ-${batch.illiquidDiscountPct}% = ` + formatPriceCur(costs.illiquidMrcUnitCost) : ''}
              </Typography>
            </Grid>
          </>
        )}
      </Grid>
    ),
    SZ: (
      <Grid container flexDirection="column">
        <Grid item>
          <Typography variant={'h6'}>Самозакуп</Typography>
        </Grid>
      </Grid>
    ),
  };

  return (
    <Grid container flexWrap="nowrap" justifyContent="flex-end" {...rest}>
      {batch.isSafeCustody ? (
        <Grid item>
          <Box
            onMouseEnter={event => handleTooltipOpen(event, 'OH')}
            onMouseLeave={handleTooltipClose}
            sx={{
              borderRadius: '3px',
              fontSize: 16,
              verticalAlign: 'middle',
              fontFamily: 'Arial, Helvetica, sans-serif',
              backgroundColor: '#E3DB41',
              display: 'inline-block',
              width: 40,
              height: 20,
              textAlign: 'center',
              lineHeight: '20px',
            }}
          >
            <Box display="flex" justifyContent="center" sx={{ color: '#f6eeee', cursor: 'default' }}>
              ОХ
            </Box>
          </Box>
        </Grid>
      ) : null}
      {Boolean(batch.selfPurchase) && (
        <Grid item sx={{ marginLeft: '3px' }}>
          <Box
            onMouseEnter={event => handleTooltipOpen(event, 'SZ')}
            onMouseLeave={handleTooltipClose}
            sx={{
              borderRadius: '3px',
              fontSize: 16,
              verticalAlign: 'middle',
              fontFamily: 'Arial, Helvetica, sans-serif',
              backgroundColor: '#00a2e8',
              display: 'inline-block',
              width: 40,
              height: 20,
              textAlign: 'center',
              lineHeight: '20px',
            }}
          >
            <Box display="flex" justifyContent="center" sx={{ color: '#f6eeee', cursor: 'default' }}>
              СЗ
            </Box>
          </Box>
        </Grid>
      )}
      {batch.todhDiscountPct > 0 || batch.todhDiscountIlliquidBlocked ? (
        <Grid item sx={{ marginLeft: '3px' }}>
          <Box
            onMouseEnter={event => handleTooltipOpen(event, 'NL')}
            onMouseLeave={handleTooltipClose}
            sx={{
              borderRadius: '3px',
              fontSize: 16,
              verticalAlign: 'middle',
              fontFamily: 'Arial, Helvetica, sans-serif',
              backgroundColor: '#9C2726',
              display: 'inline-block',
              width: 40,
              height: 20,
              textAlign: 'center',
              lineHeight: '20px',
            }}
          >
            <Box display="flex" justifyContent="center" sx={{ color: '#f6eeee', cursor: 'default' }}>
              НЛ
            </Box>
          </Box>
        </Grid>
      ) : null}
      {(Boolean(batch.illiquidDefectCode) || Boolean(batch.illiquidRustCode) || Boolean(batch.illiquidDiscountPct)) && (
        <Grid item sx={{ marginLeft: '3px' }}>
          <Box
            sx={{
              borderRadius: '3px',
              fontSize: 16,
              verticalAlign: 'middle',
              fontFamily: 'Arial, Helvetica, sans-serif',
              backgroundColor: '#DCA63E',
              display: 'inline-block',
              width: 40,
              height: 20,
              textAlign: 'center',
              lineHeight: '20px',
            }}
            onMouseEnter={event => handleTooltipOpen(event, 'NK')}
            onMouseLeave={handleTooltipClose}
          >
            <Box display="flex" justifyContent="center" sx={{ color: '#f6eeee', cursor: 'default' }}>
              НК
            </Box>
          </Box>
        </Grid>
      )}
      <Popper anchorEl={tableCellRef.current} open={isTitleTooltipOpen} placement="top-end" sx={{ zIndex: 1500, maxWidth: '80vw' }}>
        <Paper
          sx={{
            padding: 1,
          }}
        >
          {tooltipTitles[tooltipType] || null}
        </Paper>
      </Popper>
    </Grid>
  );
};

export default ProductIndicators;
