import { useRef } from 'react';

export default function useDebouncedFunction(func: any, delay: number): any {
  const ref = useRef(null);

  return (...args): void => {
    clearTimeout(ref.current);
    ref.current = setTimeout(() => func(...args), delay);
  };
}
