import { PaletteMode, Theme } from '@mui/material';
import { grey } from '@mui/material/colors';
import { responsiveFontSizes, createTheme, ThemeOptions } from '@mui/material/styles';
import { ruRU as dataGridRuRU } from '@mui/x-data-grid';
import { ruRU as coreRuRU } from '@mui/material/locale';
import { ruRU } from '@mui/x-date-pickers/locales';
export const backgroundColor = {
  dark: '#121212',
  light: '#f4f6f8',
};
const themeStyles = (mode: PaletteMode): ThemeOptions => ({
  direction: 'ltr',
  components: {
    MuiModal: {
      styleOverrides: {
        // root: {
        //   ['& .MuiPaper-root']: {
        //     color: 'red!important',
        //     boxShadow: '0px 3px 2px 0px rgb(255, 0, 0),0px -1px 1px 0px rgb(234, 0, 0),0px 2px 1px 0px rgb(249, 0, 0)',
        //   },
        // },
      },
    },
    MuiMenu: {
      // styleOverrides:{},
      // styleOverrides: {root},
      defaultProps: {
        sx: {
          // ['& .MuiMenu-paper']: {
          //   boxShadow: '0px 3px 2px 0px rgb(255, 0, 0),0px -1px 1px 0px rgb(234, 0, 0),0px 2px 1px 0px rgb(249, 0, 0)',
          // },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: 'none',
          cursor: 'pointer',
        },
      },
    },
    MuiDialog: {
      defaultProps: {
        sx: {
          // ['& .MuiDialog-paper']: {
          //   boxShadow: '0px 3px 2px 0px rgb(255, 0, 0),0px -1px 1px 0px rgb(234, 0, 0),0px 2px 1px 0px rgb(249, 0, 0)',
          // },
        },
      },
    },

    MuiPopper: {
      styleOverrides: {
        root: {
          // ...(mode === 'dark' ? { border: '1px solid rgba(255, 255, 255, 0.23)' } : {}),
          // ['& .MuiPaper-root']: {
          //   color: 'red!important',
          //   boxShadow: '0px 3px 2px 0px rgb(255, 0, 0),0px -1px 1px 0px rgb(234, 0, 0),0px 2px 1px 0px rgb(249, 0, 0)',
          // },
          // ['& .MuiTooltip-tooltip']: {
          //   boxShadow: '0px 3px 2px 0px rgb(255, 0, 0),0px -1px 1px 0px rgb(234, 0, 0),0px 2px 1px 0px rgb(249, 0, 0)',
          // },
        },
      },
      ...(mode === 'dark'
        ? {
            // defaultProps: {
            //   sx: {
            //     ['& .MuiPaper-root']: {
            //       // color:'red!important',
            //       boxShadow: '0px 3px 2px 0px rgb(255, 0, 0),0px -1px 1px 0px rgb(234, 0, 0),0px 2px 1px 0px rgb(249, 0, 0)',
            //     },
            //     ['& .MuiTooltip-tooltip']: {
            //       boxShadow: '0px 3px 2px 0px rgb(255, 0, 0),0px -1px 1px 0px rgb(234, 0, 0),0px 2px 1px 0px rgb(249, 0, 0)',
            //     },
            //   },
            // },
          }
        : {}),
    },
    MuiTooltip: {
      styleOverrides: {
        popper: {
          zIndex: 1200,
        },
        tooltip: ({ ownerState }) => ({
          ...(ownerState.componentsProps?.tooltip?.bgwhite && {
            boxShadow: theme.shadows[1],

            //* border добавляется для большего выделения элемента, но главное не оборачивать контент в Paper, то получится двойная граница (Toolip>Paper =двойные бордеры)
            ...(mode === 'dark' ? { border: '1px solid rgba(255, 255, 255, 0.23)' } : {}),
            borderRadius: '15px',
            ...(mode === 'dark'
              ? {
                  backgroundColor: '#252525',
                }
              : { backgroundColor: theme.palette.common.white, color: 'rgba(0, 0, 0, 0.87)' }),
            fontSize: 11,
            zIndex: 1200,
            maxWidth: 'none',
            padding: 0,
          }),
        }),
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: '15px',
          backgroundImage: 'unset',
          //* border добавляется для большего выделения элемента, но главное не добавлять в тултип
          ...(mode === 'dark' ? { border: '1px solid rgba(255, 255, 255, 0.23)' } : {}),
          boxShadow: '0px 3px 2px 0px rgb(0 0 0 / 16%), 0px -1px 1px 0px rgb(0 0 0 / 12%), 0px 2px 1px 0px rgb(0 0 0 / 12%)',
          // boxShadow:
          //   mode === 'dark'
          //     ? '0px 3px 2px 0px rgb(0 0 0 / 42%), 0px -1px 1px 0px rgb(0 0 0 / 34%), 0px 2px 1px 0px rgb(0 0 0 / 34%)'
          //     : '0px 3px 2px 0px rgb(0 0 0 / 16%), 0px -1px 1px 0px rgb(0 0 0 / 12%), 0px 2px 1px 0px rgb(0 0 0 / 12%)',
        },
      },
    },
  },
  palette: {
    ...(mode === 'dark'
      ? {
          background: {
            default: backgroundColor.dark,
            paper: '#242424',
          },
        }
      : {
          background: {
            default: backgroundColor.light,
          },
        }),

    mode: mode,
    primary: {
      main: mode === 'dark' ? '#374cbf' : '#080f72',
    },
    secondary: {
      // main: '#009fb5',
      main: mode === 'dark' ? '#007383' : '#009fb5',
      light: mode === 'dark' ? '#e0e0e0' : 'rgba(0, 0, 0, 0.54)', // Цвет по большей мере для второстепенных иконок
    },
    text: {
      primary: mode === 'dark' ? grey[300] : grey[900],

      // secondary: grey[600],
    },
  },
});

const themeWithNewColor = (mode: PaletteMode): Theme => {
  const theme = createTheme(themeStyles(mode));
  return createTheme(
    theme,
    {
      palette: {
        // собственный цвет юзается для текста с ценами и кнопок, показывает "success" но  successMx
        successMx: theme.palette.augmentColor({
          color: {
            contrastText: '#fff',
            main: '#19B28D',
          },
          name: 'successMx',
        }),
        // собственный цвет юзается для одной кнопки пока, показывает "error" но greyMx
        // dark: "rgb(178, 178, 178)"
        // light: "rgb(255, 255, 255)"
        // main: "#fff"
        greyMx: theme.palette.augmentColor({
          color: {
            main: mode === 'dark' ? 'rgb(178, 178, 178)' : '#4b4c4f',
          },
          name: 'greyMx',
        }),
        text: {
          successMx: '#19B28D',
          greyMx: mode === 'dark' ? 'rgb(178, 178, 178)' : '#4b4c4f',
          light: mode === 'dark' ? grey[100] : '#fff',
        },
      },
    },
    ruRU, // x-date-pickers translations
    dataGridRuRU, // x-data-grid translations
    coreRuRU // core translations
  );
};
const innerTheme = themeWithNewColor('light');
export const theme = responsiveFontSizes(innerTheme);
export const themeByMode = (mode: PaletteMode): Theme => responsiveFontSizes(themeWithNewColor(mode));
