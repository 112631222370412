import { AxiosResponse } from 'axios';
import { makeAutoObservable, runInAction, set, toJS } from 'mobx';
import {
  Employee,
  TaskChangePositionRequest,
  TaskChangePositionResponse,
  TaskCommentCreateRequest,
  TaskCommentCreateResponse,
  TaskCommentItem,
  TaskCreateRequest,
  TaskCreateResponse,
  TaskFilterItem,
  TaskItem,
  TaskLabelItem,
  TaskListResponse,
  TaskListResponseTaskStatusCountersItems0,
  TaskPriorityItem,
  TaskStatusItem,
  TaskUpdateRequest,
  TaskUpdateResponse,
} from 'src/api/marketx';
// import { setClear } from 'src/utils/mobx';
import { EmployeesStore } from './Employees/EmployeesStore';
import { FilterForTaskStore } from './FilterForTaskStore';
import { ApiStore } from './Global/ApiStore';
import { IncomeMessage } from './Global/EventManager';
import { MsgType } from './Global/WebSocketStore';
import { RouterStore } from './RouterStore';
import { RootStore } from './StoreManager';
import { SnackbarStore } from './SnackbarStore';
import { MyApprovalsAskingStore } from './Deals/MyApprovalsAsking';
import { DealListRequest } from './DealListStore';
import { AppDealDistributor } from 'src/slices/AppDeal';
const defaultPageSize = 25;
export interface TasksStoreRequest {
  initiatorCode?: string;
  entityCode?: string;
  filterCode?: 'all' | 'initiator' | 'executor';
  entityTypeCode?: string;
  executorCodes?: string[];
  branchOfficeCode?: string;
  deadlineFrom?: string;
  deadlineTo?: string;
  readyExpireDays?: number;
  labelCodes?: Array<string>;
  statusCodes?: string[];
  priorityCodes?: string[];
  employeeSetCode?: string;
  page?: number;
  count?: number;
  query?: string;
}
export type TasksStoreRequestForUrl = Omit<TasksStoreRequest, 'labelCodes' | 'executorCodes' | 'statusCodes'> & {
  labelCodes?: string;
  executorCodes?: string;
  statusCodes?: string;
  selectedFilters?: string;
};

export const columnToShowLink = ['done', 'canceled'];
export const columnToShowOverdueTimeColor = ['todo', 'in_progress'];

export class TasksStore {
  apiStore: ApiStore;
  /**
   * Управляем адресом страницы
   */
  routerControlEnabled = false;
  isLoaded = false;
  totalCount = 0;
  taskStatusCounters?: TaskListResponseTaskStatusCountersItems0[] = [];
  distributorsStore: MyApprovalsAskingStore;
  routerStore: RouterStore;
  snackbarStore: SnackbarStore;

  request: TasksStoreRequest = {
    labelCodes: [],
    statusCodes: [],
    priorityCodes: [],
    executorCodes: [],
    count: defaultPageSize,
  };
  requestInit: TasksStoreRequest = {
    labelCodes: [],
    statusCodes: [],
    priorityCodes: [],
    executorCodes: [],
  };

  filterStore: FilterForTaskStore = null;
  tasks: TaskItem[] = [];

  employeesStore: EmployeesStore = null;
  labels: TaskLabelItem[] = [];
  priorities: TaskPriorityItem[] = [];
  filters: TaskFilterItem[] = [];
  statuses: TaskStatusItem[] = [];
  isLoading = false;
  isMoreLoading = false;
  hasMore = false;
  updateDebounceTimeout: NodeJS.Timeout;

  constructor(rootStore: RootStore) {
    this.apiStore = rootStore.getApiStore();
    this.snackbarStore = rootStore.getSnackbar();
    this.routerStore = rootStore.getRouter();
    this.handleWs = this.handleWs.bind(this);
    makeAutoObservable(this, {
      apiStore: false,
    });
  }
  /**
   * собираем список офисов из всех задач для фильтра
   */
  get branchOfficeByItems(): { text: string; value: string }[] {
    const filterValuesByBranchOffice = new Map();
    this.tasks.forEach(task => {
      filterValuesByBranchOffice.set(task.branchOfficeCode, {
        text: task.branchOfficeName,
        value: task.branchOfficeCode,
      });
    });
    return Array.from(filterValuesByBranchOffice.values());
  }
  /**
   * фильтруем таски чисто по фильтру из стора filterStore.
   * этот фильтр (branchOfficeCodes) не отправляем на бек
   */
  get tasksFiltered(): TaskItem[] {
    return this.tasks.filter(i => this.filterStore.branchOfficeCodes.includes(i.branchOfficeCode));
  }
  get filteredLabels(): TaskLabelItem[] {
    const labelCodes = [];
    this.tasks.forEach(task => {
      labelCodes.push(...task.labels.map(i => i.code));
    });
    const labelCodesSet = Array.from(new Set(labelCodes));

    return this.labels.filter(label => labelCodesSet.includes(label.code));
  }
  get countFilterSelected(): number {
    let value = 0;
    if (this.request.deadlineFrom || this.request.deadlineTo) {
      value += 1;
    }
    if (this.request.labelCodes?.length) {
      value += 1;
    }
    if (this.request.executorCodes?.length) {
      value += 1;
    }
    if (this.request.initiatorCode) {
      value += 1;
    }
    return value;
  }

  handleWs(msg: IncomeMessage): void {
    if (msg.msgType === MsgType.WEB_SOCKET_TASKS_REFRESH) {
      this.refresh();
    }
  }
  setRouterControl(enabled: boolean): void {
    this.routerControlEnabled = enabled;
  }
  setFilterStore(store: FilterForTaskStore): void {
    this.filterStore = store;
  }
  setRequestFromObj(req: TasksStoreRequest): void {
    set(this.request, req);
  }
  setRequestInit(req: TasksStoreRequest): void {
    set(this.requestInit, req);
  }
  setRequestFromStr(filtersString?: string): void {
    if (filtersString && typeof filtersString === 'string') {
      const filtersObj = JSON.parse(filtersString);
      const initFilters: TasksStoreRequest = {};
      if (filtersObj.labelCodes?.length) {
        initFilters.labelCodes = filtersObj.labelCodes.split(',');
      }
      if (filtersObj.executorCodes?.length) {
        initFilters.executorCodes = filtersObj.executorCodes.split(',');
      }
      if (filtersObj.statusCodes?.length) {
        initFilters.statusCodes = filtersObj.statusCodes.split(',');
      }
      if (filtersObj.initiatorCode) {
        initFilters.initiatorCode = filtersObj.initiatorCode;
      }
      if (filtersObj.filterCode) {
        initFilters.filterCode = filtersObj.filterCode;
      }
      if (filtersObj.readyExpireDays) {
        initFilters.readyExpireDays = Number(filtersObj.readyExpireDays);
      }
      if (filtersObj.deadlineFrom) {
        initFilters.deadlineFrom = filtersObj.deadlineFrom;
      }
      if (filtersObj.deadlineTo) {
        initFilters.deadlineTo = filtersObj.deadlineTo;
      }
      if (filtersObj.employeeSetCode) {
        initFilters.employeeSetCode = filtersObj.employeeSetCode;
      }
      if (filtersObj.query) {
        initFilters.query = filtersObj.query;
      }

      set(this.request, initFilters);
      this.filterStore.setFilterInitByUrl(filtersObj.selectedFilters?.split(',') || [], this.request);
    }
  }

  employeeSetChange(currentRole: string, emp?: Employee): void {
    if (!emp) {
      this.mergeRequest({ employeeSetCode: currentRole });
      return;
    }
    let newVal = '';
    if (!this.distributorsStore.employees?.length) {
      return;
    }
    for (let i = 0; i < this.distributorsStore.employees?.length; i++) {
      if (this.distributorsStore.employees[i]?.code === emp?.code || this.distributorsStore.employees[i]?.employee?.code === emp?.code) {
        newVal = this.distributorsStore.employees[i]?.code;
        break;
      }
    }
    if (!newVal) {
      newVal = `~emp~2~${emp?.code}`;
    }
    runInAction(() => {
      const oldVal = this.request.employeeSetCode || null;
      if (newVal !== oldVal) {
        this.employeeSetMergeRequest({ employeeSetCode: newVal }, emp);
      }
    });
  }
  loadMyApprovalsAskingStore(rootStore: RootStore, initEmployee?: string): void {
    this.distributorsStore = new MyApprovalsAskingStore(rootStore);
    this.distributorsStore.loadMyList(initEmployee, true);
  }
  employeeSetMergeRequest(req: DealListRequest, emp: AppDealDistributor): void {
    this.distributorsStore.loadMyList(emp.employeeCode, true);
    this.mergeRequest(req);
  }

  mergeRequest(req: TasksStoreRequest): void {
    set(this.request, req);
    clearTimeout(this.updateDebounceTimeout);
    this.debounceUpdateRequest();
  }
  debounceUpdateRequest(delay: number = undefined): void {
    this.updateDebounceTimeout = setTimeout(() => {
      this.actualizeRouter(toJS(this.request));
      this.refresh();
    }, delay || 300);
  }
  refresh(): void {
    if (this.isLoading || this.isMoreLoading) {
      return;
    }
    this.request.page = undefined;
    this.loadTasks(this.request);
  }
  actualizeRouter(request: TasksStoreRequest): void {
    if (!this.routerControlEnabled) {
      return;
    }
    const req = toJS(request);
    const filters: TasksStoreRequestForUrl = {};
    if (req.labelCodes.length) {
      filters.labelCodes = req.labelCodes.join(',');
    }
    //* не пригодилось пока
    // if (req.priorityCodes.length) {
    //   filters.priorityCodes = req.priorityCodes.join(',');
    // }

    if (req.statusCodes.length) {
      filters.statusCodes = req.statusCodes.join(',');
    }
    if (req.executorCodes.length) {
      filters.executorCodes = req.executorCodes.join(',');
    }
    if (req.initiatorCode) {
      filters.initiatorCode = req.initiatorCode;
    }
    if (req.deadlineFrom) {
      filters.deadlineFrom = req.deadlineFrom;
    }
    if (req.filterCode) {
      filters.filterCode = req.filterCode;
    }
    if (req.query) {
      filters.query = req.query;
    }
    if (req.readyExpireDays && typeof req.readyExpireDays === 'number') {
      filters.readyExpireDays = Number(req.readyExpireDays);
    }
    if (req.deadlineTo) {
      filters.deadlineTo = req.deadlineTo;
    }
    if (req.employeeSetCode && req.employeeSetCode !== this.requestInit.employeeSetCode) {
      filters.employeeSetCode = req.employeeSetCode;
    }
    const selectedFilters = this.filterStore.selectedDependentFilters();
    if (selectedFilters.length) {
      filters.selectedFilters = selectedFilters.join(',');
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { filters: filtersQuery, ...rest } = this.routerStore.router.query;
    const q = {
      ...rest,
      ...(Object.keys(filters).length && { filters: JSON.stringify(filters) }),
    };
    this.routerStore.replace(
      {
        pathname: this.routerStore.router.pathname,
        query: q,
      },
      undefined,
      { shallow: true }
    );
  }

  setEmployeesStore(store: EmployeesStore): void {
    this.employeesStore = store;
  }
  changePositionTask(taskCode: string, request: TaskChangePositionRequest): Promise<AxiosResponse<TaskChangePositionResponse>> {
    return this.apiStore.apiTask().taskChangePosition(taskCode, request);
  }
  createTask(r: TaskCreateRequest): Promise<AxiosResponse<TaskCreateResponse>> {
    return this.apiStore.apiTask().taskCreate(r);
  }
  changeLocalStateTask(taskCode: string, request: TaskItem): void {
    const taskToChange = this.tasks.find(i => i.code === taskCode);
    runInAction(() => {
      Object.keys(request).forEach(property => {
        taskToChange[property] = request[property];
      });
    });
  }
  saveTask(taskCode: string, request: TaskUpdateRequest): Promise<AxiosResponse<TaskUpdateResponse>> {
    return this.apiStore.apiTask().taskItemSave(taskCode, request);
  }
  setTasks(data: TaskListResponse): void {
    if (this.isMoreLoading) {
      this.isMoreLoading = false;
      this.tasks.push(...data.tasks);
    } else {
      this.tasks = data.tasks || [];
    }
    this.isLoading = false;
    this.isLoaded = true;
    this.taskStatusCounters = data.taskStatusCounters || [];
    this.totalCount = data.totalCount || 0;
    this.hasMore = data.tasks.length >= this.request.count;

    this.filterStore.setFilterLocal(this);
  }
  loadMore = (): void => {
    if (this.isLoading || this.isMoreLoading) {
      // уже загружается
      return;
    }
    runInAction(() => {
      this.isMoreLoading = true;
    });
    this.request.page = (this.request.page || 1) + 1;
    this.loadTasks(this.request);
  };
  loadTasks(req: TasksStoreRequest): void {
    set(this.request, req);
    const reqObj = { ...toJS(this.requestInit), ...toJS(req) };
    this.isLoading = true;
    this.apiStore
      .apiTask()
      .tasks(
        reqObj.initiatorCode,
        reqObj.entityCode,
        reqObj.entityTypeCode,
        reqObj.executorCodes?.length ? reqObj.executorCodes : undefined,
        reqObj.filterCode,
        reqObj.branchOfficeCode,
        reqObj.deadlineFrom,
        reqObj.deadlineTo,
        reqObj.statusCodes?.length ? reqObj.statusCodes : undefined,
        reqObj.priorityCodes?.length ? reqObj.priorityCodes : undefined,
        reqObj.labelCodes?.length ? reqObj.labelCodes : undefined,
        reqObj.readyExpireDays ?? undefined,
        reqObj.page || undefined,
        reqObj.count,
        reqObj.query,
        reqObj.employeeSetCode
      )
      .then(r => {
        this.setTasks(r.data);
      });
  }
  addCommentForTask(taskCode: string, request: TaskCommentCreateRequest): Promise<AxiosResponse<TaskCommentCreateResponse>> {
    return this.apiStore.apiTask().taskCommentCreate(taskCode, request);
  }
  addCommentForTaskLocal(taskCode: string, comment: TaskCommentItem): void {
    const taskToChange = this.tasks.find(i => i.code === taskCode);
    if (taskToChange.comments) {
      taskToChange.comments.push(comment);
    }
  }
  loadedAllReferences(): void {
    this.loadTaskLabels();
    this.loadTaskPriorities();
    this.loadTaskFilters();
    this.loadTaskStatuses();
  }
  loadTaskLabels(): void {
    this.apiStore
      .apiTask()
      .taskLabels()
      .then(r => {
        runInAction(() => {
          this.labels = r.data.labels || [];
        });
      })
      .catch(r => {
        console.warn('error loadTaskLabels', r);
        this.labels = [];
      });
  }
  loadTaskPriorities(): void {
    this.apiStore
      .apiTask()
      .taskPriorities()
      .then(r => {
        runInAction(() => {
          this.priorities = r.data.priorities || [];
        });
      })
      .catch(r => {
        console.warn('error loadTaskPriorities', r);
        this.priorities = [];
      });
  }
  loadTaskFilters(): void {
    this.apiStore
      .apiTask()
      .taskFilters()
      .then(r => {
        runInAction(() => {
          this.filters = r.data.filters || [];
        });
      })
      .catch(r => {
        console.warn('error loadTaskFilters', r);
        this.filters = [];
      });
  }
  loadTaskStatuses(): void {
    this.apiStore
      .apiTask()
      .taskStatuses()
      .then(r => {
        runInAction(() => {
          this.statuses = r.data.statuses || [];
        });
      })
      .catch(r => {
        console.warn('error loadTaskStatuses', r);
        this.statuses = [];
      });
  }
}
