import React, { FC, JSX } from 'react';
import { observer } from 'mobx-react-lite';
import { BillPosition } from '../../../api/marketx';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DoneIcon from '@mui/icons-material/Done';
import CancelIcon from '@mui/icons-material/Cancel';
import { BillItemStore } from '../../../store/Documents/BillItemStore';
import TextField from '@mui/material/TextField';

interface BillAdditionalTabComentProps {
  billStore: BillItemStore;
  position?: BillPosition;
}

export const BillAdditionalTabComment: FC<BillAdditionalTabComentProps> = observer(({ billStore, position }): JSX.Element => {
  const bill = billStore.item;
  const currentCommentCode = position?.code || 'billSummary';
  const handleEditComment = (): void => {
    billStore.editComments();
  };
  const handleCanselEditComment = (): void => {
    billStore.canselEditComments();
  };
  const handleEditValue = (value: string): void => {
    billStore.addNewComment(value, currentCommentCode);
  };
  const handleSaveValue = (): void => {
    billStore.updateComment();
  };
  return (
    <Grid item xs={12}>
      <Grid container alignItems={'center'}>
        <Grid item xs={12}>
          <Typography variant="body2" color="text.secondary" sx={{ paddingTop: 1, paddingLeft: 0 }}>
            Комментарий для склада
          </Typography>
        </Grid>
        <Grid item xs={12} container alignItems={'center'} wrap={'nowrap'}>
          <Grid item flex={'1 1 auto'}>
            <TextField
              sx={{ '& .MuiOutlinedInput-input': { fontWeight: '400!important' } }}
              onChange={e => handleEditValue(e.target.value || '')}
              maxRows={3}
              type="text"
              fullWidth
              disabled={!billStore.isCommentsEditing}
              value={billStore.temporaryComments[currentCommentCode].comment}
              multiline
              size={'small'}
            />
          </Grid>
          {bill?.canUpdateStoreComments && !billStore.isCommentsEditing && (
            <Grid item ml={1} onClick={handleEditComment}>
              <IconButton size={'small'} title={'Редактировать коментарий для склада'}>
                <EditIcon />
              </IconButton>
            </Grid>
          )}
          {billStore.isCommentsEditing && (
            <Grid item ml={1} alignItems={'center'}>
              <Grid container>
                <Grid item>
                  <IconButton size={'small'} title={'Сохранить изменения'} onClick={handleSaveValue}>
                    <DoneIcon />
                  </IconButton>
                </Grid>
                <Grid item ml={1}>
                  <IconButton size={'small'} title={'Отменить редактирование'} onClick={handleCanselEditComment}>
                    <CancelIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
});
