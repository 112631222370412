import React, { FC } from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { SxProps } from '@mui/system';
import { Theme, styled } from '@mui/material/styles';
import Button, { ButtonProps } from '@mui/material/Button';
const StyledButton = styled((props: ButtonProps) => <Button {...props} />)(({ size }) => ({
  fontWeight: 700,
  ...(size === 'small' && {
    padding: '1px 5px',
  }),
  // ...(disabled === true && { // не работает(
  //   cursor: 'not-allowed',
  //   pointerEvents: 'auto',
  // }),
}));
interface DropDownButtonProps {
  onClick?: (event) => void;
  showIcon?: boolean;
  disabled?: boolean;
  children?: React.ReactNode;
  [x: string]: any;
  sx?: SxProps<Theme>;
}
export const DropDownButton: FC<DropDownButtonProps> = ({
  onClick,
  showIcon,
  children,
  disabled = false,
  sx,
  fontWeight,
  ...otherProps
}): JSX.Element => {
  return (
    <StyledButton
      onClick={onClick}
      variant="outlined"
      color="inherit"
      size="medium"
      {...otherProps}
      sx={{ ...sx, fontWeight: fontWeight }}
      endIcon={!!showIcon && <ArrowDropDownIcon />}
      disabled={disabled}
    >
      {children}
    </StyledButton>
  );
};
