import { ApprovalHistoryStore } from '../ApprovalHistoryStore';
import { ContractCostAgreementsApprovalHistoryResponse, DocumentApprovalHistory } from '../../api/marketx';
import { makeAutoObservable, observable, runInAction } from 'mobx';
import { AxiosResponse } from 'axios';
import { AgreementItemStore } from './AgreementItemStore';
import { MsgType } from '../Global/WebSocketStore';
import { IncomeMessage } from '../Global/EventManager';

export class AgreementApprovalHistoryStore implements ApprovalHistoryStore {
  agreementStore: AgreementItemStore;

  loadingEnabled = false;
  isLoading = false;
  isLoaded = false;
  loadedEpoch = 0;
  history: DocumentApprovalHistory = null;

  constructor(agreementStore: AgreementItemStore) {
    this.agreementStore = agreementStore;
    this.handleWs = this.handleWs.bind(this);
    makeAutoObservable(this, {
      agreementStore: false,
    });
  }

  reload(enableLoading: boolean): void {
    if (!this.loadingEnabled && !enableLoading) {
      return;
    }
    runInAction(() => {
      if (enableLoading) {
        this.loadingEnabled = enableLoading;
      }
      this.isLoading = true;
    });
    this.agreementStore.apiStore
      .apiClientCustomer()
      .contractCostAgreementsApprovalHistory(this.agreementStore.agreementCode)
      .then((res: AxiosResponse<ContractCostAgreementsApprovalHistoryResponse>) => {
        this.setLoadResult(res.data);
      });
  }

  setEnableLoading(enableLoading: boolean): void {
    if (!this.loadingEnabled && enableLoading) {
      this.reload(true);
    } else {
      runInAction(() => {
        this.loadingEnabled = enableLoading;
      });
    }
  }

  handleWs(msg: IncomeMessage): void {
    if (msg.msgType === MsgType.SHOP_FRONT_AGREEMENT_POSSIBLE_APPROVAL_CHANGED) {
      this.reload(false);
    }
  }

  setLoadResult(res: ContractCostAgreementsApprovalHistoryResponse): void {
    runInAction(() => {
      this.isLoaded = true;
      this.isLoading = false;
      this.loadedEpoch++;
      this.history = observable(res.history);
    });
  }
}
