import React, { FC, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { AppDealPosition } from '../../../slices/AppDeal';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { TooltipForReserveList } from '../../../components/Product/Reserve/TooltipForReseveList';
import { ContractCostPosition } from '../../../api/marketx';
import { TabSelectWrapper } from '../../../components/Deals/CustomIconTabs/TabSelectWrapper';
import { Link as MxLink } from '@mx-ui/ui';
import { Fab } from '@mui/material';
import FindInPageOutlinedIcon from '@mui/icons-material/FindInPageOutlined';

interface DealPositionAdditionalTabReservesProps {
  dealPosition?: AppDealPosition;
  agreementPosition?: ContractCostPosition;
  branchOfficeCode: string;
  documentNumber?: string;
}

export const DealPositionAdditionalTabReserves: FC<DealPositionAdditionalTabReservesProps> = observer(
  ({ dealPosition, branchOfficeCode, documentNumber, agreementPosition }): JSX.Element => {
    const [isLinkVisible, setIsLinkVisible] = useState(false);
    return (
      <TabSelectWrapper
        infiniteScrollId={`${agreementPosition?.product.code || dealPosition?.product.code}TabSelectWrapperScrollableReserveContainer`}
      >
        <Grid
          sx={{ height: '100%' }}
          position={'relative'}
          onMouseEnter={() => setIsLinkVisible(true)}
          onMouseLeave={() => setIsLinkVisible(false)}
        >
          {dealPosition?.product?.hasReserves || !!agreementPosition ? (
            <TooltipForReserveList
              product={dealPosition?.product || agreementPosition?.product}
              documentNumber={documentNumber}
              warehouseCodes={dealPosition?.warehouseCode ? [dealPosition.warehouseCode] : agreementPosition ? undefined : undefined}
              mustRender={dealPosition?.warehouseCode}
              branchOfficeCode={agreementPosition ? branchOfficeCode : undefined}
            />
          ) : (
            <Typography sx={{ py: 1, px: 2, mb: 1 }} variant="body2" component="div">
              Резервов нет
            </Typography>
          )}
          {isLinkVisible && (
            <Fab
              color="primary"
              size={'small'}
              sx={{
                position: 'absolute',
                right: '60px',
                bottom: '20px',
                width: '30px',
                height: '30px',
                minHeight: '30px',
              }}
              title={'Перейти на страницу резервов'}
            >
              <MxLink
                href={`/app/reserves?productCode=${dealPosition?.product.code || agreementPosition?.product.code}${
                  dealPosition?.warehouseCode ? '&warehouseCodes=' + [dealPosition.warehouseCode] : ''
                }`}
                target={'_blank'}
                color={'#fff'}
              >
                <FindInPageOutlinedIcon sx={{ fontSize: '25px', paddingTop: '6px', paddingLeft: '0' }} />
              </MxLink>
            </Fab>
          )}
        </Grid>
      </TabSelectWrapper>
    );
  }
);
