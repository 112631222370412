import React, { FC, JSX, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { FileUpload } from './types';
import { ParticipantUpload } from '../Clients/ClientDocuments/ClientDocuments';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import { formatDateSwaggerZ } from '@mx-ui/helpers';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import Link from 'next/link';
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import { DialogContainer } from '../ui/dialogs/DialogContainer';
import { Typography } from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import { DealDocument } from '../../api/marketx';

interface DocumentsTableProps {
  files: FileUpload[] | ParticipantUpload[] | DealDocument[];
  deleteFile?: (file: FileUpload | ParticipantUpload) => void;
  isDeletable?: boolean;
}

export const DocumentsTable: FC<DocumentsTableProps> = observer(({ files = [], deleteFile, isDeletable }): JSX.Element => {
  const calcWidth = '25%';
  const [open, setOpen] = useState(false);
  const [deletedDoc, setDeletedDoc] = useState<FileUpload | ParticipantUpload>(null);
  const [isCheckedPreview, setIsCheckedPreview] = useState(false);
  const [imageUrl, setImageUrl] = useState('');
  const [imageTitle, setImageTitle] = useState('');
  const handleOpenPreview = (doc: FileUpload | ParticipantUpload): void => {
    setIsCheckedPreview(true);
    setImageUrl(doc.url);
    setImageTitle(doc?.name);
  };
  const handleCloseZoom = (): void => {
    setIsCheckedPreview(false);
    setTimeout(() => {
      setImageUrl('');
      setImageTitle('');
    }, 200);
  };
  const handleDeleteClick = (doc: FileUpload | ParticipantUpload): void => {
    setDeletedDoc(doc);
    setTimeout(() => {
      setOpen(true);
    }, 0);
  };
  const handleClose = (): void => {
    setOpen(false);
  };
  const handleDelete = (): void => {
    if (deleteFile && !isDeletable) {
      deleteFile(deletedDoc);
    }
    setOpen(false);
    setTimeout(() => {
      setDeletedDoc(null);
    }, 0);
  };

  return (
    <>
      <TableContainer className="withCustomScroll" sx={{ overflowY: 'auto' }}>
        <Table stickyHeader={true} sx={{ minWidth: '700px', '& .MuiTableCell-root': { backgroundColor: 'background.paper' } }}>
          <TableHead>
            <TableRow>
              <TableCell align="left" style={{ width: calcWidth }}>
                Дата
              </TableCell>
              <TableCell align="left" style={{ width: calcWidth }}>
                Тип
              </TableCell>
              <TableCell align="left" style={{ width: calcWidth }}>
                Наименование
              </TableCell>
              <TableCell align="left" style={{ width: calcWidth }}></TableCell>
            </TableRow>
          </TableHead>
          {files?.length > 0 && (
            <TableBody>
              {files?.map((doc, index) => {
                const isFileImage = doc?.url?.match(/\.(jpeg|jpg|gif|png|tiff|PNG|TIFF|GIF|JPG|JPEG)/) != null;
                return (
                  <TableRow key={index}>
                    <TableCell align="left">{doc.uploadDate ? formatDateSwaggerZ(doc.uploadDate) : '-'}</TableCell>
                    <TableCell align="left">{doc?.typeName || doc?.mime || ''}</TableCell>
                    <TableCell align="left">{doc.name || doc.filename || ''}</TableCell>
                    <TableCell align="left">
                      <Grid container justifyContent="flex-end" alignItems="flex-end" flexWrap="nowrap" spacing={1}>
                        <Grid item title={doc.filename}>
                          {isFileImage ? (
                            <Avatar
                              src={doc.url}
                              variant="rounded"
                              sx={{ width: '25px', height: '25px', color: theme => theme.palette.text.light, cursor: 'pointer' }}
                              onClick={() => handleOpenPreview(doc)}
                            >
                              <InsertDriveFileIcon />
                            </Avatar>
                          ) : null}
                        </Grid>
                        {doc?.zipUrl && (
                          <Grid item title={'Скачать архив'}>
                            <Link download href={doc.zipUrl} target="_blank" color="inherit" legacyBehavior>
                              <Avatar
                                variant="rounded"
                                sx={{
                                  width: '25px',
                                  height: '25px',
                                  color: theme => theme.palette.text.light,
                                  '&:hover': { backgroundColor: '#000a72' },
                                  cursor: 'pointer',
                                }}
                              >
                                <ArchiveOutlinedIcon sx={{ width: '20px', height: '20px' }} />
                              </Avatar>
                            </Link>
                          </Grid>
                        )}
                        {doc?.url && (
                          <Grid item title={'Скачать файл'}>
                            <Link download href={doc.url} target="_blank" color="inherit" legacyBehavior>
                              <Avatar
                                variant="rounded"
                                sx={{
                                  width: '25px',
                                  height: '25px',
                                  color: theme => theme.palette.text.light,
                                  '&:hover': { backgroundColor: '#000a72' },
                                  cursor: 'pointer',
                                }}
                              >
                                <FileDownloadOutlinedIcon sx={{ width: '20px', height: '20px' }} />
                              </Avatar>
                            </Link>
                          </Grid>
                        )}
                        {deleteFile && !isDeletable ? (
                          <Grid item title={'Удалить файл'}>
                            <Avatar
                              onClick={() => !isDeletable && handleDeleteClick(doc)}
                              variant="rounded"
                              sx={{
                                width: '25px',
                                height: '25px',
                                color: theme => theme.palette.text.light,
                                '&:hover': { backgroundColor: !isDeletable && 'tomato' },
                                cursor: 'pointer',
                              }}
                            >
                              <DeleteIcon sx={{ width: '20px', height: '20px' }} />
                            </Avatar>
                          </Grid>
                        ) : null}
                      </Grid>
                    </TableCell>
                  </TableRow>
                );
              })}
              <DialogContainer
                open={open}
                onClose={handleClose}
                handleSuccess={handleDelete}
                dialogContent={<Typography variant={'body1'}>Вы подтверждаете удаление файла?</Typography>}
                canselBtnText={'Отменить'}
                successBtnText={'Удалить'}
              />
            </TableBody>
          )}
          {!files?.length ? (
            <TableBody>
              <TableRow>
                <TableCell align="left" colSpan={4} sx={{ borderBottom: 'none' }}>
                  Нет прикрепленных документов
                </TableCell>
              </TableRow>
            </TableBody>
          ) : null}
        </Table>
      </TableContainer>
      <Dialog open={isCheckedPreview} onClose={handleCloseZoom}>
        <DialogTitle sx={{ padding: '10px' }}>
          <Grid container justifyContent="space-between" alignItems="center" flexWrap="nowrap">
            <Grid item sx={{ fontSize: '1rem' }} flex="1 1 90%">
              {imageTitle}
            </Grid>
            <Grid item>
              <IconButton
                aria-label="close"
                onClick={handleCloseZoom}
                sx={{
                  color: theme => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent sx={{ padding: '10px' }}>
          <img src={imageUrl} alt={imageTitle} style={{ height: '100%', maxHeight: '65vh', width: '100%', objectFit: 'contain' }} />
        </DialogContent>
      </Dialog>
    </>
  );
});
