import React, { CSSProperties, FC, JSX, ReactNode } from 'react';
import { observer } from 'mobx-react-lite';
import { ReservesStore } from '../../../store/ReservesStore';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { IconButton, Skeleton } from '@mui/material';
import { ProductItemReserve } from '../../../api/marketx';
import InfiniteScroll from 'react-infinite-scroll-component';
import useMediaQuery from '@mui/material/useMediaQuery';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionDetails from '@mui/material/AccordionDetails';
import { ReserveItemHeader } from './ReserveItemHeader';
import { NotFormedReserveTable } from './NotFormedReserveTable';
import { FormedReserveTable } from './FormedReserveTable';
import { PartiallyFormedReserveTable } from './PartiallyFormedReserveTable';
import { ConfirmedReserveTable } from './ConfirmedReserveTable';
import { DefaultReserveTable } from './DefaultReserveTable';
import Grid from '@mui/material/Grid';
import { Link as MxLink } from '@mx-ui/ui';
import { ReserveListStore } from 'src/store/ReserveListStore';
import DoDisturbOnOutlinedIcon from '@mui/icons-material/DoDisturbOnOutlined';
import FilterListIcon from '@mui/icons-material/FilterList';
import { useRouter } from 'next/router';

export const boxGridStyle = {
  display: 'grid',
  minWidth: '950px',
  gridTemplateColumns: 'repeat(9, 1fr)',
  gridGap: '4px',
  padding: '8px 0',
};
const infiniteScrollStyle = { overflow: 'visible' } as CSSProperties;
const infiniteScrollLoader = <Skeleton variant="rectangular" style={{ margin: '10px' }} />;
export const ReserveDetailsV2Skeleton: FC = (): JSX.Element => {
  const matches = useMediaQuery('(min-width:865px)');
  const colTemplate = matches ? { gridTemplateColumns: 'repeat(6, 1fr)' } : { gridTemplateColumns: 'repeat(1, 1fr)' };

  return (
    <>
      {[1].map(item => {
        return (
          <Box sx={{ ...boxGridStyle, ...colTemplate }} height={'100px'} key={item} mb={2}>
            <Box display="grid" sx={{ gridTemplateColumns: `repeat(${matches ? 1 : 2}, 1fr)`, height: '50px' }}>
              <Box>
                <Typography fontSize={'12px'} color={'text.secondary'}>
                  <Skeleton width={'60%'} />
                </Typography>
              </Box>
              <Box>
                <Skeleton />
              </Box>
            </Box>
            <Box display="grid" sx={{ gridTemplateColumns: `repeat(${matches ? 1 : 2}, 1fr)`, height: '50px' }}>
              <Box>
                <Typography fontSize={'12px'} color={'text.secondary'}>
                  <Skeleton width={'60%'} />
                </Typography>
              </Box>
              <Box>
                <Skeleton />
              </Box>
            </Box>
            <Box display="grid" sx={{ gridTemplateColumns: `repeat(${matches ? 1 : 2}, 1fr)`, height: '50px' }}>
              <Box>
                <Typography fontSize={'12px'} color={'text.secondary'}>
                  <Skeleton width={'60%'} />
                </Typography>
              </Box>
              <Box>
                <Skeleton />
              </Box>
            </Box>
            <Box display="grid" sx={{ gridTemplateColumns: `repeat(${matches ? 1 : 2}, 1fr)`, height: '50px' }}>
              <Box>
                <Typography fontSize={'12px'} color={'text.secondary'}>
                  <Skeleton width={'60%'} />
                </Typography>
              </Box>
              <Box>
                <Skeleton />
              </Box>
            </Box>
            <Box display="grid" sx={{ gridTemplateColumns: `repeat(${matches ? 1 : 2}, 1fr)`, height: '50px' }}>
              <Box>
                <Typography fontSize={'12px'} color={'text.secondary'}>
                  <Skeleton width={'60%'} />
                </Typography>
              </Box>
              <Box>
                <Skeleton />
              </Box>
            </Box>
            <Box display="grid" sx={{ gridTemplateColumns: `repeat(${matches ? 1 : 2}, 1fr)`, height: '50px' }}>
              <Box>
                <Typography fontSize={'12px'} color={'text.secondary'}>
                  <Skeleton width={'60%'} />
                </Typography>
              </Box>
              <Box>
                <Skeleton />
              </Box>
            </Box>
          </Box>
        );
      })}
    </>
  );
};
interface ReserveDetailsV2Props {
  reserveStore: ReservesStore;
  withSkeleton?: boolean;
  isForSoftReserve?: boolean;
  selectedWarehouse?: string;
  productCode?: string;
  documentNumber?: string;
}

export const ReserveDetailsV2: FC<ReserveDetailsV2Props> = observer(
  ({ reserveStore, withSkeleton = false, productCode, documentNumber, isForSoftReserve }): JSX.Element => {
    let scrollableTargetId = document.getElementById(`${productCode}TabSelectWrapperScrollableReserveContainer`)
      ? `${productCode}TabSelectWrapperScrollableReserveContainer`
      : `${productCode}ScrollableReserveContainer`;
    if (reserveStore.nomenclatureCodes?.length) {
      scrollableTargetId = `${productCode}NomenclatureScrollableReserveContainer`;
    }

    if (!reserveStore.reserves?.length && !reserveStore.isLoading) {
      return (
        <Box p={2}>
          <Typography variant="body2">
            {isForSoftReserve ? 'Очереди резервирования по данному товару нет' : 'Резервов по данному товару нет'}
          </Typography>
        </Box>
      );
    }
    if (reserveStore.isLoading && withSkeleton && !reserveStore.reserves?.length) {
      return <ReserveDetailsV2Skeleton />;
    }

    return (
      <InfiniteScroll
        dataLength={reserveStore.reserves.length}
        next={() => reserveStore.loadMoreReserve()}
        hasMore={reserveStore.hasMore}
        style={infiniteScrollStyle}
        loader={infiniteScrollLoader}
        scrollableTarget={scrollableTargetId}
        scrollThreshold={0.7}
      >
        {reserveStore.reserves.map(reserve => {
          return <ReserveDetailsItemV2 reserve={reserve} key={reserve.code} documentNumber={documentNumber} />;
        })}
      </InfiniteScroll>
    );
  }
);

interface ReserveDetailsItemV2Props {
  reserveStore?: ReserveListStore;
  reserve: ProductItemReserve;
  selectedWarehouse?: string;
  documentNumber?: string;
  forReservePage?: boolean;
  isForReport?: boolean;
}
// NotFormed: 'not_formed',
//   Formed: 'formed',
//   PartiallyFormed: 'partially_formed',
//   Confirmed: 'confirmed',
export const ReserveDetailsItemV2: FC<ReserveDetailsItemV2Props> = observer(
  // eslint-disable-next-line complexity
  ({ reserve, selectedWarehouse, documentNumber, forReservePage = false, isForReport, reserveStore }): JSX.Element => {
    const matches = useMediaQuery('(min-width:865px)');
    let ReserveTable: ReactNode;
    let minWidth = '950px';
    const isProductReserve = useRouter().asPath.includes('app/product');

    const handleProductCodeChange = (productCode?: string): void => {
      if (reserveStore instanceof ReserveListStore) {
        if (reserveStore.request?.productCode?.includes(productCode)) {
          return;
        }
        reserveStore.mergeRequest({ productCode: productCode });
      }
    };

    const handleProductCodeRemoveChange = (): void => {
      if (reserveStore instanceof ReserveListStore) {
        reserveStore?.mergeRequest({ productCode: undefined });
      }
    };

    switch (reserve?.stateCode) {
      case 'not_formed':
        ReserveTable = <NotFormedReserveTable reserve={reserve} selectedWarehouse={selectedWarehouse} reserveStore={reserveStore} />;
        break;
      case 'partially_formed':
        ReserveTable = <PartiallyFormedReserveTable reserve={reserve} selectedWarehouse={selectedWarehouse} reserveStore={reserveStore} />;
        minWidth = '1050px';
        break;
      case 'formed':
        ReserveTable = <FormedReserveTable reserve={reserve} selectedWarehouse={selectedWarehouse} reserveStore={reserveStore} />;
        minWidth = '950px';
        break;
      case 'confirmed':
        ReserveTable = <ConfirmedReserveTable reserve={reserve} selectedWarehouse={selectedWarehouse} reserveStore={reserveStore} />;
        minWidth = '950px';
        break;
      default:
        ReserveTable = <DefaultReserveTable reserve={reserve} selectedWarehouse={selectedWarehouse} reserveStore={reserveStore} />;
        minWidth = '750px';
        break;
    }
    return (
      <>
        {matches ? (
          <Box mb={2} key={reserve.code} sx={{ minWidth: minWidth }}>
            <ReserveItemHeader
              reserve={reserve}
              documentNumber={documentNumber}
              selectedWarehouse={selectedWarehouse}
              isForReport={isForReport}
              reserveStore={reserveStore}
            />
            {ReserveTable}
          </Box>
        ) : (
          <Grid container>
            <Grid item xs={12}>
              <ReserveItemHeader
                reserve={reserve}
                documentNumber={documentNumber}
                selectedWarehouse={selectedWarehouse}
                reserveStore={reserveStore}
              />
            </Grid>
            <Accordion
              key={reserve.code}
              sx={{
                margin: '0px !important',
                boxShadow: forReservePage ? 'none' : '0px 2px 1px -1px rgb(0 0 0 / 20%)',
                ':before': {
                  display: 'none',
                },
              }}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ borderTop: forReservePage ? '' : '1px solid #DBDBDB' }}>
                <MxLink color="inherit" href={`/app/product?productCode=${encodeURIComponent(reserve.productCode)}`} fontSize={'14px'}>
                  <span style={{ fontSize: '11px', paddingRight: '8px' }}>{reserve.productCode || ''}</span>
                  <span style={{ fontSize: '14px' }}>{reserve.productTitle || ''}</span>
                </MxLink>
                {reserveStore instanceof ReserveListStore && !isProductReserve ? (
                  reserveStore?.request?.productCode?.includes(reserve?.productCode) ? (
                    <Grid item ml={1}>
                      <IconButton
                        size={'small'}
                        onClick={() => handleProductCodeRemoveChange()}
                        title={`Убрать фильтрацию по товару ${reserve?.productTitle}`}
                      >
                        <DoDisturbOnOutlinedIcon color={'primary'} sx={{ fontSize: '20px' }} />
                      </IconButton>
                    </Grid>
                  ) : (
                    <Grid item ml={1}>
                      <IconButton
                        size={'small'}
                        onClick={() => handleProductCodeChange(reserve?.productCode)}
                        title={`Найти счета по товару ${reserve?.productTitle}`}
                      >
                        <FilterListIcon color={'primary'} sx={{ fontSize: '20px' }} />
                      </IconButton>
                    </Grid>
                  )
                ) : null}
              </AccordionSummary>
              <AccordionDetails>{ReserveTable}</AccordionDetails>
            </Accordion>
          </Grid>
        )}
      </>
    );
  }
);
