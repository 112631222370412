import { AxiosCallContext, getCallContext } from '../utils/axiosInit';
import { CatalogRootResponse } from '../api/marketx';
import { mapCategories } from '../slices/AppCatalog/lib';
import { CategoryViewState } from '../slices/AppCatalog';
import { makeAutoObservable } from 'mobx';
import { AxiosResponse } from 'axios';
import { WarehouseListStore } from './WarehouseListStore';
import { ApiStore } from './Global/ApiStore';
import { RootStore } from './StoreManager';
import { StockFilter } from './CatalogStore';

interface CatalogRootRequest {
  warehouseCode: string;
}

/**
 * Список разделов от корня каталога
 */
export class CatalogRootStore {
  apiStore: ApiStore;
  svc: CatalogRootService;
  warehouseListStore: WarehouseListStore;

  ignoreBeforeDate?: Date;

  isLoaded = false;
  isLoading = false;
  loadedEpoch = 0;

  categories: CategoryViewState = {
    codes: [],
    byCode: {},
    maxLevel: 0,
  };

  constructor(rootStore: RootStore) {
    this.apiStore = rootStore.getApiStore();
    this.svc = new CatalogRootService();
    makeAutoObservable(this, {
      svc: false,
      warehouseListStore: false,
    });
  }

  loadForWarehouse(ws: WarehouseListStore, stockFilter?: StockFilter): void {
    this.isLoading = true;
    this.warehouseListStore = ws;
    this.svc.load(this, stockFilter);
  }
  loadForWarehouseByCode(warehouseCode: string, stockFilter?: StockFilter): void {
    this.isLoading = true;
    this.svc.loadByCodes(this, stockFilter, warehouseCode);
  }

  setRootResult(ctx: AxiosCallContext, req: CatalogRootRequest, res: CatalogRootResponse): void {
    if (this.ignoreBeforeDate && this.ignoreBeforeDate.getTime() > ctx.startTime.getTime()) {
      return;
    }
    this.ignoreBeforeDate = ctx.startTime;

    this.categories = mapCategories(res.categories, undefined);

    this.isLoaded = true;
    this.isLoading = false;
    this.loadedEpoch++;
  }
}

export class CatalogRootService {
  load(store: CatalogRootStore, stockFilter?: StockFilter): void {
    const warehouseCode = store.warehouseListStore.activeWarehouseCode || undefined;
    store.apiStore
      .apiClientCatalog()
      .catalogRoot(warehouseCode, undefined, stockFilter ? stockFilter : undefined)
      .then((res: AxiosResponse<CatalogRootResponse>): void => {
        store.setRootResult(getCallContext(res), { warehouseCode }, res.data);
      });
  }
  loadByCodes(store: CatalogRootStore, stockFilter?: StockFilter, warehouseCode?: string): void {
    store.apiStore
      .apiClientCatalog()
      .catalogRoot(warehouseCode, undefined, stockFilter ? stockFilter : undefined)
      .then((res: AxiosResponse<CatalogRootResponse>): void => {
        store.setRootResult(getCallContext(res), { warehouseCode }, res.data);
      });
  }
}
