import { quickDateRanges } from 'src/components/SelectDateRange/MenuButtonNew';

const defaultQuickRangeValue = 'custom_period' as const;
const defaultQuickDate = quickDateRanges.find(t => t.value === defaultQuickRangeValue);
const dateStart = new Date();
const dateEnd = new Date(dateStart.getFullYear(), dateStart.getMonth() + 1, 0);
dateStart.setDate(1);
dateStart.setMonth(dateStart.getMonth() - 1);

export { dateEnd, defaultQuickDate, dateStart };
