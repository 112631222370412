import { makeAutoObservable } from 'mobx';
import { BillPosition } from 'src/api/marketx';
// MSOMS-4015 какое конфирм показывать + решили добавить простой конфирм
export type confirmDialog = 'delete' | 'change' | 'confirm';
export type entityTypeChange = 'position' | 'bill';

export class BillItemInvoicingStore {
  public isShowDialog = false;
  public typeConfirm: confirmDialog = null;
  public typeEntity: entityTypeChange = null;
  public billDocumentNumber: string = null;
  public position: { productCode: string; warehouseCode: string } = null;
  constructor() {
    makeAutoObservable(this, {});
  }
  onClickButton(type: confirmDialog): void {
    this.isShowDialog = true;
    if (type !== undefined) {
      this.typeConfirm = type;
    }
  }
  setPosition(pos: BillPosition): void {
    this.position = { productCode: pos.product?.code, warehouseCode: pos.warehouse?.code };
  }
  onCloseDialog(): void {
    this.isShowDialog = false;
  }
  setBillDocumentNumber(code: string): void {
    this.billDocumentNumber = code;
  }
  onChangeTypeEntity(type: entityTypeChange): void {
    this.typeEntity = type;
  }
  resetStore(): void {
    this.typeConfirm = null;
    this.typeEntity = null;
    this.billDocumentNumber = null;
    this.position = null;
  }
}
