import React, { ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useDebounce } from '../../../utils/hooks/useDebounce';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';

interface StyledInputProps {
  $inputColor?: string;
}

const StyledInput = styled(TextField)<StyledInputProps>`
  padding: 0;

  & .MuiOutlinedInput-input {
    padding: 4px 0 4px 4px;
    font-weight: 700;
    width: 100%;
    font-size: 14px;
    color: ${props => props.$inputColor || 'unset'};
  }

  &.secondary .MuiOutlinedInput-input {
    font-weight: 500;
  }
`;

interface CustomInputPropsOnChangeCallback {
  (value: string): void;
}

interface CustomInputPropsCrossChangeHandler {
  (prop: string, value: string): void;
}

interface CustomInputOnKeyDownCallback {
  (e: React.KeyboardEvent<HTMLDivElement>): void;
}

type CrossChange = {
  handler: CustomInputPropsCrossChangeHandler;
  key: string;
  value: string;
};

interface CustomInputProps {
  type?: string;
  isViewOnly?: boolean;
  color?: string | any;
  value: any;
  onChange?: CustomInputPropsOnChangeCallback;
  onChangeDirect?: CustomInputPropsOnChangeCallback;
  onChangeDebounced?: CustomInputPropsOnChangeCallback;
  onKeyDown?: CustomInputOnKeyDownCallback;
  width?: string | any;
  style?: any;
  incorrectValue?: any;
  viewOnlyComponent?: (value: any, style: any) => JSX.Element;
  delay?: number;
  delayLocal?: number;
  multiline?: boolean;
  className?: string;
  maxLength?: number;
  maxRows?: number;
  sx?: SxProps<Theme>;
  crossChange?: CrossChange;
}

const commonInputProps = { className: 'digitsOnly' };

const commonTextProps = { variant: 'standard' as const };

const CustomInput: FC<CustomInputProps> = (props: CustomInputProps): JSX.Element => {
  const {
    type = 'number',
    isViewOnly,
    color,
    value,
    onChange,
    onChangeDirect,
    onChangeDebounced,
    viewOnlyComponent,
    onKeyDown,
    width,
    style,
    incorrectValue,
    delay = 1000,
    delayLocal = 1000,
    multiline,
    className,
    maxLength,
    crossChange,
    maxRows,
    sx,
  } = props;

  const callback = (value: string): void => {
    if (onChange) {
      onChange(value);
    }
    if (onChangeDebounced) {
      onChangeDebounced(value);
    }
  };

  const [dValue, setDValue] = useState(value || '');

  const changeLocalValue = useDebounce(params => setDValue(params), delayLocal);

  useEffect(() => {
    if (value) {
      changeLocalValue(value);
    }
  }, [value, changeLocalValue]);

  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);

  const onChangeDelay = (e: ChangeEvent<HTMLInputElement>): void => {
    let value = e.target.value;

    if (type === 'number') {
      value = (value || '').replace(/[\s]+/g, '');
    }
    if (maxLength && value && value.length > maxLength) {
      value = value.substr(0, maxLength);
    }
    if (value !== e.target.value && e.target.value !== '') {
      e.target.value = value;
    }
    setDValue(value);
    if (onChangeDirect) {
      onChangeDirect(value);
      changeLocalValue(value);
    }
    if (crossChange && crossChange.handler) {
      crossChange.handler(crossChange.key, value);
    }

    if (timer) clearTimeout(timer);
    setTimer(setTimeout(callback, delay, value));
  };

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>): void => {
      if (onKeyDown) {
        onKeyDown(e);
      }
    },
    [onKeyDown]
  );

  const manualInputStyle = useMemo(() => {
    return { width: width ? width : 'unset' };
  }, [width]);

  if (isViewOnly) {
    if (viewOnlyComponent) {
      return viewOnlyComponent(value, style);
    }
    return (
      <Typography
        component={'p'}
        sx={{
          cursor: 'not-allowed',
          display: 'inline-block',
          background: 'none',
          border: '1px solid',
          borderColor: theme => theme.palette.action.disabled,
          color: theme => theme.palette.text.disabled,
          overflowWrap: 'break-word',
          fontSize: '14px',
          paddingLeft: '6px',
          lineHeight: '20px',
          boxSizing: 'border-box',
          paddingTop: '2px',
          paddingBottom: '1px',
          paddingRight: '6px',
          borderRadius: '4px',
          minWidth: '75px',
          minHeight: '23px',
          ...style,
        }}
      >
        {value}
      </Typography>
    );
  }

  return (
    <StyledInput
      type={type}
      inputProps={{ ...commonInputProps, maxLength }}
      $inputColor={!incorrectValue ? color : '#C74952'}
      variant="outlined"
      onChange={onChangeDelay}
      sx={{
        '.MuiInputBase-multiline': {
          padding: '8px',
        },
        ...sx,
      }}
      onKeyDown={handleKeyDown}
      value={dValue}
      style={manualInputStyle}
      error={!!incorrectValue}
      FormHelperTextProps={commonTextProps}
      multiline={multiline}
      className={className}
      maxRows={maxRows}
    />
  );
};

export { CustomInput };
