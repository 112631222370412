import { Grid, Switch, SwitchProps, Typography, styled } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { FC, useState } from 'react';
import { BillItemStore } from 'src/store/Documents/BillItemStore';

const RetroBonusSwitchStyled = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 59,
  height: 26,
  padding: 0,
  borderRadius: 15,
  boxShadow: '0 1px 1px 0 rgb(0 0 0 / 16%), 0px -1px 1px 0px rgb(0 0 0 / 12%), 0px 2px 1px 0px rgb(0 0 0 / 12%)',
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(33px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: '#65C466',
        opacity: 1,
        border: 0,
        ...theme.applyStyles('dark', {
          backgroundColor: '#2e7d32',
        }),
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.grey[100],
      ...theme.applyStyles('dark', {
        color: theme.palette.grey[600],
      }),
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: 0.7,
      ...theme.applyStyles('dark', {
        opacity: 0.3,
      }),
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: '#E9E9EA',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
    ...theme.applyStyles('dark', {
      backgroundColor: '#39393D',
    }),
  },
}));

type BillSummaryRetroBonusControlProps = {
  billStore: BillItemStore;
};

export const BillSummaryRetroBonusControl: FC<BillSummaryRetroBonusControlProps> = observer(({ billStore }) => {
  const bill = billStore.item;
  const [isRetroBonus, setIsRetroBonus] = useState(bill.retroBonus);
  const isEditing = billStore.item.editingPermissions.canEditRetroBonus && billStore.isEditing && !billStore.isSaving;

  const handleChangeRetroBonus = (): void => {
    billStore.updateBill({ retroBonus: !isRetroBonus });
    setIsRetroBonus(!isRetroBonus);
  };

  return (
    <Grid container alignItems={'center'}>
      <Grid item xs={6}>
        <Typography color="text.secondary" variant="body2">
          Оформить премию покупателю
        </Typography>
      </Grid>

      <Grid item xs={6}>
        <Typography color="text.secondary" variant="body2">
          <RetroBonusSwitchStyled checked={isRetroBonus} onChange={handleChangeRetroBonus} disabled={!isEditing} />
        </Typography>
      </Grid>
    </Grid>
  );
});
