import { formatPrice } from '@mx-ui/helpers';

export const renderPriceCur = (x: number | string | boolean | null | undefined, currency = '₽'): JSX.Element => {
  const spaceString = '\u00A0';
  let val = '0.00';
  const isValidValue = Number(x) > 0 || typeof x === 'number' || (typeof x === 'string' && !isNaN(parseFloat(x)));
  if (isValidValue) {
    val = formatPrice(x, spaceString);
  }
  const chunks = val.split(spaceString);
  if (chunks.length > 1) {
    return (
      <>
        {chunks.map((c: string, i: number) => (
          <span className="number-rs" key={i}>
            {c}
          </span>
        ))}
        {Boolean(currency) && <span>{spaceString + currency}</span>}
      </>
    );
  }
  return (
    <>
      <span>{val}</span>
      {Boolean(currency) && <span>{spaceString + currency}</span>}
    </>
  );
};
