export const webConfig = {
  apiUrl: process.env.NEXT_PUBLIC_API_URL,
  apiWsCollieUrl: process.env.NEXT_PUBLIC_API_WS_COLLIE_URL,
  apiWsEnabled: true,
  apiWsUrl: process.env.NEXT_PUBLIC_API_WS_URL,
  gaId: process.env.NEXT_PUBLIC_GA_ID,
  version: process.env.NEXT_PUBLIC_VERSION,
};

export const authConfig = {
  sessionRefetchInterval: parseInt(process.env.NEXT_PUBLIC_SESSION_REFRETCH_INERVAL),
  ssoTokenRotationOffset: parseInt(process.env.NEXT_PUBLIC_SSO_TOKEN_ROTATION_OFFSET),
};

export const gaConfig = {
  trackingId: process.env.NEXT_PUBLIC_GA_TRACKING_ID,
  uaId: process.env.NEXT_PUBLIC_GA_UA_ID,
  resourceId: process.env.NEXT_PUBLIC_GA_RESOURCE_ID,
  dlId: process.env.NEXT_PUBLIC_GA_DL_ID,
  dl: process.env.NEXT_PUBLIC_GA_DL,
};

export const buildInfoConfig = {
  imageTime: process.env.NEXT_PUBLIC_BUILD_IMAGE_TIME || '2000-01-01T00:00:09+03:00',
  branch: process.env.NEXT_PUBLIC_BUILD_BRANCH || 'dev',
  commit: process.env.NEXT_PUBLIC_BUILD_COMMIT || 'XXX',
  env: process.env.NEXT_PUBLIC_ENV,
};

/*
NEXT_PUBLIC_GA_UA_ID=UA-185900169
NEXT_PUBLIC_GA_RESOURCE_ID=256792005
NEXT_PUBLIC_GA_DL_ID=G-5JCKHPS20S
NEXT_PUBLIC_GA_DL=2215718359
*/
