import React, { FC, useCallback, useMemo } from 'react';
import { observer } from 'mobx-react';
import Box from '@mui/material/Box';
import { ButtonProps } from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import { badgeClasses } from '@mui/material/Badge';
import { ApprovalStoreInterface } from '../../../store/DealItemStore';
import { useAuthStore } from '../../../store/MobxStoreProvider';
import { ApproveResolutionLabel } from './ApproveResolutionLabel';
import { ApproveResolutionButton } from './ApproveResolutionButton';
import BlockIcon from '@mui/icons-material/Block';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { BadgeProps } from '@mui/material/Badge';
import { AppUserGroupCodes } from 'src/types/AppUserGroup';
import { DealApprovalStateEnum } from 'src/api/marketx';

export const DealApprovalState = {
  ...DealApprovalStateEnum,
  ReadyForConfirmation: 'readyForConfirmation' as const,
};
export const DealApprovalAction = {
  Confirm: 'confirm' as const,
  Decline: 'decline' as const,
  Withdraw: 'withdraw' as const,
  // ApprovalAsk: 'approvalAsk' as const,
};
export const MenuItemsApprovalIds = {
  Confirm: 'confirm' as const,
  Decline: 'decline' as const,
  Withdraw: 'withdraw' as const,
  RevokeConfirm: 'revokeConfirm' as const,
};
export type Code = string;
type DealApprovalActionKeys = keyof typeof DealApprovalAction;
type DealApprovalStateKeys = keyof typeof DealApprovalState;
type AppUserGroupCodesKeys = keyof typeof AppUserGroupCodes;
type MenuItemsApprovalIdsKeys = keyof typeof MenuItemsApprovalIds;
type AppUserGroupCodesType = (typeof AppUserGroupCodes)[AppUserGroupCodesKeys];
export type MenuItemsApprovalIdsType = (typeof MenuItemsApprovalIds)[MenuItemsApprovalIdsKeys];
export type DealApprovalStateType = (typeof DealApprovalState)[DealApprovalStateKeys];
export type DealApprovalActionType = (typeof DealApprovalAction)[DealApprovalActionKeys];

export const StyledBadge = styled((props: BadgeProps) => <Badge {...props} />)(({ theme }) => ({
  width: '100%',
  [`& .${badgeClasses.badge}`]: {
    backgroundColor: theme.palette.warning.light,
  },
})) as any;
export interface MenuItem {
  label: string;
  type: DealApprovalActionType;
  id: MenuItemsApprovalIdsType;
  action: DealApprovalActionType;
  icon: JSX.Element;
  isShowModal?: boolean;
  itemFor: DealApprovalStateType[]; //* список состояний (DealApprovalStateType) кнопки, для которых стоит показывать MenuItem
  showFor: (
    approvalState?: DealApprovalStateType,
    currentAccessGroup?: AppUserGroupCodesType,
    creatorCode?: Code,
    currentUserCode?: Code
  ) => AppUserGroupCodesType[]; //* список доступных ролей ('seller' | 'office_chief' | 'division_chief'). Ниже идет проверка есть ли в этом списке текущая группа
}
const menuItemsForActions: MenuItem[] = [
  {
    type: DealApprovalAction.Confirm,
    id: 'confirm',
    action: DealApprovalAction.Confirm,
    label: 'Подтвердить',
    icon: <ThumbUpIcon />,
    itemFor: [
      DealApprovalState.AwaitingDepartment,
      DealApprovalState.Awaiting,
      DealApprovalState.Declined,
      DealApprovalState.AwaitingDivision,
      DealApprovalState.AwaitingCompany,
    ],
    isShowModal: true,

    showFor: (approvalState: DealApprovalStateType, currentAccessGroup?: AppUserGroupCodesType) => {
      if (currentAccessGroup === null || currentAccessGroup === undefined) {
        return [];
      }
      const accessGroup: AppUserGroupCodesType[] = [AppUserGroupCodes.COMPANY_CHIEF];
      if (
        approvalState === DealApprovalState.AwaitingDepartment ||
        approvalState === DealApprovalState.AwaitingDivision ||
        approvalState === DealApprovalState.Awaiting ||
        approvalState === DealApprovalState.Declined
      ) {
        accessGroup.push(AppUserGroupCodes.DIVISION_CHIEF);
      }
      if (
        approvalState === DealApprovalState.AwaitingDepartment ||
        approvalState === DealApprovalState.Awaiting ||
        approvalState === DealApprovalState.Declined
      ) {
        accessGroup.push(AppUserGroupCodes.OFFICE_CHIEF);
      }
      if (approvalState === DealApprovalState.AwaitingDepartment || approvalState === DealApprovalState.Declined) {
        accessGroup.push(AppUserGroupCodes.SELLER_CHIEF);
      }
      if (approvalState === DealApprovalState.Declined) {
        accessGroup.push(AppUserGroupCodes.SELLER);
      }
      return accessGroup;
    },
  },
  {
    type: DealApprovalAction.Decline,
    action: DealApprovalAction.Decline,
    id: 'decline',
    label: 'Отклонить',
    icon: <BlockIcon />,
    itemFor: [
      DealApprovalState.AwaitingDepartment,
      DealApprovalState.Awaiting,
      DealApprovalState.AwaitingDivision,
      DealApprovalState.AwaitingCompany,
    ],
    isShowModal: true,
    showFor: (approvalState: DealApprovalStateType, currentAccessGroup?: AppUserGroupCodesType) => {
      if (currentAccessGroup === null || currentAccessGroup === undefined) {
        return [];
      }
      return [
        AppUserGroupCodes.COMPANY_CHIEF,
        AppUserGroupCodes.DIVISION_CHIEF,
        AppUserGroupCodes.OFFICE_CHIEF,
        AppUserGroupCodes.SELLER_CHIEF,
      ];
    },
  },
  {
    type: DealApprovalAction.Withdraw,
    id: 'withdraw',
    action: DealApprovalAction.Withdraw,
    icon: <SettingsBackupRestoreIcon />,
    label: 'Отозвать запрос на согласование',
    itemFor: [
      DealApprovalState.AwaitingDepartment,
      DealApprovalState.Awaiting,
      DealApprovalState.Declined,
      DealApprovalState.AwaitingDivision,
      DealApprovalState.AwaitingCompany,
    ],
    showFor: (
      approvalState?: DealApprovalStateType,
      currentAccessGroup?: AppUserGroupCodesType,
      creatorCode?: Code,
      currentUserCode?: Code
    ): AppUserGroupCodesType[] => {
      const accessGroup: AppUserGroupCodesType[] = [];
      // Доступно если текущий пользователь сам и создал запрос на согласование,
      // или если данный пользователь относится к этой заявке как продавец
      if (creatorCode === currentUserCode || currentAccessGroup == AppUserGroupCodes.SELLER) {
        accessGroup.push(currentAccessGroup);
      }
      return accessGroup;
    },
  },
  {
    type: DealApprovalAction.Decline,
    id: 'revokeConfirm',
    action: DealApprovalAction.Decline,
    icon: <BlockIcon />,
    label: 'Отозвать подтверждение',
    itemFor: [DealApprovalState.Approved, DealApprovalState.SelfApproved],
    isShowModal: true,
    showFor: () => [
      AppUserGroupCodes.COMPANY_CHIEF,
      AppUserGroupCodes.DIVISION_CHIEF,
      AppUserGroupCodes.OFFICE_CHIEF,
      AppUserGroupCodes.SELLER_CHIEF,
      AppUserGroupCodes.SELLER,
    ],
  },
];
/**
 * Элемент управление заявкой. Может быть кнопкой либо текст
 */
export interface Control {
  label: string;
  typeElement: 'button' | 'label';
  type: DealApprovalStateType;
  action?: DealApprovalActionType;
  icon?: JSX.Element;
  color: ButtonProps['color'];
  variant?: ButtonProps['variant'];
}
/**
 * Элементы управление в зависимости от текущего состояние сделки
 */
const controlsByState: Record<DealApprovalStateType, Control> = {
  [DealApprovalState.ReadyForConfirmation]: {
    label: 'Подтвердить',
    action: DealApprovalAction.Confirm,
    type: DealApprovalState.ReadyForConfirmation,
    typeElement: 'button',
    icon: <CheckCircleOutlineIcon />,
    variant: 'outlined',
    color: 'primary',
  },
  [DealApprovalState.Declined]: {
    label: 'Заявка отклонена',
    icon: <ReportProblemOutlinedIcon />,
    type: DealApprovalState.Declined,
    variant: 'outlined',
    typeElement: 'button',
    color: 'error',
  },
  [DealApprovalState.Awaiting]: {
    label: 'На согласовании',
    type: DealApprovalState.Awaiting,
    icon: <CheckCircleOutlineIcon />,
    variant: 'outlined',
    typeElement: 'button',
    color: 'info',
  },
  [DealApprovalState.AwaitingDepartment]: {
    label: 'На согласовании',
    type: DealApprovalState.Awaiting,
    icon: <CheckCircleOutlineIcon />,
    variant: 'outlined',
    typeElement: 'button',
    color: 'info',
  },
  [DealApprovalState.AwaitingDivision]: {
    label: 'На согласовании',
    type: DealApprovalState.Awaiting,
    icon: <CheckCircleOutlineIcon />,
    variant: 'outlined',
    typeElement: 'button',
    color: 'info',
  },
  [DealApprovalState.AwaitingCompany]: {
    label: 'На согласовании',
    type: DealApprovalState.Awaiting,
    icon: <CheckCircleOutlineIcon />,
    variant: 'outlined',
    typeElement: 'button',
    color: 'info',
  },
  [DealApprovalState.Approved]: {
    label: 'Одобрено',
    type: DealApprovalState.Approved,
    typeElement: 'label',
    color: 'success',
  },
  [DealApprovalState.SelfApproved]: {
    label: 'Одобрено',
    type: DealApprovalState.Approved,
    typeElement: 'label',
    color: 'success',
  },
  [DealApprovalState.Signed]: { label: 'Подписано', type: DealApprovalState.Approved, typeElement: 'label', color: 'primary' },
  [DealApprovalState.AwaitingOffice]: undefined,
};

export interface ErrorForButton {
  text: string;
}

interface ApproveResolutionControlProps {
  approvalStore: ApprovalStoreInterface;
  sizeButton?: ButtonProps['size'];
}
export const ApproveResolutionControl: FC<ApproveResolutionControlProps> = observer(({ approvalStore, sizeButton = 'small' }) => {
  const auth = useAuthStore();
  const { approval, labelsMenuItems = {}, labelsControls = {} } = approvalStore;
  const menuItemsForActionsWithLabels = useMemo(
    () => menuItemsForActions.map(menuItem => ({ ...menuItem, label: labelsMenuItems[menuItem.id] ?? menuItem?.label })),
    [approvalStore]
  );
  const controlsByStateWithLabels = useMemo(
    () =>
      Object.keys(controlsByState).reduce((acc, label) => {
        acc[label] = { ...controlsByState[label], label: labelsControls[label] ?? controlsByState[label]?.label };
        return acc;
      }, {}),
    [approvalStore]
  );
  const approvalState = approvalStore.stateCode() || DealApprovalState.ReadyForConfirmation; //* текущее состояние сделки
  // текущий элемент управления для кнопки в зависимости от состояния сделки
  const currentControl = controlsByStateWithLabels[approvalState];
  const menuItems = menuItemsForActionsWithLabels
    .filter(item => item.itemFor.includes(approvalState)) // * показываем menuItems`ы только для текущего state заявки
    .filter(item =>
      item
        .showFor(
          approvalState,
          approval.currentAccessGroup as AppUserGroupCodesType,
          approvalStore.creator?.employeeCode,
          auth.profile?.employee.code
        )
        .includes(approval.currentAccessGroup as AppUserGroupCodesType)
    ); // * menuItems`ы  показываем только для определенной currentAccessGroup`ы
  const handleExecuteByType = useCallback(
    (action: DealApprovalActionType, comment?: string): void => {
      approvalStore.imposeApprovalResolutionByType(action, comment);
    },
    [approvalStore]
  );

  if (!approval?.controlVisible) {
    return null;
  }
  return (
    <Box display="flex" flexDirection={'column'}>
      {currentControl?.typeElement === 'button' && (
        <ApproveResolutionButton
          approval={approvalStore}
          isSaving={approvalStore.isSaving}
          isLoading={approvalStore.isLoading}
          errors={approvalStore.errors}
          control={currentControl}
          size={sizeButton}
          menuItems={menuItems}
          onExecuteByType={handleExecuteByType}
        />
      )}
      {currentControl?.typeElement === 'label' && (
        <ApproveResolutionLabel
          approval={approvalStore}
          control={currentControl}
          menuItems={menuItems}
          onExecuteByType={handleExecuteByType}
        />
      )}
    </Box>
  );
});
