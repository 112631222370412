import React, { FC } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ErrorPage from 'src/assets/svg/404';
import { ErrorDocuments } from 'src/store/Documents/types';
import { DocumentTypeList } from './DocumentTotalCount';
import { Link as MxLink } from '@mx-ui/ui';

interface DocumentsErrorPageProps {
  error: ErrorDocuments;
  documentType: DocumentTypeList;
}
export const documentsLinkByType: Record<DocumentTypeList, Record<string, string>> = {
  deals: {
    link: '/app/deals',
    title: 'Перейти к списку заявок',
  },
  agreements: {
    link: '/app/agreements',
    title: 'Перейти к списку соглашений',
  },
  batches: {
    link: '/app/batches',
    title: 'Перейти к списку партий',
  },
  bills: {
    link: '/app/bills',
    title: 'Перейти к списку счетов',
  },
  contracts: {
    link: '/app/contracts',
    title: 'Перейти к списку договоров',
  },
  debtRelief: {
    link: '/app/debts',
    title: 'Перейти к списку списаний долга',
  },
  payments: {
    link: '/app/payments',
    title: 'Перейти к списку оплат',
  },
  returns: {
    link: '/app/returns',
    title: 'Перейти к списку возвратов',
  },
  shipments: {
    link: '/app/shipments',
    title: 'Перейти к списку отгрузок',
  },
  approvals: {
    link: '/app/deals',
    title: 'Перейти к списку заявок',
  },
  freeze: {
    link: '/app/freeze',
    title: 'Перейти к списку фризов',
  },
  clients: {
    link: '/app/clients',
    title: 'Перейти к списку клиентов',
  },
  salesInvoice: {
    link: '/app/home',
    title: 'Перейти на главную страницу',
  },
};

export const DocumentsErrorPage: FC<DocumentsErrorPageProps> = ({ error, documentType }) => {
  const item = documentsLinkByType[documentType];

  return (
    <Grid container flexDirection={'column'} flexWrap={'nowrap'} p={3}>
      <Grid item>
        <ErrorPage />
      </Grid>
      <Typography variant="body1" color="textPrimary">
        {error.message}
      </Typography>
      <Grid item container>
        <Grid item>
          {error.hasNoAsses ? (
            <MxLink href="/app/home" variant="body1" color="textPrimary">
              Главная
            </MxLink>
          ) : (
            <MxLink href={item.link} variant="body1" color="textPrimary">
              {item.title}
            </MxLink>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};
