import { makeAutoObservable, runInAction } from 'mobx';
import { AxiosCallContext, getCallContext } from '../utils/axiosInit';
import { CatalogProductsItemPacksResponse, ProductItemPack } from '../api/marketx';
import { AxiosResponse } from 'axios';
import { ApiStore } from './Global/ApiStore';
import { RootStore } from './StoreManager';

export class ProductPacksStore {
  apiStore: ApiStore;

  ignoreBeforeDate?: Date;
  isLoaded = false;
  isLoading = false;

  productCode: string;
  warehouseCode: string;

  packs?: Array<ProductItemPack> = [];
  page = 1;
  packsCount = 100;
  hasMore = true;
  isMoreLoading = false;

  constructor(rootStore: RootStore) {
    this.apiStore = rootStore.getApiStore();
    makeAutoObservable(this, {
      apiStore: false,
    });
  }

  loadForProduct(productCode: string, warehouseCode: string): void {
    if (this.productCode === productCode && this.warehouseCode === warehouseCode) {
      return;
    }
    runInAction(() => {
      this.ignoreBeforeDate = new Date();
      this.productCode = productCode;
      this.warehouseCode = warehouseCode;
      this.isLoading = true;
      this.runLoad();
    });
  }

  loadMore(): void {
    if (this.isLoading) {
      // уже загружается
      return;
    }
    this.isMoreLoading = true;
    this.page = (this.page || 1) + 1;
    this.runLoad();
  }

  runLoad(): void {
    this.apiStore
      .apiClientCatalog()
      .catalogProductsItemPacks(this.productCode, this.warehouseCode, this.page, this.packsCount)
      .then((res: AxiosResponse<CatalogProductsItemPacksResponse>): void => {
        this.setResult(getCallContext(res), res.data);
      });
  }

  setResult(ctx: AxiosCallContext, res: CatalogProductsItemPacksResponse): void {
    if (this.ignoreBeforeDate && this.ignoreBeforeDate.getTime() > ctx.startTime.getTime()) {
      return;
    }
    this.hasMore = res.packs.length >= this.packsCount;
    this.ignoreBeforeDate = ctx.startTime;
    if (this.page === 1) {
      this.packs = res.packs;
    } else {
      this.packs.push(...res.packs);
    }
    this.isLoaded = true;
    this.isLoading = false;
    this.isMoreLoading = false;
  }
}
