import React, { FC, useCallback, useMemo } from 'react';
import { SelectChangeEvent } from '@mui/material/Select';
import { observer } from 'mobx-react-lite';
import { DealItemStore } from 'src/store/DealItemStore';
import { AppDealPosition } from 'src/slices/AppDeal';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
interface DealPositionWarehouseSelectProps {
  dealStore: DealItemStore;
  position: AppDealPosition;
}
export const DealPositionWarehouseSelect: FC<DealPositionWarehouseSelectProps> = observer(({ dealStore, position }) => {
  const isViewOnly = dealStore.isViewOnly();
  const handleChangeWareHousePosition = useCallback(
    (event: SelectChangeEvent<string>): void => {
      const warehouseCode = event.target.value;
      dealStore
        .updatePosition(position, { warehouseCode })
        .then(() => {
          dealStore.positionsManufacturersStore.reloadForDealPosition(dealStore.dealCode, position);
        })
        .catch(r => console.warn('handleChangeWareHousePosition', r));
    },
    [dealStore, position]
  );
  const warehousesStore = dealStore.getWarehousesStore();

  const warehouses = useMemo(() => {
    if (!dealStore.deal) {
      return [];
    }
    const duplicatePositionByProduct =
      dealStore.deal?.positions.filter(i => i.productCode === position.productCode && i.code !== position.code).map(i => i.warehouseCode) ||
      [];
    return (
      dealStore
        .getWarehousesStore()
        .items.filter(
          i =>
            !warehousesStore.warehouseGroupCodes.includes(i.code) &&
            (i.code === position.warehouseCode || !duplicatePositionByProduct.includes(i.code))
        ) //* убираем все склады с "isGroup=true"(все склады города) и убираем склады для позиций, которые имеют одинаковый продукт.
        //* так же проверяем выбран ли данный склад в позиции и показываем его несмотря на дубль
        .map(i => ({ value: i.code, text: i.name }))
    );
  }, [dealStore, dealStore.deal, warehousesStore.isLoaded]);
  return (
    <FormControl sx={{ minWidth: 250 }} size="small">
      <InputLabel id="demo-select-small">Склад</InputLabel>
      <Select
        labelId="demo-select-small"
        id="demo-select-small"
        disabled={isViewOnly}
        sx={{ '.MuiSelect-select': { padding: '4px 14px', fontSize: '14px' } }}
        value={position.warehouseCode || dealStore.deal.warehouseCode}
        MenuProps={{
          // disableScrollLock: true,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          // variant: 'menu',
          // disablePortal: true,
        }}
        label="Склад"
        onChange={handleChangeWareHousePosition}
      >
        {warehouses.map(warehouses => (
          <MenuItem value={warehouses.value} key={warehouses.value}>
            {warehouses.text}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
});
