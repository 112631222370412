import React, { JSX, FC } from 'react';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import CircleIcon from '@mui/icons-material/FiberManualRecord';
import { SvgIconProps } from '@mui/material/SvgIcon';
import { ClientType } from '../../../components/Clients/ClientCard/types';
import WorkIcon from '@mui/icons-material/WorkOutline';
import { getCustomerRating } from '../lib';
import CancelIcon from '@mui/icons-material/Cancel';
import { useRootStore } from 'src/store/MobxStoreProvider';
import CallIcon from '@mui/icons-material/Call';
import { observer } from 'mobx-react-lite';
import { AttentionBlock } from '../../../components/Clients/AttentionBlock/AttentionBlock';
import { SxProps } from '@mui/system';
import { CustomerDocFlowCounterAgentStateEnum } from '../../../api/marketx';
import { Link as MxLink } from '@mx-ui/ui';
interface CompanyExtInfoProps {
  client: ClientType;
  href?: string;
}

export const CustomerStatusIcon = ({ client }): JSX.Element => {
  const ratingProps = getCustomerRating(client.statCustomerRating);
  return client.activityStatusTitle !== 'archived' && client.activityStatusTitle !== 'deleted' ? (
    <CircleIcon
      fontSize="small"
      sx={{ marginRight: 0.5 }}
      titleAccess={`Рейтинг клиента: ${ratingProps.title}`}
      color={ratingProps.color as SvgIconProps['color']}
    />
  ) : (
    <Box display="flex" alignItems="center" sx={{ marginRight: 0.5, marginBottom: '4px', fontSize: '16px' }}>
      <CancelIcon color={'error'} fontSize="inherit" />
    </Box>
  );
};
export const LabelNonResident: FC<{ sx?: SxProps }> = ({ sx }): JSX.Element => (
  <Typography
    fontSize={'11px'}
    component="span"
    sx={{
      top: '-15px',
      backgroundColor: '#F4A2AC',
      color: theme => theme.palette.getContrastText('#F4A2AC'),
      padding: '2px 5px',
      lineHeight: '11px',
      borderRadius: '5px',
      ...sx,
    }}
  >
    Нерезидент
  </Typography>
);
export const EdoLabel: FC<{ type: CustomerDocFlowCounterAgentStateEnum; sx?: SxProps }> = ({ type, sx }): JSX.Element => {
  let color = '#2e7c33';
  if (type === 'active') {
    color = '#2E7C33FF';
  } else if (type === 'invited') {
    color = '#6f726f';
  } else {
    return null;
  }
  return (
    <Grid item title={type === 'active' ? 'Активный' : 'Приглашенный'}>
      <Typography
        fontSize={'11px'}
        component="span"
        sx={{
          backgroundColor: color,
          // color: theme => theme.palette.getContrastText(color),
          color: theme => theme.palette.getContrastText(color),
          padding: '2px 5px',
          lineHeight: '24px',
          borderRadius: '5px',
          ...sx,
        }}
      >
        ЭДО
      </Typography>
    </Grid>
  );
};
const CompanyExtInfo = observer(({ client, href }: CompanyExtInfoProps): JSX.Element => {
  const rootStore = useRootStore();
  const callStore = rootStore.getCallStore();

  if (!client?.code) {
    return <Skeleton variant="text" width={'100%'} />;
  }
  const itClientisCalling = callStore.callContact?.customerCode === client.code && callStore.showCallDialog;
  return (
    <Box>
      <Typography variant="body2" color="text.secondary" mb={2} gutterBottom>
        ИНН: {client.inn || '-'} &nbsp; ОГРН: {client.companyOgrn || '-'} &nbsp; КПП: {client.companyKpp || '-'}
      </Typography>
      <Grid container mb={0.5} alignItems={'center'}>
        <CustomerStatusIcon client={client} />
        <Typography variant="body1" fontWeight={600} component="h2" display="flex" alignItems={'center'}>
          {href ? (
            <MxLink href={href} color="inherit" fontWeight={600}>
              {client?.shortTitle || client.title}
            </MxLink>
          ) : (
            client?.shortTitle || client.title
          )}
        </Typography>
        {!!client.nonResident && <LabelNonResident sx={{ ml: '8px' }} />}
        {!!client.docFlowCounterAgentState && client.docFlowCounterAgentState !== 'inactive' && (
          <EdoLabel type={client.docFlowCounterAgentState} sx={{ ml: '8px' }} />
        )}
        {!!client?.attentions?.length && (
          <Grid item ml={1}>
            <AttentionBlock attentions={client?.attentions} />
          </Grid>
        )}
        <MxLink
          href={`/adm/rad#/customer/tools/detail/-?filter=${encodeURIComponent(JSON.stringify({ code: client.code }))}`}
          target="_blank"
        >
          <IconButton
            size="small"
            sx={{ ml: '6px', padding: '1px', fontSize: '18px', alignSelf: 'center' }}
            title="Перейти к клиенту на BackOffice"
          >
            <WorkIcon fontSize={'inherit'} />
          </IconButton>
        </MxLink>
        {itClientisCalling && (
          <Box fontSize={'18px'} p={'2px'} ml={'4px'} display={'flex'}>
            <CallIcon color="success" fontSize="inherit" />
          </Box>
        )}
      </Grid>
    </Box>
  );
});

export default CompanyExtInfo;
