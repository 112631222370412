import React, { FC, useCallback, useMemo, useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import { v4 } from 'uuid';
import { TextButton } from 'src/views/deals/components/TextButton';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import { SelectChangeEvent } from '@mui/material/Select';
import { observer } from 'mobx-react-lite';
import { AgreementItemStore } from 'src/store/Clients/AgreementItemStore';
import { useAuthStore, useRootStore } from 'src/store/MobxStoreProvider';
import { DealItemStore } from 'src/store/DealItemStore';
import { ContractCostAgreement, DocumentAgreementApprovalStateCodeEnum, UserProfile } from 'src/api/marketx';
import { getNormalizedDateRange } from '@mx-ui/helpers';
import { DealListStore } from 'src/store/DealListStore';
import AddCardRoundedIcon from '@mui/icons-material/AddCardRounded';
import { DealTabsSkeleton } from '../../views/deals/DealTabs';
import { CollapseButton } from 'src/views/deals/components/CollapseButton';

interface AgreementTabsProps {
  agreementStore: AgreementItemStore;
  dealsStore: DealListStore;
  selectedTab: number;
  onPositionAddDialogClick?: (categoryCode?: string) => void;
  onSelectTab: (index: number) => void;
}
export const agreementIsValidForCreateDeal = (agreement: ContractCostAgreement, profile: UserProfile): boolean => {
  const dateNow = new Date();
  const validFrom = agreement?.validFrom ? new Date(agreement?.validFrom) : new Date();
  const validUntil = agreement?.validUntil ? new Date(agreement?.validUntil) : new Date();
  const validPeriod = getNormalizedDateRange(validFrom, validUntil);
  return (
    (agreement?.approval?.stateCode === DocumentAgreementApprovalStateCodeEnum.Approved ||
      agreement?.approval?.stateCode === DocumentAgreementApprovalStateCodeEnum.SelfApproved) &&
    agreement?.positions?.filter(i => !i.isArchive)?.length > 0 &&
    agreement?.validFrom &&
    agreement?.validUntil &&
    validPeriod.start.getTime() < dateNow.getTime() &&
    validPeriod.end.getTime() > dateNow.getTime() &&
    profile.features?.canDealAgreement &&
    agreement.branchOfficeCode === profile.chosenBranchOfficeCode
  );
};
export const AgreementTabs: FC<AgreementTabsProps> = observer(
  ({ agreementStore, selectedTab, onPositionAddDialogClick, onSelectTab, dealsStore }): JSX.Element => {
    const matches = useMediaQuery('(min-width:600px)');
    const auth = useAuthStore();
    const { profile } = auth;
    const [archiveCount, setArchiveCount] = useState(0);
    const handleAddProductClick = (event): void => {
      event.stopPropagation();
      onPositionAddDialogClick();
    };
    const rootStore = useRootStore();
    const [isCreatingDeal, setIsCreatingDeal] = useState(false);
    const { agreement } = agreementStore;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const isShowButton = useMemo(
      () => agreementIsValidForCreateDeal(agreement, profile),
      [agreement, agreement.approval, agreement.positions, profile]
    );
    const dealFrontCode = useMemo(() => v4(), []);
    const handleCreateDeal = useCallback(() => {
      setIsCreatingDeal(true);
      const dealStore = new DealItemStore(rootStore);
      dealStore
        .createWithAgreementPositions(
          dealFrontCode,
          agreementStore.agreement
          // добавление позиций в заявку после привязки к соглашению
          // agreementStore.agreement?.positions.filter(i => !i.isArchive)
        )
        .then((res): void => {
          const dealCode = res.data?.deal?.code;
          if (!dealCode) {
            throw new Error('dealCode missing');
          }
          rootStore.getRouter().push('/app/deals/' + dealCode);
          setIsCreatingDeal(false);
        })
        .catch(e => {
          setIsCreatingDeal(false);
          console.error(e);
        });
    }, [rootStore, agreementStore, dealFrontCode]);

    useEffect(() => {
      if (agreementStore.agreement?.positions) {
        let newCount = 0;
        agreementStore.agreement?.positions.forEach(p => {
          if (p.isArchive) {
            newCount++;
          }
        });
        if (newCount == 0 && selectedTab == 1) {
          onSelectTab(0);
        }

        setArchiveCount(newCount);
      }
    }, [agreementStore, agreementStore.agreement?.positions, onSelectTab, selectedTab]);
    let productsCount = 0;
    const dealsCount = dealsStore.items.length;

    const handleChangeSelect = (event: SelectChangeEvent<typeof selectedTab>): void => {
      onSelectTab(event.target.value as number);
    };

    if (agreementStore.agreement?.positions?.length) {
      productsCount = agreementStore.agreement?.positions?.length - archiveCount;
    }
    const handleCloseAllPositions = useCallback(() => {
      agreementStore.closeAllPositions();
    }, [agreementStore]);
    const isAllClosed = agreementStore.isAllPositionsClosed();

    if (!agreementStore.isLoaded) {
      return <DealTabsSkeleton />;
    }

    return (
      <Grid item mt={4} mb={2} xs={12}>
        <Grid container spacing={1}>
          <Grid item flex={`0 1 ${matches ? 'auto' : '100%'}`}>
            {matches ? (
              <>
                <TextButton onClick={() => onSelectTab(0)} selected={selectedTab == 0}>
                  Товары{productsCount > 0 ? ` (${productsCount})` : ''}
                </TextButton>
                <TextButton onClick={() => onSelectTab(1)} selected={selectedTab == 1}>
                  Архив{archiveCount > 0 ? ` (${archiveCount})` : ''}
                </TextButton>
                <TextButton onClick={() => onSelectTab(2)} selected={selectedTab == 2}>
                  <AddCardRoundedIcon sx={{ marginRight: 0.5 }} />
                  Заявки {dealsCount > 0 ? ` (${dealsCount})` : ''}
                </TextButton>
              </>
            ) : (
              <>
                <Select
                  labelId="label"
                  id="select"
                  value={selectedTab}
                  sx={{ width: '100%', color: 'text.secondary', fontWeight: 500 }}
                  size={'small'}
                  onChange={handleChangeSelect}
                  defaultValue={0}
                >
                  <MenuItem value={0} sx={{ color: 'text.secondary', fontWeight: 500 }}>
                    Товары{productsCount > 0 ? ` (${productsCount})` : ''}
                  </MenuItem>
                  <MenuItem value={1} sx={{ color: 'text.secondary', fontWeight: 500 }}>
                    Архив{archiveCount > 0 ? ` (${archiveCount})` : ''}
                  </MenuItem>
                  <MenuItem value={2} sx={{ color: 'text.secondary', fontWeight: 500 }}>
                    <Grid container alignItems={'center'}>
                      <AddCardRoundedIcon sx={{ marginRight: 1, color: 'text.secondary' }} />
                      Заявки {dealsCount > 0 ? ` (${dealsCount})` : ''}
                    </Grid>
                  </MenuItem>
                </Select>
              </>
            )}
          </Grid>
          <Grid item>
            <Grid container spacing={1}>
              <Grid item>
                {!agreementStore.isViewOnly() && (
                  <Button size="small" variant="contained" color="secondary" onClick={handleAddProductClick}>
                    + Добавить товар
                  </Button>
                )}
              </Grid>
              <Grid item>
                {isShowButton && (
                  <Box component="span">
                    <Button variant={'outlined'} size="small" disabled={isCreatingDeal} onClick={handleCreateDeal} color={'secondary'}>
                      Создать заявку по соглашению
                    </Button>
                  </Box>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid position={'absolute'} sx={{ top: '40px', right: '68px' }}>
            {((!!productsCount && selectedTab === 0) || (!!archiveCount && selectedTab === 2)) && (
              <CollapseButton type={isAllClosed ? 'expand-all' : 'less-all'} onClick={handleCloseAllPositions} />
            )}
          </Grid>
        </Grid>
      </Grid>
    );
  }
);
