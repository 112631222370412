import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { formatNumberX } from '@mx-ui/helpers';
import { observer } from 'mobx-react-lite';
interface Price {
  priceBu?: number;
  priceSu?: number;
  rangeCode?: string;
  rangeMinCount?: number;
  rangeMaxCount?: number;
  rangeTitle?: string;
  wovBuCost?: number;
  wovSuCost?: number;
}

interface Unit {
  code?: string;
  name?: string;
  isCurrent?: boolean | null;
  title?: string;
  weight?: number;
}
const isValueNumber = (value: string): string => {
  const re = /^\d+(\.)?(\d+)?$/;
  const resultVal = value.trim().replace(/\s+/g, '').replace(',', '.');
  if (resultVal === '' || re.test(resultVal)) {
    return resultVal;
  }
};
interface ProductConverterProps {
  unit?: Unit;
  initialTonneVal?: string;
  catalogItem?: any;
  handleSaveAmountFromConverter?: (amount: string, unitCode: string) => void;
  isForSave?: boolean;
  pricesSpaces: Array<Price>;
}

const ProductConverter: FC<ProductConverterProps> = observer(
  ({ unit, initialTonneVal, handleSaveAmountFromConverter, isForSave = false, pricesSpaces }) => {
    const ratio = unit.weight;
    const roundingVal = useMemo((): number => {
      const result = String(ratio).split('.');
      if (result.length === 1) {
        return 0;
      } else {
        return result[1].length;
      }
    }, [ratio]);
    const initState = {
      toneVal: initialTonneVal ? formatNumberX(initialTonneVal, roundingVal) : '',
      convertVal: initialTonneVal ? formatNumberX(+initialTonneVal / ratio, roundingVal) : '',
    };
    const [convertValue, setConvertValue] = useState(initState);

    useEffect(() => {
      setConvertValue({
        toneVal: initialTonneVal ? formatNumberX(initialTonneVal, roundingVal) : '',
        convertVal: initialTonneVal ? formatNumberX(+initialTonneVal / ratio, roundingVal) : '',
      });
    }, [initialTonneVal]);

    const handleSaveTonneValue = useCallback((event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
      const val = event.target.value;
      const toneVal = isValueNumber(val);
      if (toneVal === '') {
        setConvertValue({ toneVal: '', convertVal: '' });
      } else if (toneVal) {
        const convertVal = formatNumberX(+toneVal / ratio, roundingVal);
        setConvertValue({ toneVal, convertVal });
      }
    }, []);
    const handleSaveConvertValue = useCallback((event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
      const val = event.target.value;
      const convertVal = isValueNumber(val);
      if (convertVal === '') {
        setConvertValue({ toneVal: '', convertVal: '' });
      } else if (convertVal) {
        const toneVal = formatNumberX(+convertVal * ratio, roundingVal);
        setConvertValue({ toneVal, convertVal });
      }
    }, []);
    const tonneCost = useMemo(() => {
      const currentAmount = Number(convertValue.toneVal.replace(/\s+/g, ''));
      let resultCost = '';
      if (currentAmount < 0.001) {
        resultCost = '---';
        return resultCost;
      }
      if (currentAmount < 1) {
        resultCost = `${pricesSpaces[0]?.priceBu ?? '---'}`;
      }
      if (currentAmount >= 1 && currentAmount < 6) {
        resultCost = `${pricesSpaces[1]?.priceBu ?? '---'}`;
      }
      if (currentAmount >= 6 && currentAmount < 20) {
        resultCost = `${pricesSpaces[2]?.priceBu ?? '---'}`;
      }
      if (currentAmount >= 20) {
        resultCost = `${pricesSpaces[3]?.priceBu ?? '---'}`;
      }
      return formatNumberX(`${resultCost}`);
    }, [convertValue.toneVal, pricesSpaces, pricesSpaces.length]);

    const convertCost = useMemo(() => {
      if (tonneCost === '---') {
        return '---';
      }
      const currentAmount = Number(tonneCost.replace(/\s+/g, ''));
      return formatNumberX(`${currentAmount * ratio}`);
    }, [tonneCost]);

    const covertUnitName = unit?.name || unit?.title;

    if (unit.code === 'TNE') return null;

    return (
      <Grid
        item
        container
        flexWrap="nowrap"
        justifyContent="space-between"
        mb={2}
        onClick={e => {
          e.stopPropagation();
        }}
      >
        <Grid item flex="0 0 35%" container flexDirection="column" flexWrap="nowrap">
          <Grid item container flex="1 1 auto">
            <TextField
              autoComplete="off"
              variant="standard"
              value={convertValue.toneVal}
              onChange={handleSaveTonneValue}
              size="small"
              label="т"
              sx={{ padding: '0px' }}
              InputLabelProps={{ style: { fontSize: 15 } }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {!!convertValue.toneVal && isForSave && (
                      <IconButton
                        title="Сохранить количество в т"
                        color="inherit"
                        aria-label="unit converter"
                        sx={{ padding: '6px', marginRight: '-9px' }}
                        onClick={() => handleSaveAmountFromConverter(convertValue.toneVal, 'TNE')}
                      >
                        <ContentCopyIcon fontSize={'small'} />
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item container flex="0 0 auto" sx={{ fontSize: '13px' }}>
            {tonneCost} ₽/т
          </Grid>
        </Grid>
        <Grid item flex="0 0 10%" container flexDirection="column" flexWrap="nowrap" alignSelf="center">
          <Grid item flex="1 1 auto">
            <SwapHorizIcon sx={{ color: 'secondary.light' }} />
          </Grid>
        </Grid>
        <Grid item flex="0 0 35%" container flexDirection="column" flexWrap="nowrap">
          <Grid item flex="1 1 auto">
            <TextField
              autoComplete="off"
              // label={`${covertUnitName}`}
              label={`${ratio} т/${unit.name || unit.title}`}
              variant="standard"
              value={convertValue.convertVal}
              onChange={handleSaveConvertValue}
              size="small"
              sx={{ padding: '0px' }}
              InputLabelProps={{ style: { fontSize: 15 } }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {!!convertValue.convertVal && isForSave && (
                      <IconButton
                        title={`Сохранить количество в ${covertUnitName}`}
                        color="inherit"
                        aria-label="unit converter"
                        sx={{ padding: '6px', marginRight: '-9px' }}
                        onClick={() => handleSaveAmountFromConverter(convertValue.convertVal, unit.code)}
                      >
                        <ContentCopyIcon fontSize={'small'} />
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item container flex="0 0 auto" sx={{ fontSize: '13px' }}>
            {`${convertCost} ₽/${covertUnitName}`}
          </Grid>
        </Grid>
      </Grid>
    );
  }
);

export default ProductConverter;
