import React, { FC, useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { useDropzone } from 'react-dropzone';
import { MIMEDocument, MIMEImage, MIMEText } from 'src/utils/acceptedFilesType';
type AcceptType = 'image' | 'text' | 'document';
interface UploadFileProps {
  upload?: (files: File[]) => void;
  maxFiles?: number;
  disabled?: boolean;
  accept?: AcceptType[];
  uploadText?: string;
}
const StyledDiv = styled('div')({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: '2px',
  borderRadius: '2px',
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: 'transparent',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
});
const typesMIME = {
  image: MIMEImage,
  text: MIMEText,
  document: MIMEDocument,
};
export const UploadFile: FC<UploadFileProps> = ({
  upload,
  maxFiles = 0,
  accept = ['image', 'text', 'document'],
  disabled = false,
  uploadText,
}): JSX.Element => {
  const [files, setFiles] = useState([]);
  const acceptValue = accept.map(i => typesMIME[i]).flat();
  const { getRootProps, getInputProps } = useDropzone({
    accept: acceptValue.reduce((acc, i) => {
      acc[i] = [];
      return acc;
    }, {}),
    multiple: true,
    disabled,
    maxSize: 20 * 1024 * 1024, // 20mbm

    maxFiles,
    onDrop: acceptedFiles => {
      setFiles(
        acceptedFiles.map(file =>
          Object.assign(file, {
            url: file.type.indexOf('image') !== -1 ? URL.createObjectURL(file) : undefined,
          })
        )
      );
      upload(acceptedFiles);
    },
  });
  useEffect(
    () => () => {
      files.forEach(file => URL.revokeObjectURL(file.url));
    },
    [files]
  );

  return (
    <div className="container">
      <StyledDiv {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} />
        <Typography gutterBottom variant="body2">
          {uploadText || `Перетащите сюда несколько файлов или щелкните, чтобы выбрать файлы`}
        </Typography>
      </StyledDiv>
    </div>
  );
};
